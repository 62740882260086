import { EyeOutlined, UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Image,
  Card,
  Tag,
  Form,
  Upload,
  Input,
  Menu,
  Space,
  Typography,
  Switch,
  Dropdown,
  Pagination,
  Slider,
} from "antd";
import moment from "moment";
import {
  HomeOutlined,
  BathroomOutlined,
  AreaChartOutlined,
  ShopOutlined,
  LinkOutlined,
  ClockCircleOutlined,
  UnorderedListOutlined,
  IdcardOutlined,
  UploadOutlined,
  WhatsAppOutlined,
  PlusOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  CloseOutlined,
  StarOutlined,
  DownOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EditIcon from "../../assets/images/edit.png";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import Plus from "../../assets/images/plus.svg";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import ViewLogs from "../../components/ViewLogs";
import apiPath from "../../constants/apiPath";
import sampleFile from "../../assets/images/sampleAsset.xlsx";
import { DownloadExcel } from "../../components/ExcelFile";
import * as XLSX from "xlsx";
import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../constants/reasons";
import { Months } from "../../constants/var";
import { useNavigate } from "react-router";

import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import UploadPDF from "./_UploadPDF";
import { ApproveStatus } from "../Driver/_ChangeRequest";
import CreateAsset from "./AddForm";
import notfound from "../../assets/images/not_found.png";
import { useParams } from "react-router";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import BathroomOutlinedIcon from "@mui/icons-material/BathroomOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import AssestmainImg from "../../assets/images/my-ass-img.png";
import PhoneInput from "react-phone-input-2";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { AuthContext } from "../../context/AuthContext";
import HomeImage from "../../assets/images/home.jpeg";
// import ChangeModal from "./_ChangeModal";
// import View from "./View"
const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Title, Text } = Typography;
const rooms = ["1", "2", "3", "4", "5", "6"];
const filterst = [
  {
    text: lang("Rejected"),
    value: "rejected",
  },
  {
    text: lang("Sold"),
    value: "sold",
  },
  {
    text: lang("Active"),
    value: "active",
  },
];
const filters = [
  {
    text: lang("Sale"),
    value: "buy",
  },
  {
    text: lang("Rent"),
    value: "rent",
  },
];

const filterpt = [
  {
    text: lang("Apartment"),
    value: "דירה",
  },
  {
    text: lang("House / Cottage"),
    value: "קוטג'",
  },
  {
    text: lang("Garden Apartment"),
    value: "דירת גן",
  },
  {
    text: lang("Townhouse"),
    value: "דו משפחתי",
  },
  {
    text: lang("Rooftop / Penthouse"),
    value: "גג/ פנטהאוז",
  },
  {
    text: lang("Penthouse"),
    value: "פנטהאוז",
  },
  {
    text: lang("Mini Penthouse"),
    value: "מיני פנטהאוז",
  },
  {
    text: lang("Rooftop Apartment"),
    value: "דירת גג",
  },
  {
    text: lang("Studio / Loft"),
    value: "סטודיו/ לופט",
  },
  {
    text: lang("Duplex"),
    value: "דופלקס",
  },
  {
    text: lang("Triplex"),
    value: "טריפלקס",
  },
  {
    text: lang("Apartment Unit"),
    value: "יחידת דיור",
  },
  {
    text: lang("Plots"),
    value: "מגרשים",
  },
  {
    text: lang("Farmhouse / Homestead"),
    value: "משק חקלאי/ נחלה",
  },
  {
    text: lang("Building"),
    value: "בניין",
  },
  {
    text: lang("Divided Apartment"),
    value: "דירה מחולקת",
  },
  {
    text: lang("Basement Apartment"),
    value: "דירת מרתף",
  },
  {
    text: lang("Basement / Parter"),
    value: "מרתף/ פרטר",
  },
  {
    text: lang("Residential Building"),
    value: "בניין מגורים",
  },
  {
    text: lang("Vacation Apartment"),
    value: "דירת נופש",
  },
  {
    text: lang("Parking"),
    value: "חניה",
  },
  {
    text: lang("Storage"),
    value: "מחסן",
  },
  {
    text: lang("Other"),
    value: "אחר",
  },
];

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
function Index() {
  const navigate = useNavigate();
  const heading = lang("Asset") + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();

  const sectionName = lang("Asset");
  const routeName = "customer";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const year = urlParams.get("year");

  const api = {
    status: apiPath.asset_statuschange,
    public: apiPath.publicChangeAsset,
    list: apiPath.listAsset,
    update: apiPath.editAsset,
    delete: apiPath.deleteAsset,
    uploadAssetExcel: apiPath.uploadAssetExcel,
    user: apiPath.deleteMultiAsset,
  };

  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [allCount, setAllCount] = useState(0);
  const [publicCount, setPublicCount] = useState(0);
  const [privateCount, setPrivateCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [selected, setSelected] = useState({ recordId: null });
  const [showMessage, setShowMessage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const [deleteAllModal, showDeleteAllModal] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const { logout, userProfile, isAdmin, setIsAdmin } = useContext(AuthContext);
  const { confirm } = Modal;
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState([]);
  const [priceLimit, setPriceLimit] = useState([0, 10000000]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
  });
  const [image, setImage] = useState();

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState();
  const [filterType, setFilterType] = useState(null);
  const [months, setMonths] = useState([]);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [years, setYears] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadDocModal, setUploadDocModal] = useState({ id: "", type: false });
  const [error, setError] = useState();
  const [form] = Form.useForm();
  const [importFile, setImportFile] = useState([]);
  const [file, setFile] = useState([]);
  const fileType = [
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  const [listTrue, setListTrue] = useState(true);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const debouncedSearchFilter = useDebounce(selectedFilter, 300);
  const [isToggled, setIsToggled] = useState(false);
  const [addFiltersVisible, setAddFiltersVisible] = useState(false); // State to manage visibility
  const handleToggleFilters = () => {
    setAddFiltersVisible(!addFiltersVisible); // Toggle visibility
    setIsToggled(!isToggled);
  };
  const onChangeList = (e) => {
    setListTrue(e);
  };
  const showUploadModal = () => {
    setUploadModal(true);
  };
  const handleCancel01 = () => {
    setUploadModal(false);
  };

  const showUploadDocModal = ({ record, type }) => {
    setUploadDocModal({ record, type });
    setImage();
  };
  const handleCancelDoc01 = () => {
    setUploadDocModal({ record: "", type: false });
    setImage();
  };
  const handleImport = (info) => {
    const { file, fileList } = info;
    console.log("-----------------:: ", info);
    if (fileList?.length) {
      if (!fileType.includes(file.type)) {
        setError("File format is not correct");
        return false;
      }
      setError();

      const fileData = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        uid: file.uid,
        status: "done",
        originFileObj: file.originFileObj,
        size: file.size,
        type: file.type,
        percent: file.percent,
      };

      setFile([fileData]);
      parseExcel(file.originFileObj);
    } else {
      setError("Missing Excel File.");
    }
  };

  const excelData =
    list &&
    list.length > 0 &&
    list.map((row, index) => ({
      title: row.property_title ? row.property_title : "-",
      amenities: Array.isArray(row.amenities) ? row.amenities.join(", ") : "-",
      city: row.location ? row.location.city : "-",
      neighborhood: row.location ? row.location.neighborhood : "-",
      rooms_number: row.rooms_number ? row.rooms_number : "-",
      floor_number: row.floor_number ? row.floor_number : "-",
      floors_in_building: row.floors_in_building ? row.floors_in_building : "-",
      price_per_square_meter: row.price_per_square_meter
        ? row.price_per_square_meter
        : "-",
      total_price: row.total_price ? row.total_price : "-",
      property_description: row.property_description
        ? row.property_description
        : "-",
    }));

  const onImportDoc = (record) => {
    console.log(image, record);
    setLoading(true);
    console.log("Selected status Type: ", image, record);
    request({
      url: api.update + "/" + record,
      method: "PUT",
      data: {
        signed_doc: image,
      },
      onSuccess: (data) => {
        setLoading(false);
        ShowToast("Asset Document Updated successfully", Severty.SUCCESS);
        setRefresh((prev) => !prev);
        showUploadDocModal(record, false);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onImport = () => {
    if (error) {
      return;
    }
    if (file?.length) {
      setLoading(true);
      const payload = {};
      payload.import_file = importFile;
      request({
        url: api.uploadAssetExcel,
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            // hide();
            fetchData({ ...pagination, current: 1 });
            setUploadModal(false);
            // refresh();
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error.response.data.errors[0].message, Severty.ERROR);
          setLoading(false);
        },
      });
    } else {
      setError("Please Select an Ecxel File.");
    }
  };

  const parseExcel = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Parse the worksheet data into an array of objects
      const parsedData = XLSX.utils.sheet_to_json(worksheet);

      // Filter data based on some conditions like below
      const filteredData = parsedData.filter((row) => {
        // const email = row.email;
        // const country_code = row.countryCode;
        // const mobile_number = row.mobileNumber;
        // const name = row.name;

        const shouldExclude = () => {
          let exclude = false;

          // Check conditions for name (case-insensitive)
          // if (
          //   name &&
          //   [
          //     "Brand",
          //     "Category",
          //     "Product",
          //     "Service",
          //     "Vehicle Make",
          //     "Vehicle Model",
          //     "Vehicle Type",
          //     "Vehicle Variant",
          //   ]?.includes(sectionName)
          // ) {
          //   const isDuplicateName = existingData?.some(
          //     (existingRow) =>
          //       existingRow?.name?.toLowerCase()?.trim() ===
          //       name?.toLowerCase()?.trim()
          //   );
          //   exclude = exclude || isDuplicateName;
          // }

          // Check conditions for email
          // if (email) {
          //   const isDuplicateEmail = existingData.some(
          //     (existingRow) =>
          //       existingRow.email.toLowerCase() === email.toLowerCase()
          //   );
          //   exclude = exclude || isDuplicateEmail;
          // }

          // // Check conditions for country_code and mobile_number together
          // if (country_code && mobile_number) {
          //   const isMatchingCountryMobile = existingData.some(
          //     (existingRow) =>
          //       existingRow.country_code === country_code &&
          //       existingRow.mobile_number === mobile_number
          //   );
          //   exclude = exclude || isMatchingCountryMobile;
          // }

          return exclude;
        };

        return !shouldExclude();
      });

      // Extract the remaining rows and set to setImportFile state
      setImportFile(filteredData);

      // Excluded rows and download the excel file with name of duplicate
      const excludedRows = parsedData.filter(
        (row) => !filteredData.includes(row)
      );
      if (excludedRows && excludedRows.length > 0) {
        const name = "Duplicate" + "-" + sectionName;
        // DownloadExcel(excludedRows, name)
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const [selectedFiltersMulti, setSelectedFiltersMulti] = useState({
    size_in_meters: [null, null],
  });
  const debouncedSearchFilterMulti = useDebounce(selectedFiltersMulti, 300);

  useEffect(() => {
    getCity();
  }, []);

  const getCity = () => {
    request({
      url: `/common/city`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data.list, "city");
        setCity(data.list);
      },
    });
  };

  const onDelete = (id) => {
    if (!id) {
      console.error("No record ID provided for deletion");
      return;
    }
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.list + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const handleChangeAsset = (value, id) => {
    setLoading(true);
    console.log("Selected status Type: ", value, id);
    request({
      url: api.status + "/" + value + "/" + id,
      method: "PUT",
      data: {},
      onSuccess: (data) => {
        setLoading(false);
        ShowToast("Asset status changed successfully", Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: lang("Image"),
      dataIndex: "multiImage",
      key: "multiImage",
      render: (_, { image, multiImage }) =>
        image ? (
          <Image width={40} src={image} className="table-img" />
        ) : (
          <Image
            width={40}
            src={multiImage[0] ? multiImage[0] : HomeImage}
            className="table-img"
          />
        ),
    },
    {
      title: lang("City"),
      dataIndex: "location.city",
      key: "location.city",
      width: 200,
      render: (_, { location, _id }) => {
        return location ? (
          <a
            onClick={() => navigate("/view-asset/" + _id)}
          >{`${location.city}`}</a>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Address"),
      dataIndex: "location.neighborhood",
      key: "location.neighborhood",
      width: 200,
      render: (_, { location }) => {
        const city = location.city
          ? location.city === "0"
            ? ""
            : location.city
          : "";

        const neighborhood = location.neighborhood
          ? location.neighborhood === "0"
            ? ""
            : location.neighborhood
          : "";
        const street = location.street
          ? location.street === "0"
            ? ""
            : location.street
          : "";
        const street_number = location.street_number
          ? location.street_number === "0"
            ? ""
            : location.street_number
          : "";

        return `${city}${" "}${neighborhood}${" "}${street}${" "}${street_number}`;
      },
    },
    {
      title: lang("Phone"),
      type: "phone",
      key: "mobileNumber",
      render: (_, { countryCode, mobile_number }) => (
        <a
          href={`tel:${mobile_number ? mobile_number : ''}`}
          target="_blank"
          className="wishlisted wishlisted-new"
        >
          {mobile_number ? mobile_number : '--'}
        </a>
      )
    },


    {
      title: lang("Advertiser Name"),
      dataIndex: "advertiser_name",
      key: "advertiser_name",
      width: 200,
      render: (_, { advertiser_name }) => {
        return advertiser_name ? advertiser_name : "-";
      },
    },

    {
      title: lang("Price"),
      dataIndex: "total_price",
      key: "total_price",
      width: 200,
      render: (_, { total_price }) => {
        return total_price ? new Intl.NumberFormat().format(total_price) : "-";
      },
    },
    {
      title: lang("Status"),
      key: "asset_status",
      // filters: [
      //     {
      //         text: "Active",
      //         value: "active",
      //     },
      //     {
      //         text: "Sold",
      //         value: "sold",
      //     },
      //     {
      //         text: "Rejected",
      //         value: "rejected",
      //     },
      // ],
      render: (_, { asset_status, _id }) => {
        let color;
        let statusText;
        let matchText;

        switch (asset_status) {
          case "active":
            color = "green";
            statusText = lang("Active");
            matchText = "active";
            break;
          case "sold":
            color = "red";
            statusText = lang("Sold");
            matchText = "sold";
            break;
          case "rejected":
            color = "orange";
            statusText = lang("Rejected");
            matchText = "rejected";
            break;
          default:
            color = "gray";
            statusText = lang("Unknown");
            matchText = "";
        }
        return (
          <>
            <Select
              style={{ width: "100%" }}
              placeholder={lang(`Select Asset Status`)}
              value={matchText}
              onChange={(value) => handleChangeAsset(value, _id)}
              className="status-main-sold"
            >
              <Select.Option value="active">{lang("Active")}</Select.Option>
              <Select.Option value="sold">{lang("Sold")}</Select.Option>
              <Select.Option value="rejected">{lang("Rejected")}</Select.Option>
            </Select>
          </>
        );
      },
    },

    {
      title: lang("Rent/Sale"),
      dataIndex: "to_let",
      key: "to_let",
      width: 200,
      render: (_, { to_let }) => {
        return to_let === "rent" ? lang('Rent') : to_let === "commercial_buy" ? lang('commercial_buy') : to_let === "commercial_rent" ? lang('commercial_rent') : lang('Sale');
      },
    },

    {
      title: lang("Size (Meter.)"),
      dataIndex: "size_in_meters",
      key: "size_in_meters",
      width: 200,
      render: (_, { size_in_meters }) => {
        return size_in_meters
          ? new Intl.NumberFormat().format(size_in_meters)
          : "-";
      },
    },
    {
      title: lang("Rooms"),
      dataIndex: "rooms_number",
      key: "rooms_number",
      width: 200,
      render: (_, { rooms_number }) => {
        return rooms_number ? rooms_number : "-";
      },
    },
    {
      title: lang("Property Type"),
      dataIndex: "property_type",
      key: "property_type",
      width: 200,
      render: (_, { property_type }) => {
        // const propertyTypeMapping = {

        //   דירה:"Apartment",
        //   בית פרטי/ קוטג:"House / Cottage",
        //   דירת גן:"Garden Apartment",
        //   דו משפחתי:"Town House",
        //   גג/ פנטהאוז:"Rooftop / Penthouse",
        //   פנטהאוז:"Penthouse",
        //   מיני פנטהאוז:"Mini Penthouse",
        //   דירת גג:"Rooftop Apartment",
        //   סטודיו/ לופט:"Studio / Loft",
        //   דופלקס:"Duplex",
        //   טריפלקס:"Triplex",
        //   יחידת דיור:"Apartment Unit",
        //   מגרשים:"Plots",
        //   משק חקלאי/ נחלה:"Farmhouse / Homestead",
        //   בניין:"Building",
        //   דירה מחולקת:"Divided Apartment",
        //   דירת מרתף:"Basement Apartment",
        //   מרתף/ פרטר:"Basement / Parter",
        //   בניין מגורים:"Residential Building",
        //   דירת נופש:"Vacation Apartment",
        //   חניה:"Parking",
        //   מחסן:"Storage",
        //   אחר:"Other",

        //   single_family: "Single Family",
        //   multi_family: "Multi-family",
        //   condominium: "Condominium",
        //   townhouse: "Townhouse",
        //   apartment: "Apartment",
        //   commercial: "Commercial",
        //   industrial: "Industrial",
        //   land: "Land",
        //   mixed_use: "Mixed Use",
        //   vacation_home: "Vacation Home",
        // };

        return property_type ? property_type : "-";
      },
    },
    {
      title: lang("Property"),
      dataIndex: "property_title",
      key: "property_title",
      width: 200,
      render: (_, { property_title }) => {
        return property_title ? property_title : "-";
      },
    },

    {
      title: lang("Public"),
      key: "make_public",
      // filters: [
      //     {
      //         text: "Public",
      //         value: true,
      //     },
      //     {
      //         text: "make public",
      //         value: false,
      //     },
      // ],
      render: (_, { make_public, _id }) => {
        let color;
        let statusText;

        switch (make_public) {
          case true:
            color = "green";
            statusText = lang("Public");
            break;
          case false:
            color = "red";
            statusText = lang("Private");
            break;
          default:
            color = "gray";
            statusText = lang("Unknown");
        }
        return (
          <a>
            <Tag
              onClick={(e) =>
                showConfirm({
                  record: _id,
                  path: api.public,
                  onLoading: () => setLoading(true),
                  onSuccess: () => setRefresh((prev) => !prev),
                  type: statusText === "Public" ? false : true,
                })
              }
              color={color}
              key={make_public}
            >
              {statusText}
            </Tag>
          </a>
        );
      },
    },
    // {
    //   title: lang("Owner"),
    //   key: "owner",
    //   dataIndex: "owner",
    //   render: (_, { client }) => {
    //     return client ? client[0]?.name : "-";
    //   },
    // },
    // {
    //   title: lang("Address"),
    //   key: "location",
    //   dataIndex: "location",
    //   render: (_, { location }) => {
    //     return location ? location.city : "-";
    //   },
    // },
    {
      title: lang("Action"),
      key: "_id",
      dataIndex: "_id",
      render: (_, { make_public, _id, mobile_number, signed_doc }) => {
        return (
          <div className="d-flex justify-content-start">
            <Tooltip
              title={lang("Whatsapp")}
              color={"purple"}
              key={"whatapp" + _id}
            >
              <a
                href={`https://api.whatsapp.com/send/?text=https://crm.brokerland.co.il/AssetShare/${userProfile.name}/${_id}`}
                className="whatsapp-cls"
                target="_blank"
              >
                <WhatsAppIcon />
              </a>
            </Tooltip>
            <Tooltip title={lang("Edit")} color={"purple"} key={"update" + _id}>
              <Button
                title="Edit"
                className="edit-cls btnStyle primary_btn"
                onClick={(e) => {
                  navigate(`/edit-asset/${_id}`);
                  setSelected({ recordId: _id });
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <EditOutlinedIcon />
                {/* <span>{lang("Edit")}</span> */}
              </Button>
            </Tooltip>

            <Tooltip title={"View"} color={"purple"} key={"View"}>
              <Button
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  navigate(`/view-asset/${_id}`);
                  setSelected({ recordId: _id });
                  setDetailsVisible(true);
                }}
              >
                <RemoveRedEyeOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title={"Delete"} color={"purple"} key={"Delete"}>
              <Button
                title="Delete"
                className="btnStyle deleteDangerbtn"
                onClick={() => {
                  setSelected({ recordId: _id });
                  setShowDelete(true);
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </Tooltip>

            {/* {signed_doc && signed_doc !== "" ? (
              <Tooltip title={"Signed Document"} key={"Signed Document"}>
                <a
                  href={signed_doc}
                  target="_blank"
                  title="Preview"
                  className="man-reset-bttn man-list-bttn "
                >
                  <FileDownloadOutlinedIcon />
                  Review Document
                </a>
              </Tooltip>
            ) : (
              <Tooltip title={"Signed Document"} key={"Signed Document"}>
                <Button
                  title="Upload"
                  className="man-reset-bttn man-list-bttn "
                  onClick={(e) =>
                    showUploadDocModal({
                      record: _id,
                      type: true,
                    })
                  }
                >
                  <FileUploadOutlinedIcon />
                  Upload Document
                </Button>
              </Tooltip>
            )} */}

            {!make_public && (
              <Tooltip title={lang("Publish")} key={"Publish"}>
                <Button
                  title={lang("Publish")}
                  type="default"
                  shape="round"
                  className="report-btn"
                  onClick={(e) =>
                    showConfirm({
                      record: _id,
                      path: api.public,
                      onLoading: () => setLoading(true),
                      onSuccess: () => setRefresh((prev) => !prev),
                      type: true,
                    })
                  }
                >
                  {lang("Publish")}
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
    fetchData(
      {
        ...pagination,
        current: page,
      },
      filter
    );
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [
    refresh,
    debouncedSearchText,
    startDate,
    endDate,
    selectedTab,
    debouncedSearchFilter,
    debouncedSearchFilterMulti,
  ]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const components = {
    body: {
      row: EditableRow,
    },
  };

  const DeleteAll = () => {
    if (!hasSelected) return;
    request({
      url: api.user + "/delete-all",
      method: "POST",
      data: {
        ids: selectedRowKeys,
      },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast("Data Deleted Successfully", Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk01 = () => {
    setUploadModal(false);
  };

  const fetchData = (pagination, filters) => {
    // const filterActive = filters ? filters.asset_status : null;
    // console.log(filters);
    request({
      url:
        api.list +
        `?page=${pagination ? pagination.current : 1}&pageSize=${pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${path ? `&status=1` : ""}&start_date=${startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}&&broker_id=true&make_public=${selectedTab ? selectedTab : ""
        }&debouncedSearchFilter=${JSON.stringify(debouncedSearchFilter)}${debouncedSearchFilterMulti
          ? `&selectedFiltersMulti=${JSON.stringify(
            debouncedSearchFilterMulti
          )}`
          : ""
        }`,
      method: "GET",
      onSuccess: ({
        data,
        allCount,
        publicCount,
        privateCount,
        status,
        total,
        message,
      }) => {
        setLoading(false);
        if (status) {
          console.log(data, allCount);
          const list = data.map((item) => ({ ...item, key: item._id }));
          setList(list);
          setAllCount(allCount);
          setPublicCount(publicCount);
          setPrivateCount(privateCount);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleMeClick = (e) => {
    if (!selectedFilters.includes(e.key)) {
      const newSelectedFilters = [...selectedFilters, e.key];
      setSelectedFilters(newSelectedFilters);
      setAddFiltersVisible(true);
      setSelectedFilter((prevSelectedFilter) => ({
        ...prevSelectedFilter,
        [e.key]: null,
      }));
    }
  };

  const handleClose = (removedFilter) => {
    const newFilters = selectedFilters.filter(
      (filter) => filter !== removedFilter
    );
    setSelectedFilters(newFilters);

    setSelectedFilter((prevSelectedFilter) => {
      const { [removedFilter]: _, ...rest } = prevSelectedFilter;
      return rest;
    });
    setSelectedFiltersMulti((prevSelectedFilterMulti) => {
      const { [removedFilter]: _, ...rest } = prevSelectedFilterMulti;
      return rest;
    });
  };

  const handleSetVal = (key, value) => {
    setSelectedFilter((prevSelectedFilter) => ({
      ...prevSelectedFilter,
      [key]: value,
    }));
    setAddFiltersVisible(true);
    // fetchData(pagination, debouncedSearchFilter);
  };
  const handleSetMultipleSize = (key, value, index) => {
    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };

      newSelectedFilters[key] = [...(prevSelectedFilters[key] || [])];
      newSelectedFilters[key][index] = value;

      console.log(newSelectedFilters);

      return newSelectedFilters;
    });
  };
  const setPriceLimitFilter = (value) => {
    console.log(value);
    setPriceLimit(value);
    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };

      newSelectedFilters["total_price"] = [
        ...(prevSelectedFilters["total_price"] || []),
      ];
      newSelectedFilters["total_price"] = value;

      console.log(newSelectedFilters);

      return newSelectedFilters;
    });
  };

  const changableFeilds = (value, language = 'en') => {
    switch (value) {
      case "asset_status":
        return lang("Status");
      case "property_type":
        return lang("Type");
      case "city":
        return lang("City");
      case "rooms_number":
        return lang("Rooms");
      case "total_price":
        return lang("Price");
      case "size_in_meters":
        return lang("Size");
      case "to_let":
        return lang("Rent/Sale");
      default:
        return value;
    }
  };

  // const menuItems = [
  //   { text: "Status", key: "asset_status", label: "Status" },
  //   { text: "Rent/Sale", key: "to_let", label: "Rent/Sale" },
  //   { text: "Type", key: "property_type", label: "Type" },
  //   { text: "City", key: "city", label: "City" },
  //   { text: "Rooms", key: "rooms_number", label: "Rooms" },
  //   { text: "Price", key: "total_price", label: "Price" },
  //   { text: "Size", key: "size_in_meters", label: "Size" },

  //   // { text: "Name", key: "name", label: "Name" },
  //   // { text: "Email", key: "email", label: "Email" },
  //   // { text: "Client/Lead", key: "client_lead", label: "Client/Lead" },
  //   // { text: "Client Type", key: "clientType", label: "Client Type" },
  //   // { text: "Status", key: "status", label: "Status" },
  //   // { text: "Asset Type", key: "assetType", label: "Asset Type" },
  //   // { text: "Property Type", key: "propertyType", label: "Property Type" },
  //   // { text: "Source Name", key: "SourceName", label: "Source Name" },
  //   // {
  //   //   text: "Property Living Type",
  //   //   key: "propertylivType",
  //   //   label: "Property Living Type",
  //   // },
  // ];

  const menuItems = [
    { text: lang("Status"), key: "asset_status", label: lang("Status") },
    { text: lang("Rent/Sale"), key: "to_let", label: lang("Rent/Sale") },
    { text: lang("Type"), key: "property_type", label: lang("Type") },
    { text: lang("City"), key: "city", label: lang("City") },
    { text: lang("Rooms"), key: "rooms_number", label: lang("Rooms") },
    { text: lang("Price"), key: "total_price", label: lang("Price") },
    { text: lang("Size"), key: "size_in_meters", label: lang("Size") },

    // Uncomment and add more items as needed
    // { text: lang("Name"), key: "name", label: lang("Name") },
    // { text: lang("Email"), key: "email", label: lang("Email") },
    // { text: lang("Client/Lead"), key: "client_lead", label: lang("Client/Lead") },
    // { text: lang("Client Type"), key: "clientType", label: lang("Client Type") },
    // { text: lang("Status"), key: "status", label: lang("Status") },
    // { text: lang("Asset Type"), key: "assetType", label: lang("Asset Type") },
    // { text: lang("Property Type"), key: "propertyType", label: lang("Property Type") },
    // { text: lang("Source Name"), key: "SourceName", label: lang("Source Name") },
    // {
    //   text: lang("Property Living Type"),
    //   key: "propertylivType",
    //   label: lang("Property Living Type"),
    // },
  ];
  const menu = (
    <Menu onClick={handleMeClick}>
      {menuItems.map((item) => (
        <Menu.Item text={item.text} key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu02 = (key) => {
    if (key === "asset_status") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="asset_status"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filterst.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }
    if (key === "to_let") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="to_let"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filters.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }
    if (key === "property_type") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="property_type"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filterpt.map((option) => ({
              label: option.text + "(" + option.value + ")",
              value: option.value,
            }))}
          />
        </div>
      );
    }

    if (key === "city") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            showSearch
            key="city"
            onChange={(e) => handleSetVal(key, e)}
            options={city.map((option) => ({
              label: option,
              value: option,
            }))}
          />
        </div>
      );
    }

    if (key === "rooms_number") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            showSearch
            key="rooms_number"
            onChange={(e) => handleSetVal(key, e)}
            options={rooms.map((option) => ({
              label: option,
              value: option,
            }))}
          />
        </div>
      );
    }

    if (key === "size_in_meters") {
      return (
        <div className="drop-mask">
          <div className="two-columns">
            <Input
              id="minSize"
              name="size_in_meters"
              placeholder="minimum"
              onChange={(e) =>
                handleSetMultipleSize("size_in_meters", e.target.value, 0)
              }
            />
            <Input
              id="maxSize"
              name="size_in_meters"
              placeholder="maximum"
              onChange={(e) =>
                handleSetMultipleSize("size_in_meters", e.target.value, 1)
              }
            />
          </div>
        </div>
      );
    }

    if (key === "total_price") {
      return (
        <div className="drop-mask">
          <div className="price-range">
            <Slider
              className="slaider-row"
              min={1}
              max={10000000}
              range={{
                draggableTrack: true,
              }}
              tooltip={{ open: false }}
              value={priceLimit}
              onChange={setPriceLimitFilter} // use onChange instead of onchange
            />
            <div className="count-box">
              <div className="count-detail">
                <span>
                  {priceLimit
                    ? new Intl.NumberFormat().format(priceLimit[0])
                    : "0"}
                  {"  "}₪
                </span>
                <span>
                  {priceLimit
                    ? new Intl.NumberFormat().format(priceLimit[1])
                    : "100,000"}
                  {"  "}₪
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (key === "email" || key === "name") {
      return (
        <div className="drop-mask">
          <div className="menu-items-nav">
            <Input
              placeholder={changableFeilds(key)}
              value={selectedFilter ? selectedFilter[key] : ""}
              onChange={(e) => handleSetVal(key, e.target.value)}
            />
          </div>
        </div>
      );
    }
    // if (key === "sourceName") {
    //   return (
    //     <Select
    //       placeholder={lang("Please select")}
    //       key="optionType"
    //       onChange={(selectedOption) => handleSetVal(key, selectedOption)}
    //       options={filtersn.map((option) => ({
    //         label: option.text,
    //         value: option.value,
    //       }))}
    //     />
    //   );
    // }
    // if (key === "clientType") {
    //   return (
    //     <Select
    //       placeholder={lang("Please select")}
    //       key="optionType"
    //       onChange={(selectedOption) => handleSetVal(key, selectedOption)}
    //       options={options.map((option) => ({
    //         label: option.text,
    //         value: option.value,
    //       }))}
    //     />
    //   );
    // }

    // if (key === "client_lead") {
    //   return (
    //     <Select
    //       placeholder={lang("Please select")}
    //       key="client_lead"
    //       onChange={(selectedOption) => handleSetVal(key, selectedOption)}
    //       options={filtercl.map((option) => ({
    //         label: option.text,
    //         value: option.value,
    //       }))}
    //     />
    //   );
    // }

    // if (key === "propertylivType") {
    //   return (
    //     <Select
    //       placeholder={lang("Please select")}
    //       key="propertylivType"
    //       onChange={(selectedOption) => handleSetVal(key, selectedOption)}
    //       options={filterplt.map((option) => ({
    //         label: option.text,
    //         value: option.value,
    //       }))}
    //     />
    //   );
    // }

    // if (key === "status") {
    //   return (
    //     <Select
    //       placeholder={lang("Please select")}
    //       key="status"
    //       onChange={(selectedOption) => handleSetVal(key, selectedOption)}
    //       options={filterOP.map((option) => ({
    //         label: option.text,
    //         value: option.value,
    //       }))}
    //     />
    //   );
    // }
    return null;
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <div className="main-hh-headding">
          <div className="headding-cc">{lang("My Asset")}</div>
          <Breadcrumb className="bread-menu">
            <Breadcrumb.Item className="bread-items" href="#">
              {lang("Dashboard")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="bread-items" href="#">
              {lang("My Asset")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="im-ex-btn">
          <Button
            title={lang("Import")}
            className="man-reset-bttn man-list-bttn"
            onClick={showUploadModal}
          >
            <FileDownloadOutlinedIcon />
            {lang("Import")}
          </Button>

          <Button
            title={lang("Export")}
            className="man-reset-bttn man-list-bttn"
            onClick={(e) => DownloadExcel(excelData, sectionName)}
          >
            <FileUploadOutlinedIcon />
            {lang("Export")}
          </Button>
        </div>
      </Row>

      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false}>
              <Col xs={24} xl={24}>
                <div className="w-100 d-flex align-items-baseline text-head_right_cont">
                  <div className="pageHeadingSearch clint-input ">
                    <Input.Search
                      className="searchInput"
                      placeholder={lang(
                        "Search by City,Neighborhood,Property Title"
                      )}
                      value={searchText}
                      onChange={onSearch}
                      allowClear
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setFilter({
                        country_id: undefined,
                        city_id: undefined,
                        year: undefined,
                        month: undefined,
                      });
                      setSearchText("");
                    }}
                    type="primary"
                    icon={<RestartAltOutlinedIcon />}
                    className="man-reset-bttn and-assest-btn edit-cls"
                  >
                    {/* {lang("Reset")} */}
                  </Button>

                  <div className="lead-clint-drop">
                    <Switch
                      checkedChildren={lang("Card")}
                      unCheckedChildren={lang("List")}
                      checked={listTrue}
                      onClick={(listTrue) => onChangeList(listTrue)}
                      className="main-mecu-switch"
                    />
                  </div>

                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                  // onClick={handleToggleFilters}
                  >
                    <Button className="report-btn">
                      <AddCircleOutlineOutlinedIcon />
                      {lang("Filter")}
                    </Button>
                  </Dropdown>
                  <div className="lg-adddesktop">
                    <Button
                      className="primary_btn btnStyle"
                      onClick={(e) => {
                        navigate(`/create-asset`);
                        setVisible(true);
                        setSearchText("");
                      }}
                    >
                      <span className="add-Ic">
                        <AddCircleOutlineOutlinedIcon />
                      </span>
                      {lang("Add")} {sectionName}
                    </Button>
                  </div>
                  {hasSelected && (
                    <>
                      <Button
                        className="man-list-bttn edit-cls"
                        onClick={() => {
                          showDeleteAllModal(true);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon />
                        {/* {lang("Delete All")} */}
                      </Button>

                      <Button className="man-list-bttn edit-cls">
                        <a
                          href={`https://api.whatsapp.com/send/?text=https://crm.brokerland.co.il/AssetShare/${userProfile.name}/${selectedRowKeys}`}
                          className="whatsapp-cls"
                          target="_blank"
                        >
                          <WhatsAppIcon />
                        </a>
                      </Button>
                    </>
                  )}
                </div>
              </Col>
              <Col xs={24} xl={24}>
                <div className="mb-add-mobile">
                  <Button
                    className="add-inc-btn"
                    onClick={(e) => {
                      navigate(`/create-asset`);
                      setVisible(true);
                      setSearchText("");
                    }}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </Button>
                </div>
                <div>
                  <div
                    className={`add-filters ${addFiltersVisible ? "show" : "hide"
                      }`}
                  >
                    <Space className="flex-wrap">
                      {selectedFilters.map((filter) => (
                        <Tag
                          className="ftter-main-btn"
                          key={filter}
                          closable
                          closeIcon={<CloseOutlined />}
                          onClose={() => handleClose(filter)}
                        >
                          {filter && (
                            <>
                              <Dropdown
                                overlay={menu02(filter)}
                                trigger={["click"]}
                              >
                                <Button className="filter-btns">
                                  {selectedFilter[filter]
                                    ? changableFeilds(filter) +
                                    " : " +
                                    selectedFilter[filter]
                                    : changableFeilds(filter)}
                                </Button>
                              </Dropdown>
                              {/* <Button className="filter-btns">{filter}</Button> */}
                            </>
                          )}
                        </Tag>
                      ))}
                      {/* <Dropdown overlay={menu} trigger={["click"]}>
                    <Button className="man-reset-bttn man-list-bttn ">
                      <AddCircleOutlineOutlinedIcon />
                    </Button>
                  </Dropdown> */}
                    </Space>
                  </div>
                </div>
              </Col>
              {/* <Col xs={24} xl={24}>
                <div className="sub-headding-main">
                  <div className="sub-headding02">
                    <h2>{sectionName}</h2>
                  </div>
                </div>
              </Col> */}

              <Tabs
                defaultActiveKey={selectedTab}
                className="myclients-tab"
                onTabClick={(e) => {
                  setSelectedTab(e);
                }}
              >
                <TabPane
                  tab={
                    <span className="myclients-tab-button">
                      {" "}
                      {lang("All")} ({allCount})
                    </span>
                  }
                  key="all"
                  className="myclients-tab-body"
                ></TabPane>
                <TabPane
                  tab={
                    <span className="myclients-tab-button">
                      {lang("Public")} ({publicCount})
                    </span>
                  }
                  key="true"
                  className="myclients-tab-body"
                ></TabPane>
                <TabPane
                  tab={
                    <span className="myclients-tab-button">
                      {" "}
                      {lang("Private")} ({privateCount})
                    </span>
                  }
                  key="false"
                  className="myclients-tab-body"
                ></TabPane>
              </Tabs>

              {listTrue ? (
                <div className="table-responsive customPagination">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    rowSelection={rowSelection}
                    pagination={{
                      defaultPageSize: 10,
                      responsive: true,
                      total: pagination.total,
                      // showSizeChanger: true,
                      // showQuickJumper: true,
                      // pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    onChange={handleChange}
                    className="ant-border-space"
                  />
                </div>
              ) : (
                <>
                  <Row gutter={[12, 12]}>
                    {list.map((item) => (
                      <>
                        <Col span={24} lg={12} xl={12} xxl={8}>
                          <Card
                            className="mt-2 card-border-show OnMouseP"

                          >
                            <div className="main-ass-col-div asset-card-box" onClick={() => navigate(`/view-asset/${item._id}`)}>
                              <div className="ass-col-div-1">
                                {item.image ? (
                                  <img src={item.image} />
                                ) : (
                                  <img
                                    src={
                                      item.multiImage[0]
                                        ? item.multiImage[0]
                                        : HomeImage
                                    }
                                  />
                                )}
                              </div>
                              <div className="ass-col-div-2">
                                <div className="ass-col-div-3">
                                  <div className="overview-item-main asset-section">
                                    <div className="overview-item">
                                      <ApartmentOutlinedIcon className="icon" />
                                      <Text>{lang('City')} : {item?.location.city}</Text>
                                    </div>
                                    <div className="overview-item">


                                      <a
                                        href={`tel:${item.mobile_number ? item.mobile_number : ''}`}
                                        target="_blank"

                                      >
                                        <LocalPhoneOutlinedIcon className="icon" />



                                        <Text>
                                          {item?.mobile_number}
                                        </Text>

                                      </a>


                                      {/* <BathroomOutlinedIcon className="icon" />
                                      <Text>Rooms : {item?.rooms_number}</Text> */}
                                    </div>
                                    <div className="overview-item">
                                      <AreaChartOutlined className="icon" />
                                      <Text>
                                        {lang('Size')} : {item?.size_in_meters} Meter.
                                      </Text>
                                    </div>
                                    <div className="overview-item">
                                      <FormatAlignCenterOutlinedIcon className="icon" />
                                      <Text>{lang('Type')} : {item?.property_type}</Text>
                                    </div>
                                    <div className="overview-item">
                                      <ClockCircleOutlined className="icon" />
                                      <Text>
                                        {lang('Posted')} :{" "}
                                        {moment(item?.date_of_entry).format(
                                          "YYYY-MM-DD"
                                        )}
                                      </Text>
                                    </div>
                                    <div className="overview-item">
                                      <DollarOutlined className="icon" />
                                      <Text>
                                        {lang("Price")} :{" "}
                                        {new Intl.NumberFormat().format(
                                          item.total_price
                                        )}
                                      </Text>
                                    </div>
                                  </div>
                                  <div className="for-sale-main-div-fffff">
                                    <div
                                      className="ass-view-btn"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Button
                                        type="default"
                                        shape="round"
                                        className="add-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(`/edit-asset/${item._id}`);
                                          setSelected({ recordId: item._id });
                                          setVisible(true);
                                          setSearchText("");
                                        }}
                                      >
                                        <EditOutlinedIcon />
                                      </Button>
                                      <Button
                                        type="default"
                                        shape="round"
                                        className="add-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelected({ recordId: item._id });
                                          setShowDelete(true);
                                        }}
                                      >
                                        <DeleteOutlineOutlinedIcon />
                                      </Button>
                                      <Button
                                        type="default"
                                        shape="round"
                                        className="add-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(`/view-asset/${item._id}`);
                                        }}
                                      >
                                        {" "}
                                        <RemoveRedEyeOutlinedIcon />{" "}
                                      </Button>
                                      <a
                                        // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${item._id}`}
                                        // href={`https://wa.me/+972${item.mobile_number}`}
                                        href={`https://api.whatsapp.com/send/?text=https://crm.brokerland.co.il/AssetShare/${userProfile.name}/${item._id}`}
                                        target="_blank"
                                        type="button"
                                        title={lang("Whatsapp")}
                                        data-id="135362"
                                        className="wishlisted"
                                      >
                                        <svg
                                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                          focusable="false"
                                          aria-hidden="true"
                                          viewBox="0 0 24 24"
                                          data-testid="WhatsAppIcon"
                                        >
                                          <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                                        </svg>
                                      </a>
                                      {!item.make_public && (
                                        <Button
                                          title={lang("Publish")}
                                          type="default"
                                          shape="round"
                                          className="report-btn"
                                          onClick={(e) => {
                                            showConfirm({
                                              record: item._id,
                                              path: api.public,
                                              onLoading: () => setLoading(true),
                                              onSuccess: () =>
                                                setRefresh((prev) => !prev),
                                              type: true,
                                            });
                                            e.stopPropagation();
                                          }}
                                        >
                                          {lang("Publish")}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </>
                    ))}
                  </Row>

                  <Pagination
                    className="pagination-section"
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    pageSizeOptions={["10", "20", "30", "50"]}
                    onShowSizeChange={(current, size) => {
                      setPagination((prev) => ({
                        ...prev,
                        pageSize: size,
                        current: 1, // Reset to the first page
                      }));
                      fetchData(
                        {
                          ...pagination,
                          pageSize: size,
                          current: 1,
                        },
                        filter
                      );
                    }}
                  />
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      {deleteAllModal && (
        <DeleteModal
          title={lang("Delete All Users")}
          subtitle={lang(`Are you sure you want to Delete all Users?`)}
          show={deleteAllModal}
          hide={() => {
            showDeleteAllModal(false);
            // setSelectedIds([]);
            setSelectedRowKeys([]);
          }}
          onOk={() => DeleteAll()}
        />
      )}
      {visible && (
        <CreateAsset
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {/* {detailsVisible && (
                <View
                    section={sectionName}
                    api={api}
                    show={detailsVisible}
                    hide={() => {
                        setSelected();
                        setDetailsVisible(false);
                    }}
                    data={selected}
                    refresh={() => setRefresh((prev) => !prev)}
                />
            )} */}
      {showDelete && (
        <DeleteModal
          title={lang("Delete Asset")}
          subtitle={lang("Are you sure you want to Delete this Asset?")}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected({ recordId: null });
          }}
          onOk={() => onDelete(selected.recordId)}
        />
      )}
      {showStatus && (
        <DeleteModal
          title={`${selected?.is_active ? lang(`Block`) : lang(`UnBlock`)
            } ${lang("User")}`}
          subtitle={`${lang("Are you sure you want to")} ${selected?.is_active ? lang(`block`) : lang(`unblock`)
            } ${lang("this user?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
          reasons={selected?.is_active ? BlockCustomerReasons : []}
        />
      )}
      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}

      <Modal
        open={uploadModal}
        title={lang("Import Excel")}
        okText={lang("Import")}
        cancelText={lang("Cancel")}
        onCancel={handleCancel01}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
        }}
      >
        <Form id="create" form={form} onFinish={onImport} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item name="import_file">
                <div className="text-center">
                  <Button
                    title={lang("Download Sample File")}
                    href={sampleFile}
                    download="sampleAsset.xlsx"
                  // onClick={() => {
                  //   SampleFileDownload(sectionName);
                  //   console.log(sectionName);
                  // }}
                  >
                    <i class="fas fa-download"></i>&nbsp;&nbsp;{lang("Download Sample File")}
                  </Button>
                </div>

                <div className="text-center mt-1 mb-2">
                  <span className="text-danger" style={{ fontWeight: "500" }}>
                    {lang("The column names in the sheet should match those given in the sample file.")}

                  </span>
                </div>

                {/* <Divider orientation="center" orientationMargin={0} className="devider-color">OR</Divider> */}

                <Dragger
                  fileList={file}
                  onRemove={(e) => setFile([])}
                  maxCount={1}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleImport}
                  customRequest={(e) => null}
                >
                  <p className="ant-upload-drag-icon">
                    {" "}
                    <InboxOutlined />{" "}
                  </p>
                  <p className="ant-upload-text">
                    {lang("Click or Drag File to This Area to Upload")}

                  </p>
                  <p className="ant-upload-hint">
                    {lang("Please Select an Excel File.")}

                  </p>
                </Dragger>
                {error && (
                  <div style={{ color: "red", padding: 5 }}>{error}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={uploadDocModal.type}
        title="Import Document"
        okText="Import Document"
        onCancel={handleCancelDoc01}
        okButtonProps={{
          form: "createDoc",
          htmlType: "submit",
          loading: loading,
        }}
      >
        <Form
          id="createDoc"
          form={form}
          onFinish={() => onImportDoc(uploadDocModal.record)}
          layout="vertical"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                className="upload_wrap"
                rules={[
                  {
                    validator: (_, value) => {
                      if (image) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(lang("Document is required"))
                      );
                    },
                  },
                ]}
                name="image"
                label="Document"
              >
                <UploadPDF
                  value={image}
                  setImage={setImage}
                  status={uploadDocModal.type}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Index;
