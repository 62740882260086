import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  DatePicker,
  TimePicker,
  Select,
  Row,
  Col,
  Card,
} from "antd";
import useRequest from "../../../hooks/useRequest";
import apiPath from "../../../constants/apiPath";
import moment from "moment";
import { Severty, ShowToast } from "../../../helper/toast";

const { TextArea } = Input;
const { Option } = Select;

const MyForm = () => {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const years = [2024, 2023];
  const [clientDetails, setClientDetails] = useState();
  const [loading, setLoading] = useState(years[0]);

  const onFinish = (values) => {
    console.log("Form Values:", values);
    const { title, type, time, date, description } = values;
    const payload = {};
    setLoading(true);
    payload.clientId = id;
    payload.title = title;
    payload.type = type;
    payload.time = time;
    payload.date = date;
    payload.description = description;

    request({
      url: `${
        // params.id ? apiPath.editActivity + "/" + params.id :
        apiPath.addActivity
      }`,
      method:
        // params.id ? "PUT" :
        "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          navigate(-1);
          ShowToast(data.message, Severty.SUCCESS);
          form.resetFields();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const getClientData = (id) => {
    request({
      url: apiPath.viewClientDetail + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(true);
          setClientDetails(data.data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getClientData(id);
  }, [id]);

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          date: moment(),
          time: moment(),
        }}
      >
        <Row gutter={[24, 12]}>
          <Col span={24} md={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please enter a title" }]}
            >
              <Input placeholder="Enter title" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="type"
              label="Type"
              rules={[{ required: true, message: "Please select a type" }]}
            >
              <Select placeholder="Select type">
                <Option value="message">Message</Option>
                <Option value="call">Call</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item
              name="time"
              label="Time"
              rules={[{ required: true, message: "Please select a time" }]}
              className="timepicker-main"
            >
              <TimePicker format="HH:mm" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>

          <Col span={24} md={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: "Please enter a description" },
              ]}
            >
              <TextArea rows={4} placeholder="Enter description" />
            </Form.Item>
          </Col>

          <Form.Item className="btn-row float-right">
            <Button
              type="primary"
              onClick={() => navigate(-1)}
              className="back-btns"
            >
              Back
            </Button>
            <Button type="primary" htmlType="submit" className="submit-btns">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};

export default MyForm;
