import {
    Button,
    Modal,
    Select,
    Slider,
    Switch,
    Col,
    Row,
    Tabs,
    Checkbox,
    Card,
    Collapse,
    Spin,
    Input,
    Typography,
    Table,
    Space, Tag, Menu,
    Form,
    Dropdown as DropdownAntd,
} from "antd";
import {
    HomeOutlined,
    PlusOutlined,
    PhoneOutlined,
    CloseOutlined,
    DollarOutlined,
    EnvironmentOutlined,
    StarOutlined,
    InboxOutlined,
} from "@ant-design/icons";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";


import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import BathroomOutlinedIcon from "@mui/icons-material/BathroomOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import ViewLogs from "../../components/ViewLogs";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import apiPath from "../../constants/apiPath";
import RoomBed from "../../assets/images/bedroom-icon.svg";
import PropertyType from "../../assets/images/PropertyType.svg";
import SquareIcons from "../../assets/images/square-meters.svg";
import PriceIcon from "../../assets/images/price-icon.svg";
import SortByIcon from "../../assets/images/sort-by-icon.svg";
import ScreenOutIcon from "../../assets/images/screenout-icon.svg";
import CleanUpIcon from "../../assets/images/cleanup-icon.svg";
import BrokerIcons from "../../assets/images/broker-and-non.svg";
import ForrentIcon from "../../assets/images/for-rent-icon.svg";
import ForsaleIcon from "../../assets/images/for-sale-icon.svg";
import DoubleBedIcon from "../../assets/images/double-bed.svg";
import ProprtFlorIcon from "../../assets/images/building-icon.svg";
import HomeProprtyIcon from "../../assets/images/home-proprty.svg";
import SquareIcon from "../../assets/images/square.svg";
import HomeImage from "../../assets/images/home.jpeg";
import {
    BlockCustomerReasons,
    DeleteCustomerReasons,
} from "../../constants/reasons";
import { Months } from "../../constants/var";
import { useNavigate } from "react-router";

import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { ApproveStatus } from "../Driver/_ChangeRequest";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useGeolocated } from "react-geolocated";
import Asset from "../../components/GoogleMap/Asset";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { AuthContext } from "../../context/AuthContext";
import { Margin } from "@mui/icons-material";
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Title, Text } = Typography;

const getRandomInt = (max, min = 0) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

const { Option } = Select;

const searchResult = (query) =>
    // new Array(getRandomInt(query.length))
    //   .join(".")
    //   .split(".")
    query.map((cityData) => {
        return {
            value: cityData.city + "," + cityData.neighborhood,
            label: (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <span>
                        {" "}
                        <svg
                            className="svg-location"
                            width="13"
                            height="15"
                            viewBox="0 0 13 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z"
                                stroke="#464A6B"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z"
                                stroke="#464A6B"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        {cityData.city + "," + cityData.neighborhood}
                    </span>
                </div>
            ),
        };
    });

const options12 = [
    {
        text: "Apartment",
        value: "דירה",
    },
    {
        text: "House / Cottage",
        value: "קוטג'",
    },
    {
        text: "Commercial Real Estate",
        value: "מִסְחָרִי מְקַרקְעִין",
    },
    {
        text: "Garden Apartment",
        value: "דירת גן",
    },
    {
        text: "Townhouse",
        value: "דו משפחתי",
    },

    {
        text: "Rooftop / Penthouse",
        value: "גג/ פנטהאוז",
    },
    {
        text: "Penthouse",
        value: "פנטהאוז",
    },
    {
        text: "Mini Penthouse",
        value: "מיני פנטהאוז",
    },
    {
        text: "Rooftop Apartment",
        value: "דירת גג",
    },

    {
        text: "Studio / Loft",
        value: "סטודיו/ לופט",
    },
    {
        text: "Duplex",
        value: "דופלקס",
    },
    {
        text: "Triplex",
        value: "טריפלקס",
    },
    {
        text: "Apartment Unit",
        value: "יחידת דיור",
    },
    {
        text: "Plots",
        value: "מגרשים",
    },
    {
        text: "Farmhouse / Homestead",
        value: "משק חקלאי/ נחלה",
    },
    {
        text: "Building",
        value: "בניין",
    },
    {
        text: "Divided Apartment",
        value: "דירה מחולקת",
    },
    {
        text: "Basement Apartment",
        value: "דירת מרתף",
    },
    {
        text: "Basement / Parter",
        value: "מרתף/ פרטר",
    },
    {
        text: "Residential Building",
        value: "בניין מגורים",
    },
    {
        text: "Vacation Apartment",
        value: "דירת נופש",
    },

    {
        text: "Parking",
        value: "חניה",
    },
    {
        text: "Storage",
        value: "מחסן",
    },
    {
        text: "Other",
        value: "אחר",
    },
];
const filterst = [
    {
        text: "Rejected",
        value: "rejected",
    },
    {
        text: "Sold",
        value: "sold",
    },
    {
        text: "Active",
        value: "active",
    },
];

const filterpt = [
    {
        text: "Apartment",
        value: "דירה",
    },
    {
        text: "House / Cottage",
        value: "קוטג'",
    },
    {
        text: "Garden Apartment",
        value: "דירת גן",
    },
    {
        text: "Townhouse",
        value: "דו משפחתי",
    },
    {
        text: "Rooftop / Penthouse",
        value: "גג/ פנטהאוז",
    },
    {
        text: "Penthouse",
        value: "פנטהאוז",
    },
    {
        text: "Mini Penthouse",
        value: "מיני פנטהאוז",
    },
    {
        text: "Rooftop Apartment",
        value: "דירת גג",
    },
    {
        text: "Studio / Loft",
        value: "סטודיו/ לופט",
    },
    {
        text: "Duplex",
        value: "דופלקס",
    },
    // 11 extra
    {
        text: "Triplex",
        value: "טריפלקס",
    },
    {
        text: "Apartment Unit",
        value: "יחידת דיור",
    },
    {
        text: "Plots",
        value: "מגרשים",
    },
    {
        text: "Farmhouse / Homestead",
        value: "משק חקלאי/ נחלה",
    },
    {
        text: "Building",
        value: "בניין",
    },
    {
        text: "Divided Apartment",
        value: "דירה מחולקת",
    },
    {
        text: "Basement Apartment",
        value: "דירת מרתף",
    },
    {
        text: "Basement / Parter",
        value: "מרתף/ פרטר",
    },
    {
        text: "Residential Building",
        value: "בניין מגורים",
    },
    {
        text: "Vacation Apartment",
        value: "דירת נופש",
    },
    {
        text: "Parking",
        value: "חניה",
    },
    {
        text: "Storage",
        value: "מחסן",
    },
    {
        text: "Other",
        value: "אחר",
    },
];
mapboxgl.accessToken =
    "pk.eyJ1IjoieW9hdjUyMDIiLCJhIjoiY2x3a3UwMG9zMGh1dDJpcDg4Mm4wanJzaCJ9.uY8D_Vhu9YEBbdULrVBHqg";
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

function Index() {
    const mapContainerRef = useRef(null);
    const geocoderContainerRef = useRef(null);
    const heading = lang("Public") + " " + lang("Assets");
    const { setPageHeading } = useContext(AppStateContext);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const navigate = useNavigate();
    const { request } = useRequest();
    const { showConfirm } = ConfirmationBox();
    const [list, setList] = useState([]);
    const [properties, setProperties] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [visible, setVisible] = useState(false);
    const [deleteModal, showDeleteModal] = useState(false);
    const [selected, setSelected] = useState({ recordId: null });
    const [showMessage, setShowMessage] = useState(false);
    const [form] = Form.useForm();
    const [showDelete, setShowDelete] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [logs, showLogs] = useState(false);
    const [clientDetails, setClientDetails] = useState();
    const [priceLimit, setPriceLimit] = useState([0, 10000000]);
    const [location, setLocation] = useState({ lat: "", lng: "" });
    const [searchCity, setSearchCity] = useState([]);
    const debouncedSearchCity = useDebounce(searchCity, 300);
    const [filterBroker, setFilterBroker] = useState();
    const [brokerActive, setBrokerActive] = useState();
    const [page, setPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState("buy");
    const [filterType, setFilterType] = useState(null);
    const [months, setMonths] = useState([]);
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [years, setYears] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({});
    const [selectedFiltersMulti, setSelectedFiltersMulti] = useState({
        size_in_meters: [null, null],
    });
    const debouncedSearchFilter = useDebounce(selectedFilter, 300);
    const debouncedSearchFilterMulti = useDebounce(selectedFiltersMulti, 300);
    const [addFiltersVisible, setAddFiltersVisible] = useState(false); // State to manage visibility
    const [activeButton, setActiveButton] = useState("buy");
    const debouncedActiveButton = useDebounce(activeButton, 300);
    const [sort, setSort] = useState(null);
    const [totalList, setTotalList] = useState(0);
    const debouncedSort = useDebounce(sort, 300);
    const [searchText, setSearchText] = useState("");
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get("status");
    const year = urlParams.get("year");
    const debouncedSearchText = useDebounce(searchText, 300);
    const { confirm } = Modal;
    const [cities, setCities] = useState([]);
    const debouncedSearchArrayCity = useDebounce(cities, 600);
    const [sortedcities, setSortedCities] = useState([]);
    const [listTrue, setListTrue] = useState(true);
    const [searchSimilarCity, setSearchSimilarCity] = useState("");
    const debouncedSimilarCity = useDebounce(searchSimilarCity, 300);
    const [mapTrue, setMapTrue] = useState(true);
    const { logout, userProfile, isAdmin, setIsAdmin } = useContext(AuthContext);
    const [show, setShow] = React.useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [uploadModal, setUploadModal] = useState(false);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const [options, setOptions] = useState([]);
    const handleSearch = (value) => {
        // setSearchText(value);
        console.log(value);
        setSearchSimilarCity(value);
    };
    const onSelect = (value) => {
        console.log("onSelect", value);
        setSearchText(value);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const components = {
        body: {
            row: EditableRow,
        },
    };

    const DeleteAll = () => {
        if (!hasSelected) return;
        request({
            url: api.user + "/delete-all",
            method: "POST",
            data: {
                ids: selectedRowKeys,
            },
            onSuccess: (data) => {
                setLoading(false);
                setRefresh((prev) => !prev);
                ShowToast("Data Deleted Successfully", Severty.SUCCESS);
            },
            onError: (error) => {
                console.log(error);
                setLoading(false);
                ShowToast(error, Severty.ERROR);
            },
        });
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleOk01 = () => {
        setUploadModal(false);
    };

    useEffect(() => {
        // const sortedArray = cities.slice().sort((a, b) => a.length - b.length);
        const sortedArray = cities
            .slice()
            .sort((a, b) => Object.keys(a).length - Object.keys(b).length);
        setSortedCities(sortedArray);
        console.log(sortedArray);
    }, [debouncedSearchArrayCity]);

    const [filter, setFilter] = useState({
        country_id: undefined,
        city_id: undefined,
        year: year,
        month: undefined,
    });

    const api = {
        status: apiPath.statusChangeAsset,
        public: apiPath.publicChangeAsset,
        list: apiPath.listAsset,
        update: apiPath.editAsset,
        delete: apiPath.deleteAsset,
        listforMap: apiPath.listforMap,
        edit: apiPath.editAsset,
    };

    useEffect(() => {
        request({
            url: `${api.listforMap}?page=${page}&debouncedSimilarCity=${debouncedSimilarCity}`,
            method: "GET",
            onSuccess: (data) => {
                if (debouncedSimilarCity && data?.data?.data?.length > 0) {
                    searchSimilarCity.split(",").map((id) => {
                        console.log(id);
                        data?.data?.data.map((dataIn) => {
                            setCities((prevCities = []) => {
                                const newCities = [...prevCities];
                                const existingEntry = newCities.find(
                                    (entry) => entry.city === dataIn.location.city
                                );
                                if (!existingEntry) {
                                    newCities.push({
                                        city: dataIn.location.city,
                                    });
                                }
                                return newCities;
                            });
                            setCities((prevCities = []) => {
                                const newCities = [...prevCities];
                                const existingEntry = newCities.find(
                                    (entry) =>
                                        entry.city === dataIn.location.city &&
                                        entry.neighborhood === dataIn.location.neighborhood
                                );
                                if (!existingEntry) {
                                    newCities.push({
                                        city: dataIn.location.city,
                                        neighborhood: dataIn.location.neighborhood,
                                    });
                                }
                                return newCities;
                            });
                            setCities((prevCities = []) => {
                                const newCities = [...prevCities];
                                const existingEntry = newCities.find(
                                    (entry) =>
                                        entry.city === dataIn.location.city &&
                                        entry.neighborhood === dataIn.location.neighborhood &&
                                        entry.street === dataIn.location.street
                                );
                                if (!existingEntry) {
                                    newCities.push({
                                        city: dataIn.location.city,
                                        neighborhood: dataIn.location.neighborhood,
                                        street: dataIn.location.street,
                                    });
                                }
                                return newCities;
                            });
                        });
                    });
                }
            },
        });
    }, [debouncedSimilarCity]);

    const handleChangeMap = (e) => {
        setMapTrue(e);
    };
    const onChangeList = (e) => {
        setListTrue(e);
    };
    const handleChange = (pagination, filters) => {
        fetchData(pagination, filters);
    };

    useEffect(() => {
        setOptions(cities.length > 0 ? searchResult(cities) : []);
    }, [cities]);

    useEffect(() => {
        // setLoading(true);
        request({
            url: `${api.listforMap}?page=${page}
            ${debouncedSearchCity
                    ? `&debouncedSearchCity=${JSON.stringify(debouncedSearchCity)}`
                    : ""
                }
            ${debouncedSearchFilter
                    ? `&debouncedSearchFilter=${JSON.stringify(
                        debouncedSearchFilter
                    )}`
                    : ""
                }
            ${debouncedSearchFilterMulti
                    ? `&selectedFiltersMulti=${JSON.stringify(
                        debouncedSearchFilterMulti
                    )}`
                    : ""
                }
            ${filterBroker ? `&broker_asset=${filterBroker}` : ""}
            ${debouncedActiveButton ? `&to_let=${debouncedActiveButton}` : ""}`,
            method: "GET",
            onSuccess: (data) => {
                // console.log(data.data);
                // setProperties((prevProperties) => [
                //   ...prevProperties,
                //   ...data.data.data,
                // ]);
                setProperties(data.data.data);
                // setLoading(false);
            },
        });
    }, [
        page,
        mapTrue,
        debouncedSearchCity,
        debouncedSearchFilter,
        debouncedSearchFilterMulti,
        debouncedActiveButton,
        filterBroker,
    ]);

    const handlePageChange = (page) => {
        setPagination((prev) => ({
            ...prev,
            current: page,
        }));
        fetchData(
            {
                ...pagination,
                current: page,
            },
            filter
        );
    };

    const fetchData = (pagination, filters) => {
        const filterActive = filters ? filters.asset_status : null;

        console.log(searchCity, "searchCity");

        request({
            url:
                api.list +
                `?page=${pagination ? pagination.current : 1}&pageSize=${!mapTrue ? 10000 : pagination ? pagination.pageSize : 10
                }&debouncedSearchCity=${JSON.stringify(debouncedSearchCity)}${path ? `&status=1` : ""
                }&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""
                }&debouncedSearchFilter=${JSON.stringify(
                    debouncedSearchFilter
                )}&selectedFiltersMulti=${JSON.stringify(
                    debouncedSearchFilterMulti
                )}&make_public=true&broker_asset=${filterBroker}&to_let=${debouncedActiveButton ? debouncedActiveButton : ""
                }&sort=${debouncedSort ? debouncedSort : ""}`,
            method: "GET",
            onSuccess: ({ data, status, total, message }) => {
                if (status) {
                    setList(data);
                    setTotalList(total);
                    setPagination((prev) => ({
                        ...prev,
                        current: pagination.current,
                        total: total,
                    }));
                    !mapTrue && setProperties(data);
                }
            },
            onError: (error) => {
                setLoading(false);
                ShowToast(error, Severty.ERROR);
            },
        });
    };
    const onSearch = (e) => {
        console.log(e);
        setSearchText(e);
    };
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
    useEffect(() => {
        if (coords) {
            const newLocation = { lat: coords.latitude, lng: coords.longitude };
            setLocation({ lat: coords.latitude, lng: coords.longitude });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, [coords]);

    const hanleMapVal = (lat, lng) => {
        console.log(lat, lng);
        setLocation({ lat: lat, lng: lng });
    };

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
        if (buttonType === "rent") {
            setPriceLimit([1, 15000]);
        } else {
            setPriceLimit([1, 10000000]);
        }
    };
    const [activeInputs, setActiveInputs] = useState({});

    const handleFocus = (id) => {
        setActiveInputs((prevActiveInputs) => ({
            ...prevActiveInputs,
            [id]: true,
        }));
    };

    const handleBlur = (id) => {
        setActiveInputs((prevActiveInputs) => ({
            ...prevActiveInputs,
            [id]: false,
        }));
    };
    const handleSelectChange = (value) => {
        setActiveButton(value);
        if (value === "rent") {
            setPriceLimit([1, 15000]);
        } else {
            setPriceLimit([1, 10000000]);
        }
    };
    const handleSetVal = (key, value) => {
        console.log(key, value);
        setSelectedFilter((prevSelectedFilter) => ({
            ...prevSelectedFilter,
            [key]: value,
        }));
        setAddFiltersVisible(true);
        fetchData(pagination, debouncedSearchFilter);
    };

    const setPriceLimitFilter = (value) => {
        console.log(value);
        setPriceLimit(value);
        setSelectedFiltersMulti((prevSelectedFilters) => {
            const newSelectedFilters = { ...prevSelectedFilters };

            // Ensure both min and max values are within an array using optional chaining
            newSelectedFilters["total_price"] = [
                ...(prevSelectedFilters["total_price"] || []),
            ]; // Create array if it doesn't exist
            newSelectedFilters["total_price"] = value; // Update specific index (min or max)

            console.log(newSelectedFilters);

            return newSelectedFilters;
        });
        // fetchData(pagination, debouncedSearchFilterMulti);
    };

    const handleSetMultipleSize = (key, value, index) => {
        setSelectedFiltersMulti((prevSelectedFilters) => {
            const newSelectedFilters = { ...prevSelectedFilters };

            // Ensure both min and max values are within an array using optional chaining
            newSelectedFilters[key] = [...(prevSelectedFilters[key] || [])]; // Create array if it doesn't exist
            newSelectedFilters[key][index] = value; // Update specific index (min or max)

            console.log(newSelectedFilters);

            return newSelectedFilters;
        });
        // fetchData(pagination, debouncedSearchFilterMulti);
    };

    const handleSetSort = (value) => {
        setSort(value);
    };

    const [activeChecks, setActiveChecks] = useState([]);
    const handleFocusC = (id, checked) => {
        setActiveChecks((prevActiveChecks) => {
            if (checked) {
                return [...prevActiveChecks, id]; // Add id to the array if checked
            } else {
                return prevActiveChecks.filter((item) => item !== id); // Remove id from the array if unchecked
            }
        });
    };

    const handleSetMultiple = (key, value, checked) => {
        setSelectedFiltersMulti((prevSelectedFilters) => {
            const newSelectedFilters = { ...prevSelectedFilters };
            console.log(newSelectedFilters);
            if (checked) {
                // If checked, add or append to the property_type array
                newSelectedFilters[key] = prevSelectedFilters[key]
                    ? [...prevSelectedFilters[key], value]
                    : [value];
            } else {
                // If unchecked, remove the value from property_type array
                if (prevSelectedFilters[key]) {
                    const filteredValues = prevSelectedFilters[key].filter(
                        (item) => item !== value
                    );
                    newSelectedFilters[key] = filteredValues;
                } else {
                    // If property_type doesn't exist, do nothing
                }
            }

            console.log(newSelectedFilters);
            return newSelectedFilters;
        });
        // fetchData(pagination, debouncedSearchFilterMulti);
    };

    useEffect(() => {
        fetchData({ ...pagination, current: 1 });
        // getFilter();
    }, [
        refresh,
        debouncedSearchText,
        debouncedSearchCity,
        debouncedSearchFilter,
        debouncedSearchFilterMulti,
        startDate,
        debouncedActiveButton,
        endDate,
        debouncedSort,
        filterBroker,
        !mapTrue,
    ]);
    const [hoveredFeature, setHoveredFeature] = useState(null);
    const [activeFeature, setActiveFeature] = useState(null);
    useEffect(() => {
        setPageHeading(heading);
    }, [setPageHeading]);

    useEffect(() => {
        if (!mapContainerRef.current) return;
        if (!properties) return;
        if (!properties[0]) return;
        if (!properties[0].latitude) return;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v12",
            center:
                cities && cities.length > 0
                    ? [properties[0].longitude, properties[0].latitude]
                    : [34.7818, 32.0853],
            zoom: cities && cities.length > 0 ? 15 : 10,
        });

        map.addControl(new mapboxgl.FullscreenControl());

        map.on("load", () => {
            map.addSource("properties", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: properties.map((property) => ({
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [property?.longitude, property?.latitude],
                        },
                        properties: {
                            total_price: property.total_price,
                            address: property.location.city,
                            rooms: property.rooms_number,
                            floor: property.floor_number,
                            property_type: property.property_type,
                            size: property.size_in_meters,
                            _id: property._id,
                        },
                    })),
                },
                cluster: true,
                clusterMaxZoom: 10,
                clusterRadius: 80,
            });

            map.addLayer({
                id: "clusters",
                type: "circle",
                source: "properties",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": "#51bbd6",
                    "circle-radius": 20,
                },
            });

            map.addLayer({
                id: "cluster-count",
                type: "symbol",
                source: "properties",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12,
                },
            });

            map.addLayer({
                id: "unclustered-point",
                type: "circle",
                source: "properties",
                filter: ["!", ["has", "point_count"]],
                paint: {
                    "circle-color": "#11b4da",
                    "circle-radius": 8,
                    "circle-stroke-width": 1,
                    "circle-stroke-color": "#fff",
                },
            });

            let pricePopup = null;
            let detailPopup = null;

            map.on("mouseenter", "unclustered-point", (e) => {
                // if (detailPopup) return;

                map.getCanvas().style.cursor = "pointer";

                const coordinates = e.features[0].geometry.coordinates.slice();
                const { total_price } = e.features[0].properties;

                if (pricePopup) pricePopup.remove();

                pricePopup = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false,
                })
                    .setLngLat(coordinates)
                    .setHTML(
                        `<div style="" class="locati02" >₪ ${new Intl.NumberFormat().format(
                            total_price
                        )}</div>`
                    )
                    .addTo(map);

                setHoveredFeature(e.features[0]);
            });

            map.on("mouseleave", "unclustered-point", () => {
                map.getCanvas().style.cursor = "";
                if (pricePopup) pricePopup.remove();
                setHoveredFeature(null);
            });

            map.on("click", "unclustered-point", (e) => {
                if (pricePopup) pricePopup.remove();

                const coordinates = e.features[0].geometry.coordinates.slice();
                const {
                    total_price,
                    address,
                    rooms,
                    floor,
                    property_type,
                    size,
                    _id,
                    to_let,
                } = e.features[0].properties;

                if (detailPopup) detailPopup.remove();
                detailPopup = new mapboxgl.Popup({ closeButton: true })
                    .setLngLat(coordinates)
                    .setHTML(
                        `<a href="/asset-view/${_id}" target="_blank" style="text-decoration: none; color: inherit;">
                <div>
                  <h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
                            total_price
                        )}</h3>
                  <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <span>${address}</span>
                  </div>
                  <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
                    <li>
                      <div class="item-name">
                        <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Rooms" class="img-fluid"/>
                        <span>rooms</span>
                      </div>
                      <span>${rooms}</span>
                    </li>
                    <li>
                      <div class="item-name">
                        <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Floor" class="img-fluid"/>
                        <span>floor</span>
                      </div>
                      <span>${floor}</span>
                    </li>
                    <li>
                      <div class="item-name">
                        <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Property Type" class="img-fluid"/>
                        <span>Property Type</span>
                      </div>
                      <span>${property_type}</span>
                    </li>
                    <li>
                      <div class="item-name">
                        <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Size" class="img-fluid"/>
                        <span>size</span>
                      </div>
                      <span>${size}</span>
                    </li>
                  </ul>
                </div>
              </a>`
                    )
                    .addTo(map);

                setActiveFeature(e.features[0]);
                if (pricePopup) pricePopup.remove();
            });

        });

        return () => map.remove();
    }, [properties]);

    const [selectedRooms, setSelectedRooms] = useState([]);

    const handleFocusW = (id) => {
        setActiveInputs((prev) => ({ ...prev, [id]: true }));
    };

    const handleBlurW = (id) => {
        setActiveInputs((prev) => ({ ...prev, [id]: false }));
    };

    const handleSetValW = (rooms_number) => {
        console.log(rooms_number);
        setSelectedRooms((prev) => {
            console.log(prev.length);
            if (prev.includes(rooms_number)) {
                console.log(rooms_number);
                setSelectedFilter((prevSelectedFilter) => ({
                    ...prevSelectedFilter,
                    rooms_number: [rooms_number],
                }));
                return [rooms_number];
            }

            if (prev.length === 0) {
                console.log("debt", rooms_number);
                setSelectedFilter((prevSelectedFilter) => ({
                    ...prevSelectedFilter,
                    rooms_number: [rooms_number],
                }));
                return [rooms_number];
            }

            const min = Math.min(...prev, rooms_number);
            const max = Math.max(...prev, rooms_number);

            setSelectedFilter((prevSelectedFilter) => ({
                ...prevSelectedFilter,
                rooms_number: [min, max],
            }));
            return Array.from({ length: max - min + 1 }, (_, i) => i + min);
        });
        // fetchData(pagination, debouncedSearchFilterMulti);
    };

    const getRangeText = () => {
        if (selectedRooms.length === 0) {
            return "No selection";
        }
        const min = Math.min(...selectedRooms);
        const max = Math.max(...selectedRooms);
        return min === max ? `${min}` : `${min} to ${max}`;
    };

    const handleFilterResert = () => {
        setSelectedRooms([]);
        setActiveInputs({});
        setSelectedFilter((prevSelectedFilter) => ({
            ...prevSelectedFilter,
            rooms_number: [],
        }));
        setSearchText("");
        setSelectedFilter({});
        setSelectedFiltersMulti({
            size_in_meters: [null, null],
        });
        setActiveButton("buy");
        setSort(null);
        setAddFiltersVisible(false);
        setSelectedFilters([]);
        setFilterBroker();
        setBrokerActive();
        setSelectedRooms([]);
        setActiveChecks([]);
        fetchData({ ...pagination, current: 1 });
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed
    const onHandleClick = (id) => {
        setLoading(true);
        request({
            url: api.edit + "/" + id,
            method: "PUT",
            data: {
                broker_id: true,
            },

            onSuccess: (data) => {
                setLoading(false);
                if (data?.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            },
            onError: (error) => {
                ShowToast(error.response?.data.message, Severty.ERROR);
                setLoading(false);
            },
        });
    };

    const handleFilterBroker = (value) => {
        console.log(value);
        if (value === "no_broker") {
            setBrokerActive(value);
            setFilterBroker(false);
        } else if (value === "broker") {
            setBrokerActive(value);
            setFilterBroker(true);
        } else {
            setBrokerActive();
            setFilterBroker();
        }
    };



    const onSearchSet = (e) => {
        setSearchText(e.target.value);
    };

    const handleOptionTypeChange = (value) => {
        console.log(value);

        setSelectedFiltersMulti((prevSelectedFilters) => {
            const newSelectedFilters = { ...prevSelectedFilters };
            console.log(newSelectedFilters);
            if (value.length > 0) {
                newSelectedFilters["property_type"] = value;
            } else {
                delete newSelectedFilters["property_type"];
            }
            setActiveChecks(value);
            console.log(newSelectedFilters);
            return newSelectedFilters;
        });
    };
    const handleCityUpdate = (value) => {
        console.log(value);
        console.log("onSelect", value);
        setSearchCity(value);
    };
    const handleMeClick = (e) => {
        if (!selectedFilters.includes(e.key)) {
            const newSelectedFilters = [...selectedFilters, e.key];
            setSelectedFilters(newSelectedFilters);
            setAddFiltersVisible(true);
            setSelectedFilter((prevSelectedFilter) => ({
                ...prevSelectedFilter,
                [e.key]: null,
            }));
        }
    };

    const handleClose = (removedFilter) => {
        const newFilters = selectedFilters.filter(
            (filter) => filter !== removedFilter
        );
        setSelectedFilters(newFilters);

        setSelectedFilter((prevSelectedFilter) => {
            const { [removedFilter]: _, ...rest } = prevSelectedFilter;
            return rest;
        });
    };



    const changableFeilds = (value) => {
        switch (value) {
            case "asset_status":
                return "Status";
            case "property_type":
                return "Type";
            case "city":
                return "City";
            case "rooms_number":
                return "Rooms";
            case "total_price":
                return "Price";
            case "size_in_meters":
                return "Size";
            default:
                return value;
        }
    };

    const menuItems = [
        { text: "Broker/non-broker", key: "broker_non_broker", label: "Broker/non-broker" },
        { text: "rooms", key: "rooms", label: "rooms" },
        { text: "property_type", key: "property_type", label: "property_type" },

        { text: "Area in square meters", key: "area_in_square_meters", label: "Area in square meters" },
        { text: "Price", key: "total_price", label: "Price" },
        { text: "sort by", key: "sort_by", label: "sort by" },
    ];

    const menu = (
        <Menu onClick={handleMeClick}>
            {menuItems.map((item) => (
                <Menu.Item text={item.text} key={item.key}>
                    {item.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    const menu02 = (key) => {

        if (key === "broker_non_broker") {
            return (
                <div className="selected-filter-drop">
                    <div className="panel-box-coll">
                        <div className="toggle-filter">
                            <button
                                className={`rent-btns ${brokerActive === "broker" ? "active" : ""
                                    }`}
                                onClick={() => handleFilterBroker("broker")}
                            >
                                For Broker
                            </button>
                            <button
                                className={`rent-btns ${brokerActive === "no_broker" ? "active" : ""
                                    }`}
                                onClick={() => handleFilterBroker("no_broker")}
                            >
                                For Non-Broker
                            </button>
                        </div>
                        <div className="drp-dawn-btn">
                            <button
                                className="cl-btns"
                                onClick={() => {
                                    handleFilterBroker();
                                }}
                            >
                                Clean up
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        if (key === "rooms") {
            return (
                <div className="selected-filter-drop">
                    <div className="panel-box-coll">
                        <h6 className="">rooms</h6>
                        <div
                            className="radio-option bedrooms-list"
                            data-bdnum="1"
                            id="bedrooms-list"
                        >
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <button
                                    key={num}
                                    id={`minPrice${num}`}
                                    className={`rooms-input ${selectedRooms.includes(num) ? "active" : ""
                                        }`}
                                    name="rooms_number"
                                    onFocus={() => handleFocusW(`minPrice${num}`)}
                                    onBlur={() => handleBlurW(`minPrice${num}`)}
                                    onClick={() => handleSetValW(num)}
                                >
                                    {num === 6 ? "6+" : num}
                                </button>
                            ))}
                        </div>

                        <div className="drp-dawn-btn">
                            <button
                                className="cl-btns"
                                onClick={() => {
                                    setSelectedRooms([]);
                                    setActiveInputs({});
                                    setSelectedFilter((prevSelectedFilter) => ({
                                        ...prevSelectedFilter,
                                        rooms_number: [],
                                    }));
                                }}
                            >
                                Clean up
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        if (key === "property_type") {
            return (
                <div className="selected-filter-drop">
                    <div className="panel-box-coll">
                        <h6 className="">Property Type</h6>
                        <div className="checkbox-menu-items">
                            <div className="pro-type">
                                <Checkbox
                                    value="דירה"
                                    checked={activeChecks.includes("דירה")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Apartment
                                </Checkbox>
                                <Checkbox
                                    value="מִסְחָרִי מְקַרקְעִין"
                                    checked={activeChecks.includes(
                                        "מִסְחָרִי מְקַרקְעִין"
                                    )}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Commercial Real Estate
                                </Checkbox>

                                <Checkbox
                                    value="קוטג'"
                                    checked={activeChecks?.includes("קוטג'")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    House / Cottage
                                </Checkbox>
                                <Checkbox
                                    value="דירת גן"
                                    checked={activeChecks?.includes("דירת גן")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Garden Apartment
                                </Checkbox>
                                <Checkbox
                                    value="דו משפחתי"
                                    checked={activeChecks?.includes("דו משפחתי")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Townhouse
                                </Checkbox>
                                <Checkbox
                                    value="גג/ פנטהאוז"
                                    checked={activeChecks?.includes("גג/ פנטהאוז")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Rooftop / Penthouse
                                </Checkbox>
                                <Checkbox
                                    value="פנטהאוז"
                                    checked={activeChecks?.includes("פנטהאוז")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Penthouse
                                </Checkbox>
                                <Checkbox
                                    value="מיני פנטהאוז"
                                    checked={activeChecks?.includes("מיני פנטהאוז")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Mini Penthouse
                                </Checkbox>
                                <Checkbox
                                    value="דירת גג"
                                    checked={activeChecks?.includes("דירת גג")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Rooftop Apartment
                                </Checkbox>
                                <Checkbox
                                    value="סטודיו/ לופט"
                                    checked={activeChecks?.includes("סטודיו/ לופט")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Studio / Loft
                                </Checkbox>
                                <Checkbox
                                    value="דופלקס"
                                    checked={activeChecks?.includes("דופלקס")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Duplex
                                </Checkbox>
                                <Checkbox
                                    value="טריפלקס"
                                    checked={activeChecks?.includes("טריפלקס")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Triplex
                                </Checkbox>
                                <Checkbox
                                    value="יחידת דיור"
                                    checked={activeChecks?.includes("יחידת דיור")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Apartment Unit
                                </Checkbox>
                                <Checkbox
                                    value="מגרשים"
                                    checked={activeChecks?.includes("מגרשים")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Plots
                                </Checkbox>
                                <Checkbox
                                    value="משק חקלאי/ נחלה"
                                    checked={activeChecks?.includes("משק חקלאי/ נחלה")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Farmhouse / Homestead
                                </Checkbox>
                                <Checkbox
                                    value="בניין"
                                    checked={activeChecks?.includes("בניין")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Building
                                </Checkbox>
                                <Checkbox
                                    value="דירה מחולקת"
                                    checked={activeChecks?.includes("דירה מחולקת")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Divided Apartment
                                </Checkbox>
                                <Checkbox
                                    value="דירת מרתף"
                                    checked={activeChecks?.includes("דירת מרתף")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Basement Apartment
                                </Checkbox>
                                <Checkbox
                                    value="מרתף/ פרטר"
                                    checked={activeChecks?.includes("מרתף/ פרטר")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Basement / Parter
                                </Checkbox>
                                <Checkbox
                                    value="בניין מגורים"
                                    checked={activeChecks?.includes("בניין מגורים")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Residential Building
                                </Checkbox>
                                <Checkbox
                                    value="דירת נופש"
                                    checked={activeChecks?.includes("דירת נופש")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Vacation Apartment
                                </Checkbox>
                                <Checkbox
                                    value="חניה"
                                    checked={activeChecks?.includes("חניה")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Parking
                                </Checkbox>
                                <Checkbox
                                    value="מחסן"
                                    checked={activeChecks?.includes("מחסן")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Storage
                                </Checkbox>
                                <Checkbox
                                    value="אחר"
                                    checked={activeChecks?.includes("אחר")}
                                    onChange={(e) => {
                                        handleSetMultiple(
                                            "property_type",
                                            e.target.value,
                                            e.target.checked
                                        );
                                        handleFocusC(e.target.value, e.target.checked);
                                    }}
                                >
                                    Other
                                </Checkbox>
                            </div>
                        </div>
                        <div className="drp-dawn-btn">
                            {/* <button className="cho-btns">Choose</button> */}
                            <button
                                className="cl-btns"
                                onClick={() => {
                                    setSelectedFiltersMulti((prevSelectedFilters) => {
                                        const newSelectedFilters = {
                                            ...prevSelectedFilters,
                                        };
                                        delete newSelectedFilters["property_type"];
                                        // newSelectedFilters["property_type"] = [];
                                        return newSelectedFilters;
                                    });
                                    setActiveChecks([]);
                                }}
                            >
                                Clean up
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        if (key === "area_in_square_meters") {
            return (
                <div className="selected-filter-drop">
                    <div className="panel-box-coll">
                        <h6 className=" ">Area in square meters</h6>
                        <div className="two-columns">
                            <Input
                                id="minSize"
                                name="size_in_meters"
                                placeholder="minimum"
                                onChange={(e) =>
                                    handleSetMultipleSize(
                                        "size_in_meters",
                                        e.target.value,
                                        0
                                    )
                                }
                            />
                            <Input
                                id="maxSize"
                                name="size_in_meters"
                                placeholder="maximum"
                                onChange={(e) =>
                                    handleSetMultipleSize(
                                        "size_in_meters",
                                        e.target.value,
                                        1
                                    )
                                }
                            />
                        </div>
                        <div className="drp-dawn-btn">
                            {/* <button className="cho-btns">Choose</button> */}
                            <button
                                className="cl-btns"
                                onClick={() => {
                                    setSelectedFiltersMulti((prevSelectedFilters) => {
                                        const newSelectedFilters = {
                                            ...prevSelectedFilters,
                                        };
                                        newSelectedFilters["size_in_meters"] = [
                                            null,
                                            null,
                                        ];
                                        return newSelectedFilters;
                                    });
                                }}
                            >
                                Clean up
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        if (key === "total_price") {
            return (
                <div className="selected-filter-drop">
                    <div>
                        <h6 className="">Price</h6>
                        <div className="price-range">
                            <Slider
                                className="slaider-row"
                                min={1}
                                max={
                                    debouncedActiveButton === "buy" ? 10000000 : 15000
                                }
                                range={{
                                    draggableTrack: true,
                                }}
                                tooltip={{ open: false }}
                                value={priceLimit}
                                onChange={setPriceLimitFilter} // use onChange instead of onchange
                            />
                            <div className="count-box">
                                <div className="count-detail">
                                    <span>
                                        {priceLimit
                                            ? new Intl.NumberFormat().format(priceLimit[0])
                                            : "0"}
                                        {"  "}₪
                                    </span>
                                    <span>
                                        {priceLimit
                                            ? new Intl.NumberFormat().format(priceLimit[1])
                                            : "100,000"}
                                        {"  "}₪
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="drp-dawn-btn">
                            {/* <button className="cho-btns">Choose</button> */}
                            <button
                                className="cl-btns"
                                onClick={() => {
                                    {
                                        debouncedActiveButton === "buy"
                                            ? setPriceLimit([0, 10000000])
                                            : setPriceLimit([0, 15000]);
                                    }

                                    setSelectedFiltersMulti((prevSelectedFilters) => {
                                        const newSelectedFilters = {
                                            ...prevSelectedFilters,
                                        };
                                        // newSelectedFilters["total_price"] = [];
                                        delete newSelectedFilters["total_price"];
                                        return newSelectedFilters;
                                    });
                                }}
                            >
                                Clean up
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        if (key === "sort_by") {
            return (
                <div className="selected-filter-drop">
                    <div className="short-by-cls">
                        <Select
                            placeholder="Select Sortable Value"
                            onChange={(values) => handleSetSort(values)}
                        >
                            <Select.Option value="old_to_new">
                                Date: from Old to New
                            </Select.Option>
                            <Select.Option value="new_to_old">
                                Date: from New to Old
                            </Select.Option>
                            <Select.Option value="low_to_high">
                                Price: from Low to High
                            </Select.Option>
                            <Select.Option value="high_to_low">
                                Price: from High to Low
                            </Select.Option>
                        </Select>
                    </div>
                </div>
            );
        }


        return null;
    };


    const columns = [
        {
            title: lang(" Name"),
            dataIndex: "name",
            key: "name",
            render: (_, { }) => (
                <span>Hello</span>
            ),
        },
    ];



    return (
        <>
            {loading ? (
                <div className="spiner-box">
                    <Spin />
                </div>
            ) : (
                <>
                    <Row>
                        <div className="main-hh-headding">
                            <div className="headding-cc">
                                Public Asset
                                {/* My {lang("Asset") + " " + lang("List")} */}
                            </div>
                            <Breadcrumb className="bread-menu">
                                <Breadcrumb.Item className="bread-items" href="#">
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active className="bread-items" href="#">
                                    Public Asset
                                    {/* {lang("Asset") + " " + lang("List")} */}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </Row>
                    <Card>
                        <Row>
                            <Col xs={24} xl={24}>
                                <div className="w-100 d-flex align-items-baseline text-head_right_cont">
                                    <div className="pageHeadingSearch clint-input ">
                                        <Input.Search
                                            className="searchInput"
                                            placeholder={lang(
                                                "Search by City,Neighborhood,Property Title"
                                            )}
                                            value={searchText}
                                            onChange={onSearch}
                                            allowClear
                                        />
                                    </div>
                                    <Button
                                        onClick={() => handleFilterResert()}
                                        type="primary"
                                        icon={<RestartAltOutlinedIcon />}
                                        className="man-reset-bttn and-assest-btn edit-cls"
                                    >
                                        {/* {lang("Reset")} */}
                                    </Button>

                                    {/* <div className="lead-clint-drop">
                                        <Switch
                                            checkedChildren="Card"
                                            unCheckedChildren="List"
                                            checked={listTrue}
                                            onClick={(listTrue) => onChangeList(listTrue)}
                                            className="main-mecu-switch"
                                        />
                                    </div> */}

                                    <DropdownAntd
                                        overlay={menu}
                                        trigger={["click"]}
                                    // onClick={handleToggleFilters}
                                    >
                                        <Button className="report-btn">
                                            <AddCircleOutlineOutlinedIcon />
                                            Filter
                                        </Button>
                                    </DropdownAntd>
                                    {/* <div className="lg-adddesktop">
                                        <Button
                                            className="primary_btn btnStyle"
                                            onClick={(e) => {
                                                navigate(`/create-asset`);
                                                setVisible(true);
                                                setSearchText("");
                                            }}
                                        >
                                            <span className="add-Ic">
                                                <AddCircleOutlineOutlinedIcon />
                                            </span>
                                            {lang("Add")}
                                        </Button>
                                    </div> */}
                                    {hasSelected && (
                                        <>
                                            <Button
                                                className="man-list-bttn edit-cls"

                                            >
                                                <DeleteOutlineOutlinedIcon />
                                                {/* {lang("Delete All")} */}
                                            </Button>

                                            <Button className="man-list-bttn edit-cls">
                                                <a
                                                    href={`https://api.whatsapp.com/send/?text=https://crm.brokerland.co.il/AssetShare/${userProfile.name}/${selectedRowKeys}`}
                                                    className="whatsapp-cls"
                                                    target="_blank"
                                                >
                                                    <WhatsAppIcon />
                                                </a>
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </Col>
                            <Col xs={24} xl={24}>
                                <div className="mb-add-mobile">
                                    <Button
                                        className="add-inc-btn"
                                        onClick={(e) => {
                                            navigate(`/create-asset`);
                                            setVisible(true);
                                            setSearchText("");
                                        }}
                                    >
                                        <AddCircleOutlineOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <div
                                        className={`add-filters ${addFiltersVisible ? "show" : "hide"
                                            }`}
                                    >
                                        <Space className="flex-wrap">
                                            {selectedFilters.map((filter) => (
                                                <Tag
                                                    className="ftter-main-btn"
                                                    key={filter}
                                                    closable
                                                    closeIcon={<CloseOutlined />}
                                                    onClose={() => handleClose(filter)}
                                                >
                                                    {filter && (
                                                        <>
                                                            <DropdownAntd
                                                                overlay={menu02(filter)}
                                                                trigger={["click"]}
                                                            >
                                                                <Button className="filter-btns">
                                                                    {selectedFilter[filter]
                                                                        ? changableFeilds(filter) +
                                                                        " : " +
                                                                        selectedFilter[filter]
                                                                        : changableFeilds(filter)}
                                                                </Button>
                                                            </DropdownAntd>
                                                        </>
                                                    )}
                                                </Tag>
                                            ))}

                                        </Space>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} xl={24}>
                                <div className=" ">

                                    <Tabs
                                        defaultActiveKey={activeButton}
                                        className="myclients-tab"
                                        onTabClick={(e) => {
                                            setActiveButton(e);
                                        }}
                                    >
                                        <TabPane
                                            tab={
                                                <span className="myclients-tab-button">
                                                    Sale
                                                </span>
                                            }
                                            key="buy"
                                            className="myclients-tab-body"
                                        ></TabPane>
                                        <TabPane
                                            tab={
                                                <span className="myclients-tab-button">
                                                    {" "}
                                                    Rent
                                                </span>
                                            }
                                            key="rent"
                                            className="myclients-tab-body"
                                        ></TabPane>
                                    </Tabs>
                                    <div className="table-responsive customPagination">
                                        <Table
                                            loading={loading}
                                            columns={columns}
                                            dataSource={list}
                                            rowSelection={rowSelection}
                                            pagination={{
                                                defaultPageSize: 10,
                                                responsive: true,
                                                total: pagination.total,
                                                // showSizeChanger: true,
                                                // showQuickJumper: true,
                                                // pageSizeOptions: ["10", "20", "30", "50"],
                                            }}
                                            onChange={handleChange}
                                            className="ant-border-space"
                                        />
                                    </div>



                                </div>
                            </Col>
                        </Row>



                    </Card>
                </>
            )}
        </>
    );
}

export default Index;
