import { Col, DatePicker, Form, Input, Modal, Row, InputNumber } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";

const AddForm = ({ section, api, show, hide, data, refresh }) => {
    const [form] = Form.useForm();
    const { request } = useRequest();
    const [loading, setLoading] = useState(false)

    const handleNumberInput = (e) => {
        const value = e.target.value;
        // Allow only numbers
        const numericValue = value.replace(/[^0-9]/g, '');
        e.target.value = numericValue;
    };


    useEffect(() => {
        if (!data) return;
        form.setFieldsValue({
            ...data,
            price: data.price,
            name: data.name,
        });
    }, [data]);

    const onCreate = (values) => {
        const {
            name,
            price,
            session
        } = values;
        const payload = {};
        setLoading(true);
        payload.name = name;
        payload.price = price
        payload.session = session
        request({
            url: `${data ? api.update + "/" + data._id : api.add}`,
            method: data ? "PUT" : "POST",
            data: payload,
            onSuccess: (data) => {
                setLoading(false);
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    hide();
                    refresh();
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR);
                setLoading(false);
            },
        });
    };

    return (
        <Modal
            open={show}
            width={750}
            okText={data ? lang("Update") : lang("Add")}
            onCancel={hide}
            okButtonProps={{
                form: "create",
                htmlType: "submit",
                loading: loading,
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="tab_modal"
        >
            <Form
                id="create"
                form={form}
                onFinish={onCreate}
                layout="vertical"
                initialValues={{
                }}
            >
                <div className="add_user_title">
                    <h4 className="modal_title_cls">{`${data
                        ? lang("Edit Subscription") /* sectionName */
                        : lang("Add Subscription") /* sectionName */
                        }`}</h4>
                </div>
                <Row gutter={[16, 0]}>
                    <Col span={24} sm={12}>
                        <Form.Item
                            label={lang(`Name`)}
                            name="name"
                            rules={[
                                { required: true, message: lang("Please enter the to name") },
                                {
                                    max: 100,
                                    message: lang(
                                        "Name should not contain more then 100 characters!",
                                    ),
                                },
                                {
                                    min: 2,
                                    message: lang("Name should contain at least 2 characters!"),
                                },
                            ]}
                            normalize={(value) => value.trimStart()}
                        >
                            <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
                        </Form.Item>
                    </Col>
                    {/* <Col span={24} sm={12}>
                        <Form.Item
                            label={lang(`Name Hebrew`)}
                            name="ar_name"
                            rules={[
                                {
                                    required: true,
                                    message: lang("Please enter the to name in arabic"),
                                },
                                {
                                    max: 100,
                                    message: lang(
                                        "Name should not contain more then 100 characters!",
                                    ),
                                },
                                {
                                    min: 2,
                                    message: lang("Name should contain at least 2 characters!"),
                                },
                            ]}
                            normalize={(value) => value.trimStart()}
                        >
                            <Input autoComplete="off" placeholder={`أدخل اسم الفئة`} />
                        </Form.Item>
                    </Col> */}
                    <Col span={24} sm={12}>
                        <Form.Item
                            label={lang(`Price`)}
                            name="price"
                            rules={[
                                { required: true, message: lang("Please enter the price") },
                                // {
                                //     max: 100,
                                //     message: lang("Price should not contain more than 100 characters!"),
                                // },
                                // {
                                //     min: 2,
                                //     message: lang("Price should contain at least 2 characters!"),
                                // },
                                {
                                    pattern: /^[0-9]+$/,
                                    message: lang("Please enter a valid number"),
                                },
                            ]}
                            normalize={(value) => (value !== undefined ? value?.toString().trimStart() : '')}
                        >
                            <Input
                                autoComplete="off"
                                placeholder={lang(`Enter price`)}
                                style={{ width: '100%' }}
                                onChange={handleNumberInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddForm;
