import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Main from "../../components/layout/Main";
import { useEffect } from "react";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import { Button, Drawer } from "antd";

const localizer = momentLocalizer(moment);

function GoogleCalendarIntegration() {
  const { request } = useRequest();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("userProfile"));
    request({
      url: `/broker/task/list`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data.docs);
        let list = [];
        for (let ele of data.data.docs) {
          list.push({
            ...ele,
            start: new Date(moment(ele.date_of_entry).format("yyyy, MM, DD")),
            end: new Date(moment(ele.date_of_entry).format("yyyy, MM, DD")),
          });
        }
        console.log("list", list);
        setEvents(list);
      },
      onError: (error) => {
        // ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  return <Cal values={events} />;
}

function Cal(props) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);

  const showDrawer = (due_date) => {
    setOpen(true);
    console.log("props.values", props.values[0]);
    // setValue(...props.values.filter((el) => el._id === id));
    let dateToCompare = new Date(due_date);
    let doc = props.values.filter((item) => {
      const itemDate = new Date(item.date_of_entry);
      return (
        itemDate.getDate() === dateToCompare.getDate() &&
        itemDate.getMonth() === dateToCompare.getMonth() &&
        itemDate.getFullYear() === dateToCompare.getFullYear()
      );
    });
    setValue(doc);
  };

  const onClose = () => {
    setOpen(false);
  };

  const EventComponent = ({ event }) => (
    <div
      onClick={() => {
        showDrawer(event.date_of_entry);
      }}
    >
      <h5>{event.task_name}</h5>
    </div>
  );

  return (
    <div>
      <div className="calendar-container">
        <Calendar
          localizer={localizer}
          events={props.values}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 800, width: "100%" }}
          components={{
            event: EventComponent,
          }}
        />
      </div>
      <div>
        <Drawer
          title="Task Details"
          placement="right"
          onClose={onClose}
          open={open}
          className="main-calender-main-drower"
        >
          {value &&
            value.map((el, id) => {
              return (
                <div className="mb-3" key={id}>
                  <div className="main-drower-table">
                    <h4>Task Id:</h4> <h5>{el && el.TaskId}</h5>
                  </div>
                  <div className="main-drower-table">
                    <h4> Task Title:</h4> <h5>{el && el.title}</h5>
                  </div>
                  {/* <div className="main-drower-table">
                    <h4> Purpose:</h4> <h5>{el && el.purpose}</h5>
                  </div> */}
                  {/* <div>
                  Create At :{" "}
                  <h5>{el && moment(el.created_at).format("DD/MM/YYYY")}</h5>
                </div> */}
                  <div className="main-drower-table">
                    <h4> Status: </h4>
                    <h5>{el ? el.is_status : "N/A"}</h5>
                  </div>
                  <div className="main-drower-table">
                    <h4> Client :</h4>
                    <h5>
                      {el && el.clientId ? (
                        <a href={`client-detail/${el.clientId._id}`}>
                          {el.clientId.name}
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </h5>
                  </div>
                  <div className="main-drower-table">
                    <h4>Assest  :</h4>
                    <h5>
                      {el && el.assetId ? (
                        <a href={`view-asset/${el.assetId._id}`}>
                          {el.assetId.property_title}
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </h5>
                  </div>
                  <div className="main-drower-table">
                    <h4>Notes :</h4> <h5>{el ? el.notes : "N/A"}</h5>
                  </div>
                  <div className="main-drower-table">
                    <h4>Due Date:</h4>
                    <h5>
                      {el && moment(el.date_of_entry).format("DD/MM/YYYY")}
                    </h5>
                  </div>
                </div>
              );
            })}
        </Drawer>
      </div>
    </div>
  );
}
export default GoogleCalendarIntegration;
