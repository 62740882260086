import {
  EnvironmentOutlined,
  StarOutlined,
  PrinterOutlined,
  PlusOutlined,
  HeartOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Image,
  Card,
  Tag,
  Input,
  Form,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,
  Link,
} from "react-router-dom";

import html2pdf from "html2pdf.js";
import image1 from "../../assets/images/image1.png";
import image2 from "../../assets/images/image2.png";
import image3 from "../../assets/images/image3.png";
import image4 from "../../assets/images/image4.png";
import image5 from "../../assets/images/image5.png";
import image6 from "../../assets/images/image6.png";
import image7 from "../../assets/images/image7.png";
import image8 from "../../assets/images/image8.png";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import PdfModal from "../../components/PdfModal";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import ViewLogs from "../../components/ViewLogs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import apiPath from "../../constants/apiPath";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import EyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DrawIcon from "@mui/icons-material/Draw";
import FileShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../constants/reasons";
import { Months } from "../../constants/var";

import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { ApproveStatus } from "../Driver/_ChangeRequest";
// import CreateAsset from "./AddForm"
import notfound from "../../assets/images/not_found.png";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SignatureCanva from "./SignatureCanva";
// import ChangeModal from "./_ChangeModal";
// import View from "./View"
const { TabPane } = Tabs;

function SignatureDocument() {
  const heading = lang("My") + " " + lang("Documents");
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();
  const { request } = useRequest();
  const [list, setList] = useState({});

  const params = useParams();
  const fileType = [
    ".csv",
    ".pdf",
    "application/pdf",
    ".png",
    ".jpeg",
    ".jpg",
    ".PNG",
    ".JPEG",
    ".JPG",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const api = {
    list: apiPath.listDocument,
    update: apiPath.editDocument,
    singleDocument: apiPath.singleDocument,
  };

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const [files, setFiles] = useState([]);
  const [file, setFile] = useState([]);
  const [error, setError] = useState();
  const [imageUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [refresh, setRefresh] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const [selected, setSelected] = useState();
  const [previewModal, setPreviewModal] = useState(false); // State for preview modal
  const [htmlContent, setHtmlContent] = useState("");

  const fetchData = (id) => {
    request({
      url: api.singleDocument + "/" + id,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        console.log(data);
        setList(data.data[0]);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  useEffect(() => {
    fetchData(params.id);
  }, [params.id, refresh]);

  // const handleDownload = (selected) => {
  //   const contentToAppend = `
  //   <div>
  //     <!-- Add more HTML content as needed -->

  //     <style>
  //       body {
  //           font-family: Arial, sans-serif;
  //           direction: rtl;
  //           text-align: right;
  //           margin: 20px;
  //           line-height: 1.5;
  //       }
  //       .header {
  //           margin-bottom: 20px;
  //       }
  //       .header div {
  //           margin-bottom: 5px;
  //       }
  //       .table-container {
  //           border-collapse: collapse;
  //           width: 100%;
  //           margin-top: 20px;
  //           margin-bottom: 20px;
  //       }
  //       .table-container th, .table-container td {
  //           border: 1px solid black;
  //           padding: 8px;
  //           text-align: center;
  //       }
  //       .sign-section {
  //           margin-top: 40px;
  //           text-align: center;
  //       }
  //       .footer {
  //           display: flex;
  //           justify-content: space-between;
  //           align-items: center;
  //           border-top: 1px solid black;
  //           padding-top: 10px;
  //           font-size: 12px;
  //       }
  //       .footer p {
  //           margin: 0;
  //       }
  //     </style>

  //     <main style=" max-width: 800px; width: 100%; margin: 0 auto;    border: 1px solid #ccc;
  //       padding: 20px 20px;">
  //       <div class="header">
  //           <div>עבור: ${selected.clientInfo.name}</div>
  //           <div>תעודת זהות:</div>
  //           <div>שם מתווך:${selected.brokerInfo?.name}</div>
  //           <div>מספר רישיון תיווך: 4704320</div>
  //       </div>

  //       <h2 style="text-align: center;">שירותי תיווך נכס מקרקעין</h2>
  //       <div>סוג עסקה: מכירה</div>
  //       <div>בלעדיות: בלעדיות 3 חודש</div>

  //       <table class="table-container">
  //           <tr>
  //               <th>שם הנכס</th>
  //               <th>כתובת</th>
  //               <th>מחיר מבוקש</th>
  //               <th>מחיר למ"ר</th>
  //           </tr>
  //           <tr>
  //               <td>${selected.assetInfo.property_title}</td>
  //               <td>${selected.assetInfo.location?.city}</td>
  //               <td>${selected.assetInfo.total_price}</td>
  //               <td>${selected.assetInfo.price_per_square_meter}</td>
  //           </tr>
  //       </table>

  //       <div>
  //           <p>
  //               סעיף 1: הלקוח מזמין שירותי תיווך מקרקעין מהמתווך, כדי לקבל שירותי תיווך בקשר לנכסים המפורטים לעיל.
  //           </p>
  //           <p>
  //               סעיף 2: הלקוח מאשר כי המתווך הציג בפניו את הנכסים המפורטים להלן, והוא מתחייב לדווח למתווך מיד על כל משא ומתן המתנהל עמו ואו עם שולחו
  //               בקשר לאחד או יותר מהנכסים, וכן מיד עם חתימת הסכם מחייב ואו התחייבות לביצוע העסקה, המוקדם מביניהם, ביחס לאחד או יותר מהנכסים להלן.
  //           </p>
  //           <p>
  //               סעיף 3: למען הסר ספק, הלקוח מצהיר כי מובן לו וכי הוא מסכים, כי כל הסכם אשר ייחתם בין הלקוח ואו מי מטעמו לבין בעל הנכס, בנוגע לכל אחד
  //               מהנכסים המפורטים מעלה יחייב את הלקוח בדמי התיווך כמפורט בסעיף 5 להלן.
  //           </p>
  //           <p>
  //               סעיף 4: הלקוח מתחייב לא למסור לגורם כלשהו מידע שקיבל מהמתווך בנוגע לנכסים שלהלן ומתחייב לפצות את המתווך על כל נזק שייגרם לו באם יפר
  //               התחייבות זו.
  //           </p>
  //           <p>
  //               סעיף 5: הלקוח מתחייב לשלם למתווך את דמי התיווך כמפורט להלן, מיד עם חתימת הסכם מחייב או עם התחייבות לביצוע העסקה, המוקדם מביניהם,
  //               בנוגע לאחד או יותר מהנכסים המפורטים להלן.
  //               <br>
  //               דמי התיווך ישולמו במזומן כדלקמן:
  //               <br>5.1 דמי תיווך של 1 חודש בתוספת מע"מ.
  //               <br>5.2 דמי התיווך ישולמו למתווך במזומן מיד עם חתימת הסכם מחייב ואו עם התחייבות לביצוע העסקה, המוקדם מביניהם.
  //               <br>5.3 האמור לעיל בא בנוסף לזכותו של המתווך לגבות דמי תיווך מהמוכר/משכיר.
  //           </p>
  //           <p>
  //               סעיף 6: הלקוח מאשר כי עם מכירת הנכס, המתווך יוכל להודיע לציבור כי הנכס נמכר בכל דרך שהמתווך ימצא לנכון.
  //           </p>
  //           <p>
  //               סעיף 7: הלקוח מאשר שהומלץ לו על ידי המתווך להסתייע בשירותי עורך דין ואו מומחים אחרים לפי העניין והצורך במהלך העסקה.
  //           </p>
  //           <p>הערות נוספות:</p>
  //       </div>

  //       <div class="sign-section">
  //           <p>חתימה</p>
  //           <p>
  //           ${
  //             selected.signUrl
  //               ? `
  //             <img class="" src="${selected.signUrl}" onload="this.style.display='inline-block'" />     `
  //               : `Your Signature Here`
  //           }
  //           </p>
  //       </div>

  //       <div class="footer">
  //           <div>
  //               <p>הופק על ידי מערכת ברוקרלנד</p>
  //               <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
  //           </div>
  //           <p>22/08/2024, 09:18:51</p>

  //       </div>
  //     </main>
  //   </div>
  // `;

  //   const partnershipContentToAppend = `
  //   <div>
  //     <style>
  //       body {
  //           font-family: Arial, sans-serif;
  //           direction: rtl;
  //           text-align: right;
  //           margin: 20px;
  //           line-height: 1.5;
  //       }
  //       .header {
  //           margin-bottom: 20px;
  //       }
  //       .header div {
  //           margin-bottom: 5px;
  //       }
  //       .table-container {
  //           border-collapse: collapse;
  //           width: 100%;
  //           margin-top: 20px;
  //           margin-bottom: 20px;
  //       }
  //       .table-container th, .table-container td {
  //           border: 1px solid black;
  //           padding: 8px;
  //           text-align: center;
  //       }
  //       .sign-section {
  //           margin-top: 40px;
  //           text-align: center;
  //       }
  //       .footer {
  //           display: flex;
  //           justify-content: space-between;
  //           align-items: center;
  //           border-top: 1px solid black;
  //           padding-top: 10px;
  //           font-size: 12px;
  //       }
  //       .footer p {
  //           margin: 0;
  //       }
  //     </style>

  //     <main style="max-width: 800px; width: 100%; margin: 0 auto; border: 1px solid #ccc; padding: 20px 20px;">
  //       <div class="header">
  //           <div>שם מתווך: ${selected.brokerInfo?.name}</div>
  //           <div>מספר רישיון:(partner) 12345678</div>
  //           <div>טלפון: ${selected.brokerInfo?.phone}</div>
  //           <div>אימייל: ${selected.brokerInfo?.email}</div>
  //       </div>

  //       <h2 style="text-align: center;">הסכם שיתוף פעולה בין מתווכים</h2>
  //       <h3>מספר: 00002</h3>
  //       <table class="table-container">
  //           <tr>
  //               <th>סוג נכס</th>
  //               <th>מחיר שכירות מבוקש (₪)</th>
  //               <th>מחיר מכירה מבוקש (₪)</th>
  //               <th>כתובת</th>
  //           </tr>
  //           <tr>
  //               <td>דירה</td>
  //               <td>5,000</td>
  //               <td>3,200,000</td>
  //               <td>שדרות רוטשילד 20, תל אביב</td>
  //           </tr>
  //       </table>

  //       <div>
  //           <p>סוג שיתוף: קופה משותפת (המוכר והקונה ישלמו יחד וכל מתווך יקבל חצי).</p>
  //           <ol>
  //               <li>הצדדים יפעלו מתוך נאמנות והגינות לשיתוף פעולה.</li>
  //               <li>הצדדים לא ימסרו את פרטי הנכס לאחר, אלא אם כן יקבלו אישור בכתב.</li>
  //               <li>כל קשר עם בעל הנכס יתבצע באמצעות נציג המתווך.</li>
  //               <li>ההסכם תקף לגבי כל עסקה שתצא מהנכס הנ"ל, גם אם בתיווך אחר.</li>
  //               <li>ההסכם תקף לגבי הנכס המפורט בלבד.</li>
  //               <li>מע"מ יתווסף לכל הסכומים לפי החוק.</li>
  //           </ol>
  //       </div>

  //       <div class="sign-section">
  //           <p>תאריך ושעה</p>
  //           <p>
  //           ${
  //             selected.signUrl ? (
  //               <img
  //                 class=""
  //                 src="${list.signUrl}"
  //                 onload="this.style.display='inline-block'"
  //               />
  //             ) : (
  //               "Your Signature Here"
  //             )
  //           }
  //           </p>
  //       </div>

  //       <div class="footer">
  //           <div>
  //               <p>הופק על ידי מערכת ברוקרלנד</p>
  //               <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
  //           </div>
  //           <p>22/08/2024, 09:18:51</p>
  //       </div>
  //     </main>
  //   </div>
  //   `;

  //   const exclusivityContentToAppend = `<div>
  //     <!-- Main HTML Structure -->

  //     <style>
  //       body {
  //           font-family: Arial, sans-serif;
  //           direction: rtl;
  //           text-align: right;
  //           margin: 20px;
  //           line-height: 1.5;
  //       }
  //       .header {
  //           margin-bottom: 20px;
  //       }
  //       .header div {
  //           margin-bottom: 5px;
  //       }
  //       .table-container {
  //           border-collapse: collapse;
  //           width: 100%;
  //           margin-top: 20px;
  //           margin-bottom: 20px;
  //       }
  //       .table-container th, .table-container td {
  //           border: 1px solid black;
  //           padding: 8px;
  //           text-align: center;
  //       }
  //       .sign-section {
  //           margin-top: 40px;
  //           text-align: center;
  //       }
  //       .footer {
  //           display: flex;
  //           justify-content: space-between;
  //           align-items: center;
  //           border-top: 1px solid black;
  //           padding-top: 10px;
  //           font-size: 12px;
  //       }
  //       .footer p {
  //           margin: 0;
  //       }
  //     </style>

  //     <main style=" max-width: 800px; width: 100%; margin: 0 auto; border: 1px solid #ccc; padding: 20px 20px;">
  //       <div class="header">
  //           <div>עבור: ${selected.clientInfo?.name}</div>
  //           <div>תעודת זהות: ${selected.clientInfo?.id}</div>
  //           <div>שם מתווך: ${selected.brokerInfo?.name}</div>
  //           <div>מספר רישיון תיווך: 4704320 </div>
  //       </div>

  //       <h2 style="text-align: center;">שירותי תיווך נכס מקרקעין</h2>
  //       <div>מס' הזמנה: ${selected.orderId}</div>
  //       <div>סוג עסקה: ${selected.transactionType || "מכירה"}</div>
  //       <div>בלעדיות: ${selected.exclusivity || "בלעדיות 3 חודש"}</div>

  //       <!-- Updated Table with Property Details -->
  //       <table class="table-container">
  //           <tr>
  //               <th>סוג הנכס</th>
  //               <th>מחיר מבוקש</th>
  //               <th>מחיר שכירות</th>
  //               <th>כתובת</th>
  //           </tr>
  //           <tr>
  //               <td>${selected.assetInfo?.propertyType || "דירה"}</td>
  //               <td>${selected.assetInfo?.salePrice || "3,200,000"}</td>
  //               <td>${selected.assetInfo?.rentPrice || "5,000"}</td>
  //               <td>${selected.assetInfo?.location?.city || "תל אביב"}</td>
  //           </tr>
  //       </table>

  //       <!-- Additional Clauses (Legal Text) -->
  //       <div>
  //           <p>
  //               סעיף 1: הלקוח מזמין שירותי תיווך מקרקעין מהמתווך, כדי לקבל שירותי תיווך בקשר לנכסים המפורטים לעיל.
  //           </p>
  //           <!-- Add more paragraphs if needed based on the clauses in the image -->
  //           <p>הערות נוספות:</p>
  //       </div>

  //       <!-- Signature Section -->
  //       <div class="sign-section">
  //           <p>חתימה</p>
  //           <p>
  //           ${
  //             selected.signUrl ? (
  //               <img
  //                 class=""
  //                 src="${list.signUrl}"
  //                 onload="this.style.display='inline-block'"
  //               />
  //             ) : (
  //               "Your Signature Here"
  //             )
  //           }
  //           </p>
  //       </div>

  //       <!-- Footer Section -->
  //       <div class="footer">
  //           <div>
  //               <p>הופק על ידי מערכת ברוקרלנד</p>
  //               <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
  //           </div>
  //           <p>${new Date().toLocaleDateString(
  //             "he-IL"
  //           )}, ${new Date().toLocaleTimeString("he-IL")}</p>
  //       </div>
  //     </main>
  //   </div>
  // ;`;

  //   const element = document.createElement("div");

  //   if (selected.kind_of_doc === "commission") {
  //     element.innerHTML = contentToAppend;
  //     // setHtmlContent(contentToAppend);
  //   }

  //   if (selected.kind_of_doc === "partnership") {
  //     element.innerHTML = partnershipContentToAppend;
  //     //  setHtmlContent(partnershipContentToAppend);
  //   }
  //   if (selected.kind_of_doc === "exclusivity") {
  //     element.innerHTML = exclusivityContentToAppend;
  //     // setHtmlContent(exclusivityContentToAppend);
  //   }

  //   html2pdf()
  //     .from(element)
  //     .save(`${selected.title}.pdf`)
  //     .then(() => {
  //       console.log("PDF Downloaded");
  //     })
  //     .catch((err) => console.error("PDF generation failed", err));
  // };

  const onUpload = (e, id) => {
    console.log(e, id);
    request({
      url: api.update + "/" + id,
      method: "PUT",
      data: {
        signUrl: e,
      },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(
          "Document Signed successfully. A Confirmation Mail has been sent to broker",
          Severty.SUCCESS
        );
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    if (!list?.clientInfo?.name) return;

    console.log(list);

    const contentToAppend = `
    <div>
      <!-- Add more HTML content as needed -->
      
      <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            margin: 20px;
            line-height: 1.5;
        }
        .header {
            margin-bottom: 20px;
        }
        .header div {
            margin-bottom: 5px;
        }
        .table-container {
            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .table-container th, .table-container td {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
        }
        .sign-section {
            margin-top: 40px;
            text-align: center;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid black;
            padding-top: 10px;
            font-size: 12px;
        }
        .footer p {
            margin: 0;
        }
      </style>

      <main style=" max-width: 800px; width: 100%; margin: 0 auto;    border: 1px solid #ccc;
        padding: 20px 20px;">
        <div class="header">
            <div>עבור: ${list.clientInfo?.name}</div>

            ${
              list.clientInfo?.brokerNo !== null
                ? `${
                    list.clientInfo?.brokerNo
                      ? `<div>תעודת זהות:${list.clientInfo?.brokerNo}</div>`
                      : ``
                  }`
                : ""
            }

            <div>שם מתווך:${list.brokerInfo?.name}</div>
            <div>מספר רישיון תיווך: ${list.brokerInfo?.brokerNo} </div>
        </div>

        <h2 style="text-align: center;">שירותי תיווך נכס מקרקעין</h2>
        <div>סוג עסקה: ${
          list.rent_sale
            ? list.rent_sale === "sale"
              ? "מכירה"
              : "השכרה"
            : "מכירה"
        }</div>

        <table class="table-container">
            <tr>
                <th>שם הנכס</th>
                <th>כתובת</th>
                <th>מחיר מבוקש</th>
                <th>מחיר למ"ר</th>
            </tr>
            <tr>
                <td>${list.assetInfo.property_title}</td>
                <td>${list.assetInfo.location?.city}</td>
                <td>${
                  list.total_price
                    ? list.total_price
                    : list.assetInfo.total_price
                }</td>
                <td>${(
                  (list.total_price
                    ? list.total_price
                    : list.assetInfo.total_price) /
                  list.assetInfo.size_in_meters
                ).toFixed(2)}</td>
            </tr>
        </table>

        <div>
            <p>
                סעיף 1: הלקוח מזמין שירותי תיווך מקרקעין מהמתווך, כדי לקבל שירותי תיווך בקשר לנכסים המפורטים לעיל.
            </p>
            <p>
                סעיף 2: הלקוח מאשר כי המתווך הציג בפניו את הנכסים המפורטים להלן, והוא מתחייב לדווח למתווך מיד על כל משא ומתן המתנהל עמו ואו עם שולחו
                בקשר לאחד או יותר מהנכסים, וכן מיד עם חתימת הסכם מחייב ואו התחייבות לביצוע העסקה, המוקדם מביניהם, ביחס לאחד או יותר מהנכסים להלן.
            </p>
            <p>
                סעיף 3: למען הסר ספק, הלקוח מצהיר כי מובן לו וכי הוא מסכים, כי כל הסכם אשר ייחתם בין הלקוח ואו מי מטעמו לבין בעל הנכס, בנוגע לכל אחד
                מהנכסים המפורטים מעלה יחייב את הלקוח בדמי התיווך כמפורט בסעיף 5 להלן.
            </p>
            <p>
                סעיף 4: הלקוח מתחייב לא למסור לגורם כלשהו מידע שקיבל מהמתווך בנוגע לנכסים שלהלן ומתחייב לפצות את המתווך על כל נזק שייגרם לו באם יפר
                התחייבות זו.
            </p>
            <p>
                סעיף 5: הלקוח מתחייב לשלם למתווך את דמי התיווך כמפורט להלן, מיד עם חתימת הסכם מחייב או עם התחייבות לביצוע העסקה, המוקדם מביניהם,
                בנוגע לאחד או יותר מהנכסים המפורטים להלן.
                <br>
                דמי התיווך ישולמו במזומן כדלקמן:
                <br>5.1 דמי תיווך של ${list.commission} ${
      list.rent_sale === "sale" ? "% עמלה" : "חודשי שכירות"
    }  בתוספת מע"מ.
                <br>5.2 דמי התיווך ישולמו למתווך במזומן מיד עם חתימת הסכם מחייב ואו עם התחייבות לביצוע העסקה, המוקדם מביניהם.
                <br>5.3 האמור לעיל בא בנוסף לזכותו של המתווך לגבות דמי תיווך מהמוכר/משכיר.
            </p>
            <p>
                סעיף 6: הלקוח מאשר כי עם מכירת הנכס, המתווך יוכל להודיע לציבור כי הנכס נמכר בכל דרך שהמתווך ימצא לנכון.
            </p>
            <p>
                סעיף 7: הלקוח מאשר שהומלץ לו על ידי המתווך להסתייע בשירותי עורך דין ואו מומחים אחרים לפי העניין והצורך במהלך העסקה.
            </p>
            ${
              list.description !== null
                ? `${
                    list.description
                      ? `<p>הערות נוספות:${list.description}</p>`
                      : ``
                  }`
                : ""
            }
        </div>

        <div class="sign-section">
            <p>חתימה</p>
            <p>
            ${
              list.signUrl
                ? `
              <img class="" src="${list.signUrl}" onload="this.style.display='inline-block'" />     `
                : ``
            }
            </p>
        </div>

        <div class="footer">
            <div>
                <p>הופק על ידי מערכת ברוקרלנד</p>
                <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
            </div>
            
            <p>
              ${new Date(list?.created_at).toLocaleDateString(
                "sv-SE"
              )} ${new Date(list?.created_at).toLocaleTimeString("sv-SE", {
      hour: "2-digit",
      minute: "2-digit",
    })}</p>
          
        </div>
      </main>
    </div>
  `;

    const partnershipContentToAppend = `
    <div>
      <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            margin: 20px;
            line-height: 1.5;
        }
        .header {
            margin-bottom: 20px;
        }
        .header div {
            margin-bottom: 5px;
        }
        .table-container {
            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .table-container th, .table-container td {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
        }
        .sign-section {
            margin-top: 40px;
            text-align: center;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid black;
            padding-top: 10px;
            font-size: 12px;
        }
        .footer p {
            margin: 0;
        }
      </style>

      <main style="max-width: 800px; width: 100%; margin: 0 auto; border: 1px solid #ccc; padding: 20px 20px;">
        <div class="header">
            <div>שם מתווך: ${list.brokerInfo?.name}</div>
            <div>מספר רישיון: ${list.brokerInfo?.brokerNo}</div>
        </div>

        <h2 style="text-align: center;">הסכם שיתוף פעולה בין מתווכים</h2>
        <div class="header">
            <div>שם מתווך: ${list.clientInfo?.name}</div>
            <div>יש מייל מתווך: ${list.clientInfo?.email}</div>
            <div>יש טלפון מתווך: ${list.clientInfo?.mobileNumber}</div>
        </div>
        <div>סוג עסקה: ${
          list.rent_sale
            ? list.rent_sale === "sale"
              ? "מכירה"
              : "השכרה"
            : "מכירה"
        }</div>
        <table class="table-container docs-table">
            <tr>
                <th>שם הנכס</th>
                <th>כתובת</th>
                <th>מחיר מבוקש</th>
                <th>מחיר למ"ר</th>
            </tr>
            <tr>
                <td>${list.assetInfo.property_title}</td>
                <td>${list.assetInfo.location.city}</td>
                <td>${
                  list.total_price
                    ? list.total_price
                    : list.assetInfo.total_price
                }</td>
                <td>${(
                  (list.total_price
                    ? list.total_price
                    : list.assetInfo.total_price) /
                  list.assetInfo.size_in_meters
                ).toFixed(2)}</td>
            </tr>
        </table>

        <div>
            ${
              list.type === "קופה משותפת"
                ? `<p>סוג השתפ: קופה משותפת (מחברים את עמלת המוכר, ואת עמלת הקונה, וכל מתווך מקבל חצי)</p>`
                : `<p>סוג השתפ: כל אחד מהלקןח שלו: (כל מתווך גובה מהלקןח שלו, את מה שסיכם עם הלקוח שלו)</p>`
            }
            <ol>
                <li>הצדדים יפעלו מתוך נאמנות והגינות לשיתוף פעולה.</li>
                <li>הצדדים לא ימסרו את פרטי הנכס לאחר, אלא אם כן יקבלו אישור בכתב.</li>
                <li>כל קשר עם בעל הנכס יתבצע באמצעות נציג המתווך.</li>
                <li>ההסכם תקף לגבי כל עסקה שתצא מהנכס הנ"ל, גם אם בתיווך אחר.</li>
                <li>ההסכם תקף לגבי הנכס המפורט בלבד.</li>
                <li>מע"מ יתווסף לכל הסכומים לפי החוק.</li>
            </ol>
            ${
              list.description !== null
                ? `${
                    list.description
                      ? `<p>הערות נוספות:${list.description}</p>`
                      : ``
                  }`
                : ""
            }
        </div>

        <div class="sign-section">
            <p>תאריך ושעה</p>
            <p>
            ${
              list.signUrl
                ? `
              <img class="" src="${list.signUrl}" onload="this.style.display='inline-block'" />     `
                : `Your Signature Here`
            }</p>
        </div>

        <div class="footer">
            <div>
                <p>הופק על ידי מערכת ברוקרלנד</p>
                <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
            </div>
            <p>
              ${new Date(list?.created_at).toLocaleDateString(
                "sv-SE"
              )} ${new Date(list?.created_at).toLocaleTimeString("sv-SE", {
      hour: "2-digit",
      minute: "2-digit",
    })}</p>
        </div>
      </main>
    </div>
    `;

    const exclusivityContentToAppend = `
    <div>
      <!-- Main HTML Structure -->
      <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            margin: 20px;
            line-height: 1.5;
        }
        .header {
            margin-bottom: 20px;
        }
        .header div {
            margin-bottom: 5px;
        }
        .table-container {
            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .table-container th, .table-container td {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
        }
        .sign-section {
            margin-top: 40px;
            text-align: center;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid black;
            padding-top: 10px;
            font-size: 12px;
        }
        .footer p {
            margin: 0;
        }
      </style>

      <main style="max-width: 800px; width: 100%; margin: 0 auto; border: 1px solid #ccc; padding: 20px 20px;">
        <div class="header">
          <div>עבור: ${list.clientInfo?.name}</div>

          ${
            list.clientInfo?.brokerNo !== null
              ? `${
                  list.clientInfo.brokerNo
                    ? `<div>תעודת זהות:${list.clientInfo.brokerNo}</div>`
                    : ``
                }`
              : ""
          }

          <div>שם מתווך:${list.brokerInfo?.name}</div>
          <div>מספר רישיון תיווך: ${list.brokerInfo?.brokerNo} </div>
        </div>

        <h2 style="text-align: center;">הזמנת שירותי תיווך בלעדיות</h2>
        <div>סוג עסקה: ${
          list.rent_sale
            ? list.rent_sale === "sale"
              ? "מכירה"
              : "השכרה"
            : "מכירה"
        }</div>
        <!-- Updated Table with Property Details -->
        <table class="table-container docs-table">
            <tr>
                <th>שם הנכס</th>
                <th>כתובת</th>
                <th>מחיר מבוקש</th>
                <th>מחיר למ"ר</th>
            </tr>
            <tr>
                <td>${list.assetInfo.property_title}</td>
                <td>${list.assetInfo.location.city}</td>
                <td>${
                  list.total_price
                    ? list.total_price
                    : list.assetInfo.total_price
                }</td>
                <td>${(
                  (list.total_price
                    ? list.total_price
                    : list.assetInfo.total_price) /
                  list.assetInfo.size_in_meters
                ).toFixed(2)}</td>
            </tr>
        </table>


        <div>

        <p>
            1. הלקוח מזמין בזאת שירותי תיווך מקרקעין כמפורט בטבלה דלעיל מהמתווך, וזאת לגבי כל אחד מהנכסים המפורטים בחלק העליון של מסמך זה (להלן: "הנכס") או חלק ממנו. 
            הזמנה זו מקנה למתווך בלעדיות למכירת הנכסים החל מיום 
            
            
            <span style="color:red;">${
              list?.from_date
                ? new Date(list.from_date).toLocaleDateString("sv-SE")
                : new Date(list?.created_at).toLocaleDateString("sv-SE")
            }</span>
             ועד ליום 
             <span style="color:red;">${
               list?.to_date
                 ? new Date(list.to_date).toLocaleDateString("sv-SE")
                 : new Date(
                     new Date(list?.created_at).setMonth(
                       new Date(list?.created_at).getMonth() +
                         parseInt(list?.period)
                     )
                   ).toLocaleDateString("sv-SE")
             }</span> 
             
             
             (להלן: "תקופת הבלעדיות"), עם בחר המתווך להתחיל ולשווק את הנכסים במועד מאוחר יותר, תקופת הבלעדיות תחל מהמועד בו יתחיל המתווך בשיווק בפועל את הנכסים. 
            הלקוח מתחייב שלא ינהל מו"מ עם רוכשים או שוכרים פוטנציאליים או נציגיהם, ולא יפנה למתווכים אחרים/נוספים כדי לשווק את הנכסים במשך כל תקופת הבלעדיות אלא באמצעות המתווך בלבד.
        </p>

        <p>
            2. הלקוח מתחייב למסור למתווך את כל המידע שברשותו ביחס לנכסים והמצב המשפטי, התכנוני והמיסויי שלהם, כולל אישור זכויות ונסח טאבו וכן כל מידע אחר הנוגע לנכסים כולל פרטים שנמסרו בע"פ, בכתב או בכל אופן אחר.
        </p>

        <p>
            3. הלקוח מתחייב להודיע למתווך מיד על כל שינוי שיחול במצב הנכסים, ו/או זכויותיו בהם, ו/או במצבו המשפטי והכלכלי של הלקוח, ככל שיהיו כאלה.
        </p>

        <p>
            4. תקופת הבלעדיות לא תחול ביחס לרוכשים/שוכרים שהלקוח הציג אותם למתווך בכתב, וזאת עד למועד תחילת ההסכם כמפורט לעיל.
        </p>

        <p>
            5. כל התמורה עבור מכירה/השכרה, תהא אשר תהא, כולל כל השירותים הנלווים והטבות שונות, תיחשב לצורך חישוב דמי התיווך. 
        </p>

        <p>
            6. דמי תיווך בסך <span style="color:red;">${list.commission} ${
      list.rent_sale === "sale" ? "% עמלה" : "חודשי שכירות"
    }</span> שישולמו על פי האמור בטופס הזמנת שירותי תיווך ו/או טופס ההזמנה המצורף לדוח זה, ישולמו במלואם למתווך על ידי הלקוח בתום ביצוע עסקת מכר/שכירות, בסכום שנקבע בטופס ההזמנה גם אם בסופו של דבר בוצעה עסקה כלשהי במחיר גבוה או נמוך יותר מהמחיר הרשום בהזמנה.
        </p>

        <p>
            7. הלקוח מצהיר ומתחייב כי הנכסים האמורים בטופס ההזמנה, הינם בבעלותו הבלעדית, והינם פנויים מכל זכות של צד ג'.
        </p>

        <p>
            8. במידה ויתגלה כי במועד ביצוע העסקה, הנכסים נשוא טופס הזמנת השירות ו/או טופס ההזמנה המצורף לדוח זה אינם נקיים מכל עיקול/הגבלה או זכות צד ג' והלקוח או כל אחד מבני משפחתו לא הודיעו למתווך על כך, אזי הלקוח יחויב בתשלום דמי תיווך ע"פ הסכום שנקבע בטופס ההזמנה במלואו, גם אם לא נחתמה עסקה בסופו של דבר.
        </p>

        <p>
            9. המתווך מצהיר בזאת כי הוא מחזיק ברישיון תיווך תקף מטעם משרד המשפטים, תקף לביצוע עסקאות מקרקעין כפי שמפורט בטופס הזמנה זה.
        </p>

        <p>
            10. מוסכם כי כל סכסוך ו/או חילוקי דעות בין הצדדים בכל הנוגע להזמנת שירותי התיווך, תביעות הדדיות ו/או כל דבר הקשור להסכם זה יובא להכרעה בפני בורר מוסכם שמונה על ידי הצדדים, אלא אם כן יחליטו הצדדים אחרת בהסכמה מפורשת ביניהם.
        </p>

        <p>
            11. המתווך מתחייב לבצע פעולות שיווק של הנכסים האמורים בהזמנה זו, על חשבונו, לרבות ביצוע פעולות פרסום, שלטי חוצות, סיורי בית פתוח וכיו"ב, לצורך מכירת הנכס, והמכירה תכלול כל התמורה הנובעת משיווק הנכס, במידה שווה ו/או מכר חוזר ו/או אופן אחר, ככל שיוסכם ו/או ייקבע ע"י הצדדים ובכפוף לכל דין.
        </p>

        <p>
            12. ידוע למתווך כי לקוחות המזמינים שירותי תיווך במקרקעין מבקשים לקבל שירות מקצועי ואמין ממתווך המורשה למכירת נכסים. כל הפעולות שיינקטו על ידו לצורך ביצוע הזמנת שירותי התיווך נעשות על פי דין ובהתאם לדרישות הלקוח, וכי ככל שיושג כל הסכם למכירת הנכס, התמורה תכלול כל סכום שיוסכם בין הצדדים גם אם סוכם בע"פ או בכתב ו/או באמצעי תקשורת אחרים.
        </p>


            <p>
                הלקוח מזמין בזאת שירותי תיווך מהמתווך לצורך ביצוע עסקת מכר או השכרת הנכסים המפורטים בטבלה לעיל (להלן: "הנכסים"). 
                הזמנה זו מקנה למתווך בלעדיות למכירת הנכסים לתקופה המתחילה מ- 
                
                
                ${
                  list?.from_date
                    ? new Date(list.from_date).toLocaleDateString("sv-SE")
                    : new Date(list?.created_at).toLocaleDateString("sv-SE")
                } 
              ועד ל- 
              ${
                list?.to_date
                  ? new Date(list.to_date).toLocaleDateString("sv-SE")
                  : new Date(
                      new Date(list?.created_at).setMonth(
                        new Date(list?.created_at).getMonth() +
                          parseInt(list?.period)
                      )
                    ).toLocaleDateString("sv-SE")
              }.

            </p>
            <p>
                ההזמנה כפופה לתנאים ולתעריפים שייקבעו בהמשך מסמך זה.
            </p>

        </div>

        <!-- Signature Section -->
        <div class="sign-section">
            <p>חתימה</p>
            <p>
            ${
              list.signUrl
                ? `<img class="" src="${list.signUrl}" onload="this.style.display='inline-block'" />`
                : `Your Signature Here`
            }
            </p>
        </div>

        <!-- Footer Section -->
        <div class="footer">
            <div>
                <p>הופק על ידי מערכת ברוקרלנד</p>
                <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
            </div>
            <p>
              ${new Date(list?.created_at).toLocaleDateString(
                "sv-SE"
              )} ${new Date(list?.created_at).toLocaleTimeString("sv-SE", {
      hour: "2-digit",
      minute: "2-digit",
    })}</p>
        </div>
      </main>
    </div>
  `;

    if (list?.kind_of_doc === "commission") {
      setHtmlContent(contentToAppend);
    }

    if (list?.kind_of_doc === "partnership") {
      setHtmlContent(partnershipContentToAppend);
    }
    if (list?.kind_of_doc === "exclusivity") {
      setHtmlContent(exclusivityContentToAppend);
    }
  }, [list]);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false}>
              <Col xs={24} xl={24}>
                <div className="w-100 d-flex align-items-baseline text-head_right_cont">
                  <div className="pageHeadingSearch clint-input ">
                    <h1 className="text-center">
                      {list.clientInfo?.name} you have Document to sign
                    </h1>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <div className="im-ex-btn mb-3 mt-3">
                    {!list.signUrl && (
                      <Button
                        title="Export"
                        className="documents-view-btn "
                        onClick={() => {
                          setSelected(list);
                          setSignModal(true);
                        }}
                      >
                        {/* <FileUploadOutlinedIcon /> */}
                        <DrawIcon />
                        Sign The Document
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </div>
              </Col>
            </Card>
          </Col>
        </Row>
      </div>

      {/* 
      <Row gutter={[24, 24]} className="mt-4">
        <Col span={24} md={6} sm={8}>
          <div className="document-type-main">
            <div className="document-icon-img-new">
              <img src={image1} />
              <div className="im-ex-btn">
                <Link
                  title="Preview"
                  className=" man-list-bttn "
                  onClick={() => handlePreview(list)}
                >
                  <EyeOutlined />
                </Link>

                <Link
                  title="Import"
                  className=" man-list-bttn "
                  onClick={() => {
                    setSelected(list);
                    setPdfModal(true);
                  }}
                >
                  <FileDownloadOutlinedIcon />
                </Link>

                {!list.signUrl && (
                  <Link
                    title="Export"
                    className=" man-list-bttn "
                    onClick={() => {
                      setSelected(list);
                      setSignModal(true);
                    }}
                  >
                    <FileUploadOutlinedIcon />
                  </Link>
                )}
              </div>
            </div>

            <div className="signature">{list?.title}</div>
            <div className="signature-date">{list?.description}</div>
          </div>
        </Col>
      </Row> */}

      {/* {pdfModal && (
        <PdfModal
          title={lang("Download PDF")}
          subtitle={lang("Download The PDF for " + selected.title)}
          show={pdfModal}
          data={selected}
          hide={() => {
            setPdfModal(false);
            setSelected();
          }}
          onOk={() => handleDownload(selected)}
        />
      )} */}

      {signModal && (
        <SignatureCanva
          title={lang("Upload Signature")}
          subtitle={lang("Upload The Signature for " + selected?.title)}
          show={signModal}
          data={selected}
          hide={() => {
            setSignModal(false);
            setSelected();
          }}
          onOk={(e) => onUpload(e, selected._id)}
        />
      )}
      <Modal
        title=""
        visible={previewModal}
        onCancel={() => setPreviewModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setPreviewModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Modal>
    </>
  );
}

export default SignatureDocument;
