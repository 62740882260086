import React, { createContext, useState, useContext, useEffect } from "react";

export const ThemeStateContext = createContext();

export const ThemeContextProvider = ({ children }) => {

    const [theme, setTheme] = useState("light");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
    }, [])
    return (
        <ThemeStateContext.Provider
            value={{
                theme,
                setTheme,
            }}
        >
            {children}
        </ThemeStateContext.Provider>
    );
};

export const useThemeContext = () => {
    return useContext(ThemeStateContext);
};

