import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useRequest from '../hooks/useRequest';
import { ShowToast, Severty } from '../helper/toast';
import { Button, Card, Typography, Spin, Result } from 'antd';
import lang from "../helper/langHelper";
import { AuthContext } from "../context/AuthContext";
const { Title, Text } = Typography;

const ProcessSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const { request } = useRequest();
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {
      userId: searchParams.get('userId'),
      transactionInternalNumber: searchParams.get('transactionInternalNumber'),
      order_reference: searchParams.get('order_reference'),
      ordernumber: searchParams.get('ordernumber'),
      uniqId: searchParams.get('uniqId'),
      statusCode: searchParams.get('statusCode'),
      token: searchParams.get('token'),
      Last4Digits: searchParams.get('Last4Digits'),
      TokenExpirationMonth: searchParams.get('TokenExpirationMonth'),
      TokenExpirationYear: searchParams.get('TokenExpirationYear'),
    };

    const paramsData = {
      transactionInternalNumber: searchParams.get('transactionInternalNumber'),
      ordernumber: searchParams.get('ordernumber'),
      statusCode: searchParams.get('statusCode') === '0' ? 'Success' : 'Pending',
    };

    setQueryParams(paramsData);
    sendApiData(params);
  }, [location.search]);

  const sendApiData = async (data) => {
    try {
      await request({
        url: 'common/save-transaction',
        method: 'POST',
        data,
        onSuccess: (data) => {
          console.log(data, "data")
          setResponseMessage("Transaction saved successfully!");
          setIsLoggedIn(true);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));
          setUserProfile(data.data.user);
          ShowToast(data.message, Severty.SUCCESS);

        },
      });
    } catch (error) {
      setResponseMessage("Error sending data.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <Card style={{ maxWidth: '600px', margin: 'auto', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <Title level={2} style={{ textAlign: 'center' }}>{lang("Transaction Status")}</Title>

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            {responseMessage.includes("Error") ? (
              <Result
                status="error"
                title="Transaction Failed"
                subTitle={responseMessage}
              />
            ) : (
              <Result
                status="success"
                title={lang("Transaction Successful")}
                subTitle={lang("Your transaction has been processed successfully")}
              />
            )}
          </>
        )}

        <div style={{ paddingTop: '20px' }}>
          <Text strong>{lang("Transaction Details")}</Text>
          <ul style={{ paddingLeft: '20px' }}>

            <li> {lang('Transaction ID')}: {queryParams.transactionInternalNumber}
            </li>
            <li> {lang('Order Number')}: {queryParams.ordernumber}
            </li>
            <li>  {lang('Status')}: {queryParams.statusCode === '0' ? lang('Success') : lang('Pending')}
            </li>
            {/* {Object.keys(queryParams).map((key) => (
              <li key={key}><span>{queryParams[key]}:</span><span>{key}</span></li>
            ))} */}
          </ul>
        </div>

        <Button type="primary" onClick={() => navigate('/')} style={{ marginTop: '20px', width: '100%' }}>
          {lang('Back to Home')}
        </Button>
      </Card>
    </div>
  );
};

export default ProcessSuccess;
