import {
  Card,
  Col,
  Progress,
  Radio,
  Row,
  Select,
  Skeleton,
  Tabs,
  Typography,
  Table,
  Tag,
  Image,
  Avatar,
  Dropdown,
  Space,
  Button,
  Pagination,
  Slider,
  InputNumber,
  Form,
  Input,
  DatePicker,
  Checkbox,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  PlusOutlined,
  SettingFilled,
  StarOutlined,
  PhoneOutlined,
  StarFilled,
  MailOutlined,
  WhatsAppOutlined,
  UserOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  AreaChartOutlined,
  ShopOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import contact01 from "../../assets/images/contact01.png";
import WhatsAppIcon from "../../assets/images/whatsapp-icon.svg";
import GmailFillIcon from "../../assets/images/gmail.svg";
import SkypeIcon from "../../assets/images/skype-icon.svg";
import Timeicon from "../../assets/images/time-icon.svg";
import GmailIcon from "../../assets/images/gmail-icon.svg";
import CallingIcon from "../../assets/images/calling-icon.svg";
import ChatlogoImg from "../../assets/images/phone-logo.svg";
import ThreeDotsImg from "../../assets/images/three-dots.png";
import ForrentIcon from "../../assets/images/for-rent-icon.svg";
import ForsaleIcon from "../../assets/images/for-sale-icon.svg";
import ProfileImg from "../../assets/images/partners.png";
import Chatlogo2Img from "../../assets/images/massage-icon.svg";
import Chatlogo3Img from "../../assets/images/file-icon.svg";
import clint22Img from "../../assets/images/partners.png";
import LocationIcon from "../../assets/images/location-icon-01.svg";
import pdfImg from "../../assets/images/pdf-photo.png";
import ArrowImg from "../../assets/images/Arrrowhouse.png";
import House1Img from "../../assets/images/houseimg1.png";
import { useAuthContext } from "../../context/AuthContext";
import DoubleBedIcon from "../../assets/images/double-bed.svg";
import ProprtFlorIcon from "../../assets/images/building-icon.svg";
import HomeProprtyIcon from "../../assets/images/home-proprty.svg";
import SquareIcon from "../../assets/images/square.svg";
import HomeImage from "../../assets/images/home.jpeg";
import lang from "../../helper/langHelper";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
mapboxgl.accessToken =
  "pk.eyJ1IjoieW9hdjUyMDIiLCJhIjoiY2x3a3UwMG9zMGh1dDJpcDg4Mm4wanJzaCJ9.uY8D_Vhu9YEBbdULrVBHqg";

const filterpt = [
  {
    text: "Apartment",
    value: "דירה",
  },
  {
    text: "House / Cottage",
    value: "קוטג'",
  },
  {
    text: "Garden Apartment",
    value: "דירת גן",
  },
  {
    text: "Townhouse",
    value: "דו משפחתי",
  },
  {
    text: "Rooftop / Penthouse",
    value: "גג/ פנטהאוז",
  },
  {
    text: "Penthouse",
    value: "פנטהאוז",
  },
  {
    text: "Mini Penthouse",
    value: "מיני פנטהאוז",
  },
  {
    text: "Rooftop Apartment",
    value: "דירת גג",
  },
  {
    text: "Studio / Loft",
    value: "סטודיו/ לופט",
  },
  {
    text: "Duplex",
    value: "דופלקס",
  },
  // 11 extra
  {
    text: "Triplex",
    value: "טריפלקס",
  },
  {
    text: "Apartment Unit",
    value: "יחידת דיור",
  },
  {
    text: "Plots",
    value: "מגרשים",
  },
  {
    text: "Farmhouse / Homestead",
    value: "משק חקלאי/ נחלה",
  },
  {
    text: "Building",
    value: "בניין",
  },
  {
    text: "Divided Apartment",
    value: "דירה מחולקת",
  },
  {
    text: "Basement Apartment",
    value: "דירת מרתף",
  },
  {
    text: "Basement / Parter",
    value: "מרתף/ פרטר",
  },
  {
    text: "Residential Building",
    value: "בניין מגורים",
  },
  {
    text: "Vacation Apartment",
    value: "דירת נופש",
  },
  {
    text: "Parking",
    value: "חניה",
  },
  {
    text: "Storage",
    value: "מחסן",
  },
  {
    text: "Other",
    value: "אחר",
  },
];

const data = [
  {
    key: "1",
    size: "#225555",
    client: "Ronald Richards",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    key: "2",
    size: "#23134855",
    client: "Darrell Steward",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    key: "3",
    size: "#54394837",
    client: "Marvin McKinney",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
  },
  {
    key: "4",
    size: "#54394837",
    client: "Ronald Richards",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    key: "5",
    size: "#54394837",
    client: "Ronald Richards",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    key: "6",
    size: "#54394837",
    client: "Marvin McKinney",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
  },
];

const columns = [
  {
    title: "",
    dataIndex: "check",
    className: "transiation-roww-0",

    render: (text, record) => (
      <span>
        <Checkbox />
      </span>
    ),
  },

  {
    title: "NAME",
    dataIndex: "client",
    className: "transiation-roww",

    render: (text, record) => (
      <span>
        <Avatar src={record.avatar} icon={<UserOutlined />} /> {text}
      </span>
    ),
  },

  {
    title: "Size",
    dataIndex: "size",
    className: "transiation-roww-2",
  },

  {
    title: "Date Created",
    dataIndex: "date",
    className: "transiation-roww-3",
  },
  {
    title: "File Type",
    dataIndex: "pdf",
    className: "transiation-roww-4",
    render: (text, record) => (
      <span>
        <img src={pdfImg} /> {text}
      </span>
    ),
  },
  {
    title: "Uploaded By",
    dataIndex: "Uploader",
    className: "transiation-roww-5",
  },
];

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}
const years = [2024, 2023];
const { Option } = Select;

function Index({ }) {
  const { id } = useParams();

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <ClientDetails id={id} />
        </Col>
      </Row>
    </>
  );
}

const ClientDetails = ({ id }) => {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sellCount, setSellCount] = useState(0);
  const [rentCount, setRentCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [year, setYear] = useState(years[0]);
  const [clientDetails, setClientDetails] = useState([]);
  const { userProfile } = useAuthContext();
  const [clientTasks, setClientTasks] = useState();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState("");

  const params = new URLSearchParams(location.search);
  const setKeyParam = params.get("setKey");

  const targetblank = (url) => {
    window.open(url, "_blank");
  };
  const getClientData = (activeButton) => {
    request({
      url: apiPath.viewOwnerDetail + "/" + id + "?to_let=" + activeButton,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        setClientDetails(data.data.list);
        setRentCount(data.data.rentCount);
        setSellCount(data.data.sellCount);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getClientData(activeButton);
  }, [activeButton]);
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };
  return (
    <div className="user-profile-card">
      <Col span={24} className="mb-3">
        <Button onClick={() => navigate(-1)}>
          <ArrowLeftOutlined />
          {lang("Back")}
        </Button>
      </Col>
      <div className="main-deatil-outer">
        <div className="view-detail-claint">
          <h2 className="name">
            {id ? `${id}` : "-"}
            <span>Total Assets : {clientDetails.length}</span>
          </h2>
          <div className="contact-info">
            <p className="phone">
              <img src={CallingIcon} />
              {clientDetails.length > 0
                ? clientDetails[0]?.country_code +
                "-" +
                clientDetails[0]?.mobile_number
                : "-"}
            </p>
          </div>
          <div className="rents-flow">
            <div className="social-icons">
              <img
                src={WhatsAppIcon}
                className="social-icon whatsapp"
                onClick={() =>
                  targetblank(
                    `https://api.whatsapp.com/send/?phone=${clientDetails.length > 0
                      ? clientDetails[0]?.mobile_number
                      : "-"
                    }`
                  )
                }
              />

              <img src={GmailFillIcon} className="social-icon linkedin" />
            </div>
            <div className="sale-btnsb">
              <div className="publick-asset">
                <button
                  className={`rent-btns ${activeButton === "buy" ? "active" : ""
                    }`}
                  onClick={() => handleButtonClick("buy")}
                >
                  <img src={ForsaleIcon} />
                  למכירה
                </button>
              </div>
              <div className="publick-asset">
                <button
                  className={`rent-btns ${activeButton === "rent" ? "active" : ""
                    }`}
                  onClick={() => handleButtonClick("rent")}
                >
                  <img src={ForrentIcon} />
                  להשכרה
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Search
        id={id}
        clientAssets={clientDetails}
        rentCount={rentCount}
        sellCount={sellCount}
      />
    </div>
  );
};

// Search Tab -->
const Search = ({ id, clientAssets, rentCount, sellCount }) => {
  const mapContainerRef = useRef(null);
  const { request } = useRequest();
  const [mortgageReq, setMortgageReq] = useState(true);
  const [year, setYear] = useState(years[0]);
  const [loading, setLoading] = useState(false);
  const { userProfile } = useAuthContext();
  const [clientAsset, setClientAsset] = useState();
  const [clientCityAsset, setClientCityAsset] = useState();
  // const { id } = useParams();
  const [optionType, setOptionType] = useState([]);
  const [bedrooms, setBedrooms] = useState();
  const [minYield, setMinYield] = useState();
  const [propertyType, setPropertyType] = useState();
  const [priceLimit, setPriceLimit] = useState([0, 100000]);
  const [properties, setProperties] = useState([]);
  const [clientAll, setClientAll] = useState([]);
  const [location, setLocation] = useState({});
  const [clientOptionType, setClientOptionType] = useState("");
  const [filter, setFilter] = useState({});
  // const [clientDetails, setClientDetails] = useState();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [page, setPage] = useState(1);
  const handlePageChange = (page) => {
    setPage(page);
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const uniqueCities = new Set();

  useEffect(() => {
    if (!id) return;
    if (!clientAssets.length > 0) return;

    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    const paginatedAssets = clientAssets.slice(startIndex, endIndex);
    setClientAsset(paginatedAssets);
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      total: clientAssets.length,
    }));
    setClientAll(clientAssets);
    setLocation({
      lng: clientAssets[0].longitude,
      lat: clientAssets[0].latitude,
    });
  }, [id, clientAssets, page]);

  useEffect(() => {
    if (!id) return;
    request({
      url: `${apiPath.listforMap}`,
      method: "GET",
      onSuccess: (data) => {
        setProperties(data.data.data);
      },
    });
  }, [id]);

  const cleanMobileNumber = (mobileNumber) => {
    if (!mobileNumber) return;
    return mobileNumber.replace("-", "");
  };

  const onHandleClick = (id) => {
    setLoading(true);
    request({
      url: apiPath.editAsset + "/" + id,
      method: "PUT",
      data: {
        broker_id: true,
      },

      onSuccess: (data) => {
        setLoading(false);
        if (data?.status) {
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Card className="home-card-h card-border-show">
        <h4>{lang("Assets Of")} {id}</h4>

        <Row gutter={[24, 24]}>
          <Col span={24} md={12} className="col-p">
            <div className="price-range">
              {/* <h4>Price Range</h4> */}
              <Map
                mapContainerRef={mapContainerRef}
                properties={clientAll}
              // singleAsset={clientAll}
              // location={location}
              />
            </div>
          </Col>
          <Col span={24} md={12}>
            <div className="main-result-headding">
              <h5> {lang("Showing")} {clientAssets.length} {lang("Results")}</h5>
              <div>
                <h5>
                  {lang("Rent")} (<strong> {rentCount}</strong> ) / {lang("Sale")} (
                  <strong> {sellCount} </strong>){" "}
                </h5>
              </div>
            </div>
            <div className="sale-body-inner">
              <Row gutter={[24, 24]}>
                {clientAsset &&
                  clientAsset?.map((item) => (
                    <Col span={24} xl={12} className="col-p-sm">
                      <a href={"/asset-view/" + item._id} target="_blank">
                        <div className="slider-pro-block">
                          <div className="swiper img-slider1">
                            <div className="swiper-wrapper">
                              <div className="swiper-slide">
                                <img
                                  loading="lazy"
                                  src={
                                    item.multiImage.length > 0
                                      ? item.multiImage[0]
                                      : HomeImage
                                  }
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/asset-view/${item._id}`,
                                  //     "_blank"
                                  //   )
                                  // }
                                  alt="Palm Dom 4 Hadera"
                                />
                              </div>
                            </div>
                            <div className="swiper-nav-inner d-none">
                              <div className="swiper-inner-btn swiper-button-prev">
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.14648 11L1.0977 6L6.14648 1"
                                    fill="white"
                                  ></path>
                                  <path
                                    d="M6.14648 11L1.0977 6L6.14648 1"
                                    stroke="#102846"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="swiper-inner-btn swiper-button-next">
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.853516 11L5.90228 6L0.853515 1"
                                    fill="white"
                                  ></path>
                                  <path
                                    d="M0.853516 11L5.90228 6L0.853515 1"
                                    stroke="#102846"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="slider-block-content">
                            <div className="content-between mb-30">
                              <div className="price-locate-data">
                                <div className="price-block">
                                  {new Intl.NumberFormat().format(
                                    item.total_price
                                  )}{" "}
                                  ₪
                                </div>
                                <div className="location">
                                  <svg
                                    width="13"
                                    height="15"
                                    viewBox="0 0 13 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z"
                                      stroke="#464A6B"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z"
                                      stroke="#464A6B"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span>
                                    {item.location?.city}{" "}
                                    {item.location?.neighborhood}
                                    {item.location?.street === "0"
                                      ? ""
                                      : item.location?.street}
                                    {item.location?.street_number === "0"
                                      ? ""
                                      : item.location?.street_number}
                                  </span>
                                </div>
                              </div>
                              <div className="dlx-btns">
                                {!item.broker_id.includes(userProfile._id) && (
                                  <Button
                                    onClick={() => onHandleClick(item._id)}
                                    className="add-btn"
                                  >
                                    <PlusOutlined />
                                  </Button>
                                )}
                                {item.mobile_number ? (
                                  <a
                                    // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${item._id}`}
                                    // href={`https://wa.me/+`}
                                    href={
                                      "https://wa.me/" +
                                      cleanMobileNumber(item.mobile_number)
                                    }
                                    target="_blank"
                                    type="button"
                                    title="Add to wishlist"
                                    data-id="135362"
                                    className="wishlisted"
                                  >
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="WhatsAppIcon"
                                    >
                                      <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                                    </svg>
                                  </a>
                                ) : null}
                                <a
                                  href={`callto:${cleanMobileNumber(
                                    item.mobile_number
                                  )}`}
                                  target="_blank"
                                  className="wishlisted"
                                >
                                  <PhoneOutlined />
                                </a>
                              </div>
                            </div>
                            <ul className="list-unstyled mb-0 property-list">
                              <li>
                                <div className="item-name">
                                  <img src={DoubleBedIcon} />
                                  <span>rooms</span>
                                </div>
                                <span>{item.rooms_number}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img src={ProprtFlorIcon} />
                                  <span>floor</span>
                                </div>
                                <span>{item.floor_number}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img src={HomeProprtyIcon} />
                                  <span>Property Type</span>
                                </div>
                                <span>{item.property_type}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img src={SquareIcon} />
                                  <span>size</span>
                                </div>
                                <span>{item.size_in_meters}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </Col>
                  ))}
              </Row>
            </div>
            <Pagination
              className="pagination-section"
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handlePageChange}
              showSizeChanger={false}
              pageSizeOptions={["10", "20", "30", "50"]}
              onShowSizeChange={(current, size) => {
                setPagination((prev) => ({
                  ...prev,
                  pageSize: size,
                  current: 1, // Reset to the first page
                }));
                // fetchData(
                //   {
                //     ...pagination,
                //     pageSize: size,
                //     current: 1,
                //   },
                //   filter
                // );
              }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Index;

const Map = ({ mapContainerRef, properties }) => {
  const [hoveredFeature, setHoveredFeature] = useState(null);
  useEffect(() => {
    if (!mapContainerRef.current) return;
    if (!properties) return;
    if (!properties[0]?.longitude && !properties[0]?.latitude) return;
    // Initialize the Mapbox map
    let map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center:
        properties.length > 0
          ? [properties[0].longitude, properties[0].latitude]
          : [34.7818, 32.0853],
      zoom: 14, // Initial map zoom level
    });

    // if (location.lng && location.lat) {
    //   map = new mapboxgl.Map({
    //     container: mapContainerRef.current,
    //     style: "mapbox://styles/mapbox/streets-v12",
    //     center:
    //       singleAsset.length > 0
    //         ? [properties[0].longitude, properties[0].latitude]
    //         : [location.lng, location.lat],
    //     zoom: 14, // Initial map zoom level
    //   });
    // }

    map.addControl(new mapboxgl.FullscreenControl());

    map.on("load", () => {
      // Add your property data source with clustering enabled
      map.addSource("properties", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: properties.map((property) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [
                property.longitude ? property.longitude : 0.0,
                property.latitude ? property.latitude : 0.0,
              ],
            },
            properties: {
              total_price: property.total_price,
              address: property.location.city,
              rooms: property.rooms_number,
              floor: property.floor_number,
              property_type: property.property_type,
              size: property.size_in_meters,
              _id: property._id,
              to_let: property.to_let,
              is_center:
                property.latitude === properties.length > 0
                  ? properties[0].latitude
                  : 32.0853 && property.longitude === properties.length > 0
                    ? properties[0].longitude
                    : 34.7818,
            },
          })),
        },
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 80,
      });
      // Add your Single Asset data source with clustering enabled
      // map.addSource("singleAsset", {
      //   type: "geojson",
      //   data: {
      //     type: "FeatureCollection",
      //     features: singleAsset.map((property) => ({
      //       type: "Feature",
      //       geometry: {
      //         type: "Point",
      //         coordinates: [property.longitude, property.latitude],
      //       },
      //       properties: {
      //         total_price: property.total_price,
      //         address: property.location.city,
      //         rooms: property.rooms_number,
      //         floor: property.floor_number,
      //         property_type: property.property_type,
      //         size: property.size_in_meters,
      //         _id: property._id,
      //         to_let: property.to_let,
      //         is_center:
      //           property.latitude === properties[0].latitude &&
      //           property.longitude === properties[0].longitude,
      //       },
      //     })),
      //   },
      //   cluster: true,
      //   clusterMaxZoom: 10,
      //   clusterRadius: 45,
      // });

      // Add a layer to display the clusters Zoom out circle
      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "properties",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#51bbd6",
          "circle-radius": 20,
        },
      });
      // Add a layer to display the clusters Single Zoom out circle
      // map.addLayer({
      //   id: "clustersSingle",
      //   type: "circle",
      //   source: "singleAsset",
      //   filter: ["has", "point_count"],
      //   paint: {
      //     "circle-color": "#cf692d", // Red color for the centered property
      //     "circle-radius": 20, // Larger radius for the centered property
      //   },
      // });

      // Add a layer for the cluster count Zoom out circle count
      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "properties",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });
      // Add a layer for the cluster Single count Zoom out circle count
      // map.addLayer({
      //   id: "cluster-countSingle",
      //   type: "symbol",
      //   source: "singleAsset",
      //   filter: ["has", "point_count"],
      //   layout: {
      //     "text-field": "{point_count}",
      //     "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      //     "text-size": 12,
      //   },
      // });

      // Add a layer for unclustered points small dots
      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "properties",
        filter: [
          "all",
          ["!", ["has", "point_count"]],
          ["!=", ["get", "is_center"], true],
        ],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });
      // Add a layer for unclustered points small dots
      // map.addLayer({
      //   id: "unclustered-pointSingle",
      //   type: "circle",
      //   source: "singleAsset",
      //   filter: [
      //     "all",
      //     ["!", ["has", "point_count"]],
      //     ["!=", ["get", "is_center"], true],
      //   ],
      //   paint: {
      //     "circle-color": "#cf692d",
      //     "circle-radius": 8,
      //     "circle-stroke-width": 1,
      //     "circle-stroke-color": "#fff",
      //   },
      // });

      // Add a layer for the only centered point
      // map.addLayer({
      //   id: "centered-point",
      //   type: "circle",
      //   source: "singleAsset",
      //   filter: [
      //     "all",
      //     ["!", ["has", "point_count"]],
      //     ["==", ["get", "is_center"], true],
      //   ],
      //   paint: {
      //     "circle-color": "#cf692d", // Red color for the centered property
      //     "circle-radius": 8, // Larger radius for the centered property
      //     "circle-stroke-width": 2,
      //     "circle-stroke-color": "#fff",
      //   },
      // });

      let pricePopup = null;
      let detailPopup = null;

      map.on("mouseenter", "unclustered-point", (e) => {
        // if (detailPopup) return;

        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price } = e.features[0].properties;

        if (pricePopup) pricePopup.remove();

        pricePopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(coordinates)
          .setHTML(
            `<div style="" class="locati02" >₪ ${new Intl.NumberFormat().format(
              total_price
            )}</div>`
          )
          .addTo(map);

        setHoveredFeature(e.features[0]);
      });

      map.on("mouseleave", "unclustered-point", () => {
        map.getCanvas().style.cursor = "";
        if (pricePopup) pricePopup.remove();
        setHoveredFeature(null);
      });

      // Add popups for unclustered points
      map.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const {
          total_price,
          address,
          rooms,
          floor,
          property_type,
          size,
          _id,
          to_let,
        } = e.features[0].properties;

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )} </h3>
            <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg><span>${address}</span></div>
            <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
            </ul></div></a>`
          )
          .addTo(map);
      });
      // map.on("mouseenter", "unclustered-pointSingle", (e) => {
      //   // if (detailPopup) return;

      //   map.getCanvas().style.cursor = "pointer";

      //   const coordinates = e.features[0].geometry.coordinates.slice();
      //   const { total_price } = e.features[0].properties;

      //   if (pricePopup) pricePopup.remove();

      //   pricePopup = new mapboxgl.Popup({
      //     closeButton: false,
      //     closeOnClick: false,
      //   })
      //     .setLngLat(coordinates)
      //     .setHTML(
      //       `<div style="" class="locati02" >₪ ${new Intl.NumberFormat().format(
      //         total_price
      //       )}</div>`
      //     )
      //     .addTo(map);

      //   setHoveredFeature(e.features[0]);
      // });

      // map.on("mouseleave", "unclustered-pointSingle", () => {
      //   map.getCanvas().style.cursor = "";
      //   if (pricePopup) pricePopup.remove();
      //   setHoveredFeature(null);
      // });

      // // Add popups for unclustered points
      // map.on("click", "unclustered-pointSingle", (e) => {
      //   const coordinates = e.features[0].geometry.coordinates.slice();
      //   const { total_price, address, rooms, floor, property_type, size, _id } =
      //     e.features[0].properties;

      //   new mapboxgl.Popup()
      //     .setLngLat(coordinates)
      //     .setHTML(
      //       `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
      //         total_price
      //       )}</h3>
      //       <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
      //         <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      //           <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
      //           <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
      //         </svg><span>${address}</span></div>
      //       <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
      //         <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
      //         <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
      //         <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
      //         <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
      //       </ul></div></a>`
      //     )
      //     .addTo(map);
      // });

      // Add popups for the centered point
      map.on("click", "centered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price, address, rooms, floor, property_type, size, _id } =
          e.features[0].properties;

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )}</h3>
            <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg><span>${address}</span></div>
            <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
            </ul></div></a>`
          )
          .addTo(map);
      });

      // Zoom to cluster expansion on click
      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });

        const clusterId = features[0].properties.cluster_id;
        map
          .getSource("properties")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
      });

      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });

      // map.on("click", "clustersSingle", (e) => {
      //   const features = map.queryRenderedFeatures(e.point, {
      //     layers: ["clustersSingle"],
      //   });

      //   const clusterId = features[0].properties.cluster_id;
      //   map
      //     .getSource("singleAsset")
      //     .getClusterExpansionZoom(clusterId, (err, zoom) => {
      //       if (err) return;

      //       map.easeTo({
      //         center: features[0].geometry.coordinates,
      //         zoom,
      //       });
      //     });
      // });

      // map.on("mouseenter", "clustersSingle", () => {
      //   map.getCanvas().style.cursor = "pointer";
      // });
      // map.on("mouseleave", "clustersSingle", () => {
      //   map.getCanvas().style.cursor = "";
      // });
    });
    // Cleanup on unmount
    return () => map.remove();
  }, [properties, mapContainerRef.current]);

  return (
    <div className="listing-details">
      <div className="details-section">
        <div style={{ width: "100%", height: "700px" }}>
          <div
            ref={mapContainerRef}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
