import {
    Button,
    Row,
    Col
} from "antd";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import { useAppContext } from "../../context/AppContext";
import EmailImg from "../../assets/images/email.svg"
const EmailSent = () => {

    const { request } = useRequest()
    const [data, setData] = useState('');
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const { email } = useAppContext()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        const token = urlParams.get('token');
        console.log(email, token, "email");


    }, [])


    return (
        <>
            <section className="main">
                <Container>
                    <div className="home-outers">
                        <Row gutter={24}>
                            <Col span={24} md={24}>
                                <div className="email-section">
                                    <div className="section-head-em">
                                        <img src={EmailImg} />
                                        <p> An Verification Email send to you on  email {email} please verify</p>
                                        <Button onClick={() => navigate(`/login`)}>Login</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </section>

        </>
    );
}


export default EmailSent;
