import { Image, Menu, Input , Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';

import { Severty, ShowToast } from "../../helper/toast";

import Logo from "../../assets/images/Logo.png"; 
import Disk from "../../assets/images/side_nav/discount.svg"; 
import Log from "../../assets/images/side_nav/log.svg"; 
import contact01 from "../../assets/images/contact01.png";
import contact02 from "../../assets/images/contact02.png";
import contact03 from "../../assets/images/contact03.png";
import contact04 from "../../assets/images/contact04.png";
import CallIcon from "../../assets/images/call-icon.svg";
import LocationIcon from "../../assets/images/location-icon.svg";
import VideoIcon from "../../assets/images/Video-icon.svg";

import { AuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import DeleteModal from "../DeleteModal";
import { useAppContext } from "../../context/AppContext";
import moment, { isMoment } from "moment";
const { Search } = Input;

export const countryWithAreas = [
  "646b2e0f46865f1f65565346", //Palestine
];

 

const deliveryChargeManager = [
  {
    key: "delivery-charge-manager",
    path: "/delivery-charge",
    icon: Disk,
    label: `${lang("Delivery Charge")} ${lang("Management")}`,
  },
];

function ContactSidenav({ color }) {
  const { pathname } = useLocation();
  const { userProfile, logout, setUserProfile } = useContext(AuthContext);
  const { country } = useAppContext();

  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState("vertical");
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const showDeleteConfirm = (record) => {
    setIsLogoutModalVisible(true);
    // logout();
  };

  const renderTitle = (item) => {
    return (
      <>
        <Image preview={false} src={item.icon} />
        <span className="label">{item.label}</span>
      </>
    );
  };

 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode("inline");
      } else {
        setMenuMode("vertical");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(moment());
    };

    const intervalId = setInterval(updateDateTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {loading ? (
        [1, 2, 3, 4, 5, 6].map((item) => <Skeleton active key={item} />)
      ) : (
        <>
          <div className="brand-logo">
            <NavLink to="" className="imgOuter">
              <img className="" src={Logo} alt="" />
            </NavLink>
            <div className="serach-box">
                 <Search placeholder="Ronald Richards"  />
            </div>
          </div>
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain-contact">
           
            <h4>Contacts</h4>
            <ul className="main-menu-section">
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact01} />
                  <span className="label">{("Darrell Steward")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact02} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact03} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact04} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact01} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact02} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact01} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact02} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact03} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact04} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact01} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact04} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact03} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li>
              <li className="sub-menu-con">
                <a>
                  <Image preview={false} src={contact04} />
                  <span className="label">{("Courtney Henry")}</span>
                </a>
              </li> 
            </ul>
          </Menu>
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain-contact">
          <div className="date-point">
            <h4>Today</h4>
            <span>13 Jun 24</span>
          </div>
           <ul className="main-menu-section">
             <li className="sub-menu-con">
               <a>
                 <Image preview={false} src={CallIcon} />
                 <span className="label">Call: Poison lvy</span>
               </a>
             </li>
             <li className="sub-menu-con">
               <a>
                 <Image preview={false} src={LocationIcon} />
                 <span className="label">Meeting: Bruce Wayne</span>
               </a>
             </li>
             <li className="sub-menu-con">
               <a>
                 <Image preview={false} src={VideoIcon} />
                 <span className="label">Video Conf: Nick Fury</span>
               </a>
             </li>
           </ul>
         </Menu>
        </>
      )}
      {isLogoutModalVisible && (
        <DeleteModal
          title={"Logout"}
          subtitle={`Are you sure you want to Logout the Application?`}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={async () => {
            setIsLogoutModalVisible(false); // Close the modal
            try {
              await logout();
            } catch (error) {
              console.error('Logout error:', error);
              ShowToast("Logout failed", Severty.ERROR);
            }
          }}
        />
      )}
    </>
  );
}

export default ContactSidenav;
