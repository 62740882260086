import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Statistic,
  Calendar,
  Table,
  List,
  Typography,
  Avatar,
  Space,
  Dropdown,
  Menu,
  Button,
  DatePicker,
  Badge,
  Image,
  Drawer,
} from "antd";
import {
  DownOutlined,
  PhoneOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PropertyImg from "../../assets/images/propertyies-img.png";
import ClientsIcon from "../../assets/images/Clients.svg";
import PropertiesIcon from "../../assets/images/Properties.svg";
import Home2 from "../../assets/images/side_nav/dash.svg";
import myclients2 from "../../assets/images/side_nav/myClient.svg";
import Contacts2 from "../../assets/images/side_nav/customer.svg";
import mytasks2 from "../../assets/images/side_nav/myTask.svg";
import Message2 from "../../assets/images/side_nav/message.svg";
import moment from "moment";
import { Severty, ShowToast } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import lang from "../../helper/langHelper";
import Footer from "../../components/layout/Footer";
import GoogleCalendarIntegration from "../Calendar/GoogleCalendarIntegration";
import useDebounce from "../../hooks/useDebounce";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HomeImage from "../../assets/images/home.jpeg";

const { Title, Text, Link } = Typography;
const { MonthPicker } = DatePicker;

const data = [
  { name: "Jan", uv: 20000 },
  { name: "Feb", uv: 10000 },
  { name: "Mar", uv: 60000 },
  { name: "Apr", uv: 40000 },
  { name: "May", uv: 100000 },
  { name: "Jun", uv: 150000 },
  { name: "Jul", uv: 50000 },
  { name: "Aug", uv: 90000 },
  { name: "Sep", uv: 70000 },
  { name: "Oct", uv: 30000 },
  { name: "Nov", uv: 120000 },
  { name: "Dec", uv: 10000 },
];

const dataSource = [
  {
    key: "1",
    transactionId: "#74563820",
    client: "Ronald Richards",
    date: "11 Jun, 2024",
    brokerFees: "$5000 (4%)",
    amount: "$20,000",
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    key: "2",
    transactionId: "#23134855",
    client: "Darrell Steward",
    date: "11 Jun, 2024",
    brokerFees: "$5000 (4%)",
    amount: "$20,000",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    key: "3",
    transactionId: "#54394837",
    client: "Marvin McKinney",
    date: "11 Jun, 2024",
    brokerFees: "$5000 (4%)",
    amount: "$20,000",
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    key: "4",
    transactionId: "#54394837",
    client: "Ronald Richards",
    date: "11 Jun, 2024",
    brokerFees: "$5000 (4%)",
    amount: "$20,000",
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    key: "5",
    transactionId: "#54394837",
    client: "Darrell Steward",
    date: "11 Jun, 2024",
    brokerFees: "$5000 (4%)",
    amount: "$20,000",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
];

mapboxgl.accessToken =
  "pk.eyJ1IjoieW9hdjUyMDIiLCJhIjoiY2x3a3UwMG9zMGh1dDJpcDg4Mm4wanJzaCJ9.uY8D_Vhu9YEBbdULrVBHqg";

const Dashboard = () => {
  const mapContainerRef = useRef(null);
  const { request } = useRequest();
  const navigate = useNavigate();
  const { userProfile } = useContext(AuthContext);
  const [searchCity, setSearchCity] = useState([userProfile.city]);
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [dateData, setDateData] = useState([]);
  const [userAssetlength, setuserAssetlength] = useState(null);
  const [userAsset, setuserAsset] = useState([]);
  const [userAssetpubliclength, setuserAssetpubliclength] = useState(null);
  const [allAssetlength, setAllAssetlength] = useState(null);
  const [loading, setLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [keyForDrop, setKeyForDrop] = useState();

  const [dateRange, setDateRange] = useState({ start: null, end: null });

  const [properties, setProperties] = useState([]);
  const debouncedProperties = useDebounce(properties, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [dashboard, setDashboard] = useState();

  const api = {
    getCount: apiPath.listAsset,
    getCountpublic: apiPath.list_public,
    getCountall: apiPath.list,
    getdatetask: apiPath.taskList,
  };
  // const onPanelChange = (value) => {
  //   setSelectedDate(value);
  //   console.log(selectedDate);
  // };

  const cleanMobileNumber = (mobileNumber) => {
    if (!mobileNumber) return;
    return mobileNumber.replace("-", "");
  };

  const columns1 = [
    {
      title: `${lang("Task / Meetings")}`,
      dataIndex: "task_name",
      key: "task_name",

      render: (_, col) => {
        return col.task_name ? (
          <span className="cap" onClick={() => showDrawer(col)}>
            {col.task_name}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: `${lang("Date")}`,
      dataIndex: "date_of_entry",
      key: "date_of_entry",
      render: (_, { date_of_entry }) => {
        const formattedDate = date_of_entry ? new Date(date_of_entry) : null;
        const currentDate = new Date();

        if (
          formattedDate &&
          formattedDate.getFullYear() === currentDate.getFullYear() &&
          formattedDate.getMonth() === currentDate.getMonth() &&
          formattedDate.getDate() === currentDate.getDate()
        ) {
          return "Today";
        } else {
          return formattedDate
            ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(formattedDate)
            : "-";
        }

        // return date_of_entry
        //   ? new Intl.DateTimeFormat("en-US", {
        //       year: "numeric",
        //       month: "2-digit",
        //       day: "2-digit",
        //     }).format(new Date(date_of_entry))
        //   : "-";
      },
    },
    {
      title: `${lang("Time")}`,
      dataIndex: "date_of_entry",
      key: "date_of_entry",
      render: (_, { date_of_entry }) => {
        return date_of_entry
          ? new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }).format(new Date(date_of_entry))
          : "-";
      },
    },
  ];

  const columns = [
    {
      title: lang("Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { multiImage, image }) => (
        <Image
          width={40}
          src={
            multiImage.length > 0 ? multiImage[0] : image ? image : HomeImage
          }
          className="table-img"
        />
      ),
    },
    {
      title: `${lang("City")}`,
      key: "location",
      dataIndex: "location",
      render: (_, { location }) => {
        return location ? location.city : "-";
      },
    },
    {
      title: lang("Address"),
      dataIndex: "location.neighborhood",
      key: "location.neighborhood",
      width: 200,
      render: (_, { location }) => {
        const city = location.city
          ? location.city === "0"
            ? ""
            : location.city
          : "";

        const neighborhood = location.neighborhood
          ? location.neighborhood === "0"
            ? ""
            : location.neighborhood
          : "";
        const street = location.street
          ? location.street === "0"
            ? ""
            : location.street
          : "";
        const street_number = location.street_number
          ? location.street_number === "0"
            ? ""
            : location.street_number
          : "";
        return `${city}${" "}${neighborhood}${" "}${street}${" "}${street_number}`;
      },
    },
    {
      title: lang("Price"),
      dataIndex: "total_price",
      key: "total_price",
      width: 200,
      render: (_, { total_price }) => {
        return total_price ? new Intl.NumberFormat().format(total_price) : "-";
      },
    },
    {
      title: lang("Rent/Sale"),
      dataIndex: "to_let",
      key: "to_let",
      width: 200,
      render: (_, { to_let }) => {
        return to_let === "buy" ? "Sale" : "Rent";
      },
    },
    {
      title: lang("Size (Meter.)"),
      dataIndex: "size_in_meters",
      key: "size_in_meters",
      width: 200,
      render: (_, { size_in_meters }) => {
        return size_in_meters
          ? new Intl.NumberFormat().format(size_in_meters)
          : "-";
      },
    },
    {
      title: lang("Rooms"),
      dataIndex: "rooms_number",
      key: "rooms_number",
      width: 200,
      render: (_, { rooms_number }) => {
        return rooms_number ? rooms_number : "-";
      },
    },
    {
      title: lang("Action"),
      key: "_id",
      dataIndex: "_id",
      render: (_, { make_public, _id, mobile_number, signed_doc }) => {
        return (
          <div
            className="d-flex justify-content-start"
            onClick={(e) => e.stopPropagation()}
          >
            <a
              // href={`https://api.whatsapp.com/send/?text=https://crm.brokerland.co.il/AssetShare/${userProfile.name}/${_id}`}
              className="whatsapp-cls"
              href={`https://wa.me/${cleanMobileNumber(mobile_number)}`}
            // target="_blank"
            >
              <WhatsAppIcon />
            </a>
            <a
              href={`callto:${cleanMobileNumber(mobile_number)}`}
              // target="_blank"
              className="wishlisted"
            >
              <PhoneOutlined />
            </a>
          </div>
        );
      },
    },
  ];
  const menu1 = (
    <Menu>
      <Menu.Item key="1">Daily</Menu.Item>
      <Menu.Item key="2">Weekly</Menu.Item>
      <Menu.Item key="3">Monthly</Menu.Item>
    </Menu>
  );

  const handleMenuClick = ({ key }) => {
    let startDate, endDate;
    setKeyForDrop(key);
    switch (key) {
      case "1": // 24 Hours
        startDate = moment().startOf("day");
        endDate = moment().endOf("day");
        break;
      case "2": // 7 Days
        startDate = moment().subtract(7, "days").startOf("day");
        endDate = moment().endOf("day");
        break;
      case "3": // 30 Days
        startDate = moment().subtract(30, "days").startOf("day");
        endDate = moment().endOf("day");
        break;
      default:
        break;
    }

    setDateRange({ start: startDate, end: endDate });

    const start_date = moment(startDate).format("YYYY-MM-DD");
    const end_date = moment(endDate).format("YYYY-MM-DD");
    request({
      url:
        api.getCount +
        "?start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&make_public=true",
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        console.log(data);
        setLoading(false);
        setuserAsset(data);
      },
      onError: (error) => {
        // setLoading(false);
        // ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onPanelChange = (value) => {
    const formattedDate = value.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    console.log(formattedDate, "formatted");
    console.log(selectedDate, "selected");
    request({
      url: api.getdatetask + "?cal_date=" + formattedDate + "&limit=" + 5,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        console.log(data, "data");
        setTaskList(data.docs);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    setDateData(data);
    const todayDate = moment().startOf("day");
    onPanelChange(todayDate);
    request({
      url: apiPath.dashboard + "/dashboard",
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setDashboard(data.data);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });

    request({
      url: api.getCount,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        setAllAssetlength(total);
      },
      onError: (error) => {
        // setLoading(false);
        // ShowToast(error, Severty.ERROR);
      },
    });
  }, [allAssetlength]);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">{lang("24 Hours")}</Menu.Item>
      <Menu.Item key="2">{lang("7 Days")}</Menu.Item>
      <Menu.Item key="3">{lang("30 Days")}</Menu.Item>
    </Menu>
  );

  const components = {};
  const handleChange = (pagination, filters) => {
    console.log(filters);
    fetchData(pagination, filters);
  };

  useEffect(() => {
    if (!debouncedSearchCity) return;
    request({
      url:
        apiPath.listforMap +
        `?&debouncedSearchCity=${JSON.stringify(debouncedSearchCity)}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        console.log(data.data);
        setProperties(data.data);
      },
      onError: (error) => {
        // setLoading(false);
        // ShowToast(error, Severty.ERROR);
      },
    });
    fetchData({ ...pagination, current: 1 });
  }, [debouncedSearchCity]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.asset_status : null;
    request({
      url:
        apiPath.listAsset +
        `?page=${pagination ? pagination.current : 1}&pageSize=${pagination ? pagination.pageSize : 10
        }&debouncedSearchCity=${JSON.stringify(
          debouncedSearchCity
        )}&make_public=true`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        console.log(data);
        if (status) {
          const listData = data?.map((item) => ({
            ...item,
            key: item._id,
          }));
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
          console.log(listData);
          setuserAsset(listData);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [activeFeature, setActiveFeature] = useState(null);
  const [selected, setSelected] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [value, setValue] = useState([]);
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    console.log(debouncedProperties);
    if (!mapContainerRef.current) return;
    if (!properties) return;
    if (!properties[0]) return;
    if (!properties[0].latitude) return;
    console.log(properties);
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center:
        properties && properties.length > 0
          ? [properties[0].longitude, properties[0].latitude]
          : [34.7818, 32.0853],
      zoom: properties && properties.length > 0 ? 15 : 10,
    });

    map.addControl(new mapboxgl.FullscreenControl());

    map.on("load", () => {
      map.addSource("properties", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: properties.map((property) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [property?.longitude, property?.latitude],
            },
            properties: {
              total_price: property.total_price,
              address: property.location.city,
              rooms: property.rooms_number,
              floor: property.floor_number,
              property_type: property.property_type,
              size: property.size_in_meters,
              _id: property._id,
            },
          })),
        },
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 80,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "properties",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#51bbd6",
          "circle-radius": 20,
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "properties",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "properties",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      let pricePopup = null;
      let detailPopup = null;

      map.on("mouseenter", "unclustered-point", (e) => {
        // if (detailPopup) return;

        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price } = e.features[0].properties;

        if (pricePopup) pricePopup.remove();

        pricePopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(coordinates)
          .setHTML(
            `<div style="" class="locati02" >₪ ${new Intl.NumberFormat().format(
              total_price
            )}</div>`
          )
          .addTo(map);

        setHoveredFeature(e.features[0]);
      });

      map.on("mouseleave", "unclustered-point", () => {
        map.getCanvas().style.cursor = "";
        if (pricePopup) pricePopup.remove();
        setHoveredFeature(null);
      });

      map.on("click", "unclustered-point", (e) => {
        if (pricePopup) pricePopup.remove();

        const coordinates = e.features[0].geometry.coordinates.slice();
        const {
          total_price,
          address,
          rooms,
          floor,
          property_type,
          size,
          _id,
          to_let,
        } = e.features[0].properties;

        if (detailPopup) detailPopup.remove();
        detailPopup = new mapboxgl.Popup({ closeButton: true })
          .setLngLat(coordinates)
          .setHTML(
            `<a href="/asset-view/${_id}" target="_blank" style="text-decoration: none; color: inherit;">
              <div>
                <h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )}</h3>
                <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
                  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  <span>${address}</span>
                </div>
                <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
                  <li>
                    <div class="item-name">
                      <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Rooms" class="img-fluid"/>
                      <span>rooms</span>
                    </div>
                    <span>${rooms}</span>
                  </li>
                  <li>
                    <div class="item-name">
                      <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Floor" class="img-fluid"/>
                      <span>floor</span>
                    </div>
                    <span>${floor}</span>
                  </li>
                  <li>
                    <div class="item-name">
                      <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Property Type" class="img-fluid"/>
                      <span>Property Type</span>
                    </div>
                    <span>${property_type}</span>
                  </li>
                  <li>
                    <div class="item-name">
                      <img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Size" class="img-fluid"/>
                      <span>size</span>
                    </div>
                    <span>${size}</span>
                  </li>
                </ul>
              </div>
            </a>`
          )
          .addTo(map);

        setActiveFeature(e.features[0]);
        if (pricePopup) pricePopup.remove();
      });
    });

    return () => map.remove();
  }, [debouncedProperties]);

  const getTasksForDate = (date, taskList) => {
    return taskList.filter((task) => date.isSame(task.date_of_entry, "day"));
  };

  const dateCellRender = (value) => {
    const tasksForDate = getTasksForDate(value, taskList); // Filter tasks for the current date
    return tasksForDate.length ? (
      <ul className="events-dlx">
        {tasksForDate.map((task, index) => (
          <li key={index}>
            {/* <Badge status="success" text={task.name} /> */}
            <h6>{task.task_name}</h6>
          </li>
        ))}
      </ul>
    ) : null;
  };

  const [open, setOpen] = useState(false);
  const showDrawer = (data) => {
    setOpen(true);
    console.log("props.values", data);
    // setValue(...list.filter((el) => el._id === id));
    // let dateToCompare = new Date(due_date);
    // let doc = list.filter((item) => {
    //   const itemDate = new Date(item.date_of_entry);
    //   return (
    //     itemDate.getDate() === dateToCompare.getDate() &&
    //     itemDate.getMonth() === dateToCompare.getMonth() &&
    //     itemDate.getFullYear() === dateToCompare.getFullYear()
    //   );
    // });
    setValue([data]);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="dashboard main-new-3">
        <Row gutter={[24, 24]} className="dashbord-main-para-maain">
          <Col span={8} sm={12} md={8} className="dashbord-main-para">
            <Card
              className="mian-property-2 OnMouseP"
              onClick={() => navigate("/asset")}
            >
              <div className="image-propertyes">
                <img src={ClientsIcon} />
              </div>
              <div className="teext-propertyes">
                <div className="for-neww-bttnn">
                  <p>{lang("My Total Assets")}</p>
                </div>
                <div className="teext-propertyes-count">
                  <h4>
                    {new Intl.NumberFormat().format(dashboard?.allCountList)}
                  </h4>
                </div>
                {/* <div className="teext-propertyes-count-groth">
                  <h3>+10%</h3>
                  <p>{lang("Last 7 days")}</p>
                </div> */}
              </div>
            </Card>
          </Col>

          <Col span={8} sm={12} md={8} className="dashbord-main-para">
            <Card
              className="mian-property-2 OnMouseP"
              onClick={() => navigate("/my-client")}
            >
              <div className="image-propertyes">
                <img src={PropertyImg} />
              </div>
              <div className="teext-propertyes">
                <div className="for-neww-bttnn">
                  <p>{lang("Total Clients")}</p>
                </div>
                <div className="teext-propertyes-count">
                  <h4>
                    {new Intl.NumberFormat().format(dashboard?.totalCustomer)}
                  </h4>
                </div>
                {/* <div className="teext-propertyes-count-groth">
                  <h3>+10%</h3>
                  <p>{lang("Last 7 days")}</p>
                </div> */}
              </div>
            </Card>
          </Col>

          <Col span={8} sm={12} md={8} className="dashbord-main-para">
            <Card
              className="mian-property-2 OnMouseP"
              onClick={() => navigate("/asset")}
            >
              <div className="image-propertyes">
                <img src={PropertiesIcon} />
              </div>
              <div className="teext-propertyes">
                <div className="for-neww-bttnn">
                  <p>{lang("My Published Assets")}</p>
                </div>
                <div className="teext-propertyes-count">
                  <h4>
                    {new Intl.NumberFormat().format(dashboard?.publicCountList)}
                  </h4>
                </div>
                {/* <div className="teext-propertyes-count-groth">
                  <h3 className="text-danger">+10%</h3>
                  <p>{lang("Last 7 days")}</p>
                </div> */}
              </div>
            </Card>
          </Col>

          <Col span={24} lg={24} xl={16}>
            {/* <div className="for-graph-form">
              <Space direction="vertical" style={{ width: "100%" }}>
                <Space
                  direction="horizontal"
                  justify="space-between"
                  style={{ width: "100%" }}
                >
                  <Title level={5}>{lang("Amount of Deal")}</Title>
                  <Dropdown overlay={menu1} trigger={["click"]}>
                    <a onClick={(e) => e.preventDefault()}>
                      {lang("Monthly")} <DownOutlined />
                    </a>
                  </Dropdown>
                </Space>
                <Text type="secondary" className="last-day">
                  {lang("Last 7 days")}
                </Text>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={dateData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <svg>
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="0%"
                            stopColor="#146EF5"
                            stopOpacity={1}
                          />
                          <stop
                            offset="100%"
                            stopColor="#051B3C"
                            stopOpacity={1}
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="uv" fill="url(#colorUv)" />
                  </BarChart>
                </ResponsiveContainer>
              </Space>
            </div> */}

            <>
              <Col span={24} className="p-sm-0">
                <div style={{ width: "100%", height: "700px" }}>
                  <div
                    ref={mapContainerRef}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Col>
            </>

            <Col span={24} className="pt-4 p-0 main-transiation-form">
              <Card>
                <div className="for-all-transiation-formm">
                  <Space
                    direction="vertical"
                    style={{ width: "100%", gap: "0" }}
                  >
                    <Space style={{ width: "100%", gap: "0" }}>
                      <Title level={4}>
                        {userProfile.city
                          ? lang("New Assets") +  userProfile.city
                          : ""}
                      </Title>
                      {/* <Space className="transiation-main-table">
                        <Dropdown overlay={menu} trigger={["click"]}>
                        <Button>
                          24 Hours <DownOutlined />
                        </Button>
                      </Dropdown>

                        <div>
                          <Dropdown overlay={menu} trigger={["click"]}>
                            <Button>
                              {keyForDrop === "1" && "24 Hours"}
                              {keyForDrop === "2" && "7 Days"}
                              {keyForDrop === "3" && "30 Days"}
                              {keyForDrop === "" && "All"}
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                          <div>
                        {dateRange.start && dateRange.end && (
                          <p>
                            Start Date:{" "}
                            {dateRange.start.format("YYYY-MM-DD HH:mm:ss")}{" "}
                            <br />
                            End Date:{" "}
                            {dateRange.end.format("YYYY-MM-DD HH:mm:ss")}
                          </p>
                        )}
                      </div>
                        </div>

                        <Link
                          onClick={(e) => {
                            navigate(`/asset`);
                          }}
                        >
                          {lang("View All")}
                        </Link>
                      </Space> */}
                    </Space>
                    <div className="table-responsive">
                      <Table
                        dataSource={userAsset}
                        columns={columns}
                        loading={loading}
                        components={components}
                        onChange={handleChange}
                        pagination={{
                          defaultPageSize: 10,
                          responsive: true,
                          total: pagination.total,
                        }}
                        onRow={(record) => {
                          return {
                            onClick: () => {
                              // Navigate to the desired page, for example:
                              navigate(`/asset-view/${record._id}`);
                              // window.location.href = `/AssetDetail/${record._id}`;
                            },
                          };
                        }}
                        className="ant-border-space"
                      />
                    </div>
                  </Space>
                </div>
              </Card>
            </Col>
          </Col>
          <Col span={24} lg={14} xl={8}>
            <div className="main-clalnder-from">
              <Space direction="vertical" style={{ width: "100%" }}>
                <Title level={5}>{lang("Tasks/Meetings for Today")}</Title>
                <Space
                  style={{ justifyContent: "center", width: "100%" }}
                ></Space>
                <Calendar
                  fullscreen={false}
                  onChange={(date) => onPanelChange(date)}
                // dateCellRender={dateCellRender}
                // onPanelChange={onPanelChange}
                />
                {/* <GoogleCalendarIntegration /> */}
                <div className="table-responsive mt-3">
                  <Table
                    className="calander-tables"
                    dataSource={taskList}
                    columns={columns1}
                    pagination={false}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          // Navigate to the desired page, for example:
                          // navigate(`/asset-view/${record._id}`);
                          showDrawer(record);
                          // window.location.href = `/AssetDetail/${record._id}`;
                        },
                      };
                    }}
                  />
                </div>
              </Space>
            </div>
          </Col>
        </Row>
      </div>
      <Drawer
        title="Task Details"
        placement="right"
        onClose={onClose}
        open={open}
        className="main-calender-main-drower"
      >
        {value &&
          value?.map((el, id) => {
            return (
              <>
                <div className="mb-3" key={id}>
                  <div className="main-edit-my-task-button">
                    <Button
                      onClick={(e) => {
                        navigate(`/edit-task/${el._id}`);
                        setSelected({ recordId: el._id });
                        setVisible(true);
                      }}
                    >
                      <EditOutlined />
                    </Button>
                    <Button
                      onClick={() => {
                        setSelected({ recordId: el._id });
                        setShowDelete(true);
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                  <div className="main-drower-table">
                    <h4>Task Id:</h4> <h5>{el && el.TaskId}</h5>
                  </div>
                  <div className="main-drower-table">
                    <h4> Task Title:</h4> <h5>{el && el.title}</h5>
                  </div>

                  <div className="main-drower-table">
                    <h4> Status: </h4>
                    <h5>{el ? el.is_status : "N/A"}</h5>
                  </div>
                  <div className="main-drower-table">
                    <h4> Client :</h4>
                    <h5>
                      {el && el.clientId ? (
                        <a href={`client-detail/${el.clientId._id}`}>
                          {el.clientId.name}
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </h5>
                  </div>
                  <div className="main-drower-table">
                    <h4>Assest :</h4>
                    <h5>
                      {el && el.assetId ? (
                        <a href={`view-asset/${el.assetId._id}`}>
                          {el.assetId.property_title}
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </h5>
                  </div>
                  <div className="main-drower-table">
                    <h4>Notes :</h4> <h5>{el ? el.notes : "N/A"}</h5>
                  </div>
                  <div className="main-drower-table">
                    <h4>Due Date:</h4>
                    <h5>
                      {el && moment(el.date_of_entry).format("DD/MM/YYYY")}
                    </h5>
                  </div>
                </div>
                <div>
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginTop: "20px",
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          backgroundColor: "rgb(24 114 245)",
                        }}
                      >
                        <th
                          style={{
                            padding: "10px",
                            border: "1px solid #ddd",
                            color: "white",
                          }}
                        >
                          Date & Time
                        </th>
                        <th
                          style={{
                            padding: "10px",
                            border: "1px solid #ddd",
                            color: "white",
                          }}
                        >
                          Note
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {el.notes_multi?.map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "10px",
                              border: "1px solid #ddd",
                            }}
                          >
                            {el.notes_date
                              ? new Date(
                                el.notes_date[index]
                              ).toLocaleDateString("sv-SE", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                              : ""}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            );
          })}
      </Drawer>

      {/* <Footer/> */}

      {/* <div className="for-display-blockinwebs ">
        <div className="responsive-mobile-nav">
          <div className="responsive-mobile-link">
            <img src={Home2} />
            <p>Home</p>
          </div>
          <div className="responsive-mobile-link">
          <img src={myclients2} />
            <p>My Clients</p>
          </div>
          <div className="responsive-mobile-link">
          <img src={Contacts2} />
            <p>Contacts</p>
          </div>
          <div className="responsive-mobile-link">
          <img src={mytasks2} />
            <p>My Tasks</p>
          </div>
          <div className="responsive-mobile-link">
          <img src={Message2} />
            <p>Message</p>
          </div>
        </div>
    </div> */}
    </>
  );
};

export default Dashboard;
