import { message, Upload as UploadAntd } from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { uploadFile } from "react-s3";
// import { s3Config } from "../config/s3Config";
import { getFileExtension } from "../helper/functions";
import { Severty, ShowToast } from "../helper/toast";

import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
const s3Config = {
  region: "us-east-1",
  credentials: {
    accessKeyId: "IJTXH751MBXUWMTWM0SF",
    secretAccessKey: "lfTPRJ2PqAxsp3poTW9YlHktgaL1cFkLup8LidW9",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
  forcePathStyle: true,
};

const s3Client = new S3Client(s3Config);

const MultipleImageUpload = ({
  data,
  fileType,
  imageType,
  btnName,
  onDelete,
  onChange,
  disabled = false,
  isDimension = false,
  ...props
}) => {
  const [fileList, setFileList] = useState([]);

  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");

      // img.onload = () => {
      //   if (img.width === 720 && img.height === 480) {
      //     resolve();
      //   } else {
      //     reject(
      //       `Please upload an image with dimensions 720 X 480. uploaded image is ${img.width} X ${img.height}`
      //     );
      //   }
      // };

      img.src = URL.createObjectURL(file);
    });
  };

  const beforeUpload = async (file) => {
    try {
      if (fileType.includes(file.type)) {
      } else {
        message.error("File format is not correct");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isLt2M) {
        message.error(`Image must be smaller than 5 MB!`);
        return false;
      }

      // isDimension && (await checkImageDimensions(file));

      return true;
    } catch (err) {
      ShowToast(err, Severty.ERROR);
      return false;
    }
  };

  useEffect(() => {
    if (!data) return;
    const multipleFileList = data.map((url, index) => ({
      uid: `${index + 1}`,
      name: url,
      status: "done",
      url: url,
    }));
    setFileList(multipleFileList);
  }, [data]);

  // const handleImgChange = async (event) => {
  //   const { file } = event;
  //   const extension = getFileExtension(file.name);
  //   const name = `TAWASI_${new Date().getTime()}.${extension}`;

  //   const newFile = new File([file], name, { type: file.type });
  //   uploadFile(newFile, s3Config(imageType))
  //     .then((data) => {
  //       const fileData = {
  //         uid: file.uid,
  //         name: file.name,
  //         status: "done",
  //         url: data.location,
  //         thumbUrl: data.location,
  //       };
  //       setFileList([...fileList, fileData]);
  //       if (onChange) {
  //         onChange([...fileList, fileData]);
  //       }
  //     })
  //     .catch((err) => console.error(err));
  // };

  const uploadFileToS3 = async (file, bucketName) => {
    const key = "brokerland/" + "customer" + "/" + file.name;
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
    };

    const upload = new Upload({
      client: s3Client,
      params: params,
    });

    try {
      const data = await upload.done();
      console.log(data);
      return data;
    } catch (err) {
      throw err;
    }
  };

  const handleImgChange = async (event) => {
    const { file } = event;
    try {
      const data = await uploadFileToS3(file, "invent-colab-obj-bucket");
      const fileData = {
        uid: file.uid,
        name: file.name,
        status: "done",
        url: data.Location,
        thumbUrl: data.Location,
      };
      setFileList([...fileList, fileData]);
      if (onChange) {
        onChange([...fileList, fileData]);
      }
      // setImageUrl(data.Location);
      // setImage(data.Location);
      // setLoading(false);
      //   setFile([fileData]);
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div>
      {" "}
      <PlusOutlined /> <div style={{ marginTop: 8 }}>Upload {btnName}</div>{" "}
    </div>
  );

  const handleRemove = (file) => {
    console.log(fileList, file);
    const newFile = fileList.filter((item) => item.uid != file.uid);
    setFileList(newFile);
    if (onChange) {
      onChange([...newFile]);
    }
  };

  return (
    <UploadAntd
      disabled={disabled}
      listType="picture-card"
      onRemove={handleRemove}
      maxCount={8}
      beforeUpload={beforeUpload}
      fileList={fileList}
      //onChange={handleChange}
      customRequest={handleImgChange}
    >
      {fileList.length >= 8 ? null : uploadButton}
    </UploadAntd>
  );
};

export default MultipleImageUpload;
