let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://inventcolabs.s3.amazonaws.com/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://api.brokerland.co.il/api/";
} else {
  URL = "https://api.brokerland.co.il/api/";
  // URL = "http://localhost:5845/api/";
  // URL = "http://103.189.173.7:5845/api/";
  // URL = "http://localhost:5845/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "broker/dashboard",
  terms: "broker/content/terms",

  listOrder: "broker/order/list",
  viewOrder: "broker/order/view",
  statusOrder: "broker/status",
  verifyEmail: "broker/auth/verify-email-by-link",

  listTransaction: "broker/transaction/list",
  viewTransaction: "broker/transaction/view",

  // Auth API
  logout: "broker/auth/logout",
  login: "broker/auth/login",

  profile: "broker/auth/get-profile",
  updateProfile: "broker/auth/update-profile",
  changePassword: "broker/auth/change-password",
  updateAppSetting: "broker/auth/update-app-setting",

  forgotPassword: "broker/auth/forgot-password",
  verifyOTP: "broker/auth/verify-otp",
  resetPassword: "broker/auth/reset-password",

  // broker task api
  listDocument: "broker/document/list",
  singleDocument: "broker/document/single-document",
  addDocument: "broker/document/add-document",
  editDocument: "broker/document/edit-document",
  // Asset--->
  cityForMore: "broker/asset/list-near-asset",
  list: "broker/asset/list",
  list_public: "broker/asset/list_public",
  listAsset: "broker/asset/list-asset",
  listCitiesData: "broker/asset/listCitiesData",
  clientAssetList: "broker/asset/client-list-asset",
  addAsset: "broker/asset/add-asset",
  singleAsset: "broker/asset/single-asset",
  singleAssetShare: "broker/asset/single-asset-share",
  publicChangeAsset: "broker/asset/public-asset",
  editAsset: "broker/asset/edit-asset",
  uploadAssetDoc: "broker/asset/upload-asset-doc",
  statusChangeAsset: "broker/asset/status-change",
  uploadAssetExcel: "broker/asset/add-excel-asset",
  asset_statuschange: "broker/asset/asset-status-change",
  deleteAsset: "broker/asset/delete-asset",
  listforMap: "broker/asset/listforMap",
  deleteMultiAsset: "broker/asset/multi",

  // Broker APIs
  listBroker: "broker/client/list/broker",
  listClient: "broker/client/list",
  viewClientDetail: "broker/client/get-client",
  viewClientDetailWithAsset: "broker/client/get-client-with-asset",
  listClientUsername: "broker/client/list-username",
  addEditClient: "broker/client/add-client",
  editClient: "broker/client/edit-client",
  deleteClient: "broker/client/delete-client",
  deletemultiClient: "broker/client/multi",
  statusClient: "broker/client/status-change",
  viewClient: "broker/client/view",
  importClient: "broker/client/import-file",
  uploadClientExcel: "broker/client/add-excel-client",
  update: "broker/user/edit-user",

  // Owner
  listOwner: "broker/asset/owner/list",
  viewOwnerDetail: "broker/asset/owner/detail",
  viewOwnerDetailWithAsset: "broker/asset/owner/detail-assets",
  addOwner: "broker/owner/add-owner",
  editOwner: "broker/owner/edit-owner",
  viewOwner: "broker/owner/view",
  deleteOwner: "broker/owner/delete-owner",

  // broker task api
  addTask: "broker/task/add-task",
  editTask: "broker/task/edit-task",
  editNote: "broker/task/edit-note",
  taskList: "broker/task/list",
  changetaskstatus: "broker/task/status-change",
  clientTaskList: "broker/task/clientlist",
  deleteTask: "broker/task/delete-task",
  singleTask: "broker/task/single-task",

  // broker Activity api
  addActivity: "broker/activity/add-activity",
  clientActivityList: "broker/activity/list",
  // broker task api
  addTask: "broker/task/add-task",
  taskList: "broker/task/list",
  changetaskstatus: "broker/task/status-change",
  // SubAdmin APIs
  subAdmin: "broker/sub-admin",
  addEditSubAdmin: "broker/sub-admin",
  statusSubAdmin: "broker/sub-broker/status",
  viewSubAdmin: "broker/sub-broker/view",
  getModule: "broker/sub-broker/module-list",
  addPermission: "broker/sub-broker/add-permission",

  //collector
  collector: "broker/collector",
  location: "broker/service-location",

  // driver APIs
  driver: "broker/driver",
  importDealer: "broker/dealer/import-file",

  checkCode: "broker/discount/check-code",
  discount: "broker/discount",
  revenue: "broker/revenue",
  report: "broker/report",
  request: "broker/request",

  banner: "broker/banner",
  history: "broker/delivery-history",
  adminCommon: "broker/common",

  // Content APIs
  content: "broker/content/list",
  updateContent: "broker/content/edit-content-new",
  notification: "broker/notification",
  rating: "broker/rating",

  finance: "broker/finance",

  // EmailTemplate APIs
  listEmailTemplate: "broker/email-template/list",
  addEditEmailTemplate: "broker/email-template/edit-email-template",
  statusEmailTemplate: "broker/email-template/status",
  viewEmailTemplate: "broker/email-template/view",

  // Blog APIs
  listBlog: "broker/blog/list",
  addEditBlog: "broker/blog/add-edit",
  statusBlog: "broker/blog/status",
  viewBlog: "broker/blog/view",

  //category
  listCategory: "broker/category",
  statusCategory: "broker/category/status",

  //Subscription
  addSubscription: "broker/subscription/add-subscription",
  listSubscription: "broker/subscription/list",
  editSubscription: "broker/subscription/edit-subscription",
  statusChangeSubscription: "broker/subscription/status-change",
  viewSubscription: "broker/subscription/view-subscription",

  // Subscription Order
  listSubscriptionOrder: "broker/subscription-order/list",
  statusChangeSubscriptionOrder: "broker/subscription-order/status-change",

  //company
  company: "broker/company",

  getAppSetting: "common/app-setting",

  // Size APIs
  size: "broker/size",

  //order
  order: "broker/order",

  //log
  log: "broker/activity-log",

  // delivery charge
  deliveryCharge: "broker/delivery-charge",

  //Vendor apis

  common: {
    restaurantCategories: "categories",
    foodCategories: "food-categories",
    countries: "",
    foodItems: "common/food-items",
    countries: "common/country",
    cities: "common/city",
    citiesList: "common/city-list",
    state: "common/state/",
  },

  // Auth API
  logout: "broker/auth/logout",
  signUp: "broker/auth/sign-up",
};

export default apiPath;
