import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";

import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { Form, Modal, Row, Col, Radio, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import lang from "../../../src/helper/langHelper";

const s3Config = {
  region: "us-east-1",
  credentials: {
    accessKeyId: "IJTXH751MBXUWMTWM0SF",
    secretAccessKey: "lfTPRJ2PqAxsp3poTW9YlHktgaL1cFkLup8LidW9",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
  forcePathStyle: true,
};

const s3Client = new S3Client(s3Config);

function SignatureCanva({ show, hide, onOk, title, subtitle, reasons, data }) {
  const sigCanvas = useRef({});
  const [imageUrl, setImageUrl] = useState();
  const [signatureDataCanva, setSignatureDataCanva] = useState();

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const saveSignature = () => {
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    // Convert base64 to file
    const timestamp = new Date().getTime();
    const uniqueFilename = `signature_${timestamp}.png`;
    const file = dataURLtoFile(signatureData, uniqueFilename);

    setSignatureDataCanva(file);
  };

  const uploadFileToS3 = async (file, bucketName) => {
    const key = "brokerland/" + "customer" + "/" + file.name;
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
    };

    const upload = new Upload({
      client: s3Client,
      params: params,
    });

    try {
      const data = await upload.done();
      console.log(data);
      return data;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const uploadSignature = async () => {
      console.log(signatureDataCanva, "url");
      if (!signatureDataCanva) return;

      const file = signatureDataCanva;
      try {
        const data = await uploadFileToS3(file, "invent-colab-obj-bucket");
        console.log(file);

        const fileData = {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: data.Location,
          thumbUrl: data.Location,
        };

        setImageUrl(data.Location);
      } catch (err) {
        console.error(err);
      }
    };

    uploadSignature();
  }, [signatureDataCanva]);

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  return (
    <Modal
      width={700}
      open={show}
      onOk={() => {
        if (imageUrl) {
          onOk(imageUrl);
          hide();
        } else {
          saveSignature();
        }
      }}
      okText={imageUrl ? lang("Submit") : lang("Save Signature")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      centered
      className="tab_modal deleteWarningModal"
    >
      <div>
        <h4 className="modal_title_cls mb-2">{title}</h4>
        <h4 className="modal_sub_title_cls mb-2">{subtitle}</h4>
        <SignatureCanvas
          ref={sigCanvas}
          className="signPad"
          penColor="black"
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />

        {imageUrl ? (
          <></>
        ) : (
          <Button
            onClick={clearSignature}
            style={{ marginTop: "10px" }}
            icon={<RestartAltOutlinedIcon />}
          ></Button>
        )}
      </div>
    </Modal>
  );
}

export default SignatureCanva;
