import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
  Modal,
  Checkbox,
  Empty,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { uploadFile } from "react-s3";
import { s3Config } from "../../config/s3Config";

import HandshakeImg from "../../assets/images/Partnershipnew.png";
import CommissionImg from "../../assets/images/commission.png";
import RecieptImg from "../../assets/images/receptnew.png";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";

const { Option } = Select;
const periods = ["1", "2", "3", "4", "5", "6"];
function CreateDocument({ data }) {
  const location = useLocation();
  const params = useParams();
  const req_query = new URLSearchParams(location.search);
  const client_id = req_query.get("client_id");
  const sectionName = params.id ? "Edit Document" : "Create Document";
  const heading = "My Document";
  const api = {
    add: apiPath.addDocument,
    edit: apiPath.editDocument,
    clientList: apiPath.listClient,
    singleDocument: apiPath.singleDocument,
  };
  const [form] = Form.useForm();
  const { setPageHeading } = useContext(AppStateContext);
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [brokerModal, setBrokerModal] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [assetModal, setAssetModal] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [clients, setClients] = useState([]);
  const [broker, setBroker] = useState([]);
  const [assets, setAssets] = useState([]);
  const [clientId, setClientId] = useState("");
  const [assetId, setAssetId] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [formData, setFormData] = useState({
    // notes: "",
    // purpose: "",
    clientId: clientId ? clientId : "",
    assetId: "",
    description: "",
    document_name: "",
    title: "",
    date_of_entry: null,
  });

  useEffect(() => {
    if (!client_id) return;
    if (!clients.length > 0) return;

    if (clients.some((client) => client._id === client_id)) {
      form.setFieldValue("clientId", client_id);
    } else {
      ShowToast("Client Does not Match or A Lead Type", Severty.ERROR);
    }
  }, [client_id, clients]);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
      message.error("File format is not correct");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(`Image must be smaller than 5 MB!`);
      return false;
    }
    return true;
  };

  const fetchData = () => {
    request({
      url: api.clientList + `?clientType=client`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");
          setClients(data.list);
        }
      },
      onError: (error) => {
        setLoading(false);
      },
    });

    request({
      url: apiPath.listBroker,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");
          setBroker(data);
        }
      },
      onError: (error) => {
        setLoading(false);
      },
    });

    request({
      url: apiPath.list_public,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");
          setAssets(data.list);
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onCreate = (values) => {
    const payload = {
      ...values,
    };
    console.log(payload);
    payload.kind_of_doc = value;

    // payload.to_date = moment(value?.to_date);
    // payload.from_date = moment(value?.from_date);

    setLoading(true);
    request({
      url: `${params.id ? api.edit + "/" + params.id : api.add}`,
      method: params.id ? "PUT" : "POST",
      data: payload,

      onSuccess: (data) => {
        setLoading(false);
        if (data?.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate(-1);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);
  useEffect(() => {
    fetchData();
  }, [refresh]);

  useEffect(() => {
    if (!params.id) return;
    fetchDocument(params.id);
  }, [params.id]);

  const fetchDocument = (id) => {
    request({
      url: `${api.singleDocument + "/" + id}`,
      method: "GET",
      onSuccess: (response) => {
        const data = response.data.data[0];
        console.log(data);

        setFormData({
          assetId: data.assetId,
          clientId: data.clientId,
          description: data.description,
          rent_sale: data.rent_sale,
          total_price: data.total_price,
          commission: data.commission,
          type: data.type,
          period: data.period,
          to_date: moment(data?.to_date),
          from_date: moment(data?.from_date),
        });
        form.setFieldsValue({
          assetId: data.assetId,
          clientId: data.clientId,
          description: data.description,
          rent_sale: data.rent_sale,
          total_price: data.total_price,
          commission: data.commission,
          type: data.type,
          period: data.period,
          to_date: moment(data?.to_date),
          from_date: moment(data?.from_date),
        });
        setValue(data.kind_of_doc);
        setActiveImage(data.kind_of_doc); // Update the active image state
      },
    });
  };

  const [value, setValue] = useState("");

  const handleClick = (e) => {
    setValue(e);
    form.setFieldValue("clientId", "");
    form.setFieldValue("assetId", "");
    setActiveImage(e);
  };
  const [activeImage, setActiveImage] = useState(null);

  const [propertyType, setPropertyType] = useState(null);
  const handlePropertyType = (e) => {
    setPropertyType(e);
  };

  const handleBroker = () => {
    setBrokerModal(true);
  };
  const handleClient = () => {
    setClientModal(true);
  };
  const handleAsset = () => {
    setAssetModal(true);
  };

  const [assetType, setAssetType] = useState("");
  const [assetCity, setAssetCity] = useState("");
  const [assetNeighbourhood, setAssetNeighbourhood] = useState("");
  const [assetStreet, setAssetStreet] = useState("");
  const [assetStreetNumber, setAssetStreetNumber] = useState("");
  const [assetRoom, setAssetRoom] = useState("");
  const [assetPrice, setAssetPrice] = useState("");
  const [assetSize_in_meters, setAssetPricepsm] = useState("");

  const handleAssetval = () => {
    request({
      url: apiPath.addAsset,
      method: "POST",
      data: {
        location: {
          city: assetCity,
          neighborhood: assetNeighbourhood ? assetNeighbourhood : "",
          street: assetStreet,
          street_number: assetStreetNumber ? assetStreetNumber : "",
        },
        total_price: assetPrice,
        property_type: assetType ? assetType : "דירה",
        rooms_number: assetRoom ? assetRoom : "2",
        size_in_meters: assetSize_in_meters ? assetSize_in_meters : "1",
        asset_status: "active",
        make_public: false,
        is_active: false,
      },
      onSuccess: (data) => {
        ShowToast("New Asset created Successfully", Severty.SUCCESS);
        form.setFieldValue("assetId", data.data._id);
        setRefresh((prev) => !prev);
        setAssetModal(false);
      },
      onError: (error) => {
        setAssetModal(false);
      },
    });
  };

  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [brokerNumber, setBrokerNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const handleChange = (value, data) => {
    var countryCode = data.dialCode;
    setMobileNumber({
      country_code: countryCode,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  const handleClientval = () => {
    request({
      url: apiPath.addEditClient,
      method: "POST",
      data: {
        name: clientName,
        email: clientEmail,
        mobileNumber: mobileNumber?.mobile_number,
        countryCode: mobileNumber?.country_code,
        clientType: "client",
      },
      onSuccess: (data) => {
        setRefresh((prev) => !prev);
        ShowToast("New Client created Successfully", Severty.SUCCESS);
        form.setFieldValue("clientId", data.data._id);
        setClientModal(false);
      },
      onError: (error) => {
        setClientModal(false);
      },
    });
  };

  const handleBrokerval = () => {
    request({
      url: apiPath.signUp,
      method: "POST",
      data: {
        name: clientName,
        email: clientEmail,
        mobileNumber: mobileNumber?.mobile_number,
        countryCode: mobileNumber?.country_code,
        mobile: mobileNumber?.country_code + "" + mobileNumber?.mobile_number,
        brokerNo: brokerNumber,
        confirm_password: "Test@123",
        password: "Test@123",
      },
      onSuccess: (data) => {
        setBrokerModal(false);
        ShowToast("New Broker created Successfully", Severty.SUCCESS);
        form.setFieldValue("clientId", data.data._id);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setBrokerModal(false);
      },
    });
  };

  return (
    <>
      <Card title={sectionName}>
        <div className="logo-new-scricle">
          <div className={`documents-box ${activeImage === lang("commission") ? "New-active" : ""}`}>
            <div className="documents-box-img">
              <img
                src={CommissionImg}
                onClick={() => handleClick(lang("commission"))}
              />
            </div>
            <div className="active-text-mian-new-ff">{lang("commission")}</div>
          </div>

          <div className={`documents-box ${activeImage === lang("partnership") ? "New-active" : ""}`}>
            <div className="documents-box-img">
              <img
                src={HandshakeImg}
                onClick={() => handleClick(lang("partnership"))}
                alt="Beard"
              />
            </div>
            <div className="active-text-mian-new-ff">{lang("partnership")}</div>
          </div>

          <div className={`documents-box ${activeImage === lang("exclusivity") ? "New-active" : ""}`}>
            <div className="documents-box-img">
              <img
                src={RecieptImg}
                onClick={() => handleClick(lang("exclusivity"))}
                alt="Boy"
              />
            </div>
            <div className="active-text-mian-new-ff">{lang('exclusivity')}</div>
          </div>
        </div>

        <Form
          className="edit-page-wrap"
          form={form}
          onFinish={onCreate}
          autoComplete="off"
          layout="vertical"
          name="asset_form"
        >
          {value === "עמלה" && (
            <Row gutter={[24, 0]}>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Rent/Sale")}
                  name="rent_sale"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter Rent/Sale"),
                    },
                  ]}
                >
                  <Select
                    placeholder={lang("Select Rent/Sale")}
                    onChange={(e) => handlePropertyType(e)}
                  >
                    <Option key="rent" value="rent">
                      {lang("Rent")}
                    </Option>
                    <Option key="sale" value="sale">
                      {lang("Sale")}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Total Price*")}
                  name="total_price"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the Total Price"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Enter Total Price")} />
                </Form.Item>
              </Col>

              {propertyType === "rent" ? (
                <Col span={24} sm={8}>
                  <Form.Item
                    label={lang("Commission For Months*")}
                    name="commission"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter the document commission"),
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: lang("Commission should be a valid number of months"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && parseInt(value, 10) > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("Commission should be a positive number of months"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input placeholder={lang("Enter Document Commission")} />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={24} sm={8}>
                  <Form.Item
                    label={lang("Commission in Percentage*")}
                    name="commission"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter the document commission"),
                      },
                      {
                        pattern: /^(100(\.0{1,2})?|(\d{1,2})(\.\d{1,2})?)$/,
                        message: lang("Commission should be a valid percentage (0-100)"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && parseFloat(value) > 0 && parseFloat(value) <= 100) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("Commission should be between 0 and 100 percent"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input placeholder={lang("Enter Document Commission")} />
                  </Form.Item>
                </Col>
              )}

              <Col span={24} sm={8}>
                <Form.Item label={lang("Note")} name="description">
                  <Input.TextArea placeholder={lang("Enter Note")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item label={lang("Clients")} name="clientId" className="add-docs">
                  <Select placeholder={lang("Select client")}>
                    {clients?.map((client) => (
                      <Option key={client._id} value={client._id}>
                        {client.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button onClick={() => handleClient()}>{lang("Add Client")}</Button>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item label={lang("Assets")} name="assetId" className="add-docs">
                  <Select
                    placeholder={lang("Select Assets")}
                  >
                    {assets?.map((asset) => (
                      <Option key={asset._id} value={asset._id}>
                        {asset.location.city},{asset.location.street}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button onClick={() => handleAsset()}>{lang("Add Asset")}</Button>
              </Col>
            </Row>
          )}

          {value === "שיתוף פעולה" && (
            <Row gutter={[24, 0]}>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Rent/Sale*")}
                  name="rent_sale"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter Rent/Sale"),
                    },
                  ]}
                >
                  <Select
                    placeholder={lang("Select Rent/Sale")}
                    onChange={(e) => handlePropertyType(e)}
                  >
                    <Option key="rent" value="rent">
                      {lang("Rent")}
                    </Option>
                    <Option key="sale" value="sale">
                      {lang("Sale")}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Total Price*")}
                  name="total_price"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the Total Price"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Enter Total Price")} />
                </Form.Item>
              </Col>

              {propertyType === "rent" ? (
                <Col span={24} sm={8}>
                  <Form.Item
                    label={lang("Commission For Months*")}
                    name="commission"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter the document commission"),
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: lang("Commission should be a valid number of months"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && parseInt(value, 10) > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("Commission should be a positive number of months"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input placeholder={lang("Enter Document Commission")} />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={24} sm={8}>
                  <Form.Item
                    label={lang("Commission in Percentage*")}
                    name="commission"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter the document commission"),
                      },
                      {
                        pattern: /^(100(\.0{1,2})?|(\d{1,2})(\.\d{1,2})?)$/,
                        message: lang("Commission should be a valid percentage (0-100)"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && parseFloat(value) > 0 && parseFloat(value) <= 100) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("Commission should be between 0 and 100 percent"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input placeholder={lang("Enter Document Commission")} />
                  </Form.Item>
                </Col>
              )}

              <Col span={24} sm={8}>
                <Form.Item label={lang("Note")} name="description">
                  <Input.TextArea placeholder={lang("Enter Document Note")} />
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item label={lang("Broker")} name="clientId">
                  <Select placeholder={lang("Select Broker")}>
                    {broker?.map((broker) => (
                      <Option key={broker._id} value={broker._id}>
                        {broker.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button onClick={() => handleBroker()}>{lang("Add Broker")}</Button>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item label={lang("Assets")} name="assetId">
                  <Select placeholder={lang("Select Assets")}>
                    {assets?.map((asset) => (
                      <Option key={asset._id} value={asset._id}>
                        {asset.property_title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button onClick={() => handleAsset()}>{lang("Add Asset")}</Button>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Type")}
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter Type"),
                    },
                  ]}
                >
                  <Select placeholder={lang("Select Type")}>
                    <Option key="קופה משותפת" value="קופה משותפת">
                      {lang("קופה משותפת")}
                    </Option>
                    <Option key="כל אחד מהלקוח שלו" value="כל אחד מהלקוח שלו">
                      {lang("כל אחד מהלקוח שלו")}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          {value === "בלעדיות" && (
            <Row gutter={[24, 0]}>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Rent/Sale*")}
                  name="rent_sale"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter Rent/Sale"),
                    },
                  ]}
                >
                  <Select
                    placeholder={lang("Select Rent/Sale")}
                    onChange={(e) => handlePropertyType(e)}
                  >
                    <Option key="rent" value="rent">
                      {lang("Rent")}
                    </Option>
                    <Option key="sale" value="sale">
                      {lang("Sale")}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Total Price*")}
                  name="total_price"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the Total Price"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Enter Total Price")} />
                </Form.Item>
              </Col>

              {propertyType === "rent" ? (
                <Col span={24} sm={8}>
                  <Form.Item
                    label={lang("Commission For Months*")}
                    name="commission"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter the document commission"),
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: lang("Commission should be a valid number of months"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && parseInt(value, 10) > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("Commission should be a positive number of months"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input placeholder={lang("Enter Document Commission")} />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={24} sm={8}>
                  <Form.Item
                    label={lang("Commission in Percentage*")}
                    name="commission"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter the document commission"),
                      },
                      {
                        pattern: /^(100(\.0{1,2})?|(\d{1,2})(\.\d{1,2})?)$/,
                        message: lang("Commission should be a valid percentage (0-100)"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && parseFloat(value) > 0 && parseFloat(value) <= 100) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("Commission should be between 0 and 100 percent"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input placeholder={lang("Enter Document Commission")} />
                  </Form.Item>
                </Col>
              )}

              <Col span={24} sm={8}>
                <Form.Item label={lang("Note")} name="description">
                  <Input.TextArea placeholder={lang("Enter Document Note")} />
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item label={lang("Clients")} name="clientId" className="add-docs">
                  <Select placeholder={lang("Select client")}>
                    {clients?.map((client) => (
                      <Option key={client._id} value={client._id}>
                        {client.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button onClick={() => handleClient()}>{lang("Add Client")}</Button>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item label={lang("Assets")} name="assetId" className="add-docs">
                  <Select placeholder={lang("Select Assets")}>
                    {assets?.map((asset) => (
                      <Option key={asset._id} value={asset._id}>
                        {asset.property_title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button onClick={() => handleAsset()}>{lang("Add Asset")}</Button>
              </Col>
            </Row>
          )}

          <Form.Item className="btn-row float-right mt-3">
            <Button
              type="primary"
              onClick={() => navigate(-1)}
              className="back-btns"
            >
              {lang("Back")}
            </Button>
            <Button
              type="primary"
              loading={loading}
              htmlType="submit"
              className="submit-btns"
            >
              {lang("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {assetModal && (
        <Modal
          className="docs-modal"
          title={lang("Add Asset")}
          visible={assetModal}
          onCancel={() => setAssetModal(false)}
          footer={[
            <>
              <Button key="cancel" onClick={() => setAssetModal(false)}>
                {lang("Cancel")}
              </Button>
              <Button key="ok" onClick={() => handleAssetval()}>
                {lang("Ok")}
              </Button>
            </>,
          ]}
        >
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item label={lang("Asset Type")} name="asset_type">
                <Select
                  placeholder={lang("Select Asset Type")}
                  onChange={(e) => setAssetType(e)}
                >
                  <Select.Option value="דירה">{lang("Apartment")}</Select.Option>
                  <Select.Option value="קוטג'">{lang("House / Cottage")}</Select.Option>
                  <Select.Option value="מִסְחָרִי מְקַרקְעִין">
                    {lang("Commercial/Real Estate")}
                  </Select.Option>
                  <Select.Option value="דירת גן">{lang("Garden Apartment")}</Select.Option>
                  <Select.Option value="דו משפחתי">{lang("Townhouse")}</Select.Option>
                  <Select.Option value="גג/ פנטהאוז">
                    {lang("Rooftop / Penthouse")}
                  </Select.Option>
                  <Select.Option value="פנטהאוז">{lang("Penthouse")}</Select.Option>
                  <Select.Option value="מיני פנטהאוז">
                    {lang("Mini Penthouse")}
                  </Select.Option>
                  <Select.Option value="דירת גג">{lang("Rooftop Apartment")}</Select.Option>
                  <Select.Option value="סטודיו/ לופט">
                    {lang("Studio / Loft")}
                  </Select.Option>
                  <Select.Option value="דופלקס">{lang("Duplex")}</Select.Option>
                  <Select.Option value="טריפלקס">{lang("Triplex")}</Select.Option>
                  <Select.Option value="יחידת דיור">{lang("Apartment Unit")}</Select.Option>
                  <Select.Option value="מגרשים">{lang("Plots")}</Select.Option>
                  <Select.Option value="משק חקלאי/ נחלה">
                    {lang("Farmhouse / Homestead")}
                  </Select.Option>
                  <Select.Option value="בניין">{lang("Building")}</Select.Option>
                  <Select.Option value="דירה מחולקת">
                    {lang("Divided Apartment")}
                  </Select.Option>
                  <Select.Option value="דירת מרתף">
                    {lang("Basement Apartment")}
                  </Select.Option>
                  <Select.Option value="מרתף/ פרטר">
                    {lang("Basement / Parter")}
                  </Select.Option>
                  <Select.Option value="בניין מגורים">
                    {lang("Residential Building")}
                  </Select.Option>
                  <Select.Option value="דירת נופש">
                    {lang("Vacation Apartment")}
                  </Select.Option>
                  <Select.Option value="חניה">{lang("Parking")}</Select.Option>
                  <Select.Option value="מחסן">{lang("Storage")}</Select.Option>
                  <Select.Option value="אחר">{lang("Other")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={lang("City*")}
                name="city"
                rules={[{ required: true, message: lang("Please enter the City") }]}
              >
                <Input
                  placeholder={lang("Enter City")}
                  autoComplete="off"
                  onChange={(e) => setAssetCity(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item label={lang("Neighbourhood")} name="neighbourhood">
                <Input
                  placeholder={lang("Enter Neighbourhood")}
                  autoComplete="off"
                  onChange={(e) => setAssetNeighbourhood(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={lang("Street*")}
                name="street"
                rules={[{ required: true, message: lang("Please enter the Street") }]}
              >
                <Input
                  placeholder={lang("Enter Street")}
                  autoComplete="off"
                  onChange={(e) => setAssetStreet(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item label={lang("Street Number")} name="street_number">
                <Input
                  placeholder={lang("Enter Street Number")}
                  autoComplete="off"
                  onChange={(e) => setAssetStreetNumber(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={lang("Price*")}
                name="total_price"
                rules={[{ required: true, message: lang("Please enter the Price") }]}
              >
                <Input
                  placeholder={lang("Enter Price")}
                  autoComplete="off"
                  onChange={(e) => setAssetPrice(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item label={lang("No. Of Rooms")} name="no_of_rooms">
                <Input
                  placeholder={lang("Enter No. Of Rooms")}
                  autoComplete="off"
                  onChange={(e) => setAssetRoom(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item label={lang("Size In Meter")} name="size_in_meter">
                <Input
                  placeholder={lang("Enter Size In Meter")}
                  autoComplete="off"
                  onChange={(e) => setAssetPricepsm(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      )}
      {clientModal && (
        <Modal
          className="docs-modal"
          title={lang("Add client")}
          visible={clientModal}
          onCancel={() => setClientModal(false)}
          footer={[
            <>
              <Button key="cancel" onClick={() => setClientModal(false)}>
                {lang("Cancel")}
              </Button>
              <Button key="ok" onClick={() => handleClientval()}>
                {lang("Ok")}
              </Button>
            </>,
          ]}
        >
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                label={lang("Client Name*")}
                name="client_name"
                rules={[
                  { required: true, message: lang("Please enter the Client Name") },
                ]}
              >
                <Input
                  placeholder={lang("Enter Client Name")}
                  autoComplete="off"
                  onChange={(e) => setClientName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={lang("Email*")}
                name="email"
                rules={[
                  { required: true, message: lang("Please enter the Client Email") },
                ]}
              >
                <Input
                  placeholder={lang("Enter Client Email")}
                  autoComplete="off"
                  onChange={(e) => setClientEmail(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={lang("Phone Number")}
                name="mobile"
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(lang("Please enter phone number"));
                      }
                      if (!/^\d{8,15}$/.test(value)) {
                        return Promise.reject(
                          lang("Phone number must be between 8 and 15 digits")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <PhoneInput
                  inputProps={{
                    name: "mobile",
                    required: true,
                    autoFocus: false,
                    autoFormat: false,
                    autoComplete: "off",
                  }}
                  isValid={(value, country) => {
                    if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else {
                      return true;
                    }
                  }}
                  country={"il"}
                  preferredCountries={["il"]}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>

      )}
      {brokerModal && (
        <Modal
          className="docs-modal"
          title={lang("Add Broker")}
          visible={brokerModal}
          onCancel={() => setBrokerModal(false)}
          footer={[
            <>
              <Button key="cancel" onClick={() => setBrokerModal(false)}>
                {lang("Cancel")}
              </Button>
              <Button key="ok" onClick={() => handleBrokerval()}>
                {lang("Ok")}
              </Button>
            </>,
          ]}
        >
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                label={lang("Broker Name*")}
                name="broker_name"
                rules={[
                  { required: true, message: lang("Please enter the Broker Name") },
                ]}
              >
                <Input
                  placeholder={lang("Enter Broker Name")}
                  autoComplete="off"
                  onChange={(e) => setClientName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={lang("Email*")}
                name="email"
                rules={[
                  { required: true, message: lang("Please enter the Broker Email") },
                ]}
              >
                <Input
                  placeholder={lang("Enter Broker Email")}
                  autoComplete="off"
                  onChange={(e) => setClientEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={lang("Broker Number")}
                name="broker_Number"
                rules={[
                  { required: true, message: lang("Please enter the Broker Number") },
                ]}
              >
                <Input
                  placeholder={lang("Enter Broker Number")}
                  autoComplete="off"
                  onChange={(e) => setBrokerNumber(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={lang("Phone Number")}
                name="mobile"
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(lang("Please enter phone number"));
                      }
                      if (!/^\d{8,15}$/.test(value)) {
                        return Promise.reject(
                          lang("Phone number must be between 8 and 15 digits")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <PhoneInput
                  inputProps={{
                    name: "mobile",
                    required: true,
                    autoFocus: false,
                    autoFormat: false,
                    autoComplete: "off",
                  }}
                  isValid={(value, country) => {
                    if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else {
                      return true;
                    }
                  }}
                  country={"il"}
                  preferredCountries={["il"]}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>

      )}
    </>
  );
}

export default CreateDocument;
