import {
  DownOutlined,
  KeyOutlined,
  LogoutOutlined,
  UploadOutlined,
  UserOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
  Switch,
  Avatar,
  Drawer,
  theme,
} from "antd";
import { Link } from "react-router-dom";
import Home2 from "../../assets/images/side_nav/dash.svg";
import myclients2 from "../../assets/images/side_nav/myClient.svg";
import Contacts2 from "../../assets/images/side_nav/customer.svg";
import mytasks2 from "../../assets/images/side_nav/myTask.svg";
import Message2 from "../../assets/images/side_nav/message.svg";
import MyAsset from "../../assets/images/side_nav/myAsset.svg";
import PublicAsset from "../../assets/images/side_nav/publicAsset.svg";

import { useNavigate, useLocation } from "react-router";
import { useContext, useEffect, useState } from "react";
function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="for-display-blockinwebs ">
        <div className="responsive-mobile-nav">
          <Button onClick={() => navigate("/dashboard")}>
            <div
              className={`responsive-mobile-link ${
                location.pathname === "/dashboard" ? "active" : ""
              }`}
            >
              <img src={Home2} />
              <p>Home</p>
            </div>
          </Button>
          <Button onClick={() => navigate("/my-client")}>
            <div
              className={`responsive-mobile-link ${
                location.pathname === "/my-client" ? "active" : ""
              }`}
            >
              <img src={myclients2} />
              <p>My Clients</p>
            </div>
          </Button>
          <Button onClick={() => navigate("/public-asset")}>
            <div
              className={`responsive-mobile-link ${
                location.pathname === "/public-asset" ? "active" : ""
              }`}
            >
              <img src={PublicAsset} />
              <p>Public Asset</p>
            </div>
          </Button>
          <Button onClick={() => navigate("/asset")}>
            <div
              className={`responsive-mobile-link ${
                location.pathname === "/asset" ? "active" : ""
              }`}
            >
              <img src={Message2} />
              <p>My Asset</p>
            </div>
          </Button>
          <Button onClick={() => navigate("/my-task")}>
            <div
              className={`responsive-mobile-link ${
                location.pathname === "/my-task" ? "active" : ""
              }`}
            >
              <img src={mytasks2} />
              <p>My Tasks</p>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}

export default Footer;
