import {
  Card,
  Col,
  Progress,
  Radio,
  Row,
  Select,
  Skeleton,
  Tabs,
  Typography,
  Table,
  Tag,
  Image,
  Avatar,
  Dropdown,
  Space,
  Button,
  Pagination,
  Slider,
  InputNumber,
  Form,
  Input,
  DatePicker,
  Checkbox,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  PlusOutlined,
  SettingFilled,
  StarOutlined,
  PhoneOutlined,
  StarFilled,
  MailOutlined,
  WhatsAppOutlined,
  UserOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  AreaChartOutlined,
  ShopOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import apiPath from "../../../constants/apiPath";
import { AppStateContext } from "../../../context/AppContext";
import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import AvatarIcon from "../../../assets/images/avatar-1.png";
import contact01 from "../../../assets/images/contact01.png";
import WhatsAppIcon from "../../../assets/images/whatsapp-icon.svg";
import GmailFillIcon from "../../../assets/images/gmail.svg";
import SkypeIcon from "../../../assets/images/skype-icon.svg";
import Timeicon from "../../../assets/images/time-icon.svg";
import GmailIcon from "../../../assets/images/gmail-icon.svg";
import CallingIcon from "../../../assets/images/calling-icon.svg";
import ChatlogoImg from "../../../assets/images/phone-logo.svg";
import ThreeDotsImg from "../../../assets/images/three-dots.png";
import ProfileImg from "../../../assets/images/partners.png";
import Chatlogo2Img from "../../../assets/images/massage-icon.svg";
import Chatlogo3Img from "../../../assets/images/file-icon.svg";
import clint22Img from "../../../assets/images/partners.png";
import LocationIcon from "../../../assets/images/location-icon-01.svg";
import pdfImg from "../../../assets/images/pdf-photo.png";
import ArrowImg from "../../../assets/images/Arrrowhouse.png";
import House1Img from "../../../assets/images/houseimg1.png";
import { useAuthContext } from "../../../context/AuthContext";
import DoubleBedIcon from "../../../assets/images/double-bed.svg";
import ProprtFlorIcon from "../../../assets/images/building-icon.svg";
import HomeProprtyIcon from "../../../assets/images/home-proprty.svg";
import SquareIcon from "../../../assets/images/square.svg";
import HomeImage from "../../../assets/images/home.jpeg";
import lang from "../../../helper/langHelper";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
mapboxgl.accessToken =
  "pk.eyJ1IjoieW9hdjUyMDIiLCJhIjoiY2x3a3UwMG9zMGh1dDJpcDg4Mm4wanJzaCJ9.uY8D_Vhu9YEBbdULrVBHqg";

const filterpt = [
  {
    text: "Apartment",
    value: "דירה",
  },
  {
    text: "House / Cottage",
    value: "קוטג'",
  },
  {
    text: "Garden Apartment",
    value: "דירת גן",
  },
  {
    text: "Townhouse",
    value: "דו משפחתי",
  },
  {
    text: "Rooftop / Penthouse",
    value: "גג/ פנטהאוז",
  },
  {
    text: "Penthouse",
    value: "פנטהאוז",
  },
  {
    text: "Mini Penthouse",
    value: "מיני פנטהאוז",
  },
  {
    text: "Rooftop Apartment",
    value: "דירת גג",
  },
  {
    text: "Studio / Loft",
    value: "סטודיו/ לופט",
  },
  {
    text: "Duplex",
    value: "דופלקס",
  },
  // 11 extra
  {
    text: "Triplex",
    value: "טריפלקס",
  },
  {
    text: "Apartment Unit",
    value: "יחידת דיור",
  },
  {
    text: "Plots",
    value: "מגרשים",
  },
  {
    text: "Farmhouse / Homestead",
    value: "משק חקלאי/ נחלה",
  },
  {
    text: "Building",
    value: "בניין",
  },
  {
    text: "Divided Apartment",
    value: "דירה מחולקת",
  },
  {
    text: "Basement Apartment",
    value: "דירת מרתף",
  },
  {
    text: "Basement / Parter",
    value: "מרתף/ פרטר",
  },
  {
    text: "Residential Building",
    value: "בניין מגורים",
  },
  {
    text: "Vacation Apartment",
    value: "דירת נופש",
  },
  {
    text: "Parking",
    value: "חניה",
  },
  {
    text: "Storage",
    value: "מחסן",
  },
  {
    text: "Other",
    value: "אחר",
  },
];

const data = [
  {
    key: "1",
    size: "#225555",
    client: "Ronald Richards",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    key: "2",
    size: "#23134855",
    client: "Darrell Steward",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    key: "3",
    size: "#54394837",
    client: "Marvin McKinney",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
  },
  {
    key: "4",
    size: "#54394837",
    client: "Ronald Richards",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    key: "5",
    size: "#54394837",
    client: "Ronald Richards",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    key: "6",
    size: "#54394837",
    client: "Marvin McKinney",
    date: "11 Jun, 2024",
    Uploader: "Catallina Blomquist",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
  },
];

const columns = [
  {
    title: "",
    dataIndex: "check",
    className: "transiation-roww-0",

    render: (text, record) => (
      <span>
        <Checkbox />
      </span>
    ),
  },

  {
    title: "NAME",
    dataIndex: "client",
    className: "transiation-roww",

    render: (text, record) => (
      <span>
        <Avatar src={record.avatar} icon={<UserOutlined />} /> {text}
      </span>
    ),
  },

  {
    title: "Size",
    dataIndex: "size",
    className: "transiation-roww-2",
  },

  {
    title: "Date Created",
    dataIndex: "date",
    className: "transiation-roww-3",
  },
  {
    title: "File Type",
    dataIndex: "pdf",
    className: "transiation-roww-4",
    render: (text, record) => (
      <span>
        <img src={pdfImg} /> {text}
      </span>
    ),
  },
  {
    title: "Uploaded By",
    dataIndex: "Uploader",
    className: "transiation-roww-5",
  },
];

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}
const years = [2024, 2023];
const { Option } = Select;

function Index({ }) {
  const { setPageHeading, country } = useContext(AppStateContext);
  const { request } = useRequest();
  const [year, setYear] = useState(years[0]);
  const [loading, setLoading] = useState(years[0]);
  const { userProfile, isAdmin } = useAuthContext();
  const [dashboard, setDashboard] = useState();
  const { id } = useParams();

  const navigate = useNavigate();
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setYear(value);
  };

  useEffect(() => {
    setPageHeading(lang("Welcome Broker"));
    if (!userProfile) return;

    if (userProfile.type == "SubAdmin") {
      setPageHeading(`Welcome ${userProfile.name}`);
      if (!userProfile?.permission.includes("dashboard-management")) return;
    }
    setLoading(true);
    request({
      url: apiPath.dashboard + `${year ? `?year=${year}` : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setDashboard(data.data);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, [year, country.country_id, userProfile]);
  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <ClientDetails id={id} />
        </Col>
      </Row>
    </>
  );
}
// Client Details
const ClientDetails = ({ id }) => {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [year, setYear] = useState(years[0]);
  const [clientDetails, setClientDetails] = useState();
  const { userProfile } = useAuthContext();
  const [clientTasks, setClientTasks] = useState();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const setKeyParam = params.get("setKey");

  const targetblank = (url) => {
    window.open(url, "_blank");
  };

  const getClientData = () => {
    request({
      url: apiPath.viewClientDetail + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(true);
          setClientDetails(data.data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });

    request({
      url: apiPath.clientTaskList + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(true);
          setClientTasks(data.data.list);
          console.log(clientTasks);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setYear(value);
  };

  useEffect(() => {
    getClientData();
  }, []);

  return (
    <div className="user-profile-card">
      <Col span={24}>
        <Button onClick={() => navigate(-1)}>
          <ArrowLeftOutlined />
          {lang("Back")}
        </Button>
      </Col>
      <div
        className="settings-icon"
        onClick={() => navigate(`/edit-client/${clientDetails?._id}`)}
      >
        <SettingFilled />
      </div>
      <div className="main-deatil-outer">
        {clientDetails && (
          <div className="client-profile">
            <Image
              src={clientDetails.image ? clientDetails.image : AvatarIcon}
              alt={lang("Profile")}
              style={{ borderRadius: "50%" }}
            />
          </div>
        )}
        <div className="view-detail-claint">
          <h2 className="name">
            {clientDetails?.name
              ? `${clientDetails.name} ${clientDetails.last_name}`
              : "-"}
            <span>{lang("Active")}</span>
          </h2>
          <div className="contact-info">
            <p className="time">
              <img src={Timeicon} />
              {`${new Date(clientDetails?.created_at).toLocaleDateString(
                "sv-SE"
              )} ${new Date(clientDetails?.created_at).toLocaleTimeString(
                "sv-SE",
                { hour: "2-digit", minute: "2-digit" }
              )}`}{" "}
              ({lang("CET")})
            </p>
            <p className="location">
              <img src={LocationIcon} />
              {clientDetails ? clientDetails?.city : "-"}
            </p>
            <p className="email">
              <img src={GmailIcon} />
              {clientDetails ? clientDetails?.email : "-"}
            </p>
            <p className="phone">
              <img src={CallingIcon} />
              <a
                href={`tel:${clientDetails ? clientDetails.mobileNumber : ''}`}
                target="_blank"
              >
                {clientDetails ? clientDetails.mobileNumber : "-"}
              </a>
            </p>
          </div>
          <div className="social-icons">
            <img
              src={WhatsAppIcon}
              className="social-icon whatsapp"
              onClick={() =>
                targetblank(
                  `https://api.whatsapp.com/send/?phone=${clientDetails?.mobileNumber}`
                )
              }
            />
            <img src={GmailFillIcon} className="social-icon linkedin" />
          </div>
        </div>
      </div>

      <Tabs
        defaultActiveKey={setKeyParam ? setKeyParam : "1"}
        className="dash-home-tab"
      >
        <TabPane className="tab-menubar-h" tab={lang("Summary")} key="1">
          <Summary />
        </TabPane>
        <TabPane className="tab-menubar-h" tab={lang("Search")} key="2">
          <Search />
        </TabPane>
        <TabPane className="tab-menubar-h" tab={lang("Activity")} key="4">
          <Activity />
        </TabPane>
      </Tabs>
    </div>
  );
};


// Summary Tab -->
const Summary = () => {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [year, setYear] = useState(years[0]);
  const { userProfile } = useAuthContext();
  const [clientTasks, setClientTasks] = useState();
  const [activityTasks, setactivityTasks] = useState([]);
  const [clientDetails, setClientDetails] = useState();
  const { id } = useParams();

  const getSummaryData = (id) => {
    request({
      url: apiPath.viewClientDetail + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(true);
          setClientDetails(data.data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });

    request({
      url: apiPath.clientTaskList + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(true);
          setClientTasks(data.data.list);
          console.log("qwertyui", data.data.list);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });

    request({
      url: apiPath.clientActivityList + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(true);
          setactivityTasks(data.data.list);
          console.log("qwertyui", data.data.list);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getSummaryData(id);
  }, [id]);

  return (
    <Card className="home-card-h card-border-show">
      {userProfile && userProfile.type === "SubAdmin" ? (
        <Row></Row>
      ) : (
        <div className="page-top-space home-card layout-content">
          <div className="mb-24">
            <Row gutter={40}>
              <Col span={24} lg={24} xl={12} className="col-p">
                <div className="recent-activity-header">
                  <h2>
                    {lang("Recent Activity")} <span>({activityTasks?.length})</span>{" "}
                  </h2>
                  <Button to={() => navigate(`?key=3`)} className="see-btn">
                    {lang("See Activities")}
                  </Button>
                </div>

                {activityTasks &&
                  activityTasks.map((item) => (
                    <div className="activity-notification" key={item.id}>
                      <div className="chat-logo">
                        <img src={ChatlogoImg} alt={lang("Chat Logo")} />
                      </div>
                      <div className="main-all-notification-div">
                        <div className="main-noti-date">
                          <div className="notification-name">{item.title}</div>
                          <div className="notification-date">
                            {`${new Date(item?.time).toLocaleTimeString(
                              "sv-SE",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )} ${new Date(item?.date).toLocaleDateString(
                              "sv-SE"
                            )}`}
                          </div>
                        </div>
                        <div className="notification-message">{item.description}</div>
                      </div>
                    </div>
                  ))}

                <div className="add-more-main">
                  <div className="add-first">
                    <button
                      onClick={() => navigate(`/addactivity/${clientDetails?._id}`)}
                    >
                      <PlusOutlined /> {lang("Add Activity")}
                    </button>
                  </div>
                  <div className="add-second">
                    <Pagination
                      defaultCurrent={1}
                      total={activityTasks.length}
                    />
                  </div>
                </div>
                <div className="recent-02main">
                  <div className="recent-activity-header">
                    <h2>
                      {lang("Recent Tasks")} <span>({clientTasks?.length})</span>{" "}
                    </h2>
                    <Button
                      onClick={() => navigate(`/my-task`)}
                      className="see-btn"
                    >
                      {lang("See Tasks")}
                    </Button>
                  </div>

                  {clientTasks &&
                    clientTasks.map((item) => (
                      <div className="call-notification" key={item.id}>
                        <h3>{item.task_name}</h3>
                        <h4>{item.created_at}</h4>
                      </div>
                    ))}
                  <div className="add-more-main">
                    <div className="add-first">
                      <button
                        onClick={() =>
                          navigate(`/create-task?client_id=${clientDetails?._id}`)
                        }
                      >
                        <PlusOutlined /> {lang("Add Task")}
                      </button>
                    </div>
                    <div className="add-second">
                      <Pagination
                        defaultCurrent={1}
                        total={clientTasks?.length}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24} lg={24} xl={12} className="col-p">
                <div className="personal-info-container">
                  <div className="header">
                    <h2>{lang("All Personal Informations")}</h2>
                    <button
                      className="edit-button"
                      onClick={() =>
                        navigate(`/edit-client/${clientDetails?._id}`)
                      }
                    >
                      {lang("Edit")}
                    </button>
                  </div>
                  <Row gutter={[24, 16]}>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {clientDetails?.email ? clientDetails.email : "-"}
                          </h6>
                          <p>{lang("Mail Address")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-phone"></i>
                        </div>
                        <div className="details-f">
                          <a
                            href={`tel:${clientDetails ? clientDetails.mobileNumber : ''}`}
                            target="_blank"
                          >
                            <h6>
                              {clientDetails?.mobileNumber}
                            </h6>
                          </a>
                          <p>{lang("Phone Number")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-calendar-alt"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {`${new Date(
                              clientDetails?.created_at
                            ).toLocaleDateString("sv-SE")} ${new Date(
                              clientDetails?.created_at
                            ).toLocaleTimeString("sv-SE", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}`}{" "}
                          </h6>
                          <p>{lang("Connected Since")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-money-bill-wave"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {clientDetails?.budget
                              ? new Intl.NumberFormat().format(
                                clientDetails.budget[0]
                              ) +
                              " ₪ ---" +
                              new Intl.NumberFormat().format(
                                clientDetails.budget[1]
                              ) +
                              " ₪"
                              : "-"}
                          </h6>
                          <p>{lang("Budget")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-map-marker-alt"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {clientDetails?.city}, {clientDetails?.neighborhood}
                          </h6>
                          <p>{lang("Location")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-briefcase"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {clientDetails?.clientType
                              ? clientDetails.clientType
                              : "-"}
                            ,{" "}
                            {clientDetails?.optionType
                              ? clientDetails.optionType
                              : "-"}
                          </h6>
                          <p>{lang("Work Type")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-map-marker-alt"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {clientDetails?.propertyType
                              ? clientDetails.propertyType
                              : "-"}
                          </h6>
                          <p>{lang("Property Searching")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-briefcase"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {clientDetails?.numberOfRooms
                              ? clientDetails.numberOfRooms
                              : "-"}
                          </h6>
                          <p>{lang("No. Of. Rooms")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-briefcase"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {clientDetails?.SourceName
                              ? clientDetails.SourceName
                              : "-"}
                          </h6>
                          <p>{lang("Source Name")}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} lg={24} xl={12}>
                      <div className="info-item">
                        <div className="icon-outer">
                          <i className="fas fa-briefcase"></i>
                        </div>
                        <div className="details-f">
                          <h6>
                            {clientDetails?.propertylivType
                              ? clientDetails.propertylivType
                              : "-"}
                          </h6>
                          <p>{lang("Property Living Type")}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={24} lg={24} xl={12}></Col>
            </Row>

            <div className="preference-main-divv">
              <Row gutter={[24, 24]}>
                {/* Additional sections can be uncommented and localized here */}
              </Row>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};


// Search Tab -->
const Search = () => {
  const mapContainerRef = useRef(null);
  const { request } = useRequest();
  const [mortgageReq, setMortgageReq] = useState(true);
  const [year, setYear] = useState(years[0]);
  const [loading, setLoading] = useState(false);
  const { userProfile } = useAuthContext();
  const [clientAsset, setClientAsset] = useState();
  const [clientCityAsset, setClientCityAsset] = useState();
  const { id } = useParams();
  const [optionType, setOptionType] = useState([]);
  const [bedrooms, setBedrooms] = useState();
  const [minYield, setMinYield] = useState();
  const [propertyType, setPropertyType] = useState();
  const [priceLimit, setPriceLimit] = useState([0, 100000]);
  const [properties, setProperties] = useState([]);
  const [clientAll, setClientAll] = useState([]);
  const [location, setLocation] = useState({});
  const [clientOptionType, setClientOptionType] = useState("");
  const [filter, setFilter] = useState({});
  const [clientDetails, setClientDetails] = useState();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [page, setPage] = useState(1);
  const handlePageChange = (page) => {
    setPage(page);
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const uniqueCities = new Set();

  useEffect(() => {
    request({
      url: apiPath.viewClientDetailWithAsset + "/" + id + "?page=" + page,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(false);
          setClientDetails(data?.data);
          setClientAsset(data?.dataAll);
          setClientAll(data?.filteredData);
          setLocation(data?.lat_long);
          setPagination((prev) => ({
            ...prev,
            current: page,
            total: data.total,
          }));
          setBedrooms(data?.data?.numberOfRooms);
          setPriceLimit([data?.data?.budget[0], data?.data?.budget[1]]);
          setPropertyType(data?.data?.propertyType);

          if (
            data?.data?.optionType == "buyer" ||
            data?.data?.optionType == "seller"
          ) {
            setClientOptionType("buy");
          } else {
            setClientOptionType("rent");
          }
          if (data?.data?.city) {
            setClientCityAsset(data.data.city);
          }
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  }, [id, page]);
  useEffect(() => {
    if (!id) return;
    if (!clientOptionType) return;
    console.log("clientCityAsset", clientCityAsset);
    request({
      url: `${apiPath.listforMap}?${clientAll.length === 0
        ? "city=" + clientCityAsset
        : "to_let=" + clientOptionType
        }`,
      method: "GET",
      onSuccess: (data) => {
        setProperties(data.data.data);
      },
    });
  }, [id, clientOptionType, clientCityAsset]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const handleOptionTypeChange = (value) => {
    console.log(value);
    setOptionType(value);
    setFilter((prevFilter) => ({
      ...prevFilter,
      city: value,
    }));
    // setFilter({
    //   city: value,
    // });
  };

  const setBedroomsFilter = (value) => {
    setBedrooms(value ? value : null);
    setFilter((prevFilter) => ({
      ...prevFilter,
      rooms_number: value,
    }));
    // setFilter({
    //   rooms_number: value,
    // });
  };
  const setMinYieldFilter = (value) => {
    setMinYield(value);
    setFilter((prevFilter) => ({
      ...prevFilter,
      size_in_meters: value,
    }));
    // setFilter({
    //   rooms_number: value,
    // });
  };
  const setPropertyTypeFilter = (value) => {
    setPropertyType(value);
    setFilter((prevFilter) => ({
      ...prevFilter,
      property_type: value,
    }));
    // setFilter({
    //   rooms_number: value,
    // });
  };
  const setPriceLimitFilter = (value) => {
    console.log(value);
    setPriceLimit(value);
    setFilter((prevFilter) => ({
      ...prevFilter,
      total_price: value,
    }));
  };

  const onHandleClick = (id) => {
    setLoading(true);
    request({
      url: apiPath.editAsset + "/" + id,
      method: "PUT",
      data: {
        broker_id: true,
      },

      onSuccess: (data) => {
        setLoading(false);
        if (data?.status) {
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Card className="home-card-h card-border-show">
        <h4>
          {lang("Assets For")} {clientDetails?.name} ( {clientDetails?.optionType} )
        </h4>

        <div className="property-filter">
          <div className="price-range">
            <label> {lang("City")}:</label>
            <Input
              className="bg-inn"
              defaultValue={clientDetails?.city}
              value={clientDetails?.city}
              disabled
            />

          </div>

          <div className="price-range">
            <label>{lang("Price Range")}</label>
            <Slider
              className="slaider-row"
              min={1}
              max={1000000}
              range={{
                draggableTrack: true,
              }}
              disabled
              value={clientDetails?.budget}
              onChange={setPriceLimitFilter} // use onChange instead of onchange
            />
            <div className="count-box">
              <div className="count-detail">
                <span>
                  {clientDetails?.budget
                    ? new Intl.NumberFormat().format(clientDetails?.budget[0])
                    : "0"}
                  {"  "}₪
                </span>
                <span>
                  {clientDetails?.budget
                    ? new Intl.NumberFormat().format(clientDetails?.budget[1])
                    : "100,000"}
                  {"  "}₪
                </span>
              </div>
            </div>
          </div>

          <div className="price-range">
            <label>{lang("Bedrooms")}:</label>
            <InputNumber
              min={1}
              disabled
              value={clientDetails?.numberOfRooms}
              onChange={setBedroomsFilter}
            />
          </div>

          <div className="price-range">
            <label>{lang("Property Type")}:</label>
            <Select
              value={clientDetails?.propertyType}
              onChange={setPropertyTypeFilter}
              disabled
            >
              {filterpt.map((option) => (
                <Select.Option value={option.value}>
                  {option.text}
                  {"("}
                  {option.value}
                  {")"}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="price-range">
            <label>{lang("Min Size In Meter.")}</label>
            <InputNumber
              min={1}
              disabled
              value={clientDetails?.size[0]}
              onChange={setMinYieldFilter}
            />

            {/* <label>Mortgage Req:</label>
          <Radio.Group
            onChange={(e) => setMortgageReq(e.target.value)}
            value={mortgageReq}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group> */}
          </div>
        </div>
        <div className="property-filter">
          <div className="price-range">
            <label>{lang("Amenities")}:</label>
            <Select value={clientDetails?.amenities} mode="multiple" disabled>
              {clientDetails?.amenities.map((amenity) => (
                <Select.Option key={amenity} value={amenity}>
                  {amenity}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        <Row gutter={[24, 24]}>
          <Col span={24} md={12} className="col-p">
            <div className="price-range">
              {/* <h4>Price Range</h4> */}
              <Map
                mapContainerRef={mapContainerRef}
                properties={properties}
                singleAsset={clientAll}
                location={location}
                width={100}
              />
            </div>
          </Col>
          <Col span={24} md={12}>
            <div className="main-result-divv">
              {" "}
              {lang("Showing")} {pagination?.total}  {lang("Results")}
            </div>
            <div className="sale-body-inner">
              <Row gutter={[24, 24]}>
                {clientAsset &&
                  clientAsset?.map((item) => (
                    <Col span={24} xl={12} className="col-p-sm">
                      <a href={"/asset-view/" + item._id} target="_blank">
                        <div className="slider-pro-block">
                          <div className="swiper img-slider1">
                            <div className="swiper-wrapper">
                              <div className="swiper-slide">
                                <img
                                  loading="lazy"
                                  src={
                                    item.multiImage[0]
                                      ? item.multiImage[0]
                                      : HomeImage
                                  }
                                  alt={"Palm Dom 4 Hadera" + item._id}
                                />
                              </div>
                            </div>
                            <div className="swiper-nav-inner d-none">
                              <div className="swiper-inner-btn swiper-button-prev">
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.14648 11L1.0977 6L6.14648 1"
                                    fill="white"
                                  ></path>
                                  <path
                                    d="M6.14648 11L1.0977 6L6.14648 1"
                                    stroke="#102846"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="swiper-inner-btn swiper-button-next">
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.853516 11L5.90228 6L0.853515 1"
                                    fill="white"
                                  ></path>
                                  <path
                                    d="M0.853516 11L5.90228 6L0.853515 1"
                                    stroke="#102846"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="slider-block-content">
                            <div className="content-between mb-30">
                              <div className="price-locate-data">
                                <div className="price-block">
                                  {new Intl.NumberFormat().format(
                                    item.total_price
                                  )}{" "}
                                  ₪
                                </div>
                                <div className="location">
                                  <svg
                                    width="13"
                                    height="15"
                                    viewBox="0 0 13 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z"
                                      stroke="#464A6B"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z"
                                      stroke="#464A6B"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span>
                                    {item.location.city}{" "}
                                    {item.location.neighborhood}
                                    {item.location.street === "0"
                                      ? ""
                                      : item.location.street}
                                    {item.location.street_number === "0"
                                      ? ""
                                      : item.location.street_number}
                                  </span>
                                </div>
                              </div>
                              <div className="dlx-btns">
                                {item.mobile_number ? (
                                  <a
                                    // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${item._id}`}
                                    href={`https://wa.me/+972${item.mobile_number}`}
                                    target="_blank"
                                    type="button"
                                    title="Add to wishlist"
                                    data-id="135362"
                                    className="wishlisted"
                                  >
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="WhatsAppIcon"
                                    >
                                      <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                                    </svg>
                                  </a>
                                ) : null}

                                {!item.broker_id.includes(userProfile._id) && (
                                  <Button
                                    onClick={() => onHandleClick(item._id)}
                                    className="add-btn"
                                  >
                                    <PlusOutlined />
                                  </Button>
                                )}

                                <a
                                  href={"tel:" + item?.mobile_number}
                                  className="wishlisted"
                                >
                                  <PhoneOutlined />
                                </a>
                              </div>
                            </div>
                            <ul className="list-unstyled mb-0 property-list">
                              <li>
                                <div className="item-name">
                                  <img src={DoubleBedIcon} />
                                  <span>rooms</span>
                                </div>
                                <span>{item.rooms_number}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img src={ProprtFlorIcon} />
                                  <span>floor</span>
                                </div>
                                <span>{item.floor_number}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img src={HomeProprtyIcon} />
                                  <span>Property Type</span>
                                </div>
                                <span>{item.property_type}</span>
                              </li>
                              <li>
                                <div className="item-name">
                                  <img src={SquareIcon} />
                                  <span>size</span>
                                </div>
                                <span>{item.size_in_meters}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </Col>
                  ))}
              </Row>
              <Pagination
                className="pagination-section"
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={handlePageChange}
                showSizeChanger={false}
                pageSizeOptions={["10", "20", "30", "50"]}
                onShowSizeChange={(current, size) => {
                  setPagination((prev) => ({
                    ...prev,
                    pageSize: size,
                    current: 1, // Reset to the first page
                  }));
                  // fetchData(
                  //   {
                  //     ...pagination,
                  //     pageSize: size,
                  //     current: 1,
                  //   },
                  //   filter
                  // );
                }}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

// Activity Tab -->
const Activity = () => {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [year, setYear] = useState(years[0]);
  const { userProfile } = useAuthContext();
  const [clientTasks, setClientTasks] = useState();
  const [clientDetails, setClientDetails] = useState();
  const { id } = useParams();
  const getSummaryData = (id) => {
    request({
      url: apiPath.viewClientDetail + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(true);
          setClientDetails(data.data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });

    request({
      url: apiPath.clientActivityList + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        console.log("data----", data);
        setLoading(false);
        if (data.status) {
          setLoading(true);
          setClientTasks(data.data.list);
          console.log("qwertyui", data.data.list);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getSummaryData(id);
  }, [id]);

  return (
    <>
      <div className="add-first main-create-activity">
        <button onClick={() => navigate(`/addactivity/${clientDetails?._id}`)}>
          <PlusOutlined /> Add Activity
        </button>
      </div>
      <div className="mail-btan-main">
        {clientTasks &&
          clientTasks.map((item) => (
            <div className="main-button-mail-broker">
              <div className="mail-logo-imgg">
                {" "}
                <MailOutlined />
              </div>

              <div className="mail-txt-imgg-main">
                <div className="mail-txt-imgg-main-1">
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
                <div className="mail-txt-imgg-main-2">
                  <h3>
                    <img src={contact01} className="profile-image-3" />
                  </h3>
                  <h4>
                    {`${new Date(item?.time).toLocaleTimeString("sv-SE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}`}
                  </h4>
                  <h6>{`${new Date(item?.date).toLocaleDateString(
                    "sv-SE"
                  )}`}</h6>
                </div>
              </div>
            </div>
          ))}

        {/* <div className="main-button-mail-broker for-green-3">
          <div className="mail-logo-imgg for-green-3 ">
            {" "}
            <MailOutlined />
          </div>

          <div className="mail-txt-imgg-main">
            <div className="mail-txt-imgg-main-1">
              <h2>Lorem ipsum dolo</h2>
              <p>
                Lorem ipsum dolor sit amet, conse ctetur adipiscing elit dolore
                enim ad minim sed do eiusmod tempor incididunt
              </p>
            </div>
            <div className="mail-txt-imgg-main-2">
              <h3>
                <img src={contact01} className="profile-image-3" />
              </h3>
              <h4>5:24 AM</h4>
              <h6>14 Jun</h6>
            </div>
          </div>
        </div>
        <div className="main-button-mail-broker for-blue-3">
          <div className="mail-logo-imgg for-blue-3">
            {" "}
            <MailOutlined />
          </div>

          <div className="mail-txt-imgg-main">
            <div className="mail-txt-imgg-main-1">
              <h2>Lorem ipsum dolo</h2>
              <p>
                Lorem ipsum dolor sit amet, conse ctetur adipiscing elit dolore
                enim ad minim sed do eiusmod tempor incididunt
              </p>
            </div>
            <div className="mail-txt-imgg-main-2">
              <h3>
                <img src={contact01} className="profile-image-3" />
              </h3>
              <h4>5:24 AM</h4>
              <h6>14 Jun</h6>
            </div>
          </div>
        </div>
        <div className="main-button-mail-broker for-perpal-3">
          <div className="mail-logo-imgg for-perpal-3">
            {" "}
            <MailOutlined />
          </div>

          <div className="mail-txt-imgg-main">
            <div className="mail-txt-imgg-main-1">
              <h2>Lorem ipsum dolo</h2>
              <p>
                Lorem ipsum dolor sit amet, conse ctetur adipiscing elit dolore
                enim ad minim sed do eiusmod tempor incididunt
              </p>
            </div>
            <div className="mail-txt-imgg-main-2">
              <h3>
                <img src={contact01} className="profile-image-3" />
              </h3>
              <h4>5:24 AM</h4>
              <h6>14 Jun</h6>
            </div>
          </div>
        </div>
        <div className="main-button-mail-broker">
          <div className="mail-logo-imgg">
            {" "}
            <MailOutlined />
          </div>

          <div className="mail-txt-imgg-main">
            <div className="mail-txt-imgg-main-1">
              <h2>Lorem ipsum dolo</h2>
              <p>
                Lorem ipsum dolor sit amet, conse ctetur adipiscing elit dolore
                enim ad minim sed do eiusmod tempor incididunt
              </p>
            </div>
            <div className="mail-txt-imgg-main-2">
              <h3>
                <img src={contact01} className="profile-image-3" />
              </h3>
              <h4>5:24 AM</h4>
              <h6>14 Jun</h6>
            </div>
          </div>
        </div>
        <div className="main-button-mail-broker for-blue-3s">
          <div className="mail-logo-imgg for-blue-3">
            {" "}
            <MailOutlined />
          </div>

          <div className="mail-txt-imgg-main">
            <div className="mail-txt-imgg-main-1">
              <h2>Lorem ipsum dolo</h2>
              <p>
                Lorem ipsum dolor sit amet, conse ctetur adipiscing elit dolore
                enim ad minim sed do eiusmod tempor incididunt
              </p>
            </div>
            <div className="mail-txt-imgg-main-2">
              <h3>
                <img src={contact01} className="profile-image-3" />
              </h3>
              <h4>5:24 AM</h4>
              <h6>14 Jun</h6>
            </div>
          </div>
        </div> */}
        <div className="pagnation-main">
          <Pagination defaultCurrent={1} total={clientTasks?.length} />
        </div>
      </div>
    </>
  );
};

// // Profile Tab -->
// const Profile = () => {
//   const { request } = useRequest();
//   const [loading, setLoading] = useState(false);
//   const [profileDetails, setProfileDetails] = useState();
//   const { userProfile } = useAuthContext();
//   const [year, setYear] = useState(false);
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const items = [
//     {
//       label: (
//         <a
//           target="_blank"
//           rel="noopener noreferrer"
//           href="https://www.antgroup.com"
//         >
//           1st menu item
//         </a>
//       ),
//       key: "0",
//     },
//     {
//       label: (
//         <a
//           target="_blank"
//           rel="noopener noreferrer"
//           href="https://www.aliyun.com"
//         >
//           2nd menu item
//         </a>
//       ),
//       key: "1",
//     },
//     {
//       type: "divider",
//     },
//     {
//       label: "3rd menu item（disabled）",
//       key: "3",
//       disabled: true,
//     },
//   ];

//   const getProfileData = () => {
//     request({
//       url: apiPath.viewClientDetail + "/" + id,
//       method: "GET",
//       onSuccess: (data) => {
//         console.log("data----", data);
//         setLoading(false);
//         if (data.status) {
//           setLoading(true);
//           setProfileDetails(data.data);
//         } else {
//           ShowToast(data.message, Severty.ERROR);
//         }
//       },
//       onError: (error) => {
//         ShowToast(error.response.data.message, Severty.ERROR);
//         setLoading(false);
//       },
//     });
//   };
//   useEffect(() => {
//     getProfileData();
//   }, []);

//   return (
//     <>
//       <div className="main-persional-details">
//         <Row gutter={24}>
//           <Col span={24} lg={24} xl={12}>
//             <div
//               style={{
//                 padding: "24px",
//                 border: "1px solid #f0f0f0",
//                 borderRadius: "10px",
//                 marginTop: "20px",
//               }}
//             >
//               <h5>Personal Details</h5>
//               <Form layout="vertical">
//                 <Row gutter={24} className="main-space-cls">
//                   <Col span={24} md={10} sm={10}>
//                     <h3> Title </h3>
//                   </Col>
//                   <Col span={24} md={14} sm={14}>
//                     <Form.Item name="title">
//                       <Select defaultValue="Mr.">
//                         <Option value="Mr.">Mr.</Option>
//                         <Option value="Mrs.">Mrs.</Option>
//                         <Option value="Miss">Miss</Option>
//                       </Select>
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={[24]} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Name </h3>
//                   </Col>
//                   <Col span={7}>
//                     <Form.Item name="firstName">
//                       <Input placeholder="Darrell" />
//                     </Form.Item>
//                   </Col>
//                   <Col span={7}>
//                     <Form.Item name="middlename">
//                       <Input placeholder="Steward" />
//                     </Form.Item>
//                   </Col>
//                   <Col span={10}></Col>
//                   <Col span={14}>
//                     <Form.Item name="lastName">
//                       <Input placeholder="Steward" />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={16} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Company: </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item name="company">
//                       <Input placeholder="Company" />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={16} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> DOB: </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item name="dob">
//                       <DatePicker style={{ width: "100%" }} placeholder="DOB" />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={16} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Passport No. : </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item name="passportNo">
//                       <Input placeholder="Passport No." />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={16} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Nationality. : </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item name="nationality">
//                       <Input placeholder="Nationality" />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={16} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Tax : </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item name="tax">
//                       <Select placeholder="Select Country">
//                         <Option value="Country1">Country1</Option>
//                         <Option value="Country2">Country2</Option>
//                       </Select>
//                     </Form.Item>
//                   </Col>
//                 </Row>
//               </Form>
//             </div>
//           </Col>
//           <Col span={24} lg={24} xl={12}>
//             <div
//               style={{
//                 padding: "24px",
//                 border: "1px solid #f0f0f0",
//                 borderRadius: "10px",
//                 marginTop: "20px",
//               }}
//             >
//               <h5>Address</h5>
//               <Form layout="vertical">
//                 <Row gutter={24} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Building No. </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item label="" name="buildingNo">
//                       <Input placeholder="House/Apartment No." />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={24} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Address </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item label="" name="address1">
//                       <Input placeholder="Street Line 1" />
//                     </Form.Item>
//                   </Col>

//                   <Col span={10}></Col>
//                   <Col span={14}>
//                     <Form.Item name="address2">
//                       <Input placeholder="Street Line 2" />
//                     </Form.Item>
//                   </Col>

//                   <Col span={10}></Col>
//                   <Col span={14}>
//                     <Form.Item name="address3">
//                       <Input placeholder="Street Line 3" />
//                     </Form.Item>
//                   </Col>

//                   <Col span={10}></Col>
//                   <Col span={14}>
//                     <Form.Item name="address4">
//                       <Input placeholder="Street Line 4" />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={24} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Town </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item label="" name="town">
//                       <Input placeholder="Town/City" />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={24} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Country </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item label="" name="country">
//                       <Select placeholder="Select Country">
//                         <Option value="Country1">Country1</Option>
//                         <Option value="Country2">Country2</Option>
//                       </Select>
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={24} className="main-space-cls">
//                   <Col span={10}>
//                     <h3> Passcode </h3>
//                   </Col>
//                   <Col span={14}>
//                     <Form.Item label="" name="passcode">
//                       <Input placeholder="Passcode" />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//               </Form>
//             </div>
//           </Col>
//         </Row>
//       </div>
//       <Row gutter={24} className="contact-and-people">
//         <Col span={24} lg={24} xl={12}>
//           <div className="main-tag-hdr">People</div>
//           <div className="people-partner-main">
//             <div className="partner-name-tag">Partners:</div>
//             <div className="partners-main-div main-partner-name-33">
//               <div className="partners-image">
//                 <img src={ProfileImg} />
//               </div>
//               <div className="main-add-page">
//                 <div className="partners-name">Ronald Richards</div>
//                 <div className="add-info-d">
//                   <Dropdown
//                     menu={{
//                       items,
//                     }}
//                   >
//                     <a onClick={(e) => e.preventDefault()}>
//                       <Space>
//                         <img src={ThreeDotsImg} />
//                       </Space>
//                     </a>
//                   </Dropdown>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="add-first">
//             <button onClick={() => navigate("/addactivity")}>
//               <PlusOutlined /> Add Activity
//             </button>
//           </div>
//         </Col>
//         <Col span={24} lg={24} xl={12}>
//           <div className="main-tag-hdr">Contact Details</div>
//           <Row gutter={24} className="main-space-cls">
//             <Col span={10}>
//               <h3> Email: </h3>
//             </Col>
//             <Col span={14}>
//               <Form.Item label="" name="email">
//                 <Input placeholder="Enter Email" />
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={24} className="main-space-cls">
//             <Col span={10}>
//               <h3> Phone: </h3>
//             </Col>
//             <Col span={14}>
//               <Form.Item label="" name="email">
//                 <Input placeholder="Enter Phone Number" />
//               </Form.Item>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </>
//   );
// };

// Attachments Tab -->
// const Attachments = () => {
//   const { request } = useRequest();
//   return (
//     <>
//       <div className="main-transiation-33">
//         <div className="main-tran-herader">
//           <h3>2 items Selected</h3>
//         </div>

//         <div className="table-responsive">
//           <Table columns={columns} dataSource={data} pagination={false} />
//         </div>
//       </div>

//       <div className="main-transiation-33">
//         <div className="main-tran-herader">
//           <h3>Deal Attachments</h3>
//         </div>

//         <Table columns={columns} dataSource={data} pagination={false} />
//       </div>
//     </>
//   );
// };

export default Index;

const Map = ({ mapContainerRef, properties, singleAsset, location }) => {
  const [hoveredFeature, setHoveredFeature] = useState(null);
  useEffect(() => {
    if (!mapContainerRef.current) return;
    if (!properties) return;
    if (!properties[0]?.longitude && !properties[0]?.latitude) return;
    // Initialize the Mapbox map
    let map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center:
        singleAsset.length > 0
          ? [singleAsset[0].longitude, singleAsset[0].latitude]
          : [34.7818, 32.0853],
      zoom: 14, // Initial map zoom level
    });

    if (location.lng && location.lat) {
      map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center:
          singleAsset.length > 0
            ? [singleAsset[0].longitude, singleAsset[0].latitude]
            : [location.lng, location.lat],
        zoom: 14, // Initial map zoom level
      });
    }

    map.addControl(new mapboxgl.FullscreenControl());

    map.on("load", () => {
      // Add your property data source with clustering enabled
      map.addSource("properties", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: properties.map((property) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [
                property.longitude ? property.longitude : 0.0,
                property.latitude ? property.latitude : 0.0,
              ],
            },
            properties: {
              total_price: property.total_price,
              address: property.location.city,
              rooms: property.rooms_number,
              floor: property.floor_number,
              property_type: property.property_type,
              size: property.size_in_meters,
              _id: property._id,
              to_let: property.to_let,
              is_center:
                property.latitude === singleAsset.length > 0
                  ? singleAsset[0].latitude
                  : 32.0853 && property.longitude === singleAsset.length > 0
                    ? singleAsset[0].longitude
                    : 34.7818,
            },
          })),
        },
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 80,
      });
      // Add your Single Asset data source with clustering enabled
      map.addSource("singleAsset", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: singleAsset.map((property) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [property.longitude, property.latitude],
            },
            properties: {
              total_price: property.total_price,
              address: property.location.city,
              rooms: property.rooms_number,
              floor: property.floor_number,
              property_type: property.property_type,
              size: property.size_in_meters,
              _id: property._id,
              to_let: property.to_let,
              is_center:
                property.latitude === singleAsset[0].latitude &&
                property.longitude === singleAsset[0].longitude,
            },
          })),
        },
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 80,
      });

      // Add a layer to display the clusters Zoom out circle
      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "properties",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#51bbd6",
          "circle-radius": 20,
        },
      });
      // Add a layer to display the clusters Single Zoom out circle
      map.addLayer({
        id: "clustersSingle",
        type: "circle",
        source: "singleAsset",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#cf692d", // Red color for the centered property
          "circle-radius": 20, // Larger radius for the centered property
        },
      });

      // Add a layer for the cluster count Zoom out circle count
      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "properties",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });
      // Add a layer for the cluster Single count Zoom out circle count
      map.addLayer({
        id: "cluster-countSingle",
        type: "symbol",
        source: "singleAsset",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      // Add a layer for unclustered points small dots
      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "properties",
        filter: [
          "all",
          ["!", ["has", "point_count"]],
          ["!=", ["get", "is_center"], true],
        ],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });
      // Add a layer for unclustered points small dots
      map.addLayer({
        id: "unclustered-pointSingle",
        type: "circle",
        source: "singleAsset",
        filter: [
          "all",
          ["!", ["has", "point_count"]],
          ["!=", ["get", "is_center"], true],
        ],
        paint: {
          "circle-color": "#cf692d",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      // Add a layer for the only centered point
      map.addLayer({
        id: "centered-point",
        type: "circle",
        source: "singleAsset",
        filter: [
          "all",
          ["!", ["has", "point_count"]],
          ["==", ["get", "is_center"], true],
        ],
        paint: {
          "circle-color": "#cf692d", // Red color for the centered property
          "circle-radius": 8, // Larger radius for the centered property
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        },
      });

      let pricePopup = null;
      let detailPopup = null;

      map.on("mouseenter", "unclustered-point", (e) => {
        // if (detailPopup) return;

        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price } = e.features[0].properties;

        if (pricePopup) pricePopup.remove();

        pricePopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(coordinates)
          .setHTML(
            `<div style="" class="locati02" >₪ ${new Intl.NumberFormat().format(
              total_price
            )}</div>`
          )
          .addTo(map);

        setHoveredFeature(e.features[0]);
      });

      map.on("mouseleave", "unclustered-point", () => {
        map.getCanvas().style.cursor = "";
        if (pricePopup) pricePopup.remove();
        setHoveredFeature(null);
      });

      // Add popups for unclustered points
      map.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const {
          total_price,
          address,
          rooms,
          floor,
          property_type,
          size,
          _id,
          to_let,
        } = e.features[0].properties;

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )} </h3>
            <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg><span>${address}</span></div>
            <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
            </ul></div></a>`
          )
          .addTo(map);
      });
      map.on("mouseenter", "unclustered-pointSingle", (e) => {
        // if (detailPopup) return;

        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price } = e.features[0].properties;

        if (pricePopup) pricePopup.remove();

        pricePopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(coordinates)
          .setHTML(
            `<div style="" class="locati02" >₪ ${new Intl.NumberFormat().format(
              total_price
            )}</div>`
          )
          .addTo(map);

        setHoveredFeature(e.features[0]);
      });

      map.on("mouseleave", "unclustered-pointSingle", () => {
        map.getCanvas().style.cursor = "";
        if (pricePopup) pricePopup.remove();
        setHoveredFeature(null);
      });

      // Add popups for unclustered points
      map.on("click", "unclustered-pointSingle", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price, address, rooms, floor, property_type, size, _id } =
          e.features[0].properties;

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )}</h3>
            <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg><span>${address}</span></div>
            <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
            </ul></div></a>`
          )
          .addTo(map);
      });

      // Add popups for the centered point
      map.on("click", "centered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { total_price, address, rooms, floor, property_type, size, _id } =
          e.features[0].properties;

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<a href=${`/asset-view/${_id}`}><div><h3 style="margin: 0px;">₪ ${new Intl.NumberFormat().format(
              total_price
            )}</h3>
            <div class="location" style="margin-bottom: 14px; margin-top: 4px;">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7978 6.25C11.7978 10.3333 6.49653 13.8333 6.49653 13.8333C6.49653 13.8333 1.19531 10.3333 1.19531 6.25C1.19531 4.85762 1.75383 3.52226 2.748 2.53769C3.74218 1.55312 5.09056 1 6.49653 1C7.9025 1 9.25089 1.55312 10.2451 2.53769C11.2392 3.52226 11.7978 4.85762 11.7978 6.25Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M6.49559 8C7.47152 8 8.26266 7.2165 8.26266 6.25C8.26266 5.2835 7.47152 4.5 6.49559 4.5C5.51966 4.5 4.72852 5.2835 4.72852 6.25C4.72852 7.2165 5.51966 8 6.49559 8Z" stroke="#464A6B" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg><span>${address}</span></div>
            <ul style="display: flex; gap: 17px; list-style: none; padding: 0px; margin: 0;" class="list-unstyled mb-0 property-list property-list-main44">
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/double-bed.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>rooms</span></div><span>${rooms}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/building-icon.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>floor</span></div><span>${floor}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/home.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>Property Type</span></div><span>${property_type}</span></li>
              <li><div class="item-name"><img loading="lazy" src="https://brokerland.co.il/wp-content/themes/brokerland/images/square.svg" alt="Palm Dom 4 Hadera" class="img-fluid"/><span>size</span></div><span>${size}</span></li>
            </ul></div></a>`
          )
          .addTo(map);
      });

      // Zoom to cluster expansion on click
      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });

        const clusterId = features[0].properties.cluster_id;
        map
          .getSource("properties")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
      });

      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });

      map.on("click", "clustersSingle", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clustersSingle"],
        });

        const clusterId = features[0].properties.cluster_id;
        map
          .getSource("singleAsset")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
      });

      map.on("mouseenter", "clustersSingle", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clustersSingle", () => {
        map.getCanvas().style.cursor = "";
      });
    });
    // Cleanup on unmount
    return () => map.remove();
  }, [properties, singleAsset, mapContainerRef.current]);

  return (
    <div className="listing-details">
      <div className="details-section">
        <div style={{ width: "100%", height: "700px" }}>
          <div
            ref={mapContainerRef}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
