import {
  EnvironmentOutlined,
  StarOutlined,
  PrinterOutlined,
  PlusOutlined,
  HeartOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Image,
  Card,
  Tag,
  Input,
  navigate,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import image1 from "../../assets/images/image1.png";
import image2 from "../../assets/images/image2.png";
import image3 from "../../assets/images/image3.png";
import image4 from "../../assets/images/image4.png";
import image5 from "../../assets/images/image5.png";
import image6 from "../../assets/images/image6.png";
import image7 from "../../assets/images/image7.png";
import image8 from "../../assets/images/image8.png";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import ViewLogs from "../../components/ViewLogs";
import apiPath from "../../constants/apiPath";
import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../constants/reasons";
import { Months } from "../../constants/var";
import { useNavigate } from "react-router";

import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { ApproveStatus } from "../Driver/_ChangeRequest";
// import CreateAsset from "./AddForm"
import notfound from "../../assets/images/not_found.png";
import { useParams } from "react-router";
// import ChangeModal from "./_ChangeModal";
// import View from "./View"
const { TabPane } = Tabs;

function Index() {
  const heading = lang("My") + " " + lang("Tasks");
  const { setPageHeading } = useContext(AppStateContext);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const [files, setFiles] = useState([]);

  const handleUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleRemove = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };
  const contacts = [
    { name: "Darrell Steward", unread: 3 },
    { name: "Courtney Henry", unread: 1 },
    { name: "Darlene Robertson", unread: 2 },
    { name: "Arlene McCoy", unread: 0 },
    { name: "Brooklyn Simmons", unread: 5 },
    { name: "Devon Lane", unread: 0 },
    { name: "Ralph Edwards", unread: 0 },
    { name: "Robert Fox", unread: 0 },
    { name: "Albert Flores", unread: 0 },
    { name: "Jerome Bell", unread: 0 },
    { name: "Kathryn Murphy", unread: 0 },
    { name: "Jenny Wilson", unread: 0 },
    { name: "Esther Howard", unread: 0 },
    { name: "Marvin McKinney", unread: 0 },
  ];

  const messages = [
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      time: "08:04 pm",
      sender: "Jenny Willson",
      type: "text",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      time: "08:04 pm",
      sender: "Esther Howard",
      type: "text",
      sent: true,
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      time: "08:04 pm",
      sender: "Jenny Willson",
      type: "text",
    },
    {
      text: "https://www.example.com/audio.mp3",
      time: "08:04 pm",
      sender: "Esther Howard",
      type: "audio",
      sent: true,
    },
  ];

  return (
    <>
      <div className="app">
        <Row gutter={24}>
          <Col span={24} md={8} lg={6}>
            <div className="contact-list">
              <div className="contact-name-heading">Contacts</div>
              {contacts.map((contact, index) => (
                <div key={index} className="contact">
                  <div className="contact-name-details">
                    <img
                      src={`https://randomuser.me/api/portraits/thumb/men/${index}.jpg`}
                      alt={contact.name}
                    />
                    <span>{contact.name}</span>
                  </div>
                  {contact.unread > 0 && (
                    <span className="badge">{contact.unread}</span>
                  )}
                </div>
              ))}
            </div>
          </Col>
          <Col span={24} lg={18} md={16} >
            <div className="chat-window">
              <div className="messages">
                {messages.map((msg, index) => (
                  <>
                    <div
                      key={index}
                      className={`message ${msg.sent ? "sent" : "received"}`}
                    >
                      <div className="message-content">
                        {msg.type === "text" ? (
                          <p><h3>{msg.text}</h3>
                          <div className="message-send-time">
                      <div className="profile-image-w-name">
                        <img
                          src={`https://randomuser.me/api/portraits/thumb/${
                            msg.sent ? "women" : "men"
                          }/1.jpg`}
                          alt={msg.sender}
                        />
                        <span className="sender">{msg.sender}</span>
                      </div>
                      <div className="profile-image-w-name">
                        <span className="time">{msg.time}</span>
                      </div>
                    </div>
                          </p>
                          
                        ) : (
                          <audio controls src={msg.text}></audio>
                          
                        )}

                      </div>
                    </div>
                    
                  </>
                ))}
              </div>
              <div className="input-area">
                <input type="text" placeholder="Type a message here..." />
                <button>Send</button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
