import {
  Button,
  Card,
  Dropdown,
  Image,
  Input,
  Rate,
  Table,
  Col,
  Typography,
  Select,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import apiPath from "../../../constants/apiPath";
import { useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import { getRandomColor } from "../../../helper/functions";
import TabPane from "antd/lib/tabs/TabPane";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];
const dummy = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Dataset 1",
      data: [
        null,
        null,
        null,
        null,
        null,
        16000,
        null,
        5000,
        null,
        null,
        null,
        null,
      ], // Starting from June
      borderColor: "#F3E008",
      tension: 0.1,
      spanGaps: true, // Add this option
    },
    {
      label: "Dataset 2",
      data: [
        null,
        null,
        null,
        null,
        null,
        7000,
        null,
        8000,
        null,
        null,
        null,
        null,
      ], // Starting from June
      borderColor: "#383B42",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      spanGaps: true, // Add this option
      // yAxisID: 'y1',
    },
  ],
};

const DiscountPerformance = () => {
  const { country } = useAppContext();
  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const [list, setList] = useState([]);

  const [customer, setCustomer] = useState([]);
  const [customerLine, setCustomerLine] = useState();
  const [driver, setDriver] = useState([]);
  const [driverLine, setDriverLine] = useState();
  const [restaurant, setRestaurant] = useState([]);
  const [restaurantLine, setRestaurantLine] = useState();

  const [data, setData] = useState([]);
  const [revenueRes, setRevenueRes] = useState();
  const [revenueDriver, setRevenueDriver] = useState();
  const [commissionRes, setCommissionRes] = useState();
  const [commissionDriver, setCommissionDriver] = useState();
  const [loading, setLoading] = useState(false);
  const [codPayments, setCodPayments] = useState();
  const [onlinePayments, setOnlinePayments] = useState();
  const [years, setYears] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
    total: 0,
  });

  const [clientCount, setclientCount] = useState(0);
  const [leadCount, setleadCount] = useState(0);
  const [renterCount, setrenterCount] = useState(0);
  const [sellerCount, setsellerCount] = useState(0);
  const [rentingCount, setrentingCount] = useState(0);
  const [buyerCount, setbuyerCount] = useState(0);

  const api = {
    list: apiPath.listClient,
  };

  const debouncedSearchText = useDebounce(searchText, 300);

  useEffect(() => {
    setLoading(true);
    // fetchData({ ...pagination, current: 1 });

    request({
      url: api.list,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");

          setclientCount(data.clientCount);
          setleadCount(data.leadCount);
          setsellerCount(data.sellerCount);
          setbuyerCount(data.buyerCount);
          setrenterCount(data.renterCount);
          setrentingCount(data.rentingCount);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });

    getFilters();
  }, [refresh, filter, country.country_id]);

  const getFilters = () => {
    request({
      url: `${apiPath.dashboard}/sales/filters`,
      method: "GET",
      onSuccess: (res) => {
        if (res.status) {
          const { data, months, years } = res;
          setYears(years);
        }
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchData = () => {
    setLoading(true);
    const queryString = "";

    request({
      url: `${apiPath.report}/delivery-discount${
        queryString ? `?${queryString}` : ""
      }`,
      method: "GET",
      onSuccess: ({ data, status, deliver, pickup, cancel }) => {
        console.log(data, "data");
        setLoading(false);
        if (status) {
          // setList(customer)
          // setData(payment)
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    if (!customer.length) return setCustomerLine();
    let city = [];
    let restaurant = Array.from({ length: 12 });
    const dataSetsRestaurant = customer.map((item, Index) => {
      const data = restaurant.map((res, index) => {
        const findIndex = item.monthData.find(
          ({ month }) => index + 1 == month
        );
        if (findIndex) return findIndex.count;
        return null;
      });
      return {
        label: `${item.city.name}`,
        data: data,
        borderColor: getRandomColor(Index),
        tension: 0.1,
        spanGaps: true,
      };
    });
    setCustomerLine({ ...dummy, datasets: dataSetsRestaurant });
  }, [customer]);

  useEffect(() => {
    if (!driver.length) return setDriverLine();
    let city = [];
    let restaurant = Array.from({ length: 12 });
    const dataSetsRestaurant = driver.map((item, Index) => {
      const data = restaurant.map((res, index) => {
        const findIndex = item.monthData.find(
          ({ month }) => index + 1 == month
        );
        if (findIndex) return findIndex.count;
        return null;
      });
      return {
        label: `${item.city.name}`,
        data: data,
        borderColor: getRandomColor(Index),
        tension: 0.1,
        spanGaps: true,
      };
    });
    setDriverLine({ ...dummy, datasets: dataSetsRestaurant });
  }, [driver]);

  const onChange = (key, value) => {};

  return (
    <>
      <Col xs={24} xl={12} lg={24} className="mb-3">
        <div className="chart-headding">
          <h2>Client/lead</h2>
        </div>
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: clientCount, label: "Client" },
                { id: 1, value: leadCount, label: "Lead" },
              ],
            },
          ]}
          height={300}
        />
      </Col>
      <Col xs={24} xl={12} lg={24} className="mb-3">
        <div className="chart-headding">
          <h2>Client Type</h2>
        </div>
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: buyerCount, label: "Buyer" },
                { id: 1, value: sellerCount, label: "Seller" },
                { id: 2, value: renterCount, label: "Renter" },
                { id: 3, value: rentingCount, label: "Renting" },
              ],
            },
          ]}
          height={300}
        />
      </Col>

      <Col xs={24} xl={12} lg={24} className="mb-3">
        <div className="chart-headding">
          <h2>All Page</h2>
        </div>
        <LineChart
          height={350}
          series={[
            { data: pData, label: "pv" },
            { data: uData, label: "uv" },
          ]}
          xAxis={[{ scaleType: "point", data: xLabels }]}
        />
      </Col>
      <Col xs={24} xl={12} lg={24} className="mb-3">
        <div className="chart-headding">
          <h2>BarChart</h2>
        </div>
        <BarChart
          height={400}
          series={[
            { data: pData, label: "pv", id: "pvId" },
            { data: uData, label: "uv", id: "uvId" },
          ]}
          xAxis={[{ data: xLabels, scaleType: "band" }]}
        />
      </Col>
    </>
  );
};

export default DiscountPerformance;
