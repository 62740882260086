import React, { Suspense, useContext } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";

import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

import { ToastContainer } from "react-toastify";
import "./assets/styles/responsive.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";

import Loader from "./components/Loader";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import Error from "./pages/Error";
import Profileform from "./pages/User/Customer/Profileform";

import { PATH_HEADING_MAP } from "./constants/path-heading-map.constants";

import {
  Category,
  CmsManagement,
  Collector,
  Customer,
  DeliveryHistory,
  Discount,
  Notifications,
  Order,
  Ratings,
  Register,
  Reports,
  Restaurant,
  SubAdmin,
  Company,
  Subscription,
  SubscriptionOrder,
  Asset,
  MyDocument,
  CreateDocument,
  SignatureCanva,
  SignatureDocument,
  Messege,
  TransactionManagement,
  Account,
  PublicAsset,
  IndexView,
  CreateAsset,
  ViewAsset,
  CreateTask,
  AddClient,
  VerifyEmail,
  ViewClientDetail,
  AssetShare,
  Activityform,
  Owner,
  AddOwner,
  ViewOwnerDetail,
} from "./pages";
import MyTask from "./pages/MyTask";
import DeliveryCharge from "./pages/DeliveryCharges/Index";
import Driver from "./pages/Driver/Index";
import EmailEdit from "./pages/EmailTemplate/Edit";
import EmailTemplate from "./pages/EmailTemplate/Index";
import Finance from "./pages/Finance/Index";
import ServiceLocation from "./pages/ServiceLocation/Index";
import ServiceLocationView from "./pages/ServiceLocation/View";
import TableIndex from "./pages/publicAsset/TableIndex";
import ServiceLocationAreaView from "./pages/ServiceLocation/ViewArea";
import CollectorView from "./pages/User/Collector/View";
import InvoiceView from "./pages/Finance/InvoiceView";
import GoogleCalendarIntegration from "./pages/Calendar/GoogleCalendarIntegration";
import { ThemeContextProvider } from "./context/ThemeContext";
import ProcessSuccess from "./pages/ProcessSuccess";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <ThemeContextProvider>
        <AppContextProvider>
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<Loader />}>
              <BrowserRouter>
                <ScrollToTop />
                <ToastContainer closeOnClick={false} />
                <AppRoutes />
              </BrowserRouter>
            </Suspense>
          </I18nextProvider>
        </AppContextProvider>
      </ThemeContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/processSuccess" element={<ProcessSuccess />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/sign-up" element={<Register />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/AssetShare/:username/:id" element={<AssetShare />} />
      <Route
        exact
        path="/document-uploadSign/:id"
        element={<SignatureDocument />}
      />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />
        {/* Broker Routes */}
        <Route exact path="/create-client" element={<AddClient />} />
        <Route exact path="/edit-client/:id" element={<AddClient />} />
        <Route exact path="/my-client" element={<Customer />} />
        <Route exact path="/client-detail/:id" element={<ViewClientDetail />} />
        <Route exact path="/my-profile" element={<Profileform />} />
        <Route exact path="/addactivity/:id" element={<Activityform />} />
        {/* Owner Routes */}
        <Route exact path="/create-owner" element={<AddOwner />} />
        <Route exact path="/edit-owner/:id" element={<AddOwner />} />
        <Route exact path="/owner" element={<Owner />} />
        <Route exact path="/owner-detail/:id" element={<ViewOwnerDetail />} />
        {/* Specialist Routes */}
        <Route
          exact
          path={PATH_HEADING_MAP.subadmin.path}
          element={<SubAdmin />}
        />
        {/* category */}
        <Route exact path="/create-asset" element={<CreateAsset />} />
        <Route exact path="/edit-asset/:id" element={<CreateAsset />} />
        <Route exact path="/view-asset/:id" element={<ViewAsset />} />
        {/* subscription */}
        <Route exact path="/subscription" element={<Subscription />} />
        {/* subscription order*/}
        <Route
          exact
          path="/subscription-order"
          element={<SubscriptionOrder />}
        />
        {/* cms manager */}
        <Route exact path="/cms" element={<CmsManagement />} />+{" "}
        {/* Asset manager */}
        <Route exact path="/asset" element={<Asset />} />
        {/* Asset manager */}
        <Route exact path="/public-asset" element={<PublicAsset />} />
        <Route exact path="/table-asset" element={<TableIndex />} />
        <Route exact path="/asset-view/:id" element={<IndexView />} />
        {/* my task routes*/}
        <Route exact path="/my-task" element={<MyTask />} />
        {/* my task routes*/}
        <Route exact path="/my-document" element={<MyDocument />} />
        <Route exact path="/create-document" element={<CreateDocument />} />
        <Route exact path="/create-document/:id" element={<CreateDocument />} />
        <Route exact path="/my-sign" element={<SignatureCanva />} />
        {/* my task routes*/}
        <Route exact path="/messege" element={<Messege />} />
        {/* my task routes*/}
        <Route
          exact
          path="/transaction-management"
          element={<TransactionManagement />}
        />
        {/* my task routes*/}
        <Route exact path="/account" element={<Account />} />
        <Route exact path="/create-task" element={<CreateTask />} />
        <Route exact path="/edit-task/:id" element={<CreateTask />} />
        {/* driver  */}
        <Route exact path="/drivers" element={<Driver />} />
        {/* finance */}
        <Route exact path="/finance" element={<Finance />} />
        <Route exact path="/finance/:id/invoice" element={<InvoiceView />} />
        {/* ratings */}
        <Route exact path="/ratings" element={<Ratings />} />
        {/* notification */}
        <Route exact path="/notification" element={<Notifications />} />
        {/* reports */}
        <Route exact path="/reports" element={<Reports />} />
        {/* discount */}
        <Route exact path="/discount" element={<Discount />} />
        {/* collector */}
        <Route exact path="/collector" element={<Collector />} />
        {/* view collector */}
        <Route exact path="/collector/:id" element={<CollectorView />} />
        <Route exact path="/locations" element={<ServiceLocation />} />
        <Route exact path="/locations/:id" element={<ServiceLocationView />} />
        <Route
          exact
          path="/locations/:id/area"
          element={<ServiceLocationAreaView />}
        />
        <Route exact path="/delivery-charge" element={<DeliveryCharge />} />
        <Route exact path="/email-templates" element={<EmailTemplate />} />
        <Route exact path="/email-templates/:id/edit" element={<EmailEdit />} />
        {/* order */}
        <Route exact path="/order" element={<Order />} />
        {/* view comapny */}
        <Route exact path="/companies" element={<Company />} />
        <Route path="/calendar" element={<GoogleCalendarIntegration />} />

      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
