
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Card,
  Tag,
  Menu,
  Dropdown,
  Space,
  Input,
  Upload,
  Typography,
  Form,
} from "antd";
import {
  UnorderedListOutlined,
  IdcardOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ConfirmationBox from "../../components/ConfirmationBox";
import apiPath from "../../constants/apiPath";
import { DownloadExcel } from "../../components/ExcelFile";
import * as XLSX from "xlsx";
import { Months } from "../../constants/var";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import PhoneInput from "react-phone-input-2";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

const options = [
  {
    text: "Buyer",
    value: "buyer",
  },
  {
    text: "Seller",
    value: "seller",
  },
  {
    text: "Renting",
    value: "renting",
  },
  {
    text: "Renter",
    value: "renter",
  },
];
const filterOP = [
  {
    text: "New",
    value: "new",
  },
  {
    text: "First Contact",
    value: "first_contact",
  },
  {
    text: "In Progress",
    value: "in_progress",
  },
  {
    text: "Active Client",
    value: "active_client",
  },
  {
    text: "Not Relevant",
    value: "not_relevant",
  },
];
const filterplt = [
  {
    text: "Residential",
    value: "residential",
  },
  {
    text: "Commercial",
    value: "commercial",
  },
];
const filtercl = [
  {
    text: "Client",
    value: "client",
  },
  {
    text: "Lead",
    value: "lead",
  },
];
const filtebr = [
  {
    text: "Broker",
    value: "true",
  },
  {
    text: "NO",
    value: "false",
  },
];
const filters = [
  {
    text: "Rent",
    value: "rent",
  },
  {
    text: "Sale",
    value: "buy",
  },
];

const filtersn = [
  {
    text: "Whatsapp",
    value: "whatsapp",
  },
  {
    text: "Social Media",
    value: "social media",
  },
  {
    text: "By Phone",
    value: "by phone",
  },
];
const { TabPane } = Tabs;
const { Text } = Typography;
function Index() {
  const heading = lang("My") + " " + lang("Clients");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();
  const navigate = useNavigate();
  const sectionName = lang("Owners");
  const urlParams = new URLSearchParams(window.location.search);
  const year = urlParams.get("year");
  const api = {
    status: apiPath.statusClient,
    uploadClientExcel: apiPath.uploadClientExcel,
    add: apiPath.addEditClient,
    list: apiPath.listOwner,
    delete: apiPath.deleteClient,
    update: apiPath.editClient,
    user: apiPath.deletemultiClient,
  };

  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteAllModal, showDeleteAllModal] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;

  const [city, setCity] = useState([]);
  const [neighborhood, setNeighborhood] = useState([]);

  const [clients, setClients] = useState();
  const [client, setClient] = useState(null);
  const [cities, setCities] = useState([]);
  const [listTrue, setListTrue] = useState(true);

  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [importFile, setImportFile] = useState([]);
  const [file, setFile] = useState([]);
  const fileType = [
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleImport = (info) => {
    const { file, fileList } = info;
    console.log("-----------------:: ", info);
    if (fileList?.length) {
      if (!fileType.includes(file.type)) {
        setError("File format is not correct");
        return false;
      }
      setError();

      const fileData = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        uid: file.uid,
        status: "done",
        originFileObj: file.originFileObj,
        size: file.size,
        type: file.type,
        percent: file.percent,
      };

      setFile([fileData]);
      parseExcel(file.originFileObj);
    } else {
      setError("Missing Excel File.");
    }
  };

  const parseExcel = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Parse the worksheet data into an array of objects
      const parsedData = XLSX.utils.sheet_to_json(worksheet);

      // Filter data based on some conditions like below
      const filteredData = parsedData.filter((row) => {
        const email = row.email;
        const country_code = row.countryCode;
        const mobile_number = row.mobileNumber;
        const name = row.name;

        const shouldExclude = () => {
          let exclude = false;

          // Check conditions for name (case-insensitive)
          // if (
          //   name &&
          //   [
          //     "Brand",
          //     "Category",
          //     "Product",
          //     "Service",
          //     "Vehicle Make",
          //     "Vehicle Model",
          //     "Vehicle Type",
          //     "Vehicle Variant",
          //   ]?.includes(sectionName)
          // ) {
          //   const isDuplicateName = existingData?.some(
          //     (existingRow) =>
          //       existingRow?.name?.toLowerCase()?.trim() ===
          //       name?.toLowerCase()?.trim()
          //   );
          //   exclude = exclude || isDuplicateName;
          // }

          // Check conditions for email
          // if (email) {
          //   const isDuplicateEmail = existingData.some(
          //     (existingRow) =>
          //       existingRow.email.toLowerCase() === email.toLowerCase()
          //   );
          //   exclude = exclude || isDuplicateEmail;
          // }

          // // Check conditions for country_code and mobile_number together
          // if (country_code && mobile_number) {
          //   const isMatchingCountryMobile = existingData.some(
          //     (existingRow) =>
          //       existingRow.country_code === country_code &&
          //       existingRow.mobile_number === mobile_number
          //   );
          //   exclude = exclude || isMatchingCountryMobile;
          // }

          return exclude;
        };

        return !shouldExclude();
      });

      // Extract the remaining rows and set to setImportFile state
      setImportFile(filteredData);

      // Excluded rows and download the excel file with name of duplicate
      const excludedRows = parsedData.filter(
        (row) => !filteredData.includes(row)
      );
      if (excludedRows && excludedRows.length > 0) {
        const name = "Duplicate" + "-" + sectionName;
        // DownloadExcel(excludedRows, name)
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
  });

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("all");

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const [optionType, setOptionType] = useState([]);
  const [isType, setIsType] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const debouncedSearchFilter = useDebounce(selectedFilter, 300);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const onChangeList = (e) => {
    console.log(e);
    setListTrue(e);
  };

  const onDelete = (id) => {
    if (!id) {
      console.error("No record ID provided for deletion");
      return;
    }
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.list + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const cleanMobileNumber = (mobileNumber) => {
    if (!mobileNumber) return;
    return mobileNumber.replace("-", "");
  };

  const defaultColumns = [
    {
      title: lang("Name"),
      dataIndex: "advertiser_name",
      key: "advertiser_name",
      type: "text",
      editable: true,
      width: 200,
      render: (_, { advertiser_name }) => {
        return advertiser_name ? (
          <a
            onClick={() => navigate("/owner-detail/" + advertiser_name)}
          >{`${advertiser_name}`}</a>
        ) : (
          "Unknown"
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: lang("Phone"),
      dataIndex: ["mobile_number"],
      type: "phone",
      key: "mobile_number",
      render: (_, { mobile_number }) => (
        <a
          href={`tel:${mobile_number ? mobile_number : ''}`}
          target="_blank"
          className="wishlisted wishlisted-new"
        >
          {mobile_number ? mobile_number : '--'}
        </a>
      ),


    },

    {
      title: lang("Asset Count"),
      dataIndex: ["asset_count"],
      type: "asset_count",
      editable: true,
      key: "asset_count",
      width: 200,
      render: (_, { asset_count }) => {
        return `${asset_count ? `${asset_count}` : "0"}`;
      },
    },
    {
      title: lang("From Broker"),
      dataIndex: ["broker"],
      type: "broker",
      editable: true,
      key: "broker",
      width: 200,
      render: (_, { broker }) => {
        return `${broker ? "Yes" : "No"}`;
      },
    },
    {
      title: lang("Broker Assets"),
      dataIndex: ["fromBrokerTrueCount"],
      type: "fromBrokerTrueCount",
      editable: false,
      key: "fromBrokerTrueCount",
      width: 200,
      render: (_, { fromBrokerTrueCount }) => {
        return `${fromBrokerTrueCount}`;
      },
    },
    {
      title: lang("Non Broker Assets"),
      dataIndex: ["fromBrokerFalseCount"],
      type: "fromBrokerFalseCount",
      editable: true,
      key: "fromBrokerFalseCount",
      width: 200,
      render: (_, { fromBrokerFalseCount }) => {
        return `${fromBrokerFalseCount}`;
      },
    },
    {
      title: lang("Status"),
      dataIndex: ["active"],
      type: "active",
      editable: true,
      key: "active",
      width: 200,
      render: (_, { active }) => {
        return `${active ? "Active" : "InActive"}`;
      },
    },
    {
      title: lang("Public Assets"),
      dataIndex: ["make_publicTrueCount"],
      type: "make_publicTrueCount",
      editable: true,
      key: "make_publicTrueCount",
      width: 200,
      render: (_, { make_publicTrueCount }) => {
        return `${make_publicTrueCount}`;
      },
    },
    {
      title: lang("Private Assets"),
      dataIndex: ["make_publicFalseCount"],
      type: "make_publicFalseCount",
      editable: true,
      key: "make_publicFalseCount",
      width: 200,
      render: (_, { make_publicFalseCount }) => {
        return `${make_publicFalseCount}`;
      },
    },
    {
      title: lang("Action"),
      dataIndex: ["action"],
      type: "action",
      key: "action",
      width: 200,
      render: (_, { advertiser_name, country_code, mobile_number }) => {
        return (
          <div className="d-flex justify-content-start">
            <Tooltip
              title={lang("Whatsapp")}
              color={"purple"}
              key={"whatapp" + advertiser_name}
            >
              <a
                href={`https://wa.me/+${cleanMobileNumber(
                  mobile_number
                )}?text=`}
                className="whatsapp-cls"
                target="_blank"
              >
                <WhatsAppIcon />
              </a>
            </Tooltip>
            <Tooltip title={lang("View")} color={"purple"} key={"View"}>
              <Button
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  navigate(`/owner-detail/${advertiser_name}`);
                }}
              >
                <LocationOnOutlinedIcon />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
      }),
    };
  });

  const handleChangeClientStatus = (value, id) => {
    setLoading(true);
    console.log("Selected status Type: ", value, id);
    request({
      url: api.status + "/" + id,
      method: "PUT",
      data: { is_type: value },
      onSuccess: (data) => {
        setLoading(false);
        ShowToast("Status changed successfully", Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleOptionTypeChange = (value) => {
    console.log(value);
    setOptionType(value);
  };

  const handleIsTypeChange = (value) => {
    console.log(value);
    setIsType(value);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [
    refresh,
    debouncedSearchText,
    filter,
    selectedTab,
    country?.country_id,
    debouncedSearchFilter,
  ]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = () => {
    request({
      url: `${api.list
        }?advertiser_name=${debouncedSearchText}&debouncedSearchFilter=${JSON.stringify(
          debouncedSearchFilter
        )}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");

          const list = data.list.map((item) => ({ ...item, key: item._id }));

          setClients(list);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const excelData =
    clients &&
    clients.length > 0 &&
    clients.map((row, index) => ({
      advertiser_name: row.advertiser_name ? row.advertiser_name : "-",
      country_code: row.country_code ? row.country_code : "-",
      mobile_number: row.mobile_number ? row.mobile_number : "-",
      asset_count: row.asset_count ? row.asset_count : "-",
    }));

  const handleOtFilter = (pagination, filters) => {
    filters = {
      is_type: isType,
      optionType: optionType,
    };
    setIsModalOpen(false);
    fetchData(pagination, filters);
  };

  const handleChange = (pagination, filters) => {
    console.log(filters);
    fetchData(pagination, filters);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const components = {
    // body: {
    //   row: EditableRow,
    //   cell: EditableCell,
    // },
  };

  const DeleteAll = () => {
    if (!hasSelected) return;
    request({
      url: api.user + "/delete-all",
      method: "POST",
      data: {
        ids: selectedRowKeys,
      },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast("Data Deleted Successfully", Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [uploadModal, setUploadModal] = useState(false);
  const showUploadModal = () => {
    setUploadModal(true);
  };
  const handleOk01 = () => {
    setUploadModal(false);
  };
  const handleCancel01 = () => {
    setUploadModal(false);
  };

  const onImport = () => {
    if (error) {
      return;
    }
    if (file?.length) {
      setLoading(true);
      const payload = {};
      payload.import_file = importFile;
      request({
        url: api.uploadClientExcel,
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            // hide();
            fetchData({ ...pagination, current: 1 });
            setUploadModal(false);
            // refresh();
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error.response.data.errors[0].message, Severty.ERROR);
          setLoading(false);
        },
      });
    } else {
      setError("Please Select an Ecxel File.");
    }
  };

  const menuItems = [
    // { text: "Name", key: "name", label: "Name" },
    // { text: "Email", key: "email", label: "Email" },
    { text: "City", key: "city", label: "City" },
    { text: "Neighborhood", key: "neighborhood", label: "Neighborhood" },
    { text: "Broker/NO", key: "broker", label: "Broker/NO" },
    { text: "Rent/Sale", key: "to_let", label: "Rent/Sale" },
    // { text: "Status", key: "status", label: "Status" },
    // { text: "Asset Type", key: "assetType", label: "Asset Type" },
    // { text: "Property Type", key: "propertyType", label: "Property Type" },
    // { text: "Source Name", key: "SourceName", label: "Source Name" },
    // { text: "No of Rooms", key: "numberOfRooms", label: "No of Rooms" },
    // { text: "budget", key: "budget", label: "budget" },
    // { text: "size", key: "size", label: "size" },
    // {
    //   text: "Property Living Type",
    //   key: "propertylivType",
    //   label: "Property Living Type",
    // },
  ];

  // const handleMeClick = (e) => {
  //   if (!selectedFilters.includes(e.key)) {
  //     const newSelectedFilters = [...selectedFilters, e.key];
  //     setSelectedFilters(newSelectedFilters);

  //     const filtersObject = newSelectedFilters.reduce((acc, key) => {
  //       acc[key] = null;
  //       return acc;
  //     }, {});

  //     setSelectedFilter(filtersObject);
  //   }
  // };

  // const handleClose = (removedFilter) => {
  //   const newFilters = selectedFilters.filter(
  //     (filter) => filter !== removedFilter
  //   );
  //   setSelectedFilters(newFilters);

  //   const filtersObject = newFilters.reduce((acc, key) => {
  //     acc[key] = null;
  //     return acc;
  //   }, {});
  //   setSelectedFilter(filtersObject);
  // };

  const handleMeClick = (e) => {
    if (!selectedFilters.includes(e.key)) {
      const newSelectedFilters = [...selectedFilters, e.key];
      setSelectedFilters(newSelectedFilters);
      setAddFiltersVisible(true);
      setSelectedFilter((prevSelectedFilter) => ({
        ...prevSelectedFilter,
        [e.key]: null,
      }));
    }
  };

  const handleClose = (removedFilter) => {
    if (removedFilter === "city") {
      setNeighborhood([]);
    }
    const newFilters = selectedFilters.filter(
      (filter) => filter !== removedFilter
    );
    setSelectedFilters(newFilters);

    setSelectedFilter((prevSelectedFilter) => {
      const { [removedFilter]: _, ...rest } = prevSelectedFilter;
      return rest;
    });
  };

  const menu = (
    <Menu onClick={handleMeClick}>
      {menuItems.map((item) => (
        <Menu.Item text={item.text} key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  const [isToggled, setIsToggled] = useState(false);

  const [addFiltersVisible, setAddFiltersVisible] = useState(false); // State to manage visibility

  const handleToggleFilters = () => {
    setAddFiltersVisible(!addFiltersVisible); // Toggle visibility
    setIsToggled(!isToggled);
  };

  // const handleSetVal = (value) => {
  //   console.log(value);
  // };

  // const menu02 = (value) => (
  //   <div className="menu-items-nav">
  //     <Input
  //       placeholder={value}
  //       value={selectedFilter ? selectedFilter.value : ""}
  //       onChange={handleSetVal(value)}
  //     />
  //   </div>
  // );

  const handleSetVal = (key, value) => {
    if (key === "city") {
      handleCityUpdate(value);
    }
    console.log(`Key: ${key}, Value: ${value}`);
    console.log(selectedFilter);
    setSelectedFilter((prevSelectedFilter) => ({
      ...prevSelectedFilter,
      [key]: value,
    }));
    setAddFiltersVisible(true);
    // fetchData(pagination, debouncedSearchFilter);
  };

  const changableFeilds = (value) => {
    switch (value) {
      case "city":
        return "City";
      case "neighborhood":
        return "Neighborhood";
      case "broker":
        return "Broker/NO";
      case "to_let":
        return "Rent/Sale";
      case "clientType":
        return "Client Type";
      case "numberOfRooms":
        return "Rooms";
      case "budget":
        return "Budget";
      case "size":
        return "Size";
      default:
        return value;
    }
  };

  const menu02 = (key) => {
    if (key === "broker") {
      return (
        <Select
          placeholder="Please select"
          key="broker"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filtebr.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }
    if (key === "to_let") {
      return (
        <Select
          placeholder="Please select"
          key="to_let"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filters.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }
    if (key === "sourceName") {
      return (
        <Select
          placeholder="Please select"
          key="optionType"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filtersn.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }
    if (key === "clientType") {
      return (
        <Select
          placeholder="Please select"
          key="optionType"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={options.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }

    if (key === "client_lead") {
      return (
        <Select
          placeholder="Please select"
          key="client_lead"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filtercl.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }

    if (key === "propertylivType") {
      return (
        <Select
          placeholder="Please select"
          key="propertylivType"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filterplt.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }

    if (key === "status") {
      return (
        <Select
          placeholder="Please select"
          key="status"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filterOP.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }

    if (key === "city") {
      return (
        <Select
          placeholder="Please select"
          key="status"
          showSearch
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={city.map((option) => ({
            label: option,
            value: option,
          }))}
        />
      );
    }

    if (key === "neighborhood") {
      return neighborhood.length > 0 ? (
        <Select
          placeholder="Please select"
          key="status"
          showSearch
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={neighborhood.map((option) => ({
            label: option,
            value: option,
          }))}
        />
      ) : (
        <div className="menu-items-nav">
          <Input
            placeholder={changableFeilds(key)}
            value={selectedFilter ? selectedFilter[key] : ""}
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          />
        </div>
      );
    }

    if (
      key === "email" ||
      key === "name" ||
      key === "numberOfRooms" ||
      key === "budget" ||
      key === "size"
    ) {
      return (
        <div className="menu-items-nav">
          <Input
            placeholder={changableFeilds(key)}
            value={selectedFilter ? selectedFilter[key] : ""}
            onChange={(e) => handleSetVal(key, e.target.value)}
          />
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    getCity();
  }, []);

  const getCity = () => {
    request({
      url: `/common/city`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data.list, "city");
        setCity(data.list);
      },
    });
  };

  const handleCityUpdate = (value) => {
    console.log(value);
    console.log("onSelect", value);
    getNeighborhood(value);
  };
  const getNeighborhood = (city) => {
    request({
      url: `/common/neighbourhood/${city}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        // if (status) {
        setNeighborhood(data.list);
        // }
      },
    });
  };

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <div className="main-hh-headding">
          <div className="headding-cc">{sectionName}</div>
          <Breadcrumb className="bread-menu">
            <Breadcrumb.Item className="bread-items" href="#">
              {lang("Dashboard")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="bread-items" href="#">
              {sectionName}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="im-ex-btn">
          {/* <Button
            title="Import"
            className="man-reset-bttn man-list-bttn "
            onClick={showUploadModal}
          >
            <FileUploadOutlinedIcon />
            <FileDownloadOutlinedIcon />
            Import
          </Button> */}
          {/* <Upload className="man-upload-btn" >
                  <Button className="man-btn-upload" icon={<FileUploadOutlinedIcon />}> </Button>
                </Upload> */}

          <Button
            title="Export"
            className="man-reset-bttn man-list-bttn "
            onClick={(e) => DownloadExcel(excelData, sectionName)}
          >
            {/* <FileDownloadOutlinedIcon /> */}
            <FileUploadOutlinedIcon />
            {lang("Export")}
          </Button>
        </div>
      </Row>
      <Card>
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24} className="col-p-sm">
            <div className="w-100 d-flex align-items-baseline text-head_right_cont">
              <div className="pageHeadingSearch clint-input ">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("Search by name")}
                  value={searchText}
                  onChange={onSearch}
                  allowClear
                />
              </div>

              <Button
                onClick={() => {
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  });
                  setSearchText("");
                }}
                type="primary"
                icon={<RestartAltOutlinedIcon />}
                className="edit-cls"
              >
                {/* {lang("Reset")} */}
              </Button>
              {/* <div className="sub-headding02">
                <Button
                  title="report"
                  className="report-btn"
                  onClick={() => navigate("/reports")}
                >
                  Report
                </Button>
              </div> */}
              <Dropdown
                overlay={menu}
                trigger={["click"]}
              // onClick={handleToggleFilters}
              >
                <Button className="report-btn">
                  <span className="add-more">
                    <AddCircleOutlineOutlinedIcon />
                  </span>
                  {lang("Filter")}
                </Button>
              </Dropdown>

              {/* <div className="lead-clint-drop">
                <Switch
                  checkedChildren="Card"
                  unCheckedChildren="List"
                  checked={listTrue}
                  onClick={(listTrue) => onChangeList(listTrue)}
                  className="main-mecu-switch"
                />
              </div> */}

              {/* <Button
                title="Filter"
                className="report-btn"
                onClick={handleToggleFilters} // Toggle visibility on button click
              >
                <ExpandMoreOutlinedIcon
                  className={` ${isToggled ? "angle-up" : "angle-dawn"}`}
                />
              </Button> */}

              {/* <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  console.log(e);
                  navigate(`/create-owner`);
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <span className="add-Ic ">
                  <AddCircleOutlineOutlinedIcon />
                </span>
                {lang("")} {sectionName}
              </Button> */}

              {hasSelected && (
                <Button
                  className="man-list-bttn edit-cls"
                  onClick={() => {
                    showDeleteAllModal(true);
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                  {/* {lang("Delete All")} */}
                </Button>
              )}
            </div>
          </Col>
          <Col xs={24} xl={24}>
            <div>
              <div
                className={`add-filters ${addFiltersVisible ? "show" : "hide"}`}
              >
                <Space className="flex-wrap">
                  {selectedFilters.map((filter) => (
                    <Tag
                      className="ftter-main-btn"
                      key={filter}
                      closable
                      closeIcon={<CloseOutlined />}
                      onClose={() => handleClose(filter)}
                    >
                      {filter && (
                        <>
                          <Dropdown
                            overlay={menu02(filter)}
                            trigger={["click"]}
                          >
                            <Button className="filter-btns">
                              {selectedFilter[filter]
                                ? changableFeilds(filter) +
                                " : " +
                                selectedFilter[filter]
                                : changableFeilds(filter)}
                            </Button>
                          </Dropdown>
                          {/* <Button className="filter-btns">{filter}</Button> */}
                        </>
                      )}
                    </Tag>
                  ))}
                  {/* <Dropdown overlay={menu} trigger={["click"]}>
                    <Button className="man-reset-bttn man-list-bttn ">
                      <AddCircleOutlineOutlinedIcon />
                    </Button>
                  </Dropdown> */}
                </Space>
              </div>
            </div>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={selectedTab}
          className="myclients-tab"
          onTabClick={(e) => {
            setSelectedTab(e);
          }}
        >
          <TabPane
            tab={
              <span className="myclients-tab-button">
                {" "}
                {lang("All")} ({clients?.length})
              </span>
            }
            key="all"
            className="myclients-tab-body"
          ></TabPane>
          {/* <TabPane
            tab={
              <span className="myclients-tab-button">
                Clients ({clientCount})
              </span>
            }
            key="client"
            className="myclients-tab-body"
          ></TabPane>
          <TabPane
            tab={
              <span className="myclients-tab-button"> Leads ({leadCount})</span>
            }
            key="lead"
            className="myclients-tab-body"
          ></TabPane> */}
        </Tabs>
        <Row gutter={[24, 16]}>
          {listTrue ? (
            <Col span={24} md={24}>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  components={components}
                  // rowSelection={rowSelection}
                  onChange={handleChange}
                  dataSource={clients}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                    // showSizeChanger: true,
                    // showQuickJumper: true,
                    // pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  className="ant-border-space"
                />
              </div>
            </Col>
          ) : (
            clients?.map((item) => (
              <Col span={24} lg={12} xxl={8} xl={12} md={12} sm={24}>
                <div className="main-card-all">
                  <div className="main-card-headding">
                    <div className="for-sale-main-div-fffff">
                      <div className="ass-view-btn">
                        <Button
                          type="default"
                          shape="round"
                          className="add-btn"
                          onClick={(e) => {
                            navigate(`/owner-detail/${item.advertiser_name}`);
                            // setVisible(true);
                            // setSearchText("");
                          }}
                        >
                          {" "}
                          <RemoveRedEyeOutlinedIcon />{" "}
                        </Button>
                        <a
                          // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${item._id}`}
                          // href={`https://wa.me/+972${item.mobile_number}`}
                          href={`https://api.whatsapp.com/send/?phone=${item.mobile_number}`}
                          target="_blank"
                          type="button"
                          title="Add to wishlist"
                          key={"whatapp" + item.mobile_number}
                          className="wishlisted"
                        >
                          <svg
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="WhatsAppIcon"
                          >
                            <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="card-detail-client">
                    <div>
                      <Text strong style={{ fontSize: "16px" }}>
                        {item?.advertiser_name ? item.advertiser_name : "-"}
                      </Text>
                    </div>
                    <Space
                      direction="vertical"
                      size="small"
                      className="for-grid-1"
                    >
                      <Space className="card-big-icon">
                        <LocalPhoneOutlinedIcon />
                        <div className="main-active-status">
                          <Text>
                            {"+" +
                              item?.country_code +
                              " - " +
                              item?.mobile_number}
                          </Text>
                        </div>
                      </Space>
                    </Space>
                  </div>
                </div>
              </Col>

              // <Client data={item} key={item.advertiser_name} />
            ))
          )}
        </Row>
      </Card>
    </>
  );
}

export default Index;
