import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Button,
  navigate,
  Select,
  List,
  Tag,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import MachesImg from "../../../assets/images/machesimg.png";
import { useAppContext } from "../../../context/AppContext";
import { SelectInput } from "../../../components/InputField";
import lang from "../../../helper/langHelper";
import apiPath from "../../../constants/apiPath";

const { Option } = Select;

const matchesData = [
  {
    key: "1",
    property: "Steward - Adipiscing II",
    score: "4 - Position",
    status: "Available",
    image: "./",
  },
  {
    key: "2",
    property: "Steward - Adipiscing II",
    score: "4 - Position",
    status: "Available",
    image: "https://via.placeholder.com/40",
  },
  {
    key: "3",
    property: "Steward - Adipiscing II",
    score: "4 - Position",
    status: "Available",
    image: "https://via.placeholder.com/40",
  },
  {
    key: "4",
    property: "Steward - Adipiscing II",
    score: "4 - Position",
    status: "Available",
    image: "https://via.placeholder.com/40",
  },
  {
    key: "5",
    property: "Steward - Adipiscing II",
    score: "4 - Position",
    status: "Available",
    image: "https://via.placeholder.com/40",
  },
];

const getStatusColor = (score) => {
  switch (score) {
    case "4 - Position":
      return "green";
    case "3 - Position":
      return "red";
    default:
      return "blue";
  }
};

const columns = [
  {
    title: "Property",
    dataIndex: "property",
    key: "property",
    render: (text, record) => (
      <div style={{ display: "flex", alignItems: "center", width: "max-content" }}>
        <img src={MachesImg} alt="Property" style={{ marginRight: "8px" }} />
        {text}
      </div>
    ),
  },
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
    render: (text) => <Tag color={getStatusColor(text)}>{text}</Tag>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const AddclintIndex = () => {
  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col span={24} md={24} xl={12} lg={24} className="profile-cart-m">
          <Card title="Profile" className="p-4">
            <Form layout="vertical">
              <Row gutter={16} className="main-space-cls">
                <Col md={10} span={24}>
                  <h3> Title </h3>
                </Col>
                <Col span={24} md={14}>
                  <Form.Item>
                    <Select defaultValue="Mr.">
                      <Option value="Mr.">Mr.</Option>
                      <Option value="Mrs.">Mrs.</Option>
                      <Option value="Miss">Miss</Option>
                      <Option value="Dr.">Dr.</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24]} className="main-space-cls">
                <Col span={24} md={10}>
                  <h3> Name </h3>
                </Col>
                <Col md={7} span={12}>
                  <Form.Item name="firstName">
                    <Input placeholder="Darrell" />
                  </Form.Item>
                </Col>
                <Col span={12} md={7}>
                  <Form.Item name="middlename">
                    <Input placeholder="Steward" />
                  </Form.Item>
                </Col>
                <Col md={10} span={24}></Col>
                <Col md={14} span={24}>
                  <Form.Item name="lastName">
                    <Input placeholder="Steward" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 24]} className="main-space-cls">
                <Col md={10} span={24}>
                  <h3> Company: </h3>
                </Col>
                <Col span={24} md={14}>
                  <Form.Item name="company">
                    <Input placeholder="Company" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="main-space-cls">
                <Col span={24} md={10}>
                  <h3> DOB: </h3>
                </Col>
                <Col span={24} md={14}>
                  <Form.Item name="dob">
                    <DatePicker style={{ width: "100%" }} placeholder="DOB" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="main-space-cls">
                <Col span={24} md={10}>
                  <h3> Passport No. : </h3>
                </Col>
                <Col span={24} md={14}>
                  <Form.Item name="passportNo">
                    <Input placeholder="Passport No." />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="main-space-cls">
                <Col span={24} md={10}>
                  <h3> Nationality. : </h3>
                </Col>
                <Col span={24} md={14}>
                  <Form.Item name="nationality">
                    <Input placeholder="Nationality" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={24} md={24} xl={12} lg={24} className="matches-cart-m">
          <Card title="Matches" className="p-4">
            <div className="table-responsive">
            <Table
              columns={columns}
              dataSource={matchesData}
              pagination={false}
            />
            </div>
          </Card>
        </Col>
        <Col span={24} className="maps-cart-m">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115548.15439423658!2d75.76456413394207!3d25.173535353831408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396f9b2648ca66a5%3A0x89f66a6a1b355149!2sSeven%20Wonder&#39;s%20Park%2C%20Kota!5e0!3m2!1sen!2sin!4v1719913306858!5m2!1sen!2sin"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
className="main-form-pmappss"
          ></iframe>
        </Col>
      </Row>
    </div>
  );
};

export default AddclintIndex;
