import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
  Checkbox,
  Empty,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState, useRef } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { uploadFile } from "react-s3";
import { s3Config } from "../../config/s3Config";
import UploadImage from "../../pages/User/Customer/_UploadImage";
import MultipleImageUpload from "../../components/MultipleImageUpload2";
import notfound from "../../assets/images/not_found.png";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import { useGeolocated } from "react-geolocated";
import Asset from "../../components/GoogleMap/Asset";

const { Option } = Select;

function CreateAsset({ data }) {
  const [location, setLocation] = useState({ lat: "", lng: "" });
  const [assetLoc, setAssetLoc] = useState({ lat: "", lng: "" });
  const params = useParams();
  const sectionName = params.id ? lang("Edit Asset") : lang("Create Asset");
  const heading = "Asset Management";
  const api = {
    add: apiPath.addAsset,
    edit: apiPath.editAsset,
    singleAsset: apiPath.singleAsset,
    clientList: apiPath.listClient,
  };
  const [form] = Form.useForm();
  const { setPageHeading } = useContext(AppStateContext);
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [image, setImage] = useState();
  const [multiImage, setMultiImage] = useState([]);
  const [city, setCity] = useState([]);
  const [singleAsset, setsingleAsset] = useState({});
  const [neighborhood, setNeighborhood] = useState([]);
  const isFirstRender = useRef(true);

  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const [formData, setFormData] = useState({
    location: {
      city: "",
      neighborhood: "",
      street: "",
      street_number: "",
    },
    client_id: "",
    to_let: "",
    property_type: "",
    rooms_number: "",
    floor_number: "",
    floors_in_building: "",
    size_in_meters: "",
    property_status: "",
    price_per_square_meter: "",
    total_price: "",
    property_title: "",
    longitude: "",
    latitude: "",
    property_description: "",
    advertiser_name: "",
    // mobile: "",
    date_of_entry: null,
    taxes: "",
    image: "",
    make_public: false,
    multiImage: [],
    amenities: [],
  });
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  useEffect(() => {
    if (coords) {
      const newLocation = { lat: coords.latitude, lng: coords.longitude };
      setLocation({ lat: coords.latitude, lng: coords.longitude });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [coords]);

  useEffect(() => {
    if (isFirstRender.current && location.lat && location.lng) {
      isFirstRender.current = false;
      return;
    } else {
      form.setFieldValue("longitude", location.lng);
      form.setFieldValue("latitude", location.lat);
    }
  }, [location]);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
      message.error("File format is not correct");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(`Image must be smaller than 5 MB!`);
      return false;
    }
    return true;
  };
  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  const handleImage = (value) => {
    setMultiImage((prev) => {
      return value.map((item) => {
        return item.url;
      });
    });
  };

  useEffect(() => {
    fetchAsset(params.id);
  }, [params.id]);

  const fetchAsset = (id) => {
    request({
      url: `${api.singleAsset + "/" + id}`,
      method: "GET",
      onSuccess: (response) => {
        const data = response.data;

        // Set the fetched data into formData state
        setFormData({
          location: {
            city: data.location.city,
            neighborhood: data.location.neighborhood,
            street: data.location.street,
            street_number: data.location.street_number,
          },
          to_let: data.to_let,
          client_id: data.client_id,
          property_type: data.property_type,
          rooms_number: data.rooms_number,
          floor_number: data.floor_number,
          floors_in_building: data.floors_in_building,
          size_in_meters: data.size_in_meters,
          property_status: data.property_status,
          price_per_square_meter: data.price_per_square_meter,
          total_price: data.total_price,
          property_title: data.property_title,
          longitude: data.longitude,
          latitude: data.latitude,
          property_description: data.property_description,
          advertiser_name: data.advertiser_name,
          mobile: data.mobile_number,
          date_of_entry: data.date_of_entry ? moment(data.date_of_entry) : null,
          taxes: data.taxes,
          image: data.image,
          make_public: data.make_public,
          multiImage: data.multiImage || [],
          amenities: data.amenities || [],
        });
        // Set the fetched data into form fields
        form.setFieldsValue({
          // location: {
          city: data.location.city,
          neighborhood: data.location.neighborhood,
          street: data.location.street,
          street_number: data.location.street_number,
          // },
          to_let: data.to_let,
          client_id: data.client_id,
          property_type: data.property_type,
          rooms_number: data.rooms_number,
          floor_number: data.floor_number,
          floors_in_building: data.floors_in_building,
          size_in_meters: data.size_in_meters,
          property_status: data.property_status,
          price_per_square_meter: data.price_per_square_meter,
          total_price: data.total_price,
          property_title: data.property_title,
          longitude: data.longitude,
          latitude: data.latitude,
          property_description: data.property_description,
          advertiser_name: data.advertiser_name,
          mobile: data.mobile_number,
          date_of_entry: data.date_of_entry ? moment(data.date_of_entry) : null,
          taxes: data.taxes,
          image: data.image,
          make_public: data.make_public,
          multiImage: data.multiImage || [],
          amenities: data.amenities || [],
        });

        getNeighborhood(data.location.city);
        setImage(data.image ? data.image : "");
        setMultiImage(data.multiImage ? data.multiImage : []);
        if (data.latitude && data.longitude) {
          setLocation({ lat: data.latitude, lng: data.longitude });
        }
        setMobileNumber({
          mobile_number: data?.mobile_number,
          country_code: data?.country_code,
        });
      },
    });
  };

  const onCreate = (values) => {
    const payload = {
      ...values,
      location: {
        city: values.city,
        neighborhood: values.neighborhood,
        street: values.street,
        street_number: values.street_number,
      },
      image: image,
    };
    payload.mobile_number = values.mobile;
    payload.country_code = mobileNumber.country_code;
    console.log(payload);
    setLoading(true);
    request({
      url: `${params.id ? api.edit + "/" + params.id : api.add}`,
      method: params.id ? "PUT" : "POST",
      data: payload,

      onSuccess: (data) => {
        setLoading(false);
        if (data?.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate(-1);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const [filter, setFilter] = useState({
    // country_id: undefined,
    // city_id: undefined,
    // year: year,
    // month: undefined,
  });
  const fetchData = () => {
    request({
      url: api.clientList + `?clientType=client`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");
          setClients(data.list);
          //   setPagination((prev) => ({
          //     ...prev,
          //     current: pagination.current,
          //     total: total,
          //   }));
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    const formValues = {
      ...data,
      ...data.location,
      ...data.amenities,
      ...data.multiImage,
      mobile: data.country_code + data.mobile_number,
      date_of_entry: data.date_of_entry ? moment(data.date_of_entry) : null,
    };
    form.setFieldsValue(formValues);
    setMobileNumber({
      mobile_number: data.mobile_number,
      country_code: data.country_code,
    });
    if (data.image != undefined) {
      setImage(data.image);
    }

    if (data.multiImage != undefined) {
      setMultiImage(data.multiImage);
    }
    console.log(data);
  }, [data]);

  useEffect(() => {
    setPageHeading(heading);
    fetchData();
    getCity();
  }, [setPageHeading]);

  const getCity = () => {
    request({
      url: `/common/city`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data.list, "city");
        setCity(data.list);
      },
    });
  };

  const handleCityUpdate = (value) => {
    console.log(value);
    console.log("onSelect", value);
    getNeighborhood(value);
  };
  const getNeighborhood = (city) => {
    request({
      url: `/common/neighbourhood/${city}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        // if (status) {
        setNeighborhood(data.list);
        // }
      },
    });
  };

  return (
    <>
      <Card title={sectionName}>
        <Form
          className="edit-page-wrap"
          form={form}
          onFinish={onCreate}
          autoComplete="off"
          layout="vertical"
          name="asset_form"
        >
          <Card className="mt-3 card-border-show">
            <div className="headding-add">{lang("addressDetails")}</div>

            <Row gutter={[24, 0]}>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("city")}
                  name="city"
                  rules={[{ required: true, message: lang("city") }]}
                >
                  <Select
                    className="select-proprty"
                    showSearch
                    placeholder={lang("selectClient")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={handleCityUpdate}
                    options={city?.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("neighborhood")}
                  name="neighborhood"
                >
                  <Select
                    className="select-Neighbourhood"
                    showSearch
                    placeholder={lang("neighborhood")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={neighborhood?.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("street")}
                  name="street"
                  rules={[{ required: true, message: lang("street") }]}
                >
                  <Input placeholder={lang("street")} />
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("streetNumber")}
                  name="street_number"
                >
                  <Input placeholder={lang("streetNumber")} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card className="mt-3 card-border-show">
            <div className="headding-add">{lang("Property Details")}</div>

            <Row gutter={[24, 0]}>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Property Type")}
                  name="property_type"
                >
                  <Select placeholder={lang("Select Property Type")}>
                    <Select.Option value="דירה">{lang("Apartment")}</Select.Option>
                    <Select.Option value="קוטג'">{lang("House / Cottage")}</Select.Option>
                    <Select.Option value="מִסְחָרִי מְקַרקְעִין">
                      {lang("Commercial/Real Estate")}
                    </Select.Option>
                    <Select.Option value="דירת גן">{lang("Garden Apartment")}</Select.Option>
                    <Select.Option value="דו משפחתי">{lang("Townhouse")}</Select.Option>
                    <Select.Option value="גג/ פנטהאוז">
                      {lang("Rooftop / Penthouse")}
                    </Select.Option>
                    <Select.Option value="פנטהאוז">{lang("Penthouse")}</Select.Option>
                    <Select.Option value="מיני פנטהאוז">
                      {lang("Mini Penthouse")}
                    </Select.Option>
                    <Select.Option value="דירת גג">{lang("Rooftop Apartment")}</Select.Option>
                    <Select.Option value="סטודיו/ לופט">
                      {lang("Studio / Loft")}
                    </Select.Option>
                    <Select.Option value="דופלקס">{lang("Duplex")}</Select.Option>
                    <Select.Option value="טריפלקס">{lang("Triplex")}</Select.Option>
                    <Select.Option value="יחידת דיור">
                      {lang("Apartment Unit")}
                    </Select.Option>
                    <Select.Option value="מגרשים">{lang("Plots")}</Select.Option>
                    <Select.Option value="משק חקלאי/ נחלה">
                      {lang("Farmhouse / Homestead")}
                    </Select.Option>
                    <Select.Option value="בניין">{lang("Building")}</Select.Option>
                    <Select.Option value="דירה מחולקת">
                      {lang("Divided Apartment")}
                    </Select.Option>
                    <Select.Option value="דירת מרתף">
                      {lang("Basement Apartment")}
                    </Select.Option>
                    <Select.Option value="מרתף/ פרטר">
                      {lang("Basement / Parter")}
                    </Select.Option>
                    <Select.Option value="בניין מגורים">
                      {lang("Residential Building")}
                    </Select.Option>
                    <Select.Option value="דירת נופש">
                      {lang("Vacation Apartment")}
                    </Select.Option>
                    <Select.Option value="חניה">{lang("Parking")}</Select.Option>
                    <Select.Option value="מחסן">{lang("Storage")}</Select.Option>
                    <Select.Option value="אחר">{lang("Other")}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item label={lang("Property Status")} name="property_status">
                  <Select placeholder={lang("Select Property Status")}>
                    <Option value="Active">{lang("Active")}</Option>
                    <Option value="Inactive">{lang("Inactive")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={24} sm={8}>
                <Form.Item
                  label="Property Title"
                  name="property_title"
                  // rules={[{ required: true, message: "Please enter the property title" }]}
                >
                  <Input placeholder="Enter Property Title" />
                </Form.Item>
              </Col>

              <Col span={24} sm={8}>
                <Form.Item
                  label="Property Description"
                  name="property_description"
                  // rules={[{ required: true, message: "Please enter the property description" }]}
                >
                  <Input.TextArea placeholder="Enter Property Description" />
                </Form.Item>
              </Col> */}

              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Advertiser Name")}
                  name="advertiser_name"
                >
                  <Input placeholder={lang("Enter Advertiser Name")} />
                </Form.Item>
              </Col>
              <Col span={24} lg={8} sm={12} className="flagMobileNumber">
                <Form.Item
                  label={lang("Phone Number")}
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      validator: (rule, value) => {
                        if (!value) {
                          return Promise.reject(lang("Please enter phone number"));
                        }
                        if (!/^\d{8,15}$/.test(value)) {
                          return Promise.reject(
                            lang("Phone number must be between 8 and 15 digits")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    name="mobile"
                    maxLength={15}
                    placeholder={lang("Enter mobile number")}
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Asset Status*")}
                  name="to_let"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the Asset Status"),
                    },
                  ]}
                >
                  <Select placeholder={lang("Select Asset Status")}>
                    <Select.Option value="sell">{lang("Sell")}</Select.Option>
                    <Select.Option value="buy">{lang("Buy")}</Select.Option>
                    <Select.Option value="rent">{lang("Rent")}</Select.Option>
                    <Select.Option value="lease">{lang("Lease")}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card className="mt-3 card-border-show">
            <div className="headding-add">{lang("Specifics")}</div>
            <Row gutter={[24, 0]}>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Rooms Number*")}
                  name="rooms_number"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the number of rooms"),
                    },
                  ]}
                >
                  <Input type="number" placeholder={lang("Enter Rooms Number")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Floor Number")}
                  name="floor_number"
                >
                  <Input type="number" placeholder={lang("Enter Floor Number")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Floors In Building")}
                  name="floors_in_building"
                >
                  <Input type="number" placeholder={lang("Enter Floors In Building")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Size in Meters*")}
                  name="size_in_meters"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the size in meters"),
                    },
                  ]}
                >
                  <Input type="number" placeholder={lang("Enter Size in Meters")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Total Price*")}
                  name="total_price"
                  rules={[
                    { required: true, message: lang("Please enter the total price") },
                  ]}
                >
                  <Input type="number" placeholder={lang("Enter Total Price")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Date of Entry")}
                  name="date_of_entry"
                >
                  <DatePicker format="YYYY-MM-DD" placeholder={lang("Select Date")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={8}>
                <Form.Item
                  label={lang("Taxes")}
                  name="taxes"
                >
                  <Input type="number" placeholder={lang("Enter Taxes")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={24}>
                <Form.Item label={lang("Amenities")} name="amenities">
                  <Checkbox.Group>
                    <Row gutter={[12, 12]}>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="elevator">{lang("Elevator")}</Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="air_conditioning">
                          {lang("Air Conditioning")}
                        </Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="parking">{lang("Parking")}</Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="balcony">{lang("Balcony")}</Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="bars">{lang("Bars")}</Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="dimension">{lang("Dimension")}</Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="warehouse">{lang("Warehouse")}</Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="handicap_accessibility">
                          {lang("Handicap Accessibility")}
                        </Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="furniture">{lang("Furniture")}</Checkbox>
                      </Col>
                      <Col span={24} sm={12} md={8}>
                        <Checkbox value="security">{lang("Security")}</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card className="mt-3 card-border-show">
            <div className="headding-add">Images</div>
            <Row gutter={[24, 0]}>
              <Col span={24} sm={8}>
                <div className="text-center">
                  <Form.Item
                    className="upload_wrap"
                    // rules={[
                    //   {
                    //     validator: (_, value) => {
                    //       if (image) {
                    //         return Promise.resolve();
                    //       }
                    //       return Promise.reject(
                    //         new Error(lang("main image is required"))
                    //       );
                    //     },
                    //   },
                    // ]}
                    name="image"
                    label="Main Image"
                  >
                    <UploadImage value={image} setImage={setImage} />
                  </Form.Item>
                </div>
              </Col>

              <div className="col-md-6">
                <Card className="" style={{ height: "100%" }}>
                  <div className="row g-3">
                    <Col span={24}>
                      <Form.Item
                        className="upload_wrap img-uploaded"
                        // rules={[
                        //   {
                        //     validator: (_, value) => {
                        //       if (value !== undefined && value?.length > 0) {
                        //         return Promise.resolve();
                        //       }
                        //       // return Promise.reject(
                        //       //   new Error("Image is required"),
                        //       // );
                        //     },
                        //   },
                        // ]}
                        label={lang("Upload More Images")}
                        name="multiImage"
                        getValueFromEvent={(event) => {
                          console.log(event);
                          return event.map((item) => item.url);
                        }}
                      >
                        <MultipleImageUpload
                          // disabled={!isEdit}
                          value={multiImage}
                          data={multiImage}
                          fileType={FileType}
                          imageType={"asset"}
                          btnName={"Other Image"}
                          isDimension={true}
                          onChange={(data) => handleImage(data)}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </Card>
              </div>

              {/* <Col span={24} sm={8}>
                            <Form.Item
                                label="Make Public"
                                name="make_public"
                                valuePropName="checked"
                            >
                                <Checkbox>Make Public</Checkbox>
                            </Form.Item>
                        </Col> */}

              {/* <Col span={24} sm={24}>
                {location.lat && location.lng && (
                  <Asset
                    location={location}
                    setLocation={setLocation}
                    style={{ height: "500px" }}
                  />
                )}
               
              </Col> */}
            </Row>
          </Card>
          <Form.Item className="btn-row float-right mt-2">
            <Button
              type="primary"
              onClick={() => navigate(-1)}
              className="back-btns"
            >
              {lang("Back")}
            </Button>
            <Button
              type="primary"
              loading={loading}
              htmlType="submit"
              className="submit-btns"
            >
              {lang("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Card >
    </>
  );
}

export default CreateAsset;
