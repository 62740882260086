export const s3Config = (type) => ({
  region: "us-east-1",
  credentials: {
    accessKeyId: "IJTXH751MBXUWMTWM0SF",
    secretAccessKey: "lfTPRJ2PqAxsp3poTW9YlHktgaL1cFkLup8LidW9",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
  forcePathStyle: true,
});
// export const s3Config = (type) => ({
//   dirName: "brokerland/" + type,
//   bucketName: "sugamaya",
//   region: "us-east-1",
//   accessKeyId: "AKIA6GBMIAPJULCPWKU5",
//   secretAccessKey: "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",
// });
// export const s3Config = (type) => ({
//   dirName: "tawasi/" + type,
//   bucketName: "inventcolabs",
//   region: "ap-south-1",
//   accessKeyId: "AKIAXTJNUUWFIPVPT4U7",
//   secretAccessKey: "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",
// });
