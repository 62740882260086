import {
  Col,
  Row,
  Select,
  Tabs,
  Typography,
  Image,
  Avatar,
  Checkbox,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  SettingFilled,
  UserOutlined,
} from "@ant-design/icons";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import WhatsAppIcon from "../../assets/images/whatsapp-icon.svg";
import LinkedinIcon from "../../assets/images/linkdin-icon.svg";
import SkypeIcon from "../../assets/images/skype-icon.svg";
import Timeicon from "../../assets/images/time-icon.svg";
import GmailIcon from "../../assets/images/gmail-icon.svg";
import CallingIcon from "../../assets/images/calling-icon.svg";
import LocationIcon from "../../assets/images/location-icon-01.svg";
import pdfImg from "../../assets/images/pdf-photo.png";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import moment from "moment";





const { TabPane } = Tabs;
const { Title, Text } = Typography;

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}
const years = [2024, 2023];
const { Option } = Select;

function Index({ }) {
  const { setPageHeading, country } = useContext(AppStateContext);
  const { request } = useRequest();
  const [year, setYear] = useState(years[0]);
  const [loading, setLoading] = useState(years[0]);
  const { userProfile, isAdmin } = useAuthContext();
  const [dashboard, setDashboard] = useState([]);
  const { id } = useParams();


  useEffect(() => {
    setPageHeading(lang("Welcome Broker"));
    if (!userProfile) return;

    if (userProfile.type == "SubAdmin") {
      setPageHeading(`Welcome ${userProfile.name}`);
      if (!userProfile?.permission.includes("dashboard-management")) return;
    }
    setLoading(true);
    request({
      url: '/broker/transaction/list',
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        console.log(data.data)
        setDashboard(data.data.list.docs);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);
  return (
    <>
      <Row gutter={[24, 24]}>

        <Col span={24} md={16} lg={16} xl={18}>


          <div className="booking-notification">

            {dashboard.length > 0 ? dashboard.map((data) => (
              <div className="today-notifiaction">

                <div className="about-notifiaction">
                  <div className="booking-id">
                    <h3>Transaction <span>#{data.transactionInternalNumber}</span></h3>
                    <p>{moment(data.created_at).format("YYYY-DD-MM HH:mm")}</p>
                  </div>
                  <div className="booking-id">
                    <h3 className="text-danger for-right-aligne ">₪{data.amount}</h3>

                  </div>

                </div>

              </div>
            )) : null}




          </div>






        </Col>
      </Row>
    </>
  );
}



export default Index;