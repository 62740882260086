import { useState } from "react";
import { Form, Modal, Row, Col, Radio } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import lang from "../helper/langHelper";

const DeleteModal = ({ show, hide, onOk, title, subtitle, reasons }) => {
  const [value, setValue] = useState(reasons?.length > 0 ? reasons[1] : "");

  return (
    <Modal
      width={700}
      open={show}
      onOk={() => {
        if (onOk) onOk(value);
        hide();
      }}
      okText={lang("Ok")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      centered
      className="tab_modal deleteWarningModal"
    >
      <Form layout="vertical" className="p-2 for-forgottt">
        <div
          style={{
            margin: "auto",
            width: 100,
            fontSize: 80,
            color: "red",
            textAlign: "center",
          }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h4 className="modal_title_cls mb-2">{title}</h4>
        <h4 className="modal_sub_title_cls mb-2">{subtitle}</h4>
      </Form>
    </Modal>
  );
};

export default DeleteModal;
