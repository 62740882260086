import {
  Card,
  Col,
  DatePicker,
  Form,
  Select,
  Input,
  Modal,
  Row,
  Button,
  Checkbox,
  navigate,
  Slider,
  Switch,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";

import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import UploadImage from "./_UploadImage";
import { useAppContext } from "../../../context/AppContext";
import { SelectInput } from "../../../components/InputField";
import lang from "../../../helper/langHelper";
import apiPath from "../../../constants/apiPath";

const filterpt = [
  {
    text: "Apartment",
    value: "דירה",
  },
  {
    text: "Commercial Real Estate",
    value: "מִסְחָרִי מְקַרקְעִין",
  },
  {
    text: "House / Cottage",
    value: "קוטג'",
  },
  {
    text: "Garden Apartment",
    value: "דירת גן",
  },
  {
    text: "Townhouse",
    value: "דו משפחתי",
  },
  {
    text: "Rooftop / Penthouse",
    value: "גג/ פנטהאוז",
  },
  {
    text: "Penthouse",
    value: "פנטהאוז",
  },
  {
    text: "Mini Penthouse",
    value: "מיני פנטהאוז",
  },
  {
    text: "Rooftop Apartment",
    value: "דירת גג",
  },
  {
    text: "Studio / Loft",
    value: "סטודיו/ לופט",
  },
  {
    text: "Duplex",
    value: "דופלקס",
  },
  // 11 extra
  {
    text: "Triplex",
    value: "טריפלקס",
  },
  {
    text: "Apartment Unit",
    value: "יחידת דיור",
  },
  {
    text: "Plots",
    value: "מגרשים",
  },
  {
    text: "Farmhouse / Homestead",
    value: "משק חקלאי/ נחלה",
  },
  {
    text: "Building",
    value: "בניין",
  },
  {
    text: "Divided Apartment",
    value: "דירה מחולקת",
  },
  {
    text: "Basement Apartment",
    value: "דירת מרתף",
  },
  {
    text: "Basement / Parter",
    value: "מרתף/ פרטר",
  },
  {
    text: "Residential Building",
    value: "בניין מגורים",
  },
  {
    text: "Vacation Apartment",
    value: "דירת נופש",
  },
  {
    text: "Parking",
    value: "חניה",
  },
  {
    text: "Storage",
    value: "מחסן",
  },
  {
    text: "Other",
    value: "אחר",
  },
];

const AddForm = ({ section, api, show, hide, data, refresh }) => {
  const params = useParams();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formtype, setFormType] = useState(true);
  const [budget, setBudget] = useState([0, 1000000]);
  const [size, setSize] = useState([0, 1000]);
  const [budgetReg, setBudgetReg] = useState([0, 1000000]);
  const [sizeReg, setSizeReg] = useState([0, 500]);
  function parseFormattedNumber(formattedNumber) {
    let normalizedNumber = formattedNumber
      ? formattedNumber.replace(/[^0-9.-]+/g, "")
      : 0;
    return parseFloat(normalizedNumber);
  }

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
  const [image, setImage] = useState();
  const [mobileNumber, setMobileNumber] = useState({
    mobileNumber: "",
    countryCode: "",
  });

  const [formData, setFormData] = useState({
    image: "",
    clientType: "",
    optionType: "",
    name: "",
    last_name: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    mobile: "",
    SourceName: "",
    status: "",
    city: "",
    // country_id: "",
    // state: "",
    neighborhood: "",
    propertylivType: "",
    budget: [0, 1000000],
    size: [0, 10000],
    numberOfRooms: "",
    propertyType: "",
    assetType: "",
    notes: "",
    type: "",
    amenities: [],
  });

  // const [isUsernameDisabled, setIsUsernameDisabled] = useState(false);
  // const [usernames, setUsernames] = useState([]);
  // const getUsername = () => {
  //   request({
  //     url: apiPath.listClientUsername,
  //     method: "GET",
  //     onSuccess: ({ data, status }) => {
  //       console.log(data, "Username")
  //       if (status) {
  //         setUsernames(data);
  //       }
  //     },
  //   });
  // };
  // const handleUsernameChange = (e) => {
  //   if (e.target.value) {
  //     setIsUsernameDisabled(true);
  //   }
  // };
  // Function to generate a random username
  const generateRandomUsername = (firstName, lastName) => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789.-";
    const randomNum = Math.floor(Math.random() * 1000);
    const randomChar = characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
    return `${firstName}${lastName}${randomNum}${randomChar}`;
  };
  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues.name || changedValues.last_name) {
      const username = generateRandomUsername(
        allValues.name,
        allValues.last_name
      );
      form.setFieldsValue({ username });
    }
  };

  useEffect(() => {
    // getCountry();
    getCity();
    // getUsername();
  }, []);

  const [country, setCountry] = useState();
  const [city, setCity] = useState([]);
  const [state, setState] = useState();
  const [neighborhood, setNeighborhood] = useState([]);

  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [optionTypeStat, setOptionTypeStat] = useState("buy");

  const { country: mCountry, cities } = useAppContext();

  const handleChange = (value, data) => {
    var countryCode = data.dialCode;
    setMobileNumber({
      countryCode: countryCode,
      mobileNumber: value.slice(data.dialCode.length),
    });
  };

  const getCity = () => {
    request({
      url: `/common/city`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data.list, "city");
        setCity(data.list);
      },
    });
  };

  const getCountry = () => {
    request({
      url: `/common/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (status) {
          setCountry(data.data);
        }
      },
    });
  };
  const getCities = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "city");
        if (status) {
          setCity(data);
        }
      },
    });
  };

  const handleChangeClient = (value) => {
    console.log("Selected Client Type: ", value);
    if (value === "client") {
      setFormType(true);
    } else {
      setFormType(false);
    }
    form.setFieldsValue({ clientType: value });
  };

  // useEffect(() => {
  //   if (!data) return;

  //   form.setFieldsValue({
  //     ...data,
  //     dob: moment(data.dob),
  //     mobile: data?.countryCode + data?.mobileNumber,
  //   });

  //   setImage(data.image);
  //   setMobileNumber({
  //     mobile: data?.mobileNumber,
  //     countryCode: data?.countryCode,
  //   });
  // }, [data]);

  // useEffect(() => {
  //   if (!mCountry.country_id) return;

  //   form.setFieldsValue({
  //     country_id: mCountry.country_id,
  //   });

  //   getCity(mCountry.country_id);
  // }, [mCountry.country_id]);

  useEffect(() => {
    if (!params.id) return;
    fetchClient(params.id);
  }, [params.id]);

  const fetchClient = (id) => {
    request({
      url: `${apiPath.viewClientDetail + "/" + id}`,
      method: "GET",
      onSuccess: (response) => {
        handleChangeClient(response.data.clientType);
        const data = response.data;
        setBudgetReg(data.budget);
        setSizeReg(data.size);
        console.log(data.budget);

        setMobileNumber({
          mobileNumber: data.mobileNumber,
          countryCode: data.countryCode,
        });

        // Set the fetched data into formData state
        setFormData({
          image: data.image,
          clientType: data.clientType,
          optionType: data.optionType,
          name: data.name,
          last_name: data.last_name,
          email: data.email,
          SourceName: data.SourceName,
          status: data.is_type,
          // countryCode: data.countryCode,
          // mobileNumber: data.mobileNumber,
          mobile: data.countryCode + "" + data.mobileNumber,
          city: data.city,
          neighborhood: data.neighborhood,
          propertylivType: data.propertylivType,
          budget: data.budget ? data.budget : [0, 100000],
          size: data.size ? data.size : [0, 100000],
          numberOfRooms: data.numberOfRooms,
          propertyType: data.propertyType,
          assetType: data.assetType,
          notes: data.notes,
          amenities: data.amenities,
        });

        // Set the fetched data into form fields
        form.setFieldsValue({
          image: data.image,
          clientType: data.clientType,
          optionType: data.optionType,
          name: data.name,
          last_name: data.last_name,
          email: data.email,
          SourceName: data.SourceName,
          // countryCode: data.countryCode,
          // mobileNumber: data.mobileNumber,
          mobile: data.countryCode + "" + data.mobileNumber,
          status: data.is_type,
          city: data.city,
          neighborhood: data.neighborhood,
          propertylivType: data.propertylivType,
          budget: data.budget ? data.budget : [0, 100000],
          size: data.size ? data.size : [0, 100000],
          numberOfRooms: data.numberOfRooms,
          propertyType: data.propertyType,
          assetType: data.assetType,
          notes: data.notes,
          amenities: data.amenities,
        });

        setImage(data.image ? data.image : []);
      },
    });
  };

  const onCreate = (values) => {
    const {
      // ar_name,
      // countryCode,
      // userName,
      // gender
      clientType,
      optionType,
      name,
      last_name,
      email,
      mobile,
      SourceName,
      status,
      city,
      // country,
      // state,
      neighborhood,
      propertylivType,
      budget,
      size,
      numberOfRooms,
      propertyType,
      assetType,
      notes,
      amenities,

    } = values;
    const payload = {};
    setLoading(true);
    payload.image = image;
    payload.clientType = clientType;
    payload.optionType = optionType;
    payload.name = name;
    payload.last_name = last_name;
    payload.email = email;
    payload.mobileNumber = mobile;
    payload.countryCode = mobileNumber?.countryCode;
    payload.SourceName = SourceName;
    payload.status = status ? status : "new";
    payload.city = city;
    // payload.country_id = country;
    // payload.state = state;
    payload.neighborhood = neighborhood;
    payload.propertylivType = propertylivType;
    payload.budget = budgetReg;
    payload.size = sizeReg;
    payload.numberOfRooms = numberOfRooms;
    payload.propertyType = propertyType;
    payload.assetType = assetType;
    payload.notes = notes;
    payload.amenities = amenities;
    payload.type = "client";

    // payload.gender = gender;
    // // payload.area = area;
    // payload.ar_name = ar_name;
    // payload.neighborhood = neighborhood;
    // payload.userName = userName;
    // payload.country_id = mCountry.country_id;
    // payload.city = city;

    request({
      url: `${params.id ? apiPath.editClient + "/" + params.id : apiPath.addEditClient
        }`,
      method: params.id ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          navigate(-1);
          ShowToast(data.message, Severty.SUCCESS);
          form.resetFields();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleBudgetChange = (value) => {
    console.log(value);
    setBudget(value);
    setBudgetReg(value);
  };
  const handleBudgetChangeAr = (value, key) => {
    const tempVal = parseFormattedNumber(value);
    const numericValue = tempVal;

    console.log(numericValue);

    const newBudget = [...budget];
    const newBudgetReg = [...budgetReg];

    if (key === 0 && numericValue >= budgetReg[1]) {
      console.warn(
        "Value for key 0 cannot be greater than or equal to value for key 1"
      );
      return;
    }

    if (key === 1 && numericValue <= budgetReg[0]) {
      console.warn(
        "Value for key 1 cannot be less than or equal to value for key 0"
      );
      return;
    }

    newBudget[key] = isNaN(numericValue) ? 0 : numericValue;
    newBudgetReg[key] = isNaN(numericValue) ? 0 : numericValue;
    setBudget(newBudget);
    setBudgetReg(newBudgetReg);
  };

  const handleSizeChangeAr = (value, key) => {
    const tempVal = parseFormattedNumber(value);
    const numericValue = tempVal;

    console.log(numericValue);

    const newSize = [...size];
    const newSizeReg = [...sizeReg];

    if (key === 0 && numericValue >= sizeReg[1]) {
      console.warn(
        "Value for key 0 cannot be greater than or equal to value for key 1"
      );
      return;
    }

    if (key === 1 && numericValue <= sizeReg[0]) {
      console.warn(
        "Value for key 1 cannot be less than or equal to value for key 0"
      );
      return;
    }

    newSize[key] = isNaN(numericValue) ? 0 : numericValue;
    newSizeReg[key] = isNaN(numericValue) ? 0 : numericValue;
    setSize(newSize);
    setSizeReg(newSizeReg);
  };

  const handleSizeChange = (value) => {
    console.log(value);
    setSize(value);
    setSizeReg(value);
  };

  const handleCityUpdate = (value) => {
    console.log(value);
    console.log("onSelect", value);
    getNeighborhood(value);
  };
  const getNeighborhood = (city) => {
    request({
      url: `/common/neighbourhood/${city}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        // if (status) {
        setNeighborhood(data.list);
        // }
      },
    });
  };

  const handleOption = (value) => {
    if (value === "renting" || value === "renter") {
      setOptionTypeStat("rent");
      setBudget([0, 15000]);
      setBudgetReg([0, 15000]);
    } else {
      setOptionTypeStat("buy");
      setBudget([0, 1000000]);
      setBudgetReg([0, 1000000]);
    }
  };

  return (
    <Card>
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        onValuesChange={handleValuesChange}
        initialValues={
          {
            // dob: moment("1990-01-01", "YYYY-MM-DD"),
          }
        }
      >
        <div className="add_user_title">
          <h4 className="modal_title_cls">{`${params.id
            ? formtype
              ? lang("Edit Client")
              : lang("Edit Lead") /* sectionName */
            : formtype
              ? lang("Add Client")
              : lang("Add Lead") /* sectionName */
            }`}</h4>
        </div>

        <Card className="mt-3 card-border-show">
          <div className="headding-add">{lang("Personal Details")}</div>
          <Row gutter={24}>
            <Col span={24}>
              <div className="text-center">
                <Form.Item
                  className="upload_wrap"
                  name="image"
                >
                  <UploadImage value={image} setImage={setImage} />
                </Form.Item>
              </div>
            </Col>

            <Col span={24} lg={8} sm={12}>
              <Form.Item
                label={lang(`Options`)}
                name="optionType"
                rules={[
                  {
                    required: true,
                    message: lang("Please enter the Option type"),
                  },
                  {
                    max: 100,
                    message: lang("Options type should not contain more than 100 characters!"),
                  },
                  {
                    min: 2,
                    message: lang("Options type should contain at least 2 characters!"),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder={lang(`Select Options Type`)}
                  onChange={handleOption}
                >
                  <Select.Option value="buyer">{lang("Buyer")}</Select.Option>
                  <Select.Option value="seller">{lang("Seller")}</Select.Option>
                  <Select.Option value="renting">{lang("Renting")}</Select.Option>
                  <Select.Option value="renter">{lang("Renter")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} lg={8} sm={12}>
              <Form.Item
                label={lang(`First Name`)}
                name="name"
                rules={[
                  {
                    required: true,
                    message: lang("Please enter the first name"),
                  },
                  {
                    max: 100,
                    message: lang("Name should not contain more than 100 characters!"),
                  },
                  {
                    min: 2,
                    message: lang("Name should contain at least 2 characters!"),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <Input
                  autoComplete="off"
                  placeholder={lang(`Enter First Name`)}
                />
              </Form.Item>
            </Col>

            <Col span={24} lg={8} sm={12}>
              <Form.Item
                label={lang(`Last Name`)}
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: lang("Please enter the last name"),
                  },
                  {
                    max: 100,
                    message: lang("Last Name should not contain more than 100 characters!"),
                  },
                  {
                    min: 2,
                    message: lang("Last Name should contain at least 2 characters!"),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <Input
                  autoComplete="off"
                  placeholder={lang(`Enter Last Name`)}
                />
              </Form.Item>
            </Col>

            <Col span={24} lg={8} sm={12}>
              <Form.Item
                label={lang("Email Address")}
                name="email"
              >
                <Input
                  autoComplete="off"
                  placeholder={lang("Enter Email Address")}
                />
              </Form.Item>
            </Col>

            <Col span={24} lg={8} sm={12} className="flagMobileNumber">
              <Form.Item
                label={lang("Phone Number")}
                name="mobile"
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject(lang("Please enter phone number"));
                      }
                      if (!/^\d{8,15}$/.test(value)) {
                        return Promise.reject(lang("Phone number must be between 8 and 15 digits"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  name="mobile"
                  maxLength={15}
                  placeholder={lang("Enter mobile number")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card className="mt-3 card-border-show">
          <div className="heading-add">{lang("Client Specifics")}</div>
          <Row gutter={[32, 10]}>
            <Col span={24} lg={8} sm={12}>
              <Form.Item
                label={lang("Source Name")}
                name="SourceName"
                rules={[
                  { required: true, message: lang("Please enter the Source Name") },
                  {
                    max: 100,
                    message: lang("Source Name should not contain more than 100 characters!"),
                  },
                  {
                    min: 2,
                    message: lang("Source Name should contain at least 2 characters!"),
                  },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder={lang("Select Source Name")}
                >
                  <Select.Option value="whatsapp">{lang("Whatsapp")}</Select.Option>
                  <Select.Option value="social media">{lang("Social Media")}</Select.Option>
                  <Select.Option value="by phone">{lang("By Phone")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} lg={8} sm={12}>
              <Form.Item className="mb-0" label={lang("Status")} name="status">
                <Select placeholder={lang("Select Your Status")}>
                  <Select.Option value="new">{lang("New")}</Select.Option>
                  <Select.Option value="first_contact">{lang("First Contact")}</Select.Option>
                  <Select.Option value="in_progress">{lang("In Progress")}</Select.Option>
                  <Select.Option value="active_client">{lang("Active Client")}</Select.Option>
                  <Select.Option value="not_relevant">{lang("Not Relevant")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} lg={8} sm={12}>
              <Form.Item
                label={lang("City")}
                name="city"
                rules={[{ required: true, message: lang("Please enter the city") }]}
              >
                <Select
                  className="select-proprty"
                  showSearch
                  placeholder={lang("Select property City")}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={handleCityUpdate}
                  options={city?.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                />
              </Form.Item>
            </Col>
            {/* Uncomment this section if you want to include Country and State fields
    <Col span={24} lg={8} sm={12}>
      <Form.Item
        label={lang("Country")}
        name="country"
        rules={[{ required: true, message: lang("Please enter the Country") }]}
      >
        <Input placeholder={lang("Enter Country")} />
      </Form.Item>
    </Col>
    <Col span={24} lg={8} sm={12}>
      <Form.Item
        label={lang("State")}
        name="state"
        rules={[{ required: true, message: lang("Please enter the State") }]}
      >
        <Input placeholder={lang("Enter State")} />
      </Form.Item>
    </Col>
    */}
          </Row>
        </Card>

        <Card className="mt-3 card-border-show">
          <div className="headding-add">{lang("Listing Requirements")}</div>
          <Row gutter={[32, 10]}>
            <Col span={24} lg={8} sm={12}>
              <Form.Item
                label={lang(`Neighborhood`)}
                name="neighborhood"
                // rules={[
                //   {
                //     required: true,
                //     message: lang("Please enter the neighborhood"),
                //   },
                //   {
                //     max: 100,
                //     message: lang(
                //       "Neighborhood should not contain more than 100 characters!"
                //     ),
                //   },
                //   {
                //     min: 2,
                //     message: lang(
                //       "Neighborhood should contain at least 2 characters!"
                //     ),
                //   },
                // ]}
                normalize={(value) => value.trimStart()}
              >
                {/* <Input
                  autoComplete="off"
                  placeholder={lang(`Enter Neighborhood`)}
                /> */}

                <Select
                  className="select-Neighbourhood"
                  showSearch
                  placeholder={lang("Select Neighborhood")}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={neighborhood?.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                ></Select>
              </Form.Item>
            </Col>

            {formtype && (
              <>
                <Col span={24} lg={8} sm={12}>
                  <Form.Item
                    label={lang(`Property Living Type`)}
                    name="propertylivType"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: lang("Please enter the Property Living Type"),
                    //   },
                    //   {
                    //     max: 100,
                    //     message: lang(
                    //       "Property Living type should not contain more than 100 characters!"
                    //     ),
                    //   },
                    //   {
                    //     min: 2,
                    //     message: lang(
                    //       "Property Living type should contain at least 2 characters!"
                    //     ),
                    //   },
                    // ]}
                    normalize={(value) => value.trimStart()}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder={lang(`Select Property Living Type`)}
                    >
                      <Select.Option value="residential">
                        {lang("Residential")}
                      </Select.Option>
                      <Select.Option value="commercial">
                        {lang("Commercial")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} lg={8} sm={12}>
                  <Form.Item
                    label={lang(`Number of Rooms`)}
                    name="numberOfRooms"
                    rules={
                      [
                        // {
                        //   required: true,
                        //   message: lang("Please enter the number of rooms"),
                        // },
                        //   {
                        //     max: 100,
                        //     message: lang(
                        //       "Number of rooms should not contain more than 100 characters!"
                        //     ),
                        //   },
                        //   {
                        //     min: 1,
                        //     message: lang(
                        //       "Number of rooms should contain at least 1 character!"
                        //     ),
                        //   },
                      ]
                    }
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder={lang(`Select Number of Rooms`)}
                    >
                      <Select.Option value={1}>{lang("1")}</Select.Option>
                      <Select.Option value={2}>{lang("2")}</Select.Option>
                      <Select.Option value={3}>{lang("3")}</Select.Option>
                      <Select.Option value={4}>{lang("4")}</Select.Option>
                      <Select.Option value={5}>{lang("5")}</Select.Option>
                      <Select.Option value={6}>{lang("6")}</Select.Option>
                      <Select.Option value={7}>{lang("7")}</Select.Option>
                      <Select.Option value={8}>{lang("8")}</Select.Option>
                      <Select.Option value={9}>{lang("9")}</Select.Option>
                      <Select.Option value={10}>{lang("10")}</Select.Option>
                      <Select.Option value={11}>{lang("11")}</Select.Option>
                      <Select.Option value={12}>{lang("12")}</Select.Option>
                      <Select.Option value={13}>{lang("13")}</Select.Option>
                      <Select.Option value={14}>{lang("14")}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item
                    label={lang(`BudgetReg (₪)`)}
                    name="budget"
                    rules={
                      [
                        // {
                        //   required: true,
                        //   message: lang("Please enter the budget"),
                        // },
                        //   // {
                        //   //   max: 100,
                        //   //   message: lang(
                        //   //     "Budget should not contain more than 100 characters!"
                        //   //   ),
                        //   // },
                        //   // {
                        //   //   min: 2,
                        //   //   message: lang(
                        //   //     "Budget should contain at least 2 characters!"
                        //   //   ),
                        //   // },
                        // {
                        //   pattern: /^\d+$/,
                        //   message: lang("Budget should be a number!"),
                        // },
                      ]
                    }
                  // normalize={(value) => value.trimStart()}
                  >
                    <Slider
                      className="slaider-row"
                      min={1}
                      max={optionTypeStat === "buy" ? 10000000 : 15000}
                      tooltip={{ open: false }}
                      range={{
                        draggableTrack: true,
                      }}
                      value={budgetReg ? budgetReg : [0, 100000]}
                      onChange={handleBudgetChange} // use onChange instead of onchange
                    />
                    <div className="count-box">
                      <div className="count-detail">
                        <div className="count-box-menus">
                          <span>₪</span>

                          <Input
                            value={
                              budgetReg
                                ? new Intl.NumberFormat().format(budgetReg[0])
                                : "0"
                            }
                            onChange={(e) =>
                              handleBudgetChangeAr(e.target.value, 0)
                            }
                          />

                          {/* {budgetReg
                            ? new Intl.NumberFormat().format(budgetReg[0])
                            : "0"}
                          {"  "} */}
                        </div>
                        <div className="count-box-menus">
                          <span>₪</span>
                          <Input
                            value={
                              budgetReg
                                ? new Intl.NumberFormat().format(budgetReg[1])
                                : "1,000,000"
                            }
                            onChange={(e) =>
                              handleBudgetChangeAr(e.target.value, 1)
                            }
                          />
                          {/* {budgetReg
                            ? new Intl.NumberFormat().format(budgetReg[1])
                            : "100,000"}
                          {"  "} */}
                        </div>
                        {/* <span>{budgetReg ? budgetReg[0] : 0}</span>
                        <span>{budgetReg ? budgetReg[1] : 100000}</span> */}
                      </div>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item
                    label={lang(`size (Meter)`)}
                    name="size"
                    rules={
                      [
                        // {
                        //   required: true,
                        //   message: lang("Please enter the size"),
                        // },
                        //   // {
                        //   //   max: 100,
                        //   //   message: lang(
                        //   //     "size should not contain more than 100 characters!"
                        //   //   ),
                        //   // },
                        //   // {
                        //   //   min: 2,
                        //   //   message: lang(
                        //   //     "size should contain at least 2 characters!"
                        //   //   ),
                        //   // },
                        //   // {
                        //   //   pattern: /^\d+$/,
                        //   //   message: lang("size should be a number!"),
                        //   // },
                      ]
                    }
                  // normalize={(value) => value.trimStart()}
                  >
                    <Slider
                      className="slaider-row"
                      min={1}
                      max={1000}
                      range={{
                        draggableTrack: true,
                      }}
                      tooltip={{ open: false }}
                      value={sizeReg ? sizeReg : [0, 500]}
                      onChange={handleSizeChange} // use onChange instead of onchange
                    />
                    <div className="count-box">
                      <div className="count-detail">
                        <div className="count-box-menus">
                          <span>{lang("Meter")}</span>

                          <Input
                            value={
                              sizeReg
                                ? new Intl.NumberFormat().format(sizeReg[0])
                                : "0"
                            }
                            onChange={(e) =>
                              handleSizeChangeAr(e.target.value, 0)
                            }
                          />

                          {/* {sizeReg
                            ? new Intl.NumberFormat().format(sizeReg[0])
                            : "0"}
                          {"  "}   */}
                        </div>
                        <div className="count-box-menus">
                          <span>{lang("Meter")}</span>
                          <Input
                            value={
                              sizeReg
                                ? new Intl.NumberFormat().format(sizeReg[1])
                                : "15,000"
                            }
                            onChange={(e) =>
                              handleSizeChangeAr(e.target.value, 1)
                            }
                          />
                          {/* {sizeReg
                            ? new Intl.NumberFormat().format(sizeReg[1])
                            : "100,000"}
                          {"  "}  */}
                        </div>
                        {/* <span>{sizeReg ? sizeReg[0] : 0} sq ft.</span>
                        <span>{sizeReg ? sizeReg[1] : 100000} sq ft.</span> */}
                      </div>
                    </div>
                  </Form.Item>
                </Col>

                <Col span={24} lg={8} sm={12}>
                  <Form.Item
                    label={lang(`Property Type`)}
                    name="propertyType"
                    rules={
                      [
                        // {
                        //   required: true,
                        //   message: lang("Please enter the property type"),
                        // },
                        // {
                        //   max: 100,
                        //   message: lang(
                        //     "Property type should not contain more than 100 characters!",
                        //   ),
                        // },
                        // {
                        //   min: 2,
                        //   message: lang("Property type should contain at least 2 characters!"),
                        // },
                      ]
                    }
                  // normalize={(value) => value.trimStart()}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder={lang(`Enter Property Type`)}
                      mode="multiple"
                    >
                      {filterpt.map((option) => (
                        <Select.Option value={option.value}>
                          {option.text}
                          {"("}
                          {option.value}
                          {")"}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* <Col span={24} sm={12}>
                  <Form.Item
                    label={lang(`Asset Type`)}
                    name="assetType"
                    rules={[
                      {
                        required: true,
                        message: lang("Please select the asset type"),
                      },
                      {
                        max: 100,
                        message: lang(
                          "Asset type should not contain more than 100 characters!"
                        ),
                      },
                      {
                        min: 2,
                        message: lang(
                          "Asset type should contain at least 2 characters!"
                        ),
                      },
                    ]}
                    normalize={(value) => value.trimStart()}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder={lang(`Select Asset Type`)}
                    >
                      <Select.Option value="real estate">
                        {lang("Real Estate")}
                      </Select.Option>
                      <Select.Option value="personal property">
                        {lang("Personal Property")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col> */}

                {/* <Col span={24} lg={8} sm={12}>
            <Form.Item
              label={lang("Username")}
              name="userName"
              rules={[
                { required: true, message: lang("Please enter the username") },
                {
                  max: 100,
                  message: lang("Username should not contain more than 100 characters!"),
                },
                {
                  min: 2,
                  message: lang("Username should contain at least 2 characters!"),
                },
                {
                  pattern: /^[a-zA-Z0-9._-]+$/,
                  message: lang("Username can only include (a-z),(0-9), (.), (_), and (-)"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Username")}
                // disabled={isUsernameDisabled}
                onChange={handleUsernameChange}
              />
            </Form.Item>
          </Col> */}

                <Col span={24} md={12}>
                  <Form.Item
                    label={lang("Notes")}
                    name="notes"
                    rules={[
                      {
                        max: 1000,
                        message: lang(
                          "Notes should not contain more than 1000 characters!"
                        ),
                      },
                      {
                        min: 10,
                        message: lang(
                          "Notes should contain at least 10 characters!"
                        ),
                      },
                    ]}
                    normalize={(value) => value.trimStart()}
                  >
                    <Input.TextArea
                      autoComplete="off"
                      placeholder={lang("Enter Notes")}
                    />
                  </Form.Item>
                </Col>


                <Col span={24} sm={24}>
                  <Form.Item label={lang("Amenities")} name="amenities">
                    <Checkbox.Group>
                      <Row gutter={[12, 12]}>
                        <Col span={24} sm={12} md={8}>
                          <Checkbox value="bar">{lang("Bars")}</Checkbox>
                        </Col>
                        <Col span={24} sm={12} md={8}>
                          <Checkbox value="elevator">{lang("Elevator")}</Checkbox>
                        </Col>
                        <Col span={24} sm={12} md={8}>
                          <Checkbox value="airConditioning">
                            {lang("Air Conditioning")}
                          </Checkbox>
                        </Col>
                        <Col span={24} sm={12} md={8}>
                          <Checkbox value="parking">{lang("Parking")}</Checkbox>
                        </Col>
                        <Col span={24} sm={12} md={8}>
                          <Checkbox value="handicapAccessibility">
                            {lang("Handicap Accessibility")}
                          </Checkbox>
                        </Col>
                        <Col span={24} sm={12} md={8}>
                          <Checkbox value="warehouse">{lang("Warehouse")}</Checkbox>
                        </Col>
                        <Col span={24} sm={12} md={8}>
                          <Checkbox value="dimension">{lang("Dimension")}</Checkbox>
                        </Col>
                        <Col span={24} sm={12} md={8}>
                          <Checkbox value="balcony">{lang("Balcony")}</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Form.Item className="btn-row float-right">
            <Button
              type="primary"
              onClick={() => navigate(-1)}
              className="back-btns"
            >
              {lang('Back')}
            </Button>
            <Button
              type="primary"
              loading={loading}
              htmlType="submit"
              className="submit-btns"
            >
              {lang("Submit")}
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </Card>
  );
};

export default AddForm;
