import {
  EnvironmentOutlined,
  StarOutlined,
  PrinterOutlined,
  PlusOutlined,
  HeartOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Image,
  Card,
  Tag,
  Dropdown,
  Switch,
  Input,
  Menu,
  Form,
  Space,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import image1 from "../../assets/images/image1.png";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import apiPath from "../../constants/apiPath";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DrawIcon from "@mui/icons-material/Draw";
import FileShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import EyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import { Link } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../constants/reasons";
import { Months } from "../../constants/var";
import { useNavigate } from "react-router";

import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { ApproveStatus } from "../Driver/_ChangeRequest";
// import CreateAsset from "./AddForm"
import notfound from "../../assets/images/not_found.png";
import { useParams } from "react-router";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SignatureCanva from "./SignatureCanva";
// import ChangeModal from "./_ChangeModal";
// import View from "./View"
const { TabPane } = Tabs;

const filterst = [
  {
    text: "נוצר",
    value: "נוצר",
  },
  {
    text: "נחתם",
    value: "נחתם",
  },
  {
    text: "שולם",
    value: "שולם",
  },
];

const filterkod = [
  {
    text: "עמלה",
    value: "עמלה",
  },
  {
    text: "בלעדיות",
    value: "בלעדיות",
  },
  {
    text: "שיתוף פעולה",
    value: "שיתוף פעולה",
  },
];


function Index() {
  const heading = lang("My") + " " + lang("Documents");
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const fileType = [
    ".csv",
    ".pdf",
    "application/pdf",
    ".png",
    ".jpeg",
    ".jpg",
    ".PNG",
    ".JPEG",
    ".JPG",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const api = {
    list: apiPath.listDocument,
    update: apiPath.editDocument,
  };

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const [files, setFiles] = useState([]);
  const [file, setFile] = useState([]);
  const [error, setError] = useState();
  const [imageUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [refresh, setRefresh] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const [statementModal, setStatementModal] = useState(false);
  const [selected, setSelected] = useState();
  const [previewModal, setPreviewModal] = useState(false); // State for preview modal
  const [htmlContent, setHtmlContent] = useState("");
  const [visible, setVisible] = useState(false);
  const [listTrue, setListTrue] = useState(true);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const debouncedSearchFilter = useDebounce(selectedFilter, 300);
  const [isToggled, setIsToggled] = useState(false);
  const [addFiltersVisible, setAddFiltersVisible] = useState(false);
  const handleToggleFilters = () => {
    setAddFiltersVisible(!addFiltersVisible);
    setIsToggled(!isToggled);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.asset_status : null;
    request({
      url:
        api.list +
        `?page=${pagination ? pagination.current : 1}&pageSize=${pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}&debouncedSearchFilter=${JSON.stringify(
          debouncedSearchFilter
        )}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        console.log(data.docs);
        if (status) {
          const listData = data?.docs.map((item) => ({
            ...item,
            key: item._id,
          }));
          setList(listData);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: data.totalDocs,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  useEffect(() => {
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, debouncedSearchFilter]);

  const onDelete = (id) => {
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onChangeList = (e) => {
    setListTrue(e);
  };

  // const handleDownload = (selected) => {
  //   console.log(selected);
  //   const contentToAppend = `
  //   <div>
  //     <!-- Add more HTML content as needed -->

  //     <style>
  //       body {
  //           font-family: Arial, sans-serif;
  //           direction: rtl;
  //           text-align: right;
  //           margin: 20px;
  //           line-height: 1.5;
  //       }
  //       .header {
  //           margin-bottom: 20px;
  //       }
  //       .header div {
  //           margin-bottom: 5px;
  //       }
  //       .table-container {
  //           border-collapse: collapse;
  //           width: 100%;
  //           margin-top: 20px;
  //           margin-bottom: 20px;
  //       }
  //       .table-container th, .table-container td {
  //           border: 1px solid black;
  //           padding: 8px;
  //           text-align: center;
  //       }
  //       .sign-section {
  //           margin-top: 40px;
  //           text-align: center;
  //       }
  //       .footer {
  //           display: flex;
  //           justify-content: space-between;
  //           align-items: center;
  //           border-top: 1px solid black;
  //           padding-top: 10px;
  //           font-size: 12px;
  //       }
  //       .footer p {
  //           margin: 0;
  //       }
  //     </style>

  //     <main style=" max-width: 800px; width: 100%; margin: 0 auto;    border: 1px solid #ccc;
  //       padding: 20px 20px;">
  //       <div class="header">
  //           <div>עבור: ${selected.clientInfo?.name}</div>
  //           <div>תעודת זהות:</div>
  //           <div>שם מתווך:${selected.brokerInfo?.name}</div>
  //           <div>מספר רישיון תיווך: 4704320</div>
  //       </div>

  //       <h2 style="text-align: center;">שירותי תיווך נכס מקרקעין</h2>
  //       <div>סוג עסקה: מכירה</div>
  //       <div>בלעדיות: בלעדיות 3 חודש</div>

  //       <table class="table-container">
  //           <tr>
  //               <th>שם הנכס</th>
  //               <th>כתובת</th>
  //               <th>מחיר מבוקש</th>
  //               <th>מחיר למ"ר</th>
  //           </tr>
  //           <tr>
  //               <td>${selected.assetId.property_title}</td>
  //               <td>${selected.assetId.location.city}</td>
  //               <td>${selected.assetId.total_price}</td>
  //               <td>${selected.assetId.price_per_square_meter}</td>
  //           </tr>
  //       </table>

  //       <div>
  //           <p>
  //               סעיף 1: הלקוח מזמין שירותי תיווך מקרקעין מהמתווך, כדי לקבל שירותי תיווך בקשר לנכסים המפורטים לעיל.
  //           </p>
  //           <p>
  //               סעיף 2: הלקוח מאשר כי המתווך הציג בפניו את הנכסים המפורטים להלן, והוא מתחייב לדווח למתווך מיד על כל משא ומתן המתנהל עמו ואו עם שולחו
  //               בקשר לאחד או יותר מהנכסים, וכן מיד עם חתימת הסכם מחייב ואו התחייבות לביצוע העסקה, המוקדם מביניהם, ביחס לאחד או יותר מהנכסים להלן.
  //           </p>
  //           <p>
  //               סעיף 3: למען הסר ספק, הלקוח מצהיר כי מובן לו וכי הוא מסכים, כי כל הסכם אשר ייחתם בין הלקוח ואו מי מטעמו לבין בעל הנכס, בנוגע לכל אחד
  //               מהנכסים המפורטים מעלה יחייב את הלקוח בדמי התיווך כמפורט בסעיף 5 להלן.
  //           </p>
  //           <p>
  //               סעיף 4: הלקוח מתחייב לא למסור לגורם כלשהו מידע שקיבל מהמתווך בנוגע לנכסים שלהלן ומתחייב לפצות את המתווך על כל נזק שייגרם לו באם יפר
  //               התחייבות זו.
  //           </p>
  //           <p>
  //               סעיף 5: הלקוח מתחייב לשלם למתווך את דמי התיווך כמפורט להלן, מיד עם חתימת הסכם מחייב או עם התחייבות לביצוע העסקה, המוקדם מביניהם,
  //               בנוגע לאחד או יותר מהנכסים המפורטים להלן.
  //               <br>
  //               דמי התיווך ישולמו במזומן כדלקמן:
  //               <br>5.1 דמי תיווך של 1 חודש בתוספת מע"מ.
  //               <br>5.2 דמי התיווך ישולמו למתווך במזומן מיד עם חתימת הסכם מחייב ואו עם התחייבות לביצוע העסקה, המוקדם מביניהם.
  //               <br>5.3 האמור לעיל בא בנוסף לזכותו של המתווך לגבות דמי תיווך מהמוכר/משכיר.
  //           </p>
  //           <p>
  //               סעיף 6: הלקוח מאשר כי עם מכירת הנכס, המתווך יוכל להודיע לציבור כי הנכס נמכר בכל דרך שהמתווך ימצא לנכון.
  //           </p>
  //           <p>
  //               סעיף 7: הלקוח מאשר שהומלץ לו על ידי המתווך להסתייע בשירותי עורך דין ואו מומחים אחרים לפי העניין והצורך במהלך העסקה.
  //           </p>
  //           <p>הערות נוספות:</p>
  //       </div>

  //       <div class="sign-section">
  //           <p>חתימה</p>
  //           <p>
  //             <img class="imageDoc" src="${selected.signUrl}" />
  //           </p>
  //       </div>

  //       <div class="footer">
  //           <div>
  //               <p>הופק על ידי מערכת ברוקרלנד</p>
  //               <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
  //           </div>
  //           <p>22/08/2024, 09:18:51</p>

  //       </div>
  //     </main>
  //   </div>
  // `;

  //   const element = document.createElement("div");
  //   element.innerHTML = contentToAppend;

  //   html2pdf()
  //     .from(element)
  //     .save(`${selected.title}.pdf`)
  //     .then(() => {
  //       console.log("PDF Downloaded");
  //     })
  //     .catch((err) => console.error("PDF generation failed", err));
  // };

  const onUpload = (e, id) => {
    console.log(e, id);
    request({
      url: api.update + "/" + id,
      method: "PUT",
      data: {
        signUrl: e,
      },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const sendUrlShare = (id) => {
    // Generate the URL
    const url = `https://crm.brokerland.co.il/document-uploadSign/${id}`;
    // const url = `http://localhost:3001/document-uploadSign/${id}`;

    // Copy the URL to the clipboard
    // navigator.clipboard.writeText(url).then(
    //   () => {
    //     console.log("URL copied to clipboard:", url);
    //     // Optionally show a notification or alert to the user
    //     alert("URL copied to clipboard!");
    //   },
    //   (err) => {
    //     console.error("Failed to copy URL: ", err);
    //   }
    // );

    if (navigator.clipboard) {
      navigator.clipboard.writeText(url).then(
        () => {
          console.log("URL copied to clipboard:", url);
          alert("URL copied to clipboard!");
        },
        (err) => {
          console.error("Failed to copy URL: ", err);
        }
      );
    } else {
      fallbackCopyTextToClipboard(url);
    }
  };

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
      alert("URL copied to clipboard!");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
      alert("Failed to copy URL.");
    }
    document.body.removeChild(textArea);
  }

  const handlePreview = (selected) => {
    const contentToAppend = `
    <div>
      <!-- Add more HTML content as needed -->
      
      <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            margin: 20px;
            line-height: 1.5;
        }
        .header-docs {
            margin-bottom: 20px;
        }
        .header-docs div {
            margin-bottom: 5px;
        }
        .table-container {
            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .table-container th, .table-container td {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
        }
        .sign-section {
            margin-top: 40px;
            text-align: center;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid black;
            padding-top: 10px;
            font-size: 12px;
        }
        .footer p {
            margin: 0;
        }
      </style>

      <main  style=" max-width: 800px; width: 100%; margin: 0 auto;    border: 1px solid #ccc;
        padding: 20px 20px;">
        <div class="dgsethtml-main-docs">
        <div class="header-docs">
            <div>עבור: ${selected.clientId?.name}</div>

            ${selected.clientId?.brokerNo !== null
        ? `${selected.clientId.brokerNo
          ? `<div>תעודת זהות:${selected.clientId.brokerNo}</div>`
          : ``
        }`
        : ""
      }

            <div>שם מתווך:${selected.brokerId?.name}</div>
            <div>מספר רישיון תיווך: ${selected.brokerId?.brokerNo} </div>
        </div>

        <h2 style="text-align: center;">שירותי תיווך נכס מקרקעין</h2>
        <div>סוג עסקה: ${selected.rent_sale
        ? selected.rent_sale === "sale"
          ? "מכירה"
          : "השכרה"
        : "מכירה"
      }</div>

        <table class="table-container docs-table">
            <tr>
                <th>שם הנכס</th>
                <th>כתובת</th>
                <th>מחיר מבוקש</th>
                <th>מחיר למ"ר</th>
            </tr>
            <tr>
                <td>${selected.assetId.property_title}</td>
                <td>${selected.assetId.location.city}</td>
                <td>${selected.total_price
        ? selected.total_price
        : selected.assetId.total_price
      }</td>
                <td>${(
        (selected.total_price
          ? selected.total_price
          : selected.assetId.total_price) /
        selected.assetId.size_in_meters
      ).toFixed(2)}</td>
            </tr>
        </table>

        <div>
            <p>
                סעיף 1: הלקוח מזמין שירותי תיווך מקרקעין מהמתווך, כדי לקבל שירותי תיווך בקשר לנכסים המפורטים לעיל.
            </p>
            <p>
                סעיף 2: הלקוח מאשר כי המתווך הציג בפניו את הנכסים המפורטים להלן, והוא מתחייב לדווח למתווך מיד על כל משא ומתן המתנהל עמו ואו עם שולחו
                בקשר לאחד או יותר מהנכסים, וכן מיד עם חתימת הסכם מחייב ואו התחייבות לביצוע העסקה, המוקדם מביניהם, ביחס לאחד או יותר מהנכסים להלן.
            </p>
            <p>
                סעיף 3: למען הסר ספק, הלקוח מצהיר כי מובן לו וכי הוא מסכים, כי כל הסכם אשר ייחתם בין הלקוח ואו מי מטעמו לבין בעל הנכס, בנוגע לכל אחד
                מהנכסים המפורטים מעלה יחייב את הלקוח בדמי התיווך כמפורט בסעיף 5 להלן.
            </p>
            <p>
                סעיף 4: הלקוח מתחייב לא למסור לגורם כלשהו מידע שקיבל מהמתווך בנוגע לנכסים שלהלן ומתחייב לפצות את המתווך על כל נזק שייגרם לו באם יפר
                התחייבות זו.
            </p>
            <p>
                סעיף 5: הלקוח מתחייב לשלם למתווך את דמי התיווך כמפורט להלן, מיד עם חתימת הסכם מחייב או עם התחייבות לביצוע העסקה, המוקדם מביניהם,
                בנוגע לאחד או יותר מהנכסים המפורטים להלן.
                <br>
                דמי התיווך ישולמו במזומן כדלקמן:
                <br>5.1 דמי תיווך של ${selected.commission} ${selected.rent_sale === "sale" ? "% עמלה" : "חודשי שכירות"
      }  בתוספת מע"מ.
                <br>5.2 דמי התיווך ישולמו למתווך במזומן מיד עם חתימת הסכם מחייב ואו עם התחייבות לביצוע העסקה, המוקדם מביניהם.
                <br>5.3 האמור לעיל בא בנוסף לזכותו של המתווך לגבות דמי תיווך מהמוכר/משכיר.
            </p>
            <p>
                סעיף 6: הלקוח מאשר כי עם מכירת הנכס, המתווך יוכל להודיע לציבור כי הנכס נמכר בכל דרך שהמתווך ימצא לנכון.
            </p>
            <p>
                סעיף 7: הלקוח מאשר שהומלץ לו על ידי המתווך להסתייע בשירותי עורך דין ואו מומחים אחרים לפי העניין והצורך במהלך העסקה.
            </p>
            ${selected.description !== null
        ? `${selected.description
          ? `<p>הערות נוספות:${selected.description}</p>`
          : ``
        }`
        : ""
      }
        </div>

        <div class="sign-section">
            <p>חתימה</p>
            <p>${selected.signUrl
        ? `<img src="${selected.signUrl}" />`
        : `___________`
      }</p>   
        </div>

        <div class="footer">
            <div>
                <p>הופק על ידי מערכת ברוקרלנד</p>
                <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
            </div>
            <p>
              ${new Date(selected?.created_at).toLocaleDateString(
        "sv-SE"
      )} ${new Date(selected?.created_at).toLocaleTimeString("sv-SE", {
        hour: "2-digit",
        minute: "2-digit",
      })}</p>
          
        </div>
        </div>
      </main>
    </div>
  `;

    const partnershipContentToAppend = `
    <div>
      <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            margin: 20px;
            line-height: 1.5;
        }
        .header {
            margin-bottom: 20px;
        }
        .header div {
            margin-bottom: 5px;
        }
        .table-container {
            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .table-container th, .table-container td {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
        }
        .sign-section {
            margin-top: 40px;
            text-align: center;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid black;
            padding-top: 10px;
            font-size: 12px;
        }
        .footer p {
            margin: 0;
        }
      </style>

      <main style="max-width: 800px; width: 100%; margin: 0 auto; border: 1px solid #ccc; padding: 20px 20px;">
        <div class="header">
            <div>שם מתווך: ${selected.brokerId?.name}</div>
            <div>מספר רישיון: ${selected.brokerId?.brokerNo}</div>
        </div>

        <h2 style="text-align: center;">הסכם שיתוף פעולה בין מתווכים</h2>
        <div class="header">
            <div>שם מתווך: ${selected.clientId?.name}</div>
            <div>מייל מתווך: ${selected.clientId?.email}</div>
            <div>טלפון מתווך: ${selected.clientId?.mobileNumber}</div>
        </div>
        <div>סוג עסקה: ${selected.rent_sale
        ? selected.rent_sale === "sale"
          ? "מכירה"
          : "השכרה"
        : "מכירה"
      }</div>
        <table class="table-container docs-table">
            <tr>
                <th>שם הנכס</th>
                <th>כתובת</th>
                <th>מחיר מבוקש</th>
                <th>מחיר למ"ר</th>
            </tr>
            <tr>
                <td>${selected.assetId.property_title}</td>
                <td>${selected.assetId.location.city}</td>
                <td>${selected.total_price
        ? selected.total_price
        : selected.assetId.total_price
      }</td>
                <td>${(
        (selected.total_price
          ? selected.total_price
          : selected.assetId.total_price) /
        selected.assetId.size_in_meters
      ).toFixed(2)}</td>
            </tr>
        </table>

        <div>

            ${selected.type === "קופה משותפת"
        ? `<p>סוג השתפ: קופה משותפת (מחברים את עמלת המוכר, ואת עמלת הקונה, וכל מתווך מקבל חצי)</p>`
        : `<p>סוג השתפ: כל אחד מהלקןח שלו: (כל מתווך גובה מהלקןח שלו, את מה שסיכם עם הלקוח שלו)</p>`
      }
            <ol>
                <li>הצדדים יפעלו מתוך נאמנות והגינות לשיתוף פעולה.</li>
                <li>הצדדים לא ימסרו את פרטי הנכס לאחר, אלא אם כן יקבלו אישור בכתב.</li>
                <li>כל קשר עם בעל הנכס יתבצע באמצעות נציג המתווך.</li>
                <li>ההסכם תקף לגבי כל עסקה שתצא מהנכס הנ"ל, גם אם בתיווך אחר.</li>
                <li>ההסכם תקף לגבי הנכס המפורט בלבד.</li>
                <li>מע"מ יתווסף לכל הסכומים לפי החוק.</li>
            </ol>
            ${selected.description !== null
        ? `${selected.description
          ? `<p>הערות נוספות:${selected.description}</p>`
          : ``
        }`
        : ""
      }
        </div>

        <div class="sign-section">
            <p>חתימה: 
              ${selected.signUrl
        ? `
                <img class="" src="${selected.signUrl}" onload="this.style.display='inline-block'" />     `
        : `Your Signature Here`
      }
            </p>
        </div>

        <div class="footer">
            <div>
                <p>הופק על ידי מערכת ברוקרלנד</p>
                <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
            </div>
            <p>
              ${new Date(selected?.created_at).toLocaleDateString(
        "sv-SE"
      )} ${new Date(selected?.created_at).toLocaleTimeString("sv-SE", {
        hour: "2-digit",
        minute: "2-digit",
      })}</p>
        </div>
      </main>
    </div>
    `;

    const exclusivityContentToAppend = `
    <div>
      <!-- Main HTML Structure -->
      <style>
        body {
            font-family: Arial, sans-serif;
            direction: rtl;
            text-align: right;
            margin: 20px;
            line-height: 1.5;
        }
        .header {
            margin-bottom: 20px;
        }
        .header div {
            margin-bottom: 5px;
        }
        .table-container {
            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .table-container th, .table-container td {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
        }
        .sign-section {
            margin-top: 40px;
            text-align: center;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid black;
            padding-top: 10px;
            font-size: 12px;
        }
        .footer p {
            margin: 0;
        }
      </style>

      <main style="max-width: 800px; width: 100%; margin: 0 auto; border: 1px solid #ccc; padding: 20px 20px;">
        <div class="header-docs">
            <div>עבור: ${selected.clientId?.name}</div>

            ${selected.clientId?.brokerNo !== null
        ? `${selected.clientId.brokerNo
          ? `<div>תעודת זהות:${selected.clientId.brokerNo}</div>`
          : ``
        }`
        : ""
      }

            <div>שם מתווך:${selected.brokerId?.name}</div>
            <div>מספר רישיון תיווך: ${selected.brokerId?.brokerNo} </div>
        </div>

        <h2 style="text-align: center;">הזמנת שירותי תיווך בלעדיות</h2>
        <div>סוג עסקה: ${selected.rent_sale
        ? selected.rent_sale === "sale"
          ? "מכירה"
          : "השכרה"
        : "מכירה"
      }</div>

        <!-- Updated Table with Property Details -->
        <table class="table-container docs-table">
            <tr>
                <th>שם הנכס</th>
                <th>כתובת</th>
                <th>מחיר מבוקש</th>
                <th>מחיר למ"ר</th>
            </tr>
            <tr>
                <td>${selected.assetId.property_title}</td>
                <td>${selected.assetId.location.city}</td>
                <td>${selected.total_price
        ? selected.total_price
        : selected.assetId.total_price
      }</td>
                <td>${(
        (selected.total_price
          ? selected.total_price
          : selected.assetId.total_price) /
        selected.assetId.size_in_meters
      ).toFixed(2)}</td>
            </tr>
        </table>


        <div>

        <p>
            1. הלקוח מזמין בזאת שירותי תיווך מקרקעין כמפורט בטבלה דלעיל מהמתווך, וזאת לגבי כל אחד מהנכסים המפורטים בחלק העליון של מסמך זה (להלן: "הנכס") או חלק ממנו. 
            הזמנה זו מקנה למתווך בלעדיות למכירת הנכסים החל מיום 
            
            
            <span style="color:red;">${selected?.from_date
        ? new Date(selected.from_date).toLocaleDateString("sv-SE")
        : new Date(selected?.created_at).toLocaleDateString("sv-SE")
      }</span>
             ועד ליום 
             <span style="color:red;">${selected?.to_date
        ? new Date(selected.to_date).toLocaleDateString("sv-SE")
        : new Date(
          new Date(selected?.created_at).setMonth(
            new Date(selected?.created_at).getMonth() +
            parseInt(selected?.period)
          )
        ).toLocaleDateString("sv-SE")
      }</span> 

             (להלן: "תקופת הבלעדיות"), עם בחר המתווך להתחיל ולשווק את הנכסים במועד מאוחר יותר, תקופת הבלעדיות תחל מהמועד בו יתחיל המתווך בשיווק בפועל את הנכסים. 
            הלקוח מתחייב שלא ינהל מו"מ עם רוכשים או שוכרים פוטנציאליים או נציגיהם, ולא יפנה למתווכים אחרים/נוספים כדי לשווק את הנכסים במשך כל תקופת הבלעדיות אלא באמצעות המתווך בלבד.
        </p>

        <p>
            2. הלקוח מתחייב למסור למתווך את כל המידע שברשותו ביחס לנכסים והמצב המשפטי, התכנוני והמיסויי שלהם, כולל אישור זכויות ונסח טאבו וכן כל מידע אחר הנוגע לנכסים כולל פרטים שנמסרו בע"פ, בכתב או בכל אופן אחר.
        </p>

        <p>
            3. הלקוח מתחייב להודיע למתווך מיד על כל שינוי שיחול במצב הנכסים, ו/או זכויותיו בהם, ו/או במצבו המשפטי והכלכלי של הלקוח, ככל שיהיו כאלה.
        </p>

        <p>
            4. תקופת הבלעדיות לא תחול ביחס לרוכשים/שוכרים שהלקוח הציג אותם למתווך בכתב, וזאת עד למועד תחילת ההסכם כמפורט לעיל.
        </p>

        <p>
            5. כל התמורה עבור מכירה/השכרה, תהא אשר תהא, כולל כל השירותים הנלווים והטבות שונות, תיחשב לצורך חישוב דמי התיווך. 
        </p>

        <p>
            6. דמי תיווך בסך <span style="color:red;">${selected.commission} ${selected.rent_sale === "sale" ? "% עמלה" : "חודשי שכירות"
      }</span> שישולמו על פי האמור בטופס הזמנת שירותי תיווך ו/או טופס ההזמנה המצורף לדוח זה, ישולמו במלואם למתווך על ידי הלקוח בתום ביצוע עסקת מכר/שכירות, בסכום שנקבע בטופס ההזמנה גם אם בסופו של דבר בוצעה עסקה כלשהי במחיר גבוה או נמוך יותר מהמחיר הרשום בהזמנה.
        </p>

        <p>
            7. הלקוח מצהיר ומתחייב כי הנכסים האמורים בטופס ההזמנה, הינם בבעלותו הבלעדית, והינם פנויים מכל זכות של צד ג'.
        </p>

        <p>
            8. במידה ויתגלה כי במועד ביצוע העסקה, הנכסים נשוא טופס הזמנת השירות ו/או טופס ההזמנה המצורף לדוח זה אינם נקיים מכל עיקול/הגבלה או זכות צד ג' והלקוח או כל אחד מבני משפחתו לא הודיעו למתווך על כך, אזי הלקוח יחויב בתשלום דמי תיווך ע"פ הסכום שנקבע בטופס ההזמנה במלואו, גם אם לא נחתמה עסקה בסופו של דבר.
        </p>

        <p>
            9. המתווך מצהיר בזאת כי הוא מחזיק ברישיון תיווך תקף מטעם משרד המשפטים, תקף לביצוע עסקאות מקרקעין כפי שמפורט בטופס הזמנה זה.
        </p>

        <p>
            10. מוסכם כי כל סכסוך ו/או חילוקי דעות בין הצדדים בכל הנוגע להזמנת שירותי התיווך, תביעות הדדיות ו/או כל דבר הקשור להסכם זה יובא להכרעה בפני בורר מוסכם שמונה על ידי הצדדים, אלא אם כן יחליטו הצדדים אחרת בהסכמה מפורשת ביניהם.
        </p>

        <p>
            11. המתווך מתחייב לבצע פעולות שיווק של הנכסים האמורים בהזמנה זו, על חשבונו, לרבות ביצוע פעולות פרסום, שלטי חוצות, סיורי בית פתוח וכיו"ב, לצורך מכירת הנכס, והמכירה תכלול כל התמורה הנובעת משיווק הנכס, במידה שווה ו/או מכר חוזר ו/או אופן אחר, ככל שיוסכם ו/או ייקבע ע"י הצדדים ובכפוף לכל דין.
        </p>

        <p>
            12. ידוע למתווך כי לקוחות המזמינים שירותי תיווך במקרקעין מבקשים לקבל שירות מקצועי ואמין ממתווך המורשה למכירת נכסים. כל הפעולות שיינקטו על ידו לצורך ביצוע הזמנת שירותי התיווך נעשות על פי דין ובהתאם לדרישות הלקוח, וכי ככל שיושג כל הסכם למכירת הנכס, התמורה תכלול כל סכום שיוסכם בין הצדדים גם אם סוכם בע"פ או בכתב ו/או באמצעי תקשורת אחרים.
        </p>


            <p>

              הלקוח מזמין בזאת שירותי תיווך מהמתווך לצורך ביצוע עסקת מכר או השכרת הנכסים המפורטים בטבלה לעיל (להלן: "הנכסים"). 
              הזמנה זו מקנה למתווך בלעדיות למכירת הנכסים לתקופה המתחילה מ- 
              
              
              ${selected?.from_date
        ? new Date(selected.from_date).toLocaleDateString("sv-SE")
        : new Date(selected?.created_at).toLocaleDateString("sv-SE")
      } 
              ועד ל- 
              ${selected?.to_date
        ? new Date(selected.to_date).toLocaleDateString("sv-SE")
        : new Date(
          new Date(selected?.created_at).setMonth(
            new Date(selected?.created_at).getMonth() +
            parseInt(selected?.period)
          )
        ).toLocaleDateString("sv-SE")
      }.

            </p>
            <p>
                ההזמנה כפופה לתנאים ולתעריפים שייקבעו בהמשך מסמך זה.
            </p>

        </div>

        <!-- Signature Section -->
        <div class="sign-section">
            <p>חתימה</p>
            <p>
            ${selected.signUrl
        ? `<img class="" src="${selected.signUrl}" onload="this.style.display='inline-block'" />`
        : `Your Signature Here`
      }
            </p>
        </div>

        <!-- Footer Section -->
        <div class="footer">
            <div>
                <p>הופק על ידי מערכת ברוקרלנד</p>
                <p><a href="https://www.brokerland.co.il" target="_blank">www.brokerland.co.il</a></p>
            </div>
            <p>
              ${new Date(selected?.created_at).toLocaleDateString(
        "sv-SE"
      )} ${new Date(selected?.created_at).toLocaleTimeString("sv-SE", {
        hour: "2-digit",
        minute: "2-digit",
      })}</p>
        </div>
      </main>
    </div>
  `;

    if (selected.kind_of_doc === "עמלה") {
      setHtmlContent(contentToAppend);
    }

    if (selected.kind_of_doc === "שיתוף פעולה") {
      setHtmlContent(partnershipContentToAppend);
    }
    if (selected.kind_of_doc === "בלעדיות") {
      setHtmlContent(exclusivityContentToAppend);
    }

    setPreviewModal(true);
  };

  const handleChange = (pagination, filters) => {
    console.log(filters);
    fetchData(pagination, filters);
  };

  const components = {};
  const defaultColumns = [
    {
      title: lang("Kind"),
      dataIndex: "kind_of_doc",
      type: "kind_of_doc",
      editable: true,
      key: "kind_of_doc",
      width: 200,
      render: (_, { kind_of_doc }) => {
        return `${kind_of_doc}`;
      },
    },
    // {
    //   title: lang("Title"),
    //   dataIndex: "title",
    //   key: "title",
    //   type: "text",
    //   editable: true,
    //   width: 200,
    //   render: (_, { title }) => {
    //     return title ? <a>{`${title}`}</a> : "Unknown";
    //   },
    // },
    // {
    //   title: lang("Description"),
    //   dataIndex: "description",
    //   key: "description",
    //   type: "text",
    //   editable: true,
    //   width: 200,
    //   render: (_, { description }) => {
    //     return description ? <a>{`${description}`}</a> : "Unknown";
    //   },
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    // },
    {
      title: lang("Commission"),
      dataIndex: "commission",
      key: "commission",
      type: "text",
      editable: true,
      width: 200,
      render: (_, { commission, rent_sale }) => {
        return commission ? (
          <a>{`${commission}  ${rent_sale === "sale" ? "%" : "Months"}`}</a>
        ) : (
          "none"
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: lang("Status"),
      dataIndex: "status",
      type: "status",
      editable: true,
      key: "status",
      width: 200,
      filters: [
        {
          text: lang("Signed"),
          value: "signed",
        },
        {
          text: lang("Paid"),
          value: "paid",
        },
        {
          text: lang("Waiting To Be Signed"), // Adjusted to match the translated text
          value: "created",
        },
      ],
      render: (_, { status }) => {
        let statusText;
        let color;

        switch (status) {
          case "signed":
            color = "green";
            statusText = lang("Signed");
            break;
          case "paid":
            color = "blue";
            statusText = lang("Paid");
            break;
          case "created":
            color = "orange";
            statusText = lang("Waiting To Be Signed");
            break;
          default:
            color = "olivedrab";
            statusText = lang("Unknown");
        }
        return (
          <h6>
            <Tag color={color} key={status}>
              {statusText ? statusText : "-"}
            </Tag>
          </h6>
        );
      },
    },
    {
      title: lang("Created On"),
      dataIndex: "created_at",
      type: "created_at",
      editable: true,
      key: "created_at",
      width: 200,
      render: (_, { created_at }) => {
        return `${new Date(created_at).toLocaleDateString("sv-SE")}`;
      },
    },
    {
      title: lang("Signed On"),
      dataIndex: "signed_at",
      type: "signed_at",
      editable: true,
      key: "signed_at",
      width: 200,
      render: (_, { signed_at }) => {
        return `${signed_at
          ? new Date(signed_at).toLocaleDateString("sv-SE")
          : lang("Not signed")
          }`;
      },
    },
    {
      title: lang("Client"),
      dataIndex: "clientId",
      type: "clientId",
      editable: true,
      key: "clientId",
      width: 200,
      render: (_, { clientId }) => {
        return `${clientId?.name}`;
      },
    },
    {
      title: lang("Asset"),
      dataIndex: "assetId",
      type: "assetId",
      editable: true,
      key: "assetId",
      width: 200,
      render: (_, { assetId }) => {
        return `${assetId?.property_title}`;
      },
    },
    {
      title: lang("Action"),
      dataIndex: ["action"],
      type: "action",
      key: "action",
      width: 200,
      // render: (_, { col, _id, signUrl }) => {
      //   return (
      render: (_, col) => {
        const { _id, signUrl, clientId } = col;
        return (
          <div className="d-flex justify-content-start">
            <Tooltip title={lang("View")} color={"purple"} key={"View"}>
              <Button className="edit-cls btnStyle primary_btn">
                <EyeOutlined onClick={() => handlePreview(col)} />
              </Button>
            </Tooltip>

            {/* {signUrl && (
              <Tooltip title={"Statement"} color={"purple"} key={"Statement"}>
                <Button
                  title="Statement"
                  className="edit-cls btnStyle primary_btn"
                  onClick={() => {
                    setSelected(col);
                    setStatementModal(true);
                  }}
                >
                  <FileUploadOutlinedIcon />
                  <img src={EditIcon} />
                </Button>
              </Tooltip>
            )} */}

            <Tooltip title={lang("Share")} color={"purple"} key={"Share"}>
              <Button
                title="Export"
                className=" edit-cls btnStyle primary_btn "
                onClick={() => {
                  sendUrlShare(_id);
                }}
              >
                <FileShareOutlinedIcon />
              </Button>
            </Tooltip>

            {!signUrl && (
              <Tooltip title={lang("Edit")} color={"purple"} key={"Edit"}>
                <Button
                  title="Export"
                  className=" edit-cls btnStyle primary_btn "
                  onClick={() => {
                    navigate("/create-document/" + _id);
                  }}
                >
                  <EditOutlinedIcon />
                </Button>
              </Tooltip>
            )}

            <Tooltip title={lang("View")} color={"purple"} key={"View"}>
              <a
                // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${item._id}`}
                href={`https://wa.me/${clientId?.mobileNumber}?text=https://crm.brokerland.co.il/document-uploadSign/${_id}`}
                target="_blank"
                type="button"
                title="Add to wishlist"
                data-id="135362"
                className="wishlisted edit-cls"
              >
                <svg
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="WhatsAppIcon"
                >
                  <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                </svg>
              </a>
            </Tooltip>

            {!signUrl && (
              <Tooltip title={lang("Quick Sign")} color={"purple"} key={"Quick Sign"}>
                <a
                  title="Draw"
                  className="wishlisted edit-cls"
                  target="_blank"
                  href={"/document-uploadSign/" + _id}
                >
                  <DrawIcon />
                </a>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
      }),
    };
  });

  const handleMeClick = (e) => {
    if (!selectedFilters.includes(e.key)) {
      const newSelectedFilters = [...selectedFilters, e.key];
      setSelectedFilters(newSelectedFilters);
      setAddFiltersVisible(true);
      setSelectedFilter((prevSelectedFilter) => ({
        ...prevSelectedFilter,
        [e.key]: null,
      }));
    }
  };
  const handleClose = (removedFilter) => {
    const newFilters = selectedFilters.filter(
      (filter) => filter !== removedFilter
    );
    setSelectedFilters(newFilters);

    setSelectedFilter((prevSelectedFilter) => {
      const { [removedFilter]: _, ...rest } = prevSelectedFilter;
      return rest;
    });
  };
  const handleSetVal = (key, value) => {
    setSelectedFilter((prevSelectedFilter) => ({
      ...prevSelectedFilter,
      [key]: value,
    }));
    setAddFiltersVisible(true);
  };
  const menuItems = [
    { text: lang('Status'), key: "status", label: lang('Status') },
    { text: lang('kind_of_doc'), key: "kind_of_doc", label: lang('kind_of_doc') },
  ];

  const menu = (
    <Menu onClick={handleMeClick}>
      {menuItems.map((item) => (
        <Menu.Item text={item.text} key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu02 = (key) => {
    if (key === "status") {
      return (
        <div className="menu-items-nav">
          <Select
            placeholder={lang("Please select")}
            key="status"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filterst.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }
    if (key === "kind_of_doc") {
      return (
        <div className="menu-items-nav">
          <Select
            placeholder={lang("Please select")}
            key="kind_of_doc"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filterkod.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }
    return null;
  };

  const changableFeilds = (value) => {
    switch (value) {
      case "status":
        return lang("Status");
      case "kind_of_doc":
        return lang("kind_of_doc");
      default:
        return value;
    }
  };

  const [stateVal, setStateVal] = useState();
  const handleINputValState = (e) => {
    setStateVal(e);
  };
  const handleStateVal = (id) => {
    // console.log(stateVal);
    request({
      url: api.update + "/" + id,
      method: "PUT",
      data: {
        statement: stateVal,
      },
      onSuccess: (data) => {
        setStatementModal(false);
        setSelected("");
      },
      onError: (error) => {
        setStatementModal(false);
        setSelected("");
      },
    });
  };
  return (
    <>
      <Row className="justify-content-between align-items-center">
        <div className="main-hh-headding">
          <div className="headding-cc">{lang("My Document")}</div>
          <Breadcrumb className="bread-menu">
            <Breadcrumb.Item className="bread-items" href="#">
              {lang('Dashboard')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="bread-items" href="#">
              {lang("My Document")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Row>

      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false}>
              <Col xs={24} xl={24}>
                <div className="w-100 d-flex align-items-baseline text-head_right_cont">
                  <div className="pageHeadingSearch clint-input ">
                    <Input.Search
                      className="searchInput"
                      placeholder={lang("Search Document")}
                      value={searchText}
                      onChange={onSearch}
                      allowClear
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setSearchText("");
                    }}
                    type="primary"
                    icon={<RestartAltOutlinedIcon />}
                    className="man-reset-bttn and-assest-btn edit-cls"
                  >
                    {/* {lang("Reset")} */}
                  </Button>

                  <div className="lead-clint-drop">
                    <Switch
                      checkedChildren={lang("Card")}
                      unCheckedChildren={lang("List")}
                      checked={listTrue}
                      onClick={(listTrue) => onChangeList(listTrue)}
                      className="main-mecu-switch"
                    />
                  </div>

                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                  // onClick={handleToggleFilters}
                  >
                    <Button className="report-btn">
                      <AddCircleOutlineOutlinedIcon />
                      {lang("Filter")}
                    </Button>
                  </Dropdown>

                  <div className="lg-adddesktop">
                    <Button
                      className="primary_btn btnStyle"
                      onClick={(e) => {
                        navigate(`/create-document`);
                      }}
                    >
                      <span className="add-Ic">
                        <AddCircleOutlineOutlinedIcon />
                      </span>
                      {lang("Document")}

                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={24}>
                <div className="mb-add-mobile">
                  <Button
                    className="add-inc-btn"
                    onClick={(e) => {
                      navigate(`/create-document`);
                      // setVisible(true);
                      // setSearchText("");
                    }}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </Button>
                </div>
                <div>
                  <div
                    className={`add-filters ${addFiltersVisible ? "show" : "hide"
                      }`}
                  >
                    <Space className="flex-wrap">
                      {selectedFilters.map((filter) => (
                        <Tag
                          className="ftter-main-btn"
                          key={filter}
                          closable
                          closeIcon={<CloseOutlined />}
                          onClose={() => handleClose(filter)}
                        >
                          {filter && (
                            <>
                              <Dropdown
                                overlay={menu02(filter)}
                                trigger={["click"]}
                              >
                                <Button className="filter-btns">
                                  {selectedFilter[filter]
                                    ? changableFeilds(filter) +
                                    " : " +
                                    selectedFilter[filter]
                                    : changableFeilds(filter)}
                                </Button>
                              </Dropdown>
                            </>
                          )}
                        </Tag>
                      ))}
                    </Space>
                  </div>
                </div>
              </Col>
            </Card>
          </Col>
        </Row>
      </div>

      {/* <div className="upload-container">
        <h3 className="my-document-header">My Documents</h3>
        <div className="upload-box">
          <input
            type="file"
            multiple
            onChange={handleUpload}
            className="file-input"
            fileList={file}
            onRemove={(e) => setFile([])}
            maxCount={1}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
                .pdf,
                application/pdf,
                .png,
                .jpeg,
                .jpg,
                .PNG,
                .JPEG,
                .JPG"
            customRequest={(e) => null}
          />
          <div className="main-upload-iconss">
            <h4>
              <UploadOutlined />
            </h4>
            <p>Upload Documents</p>
          </div>
        </div>
        <div className="files-container">
          {files.map((file, index) => (
            <div key={index} className="file-item">
              <img src={URL.createObjectURL(file)} alt={file.name} />
              <button
                onClick={() => handleRemove(index)}
                className="remove-button"
              >
                ×
              </button>
            </div>
          ))}
          {files.length > 0 && (
            <Button type="primary" className="back-btns" onClick={handleDocSub}>
              Submit
            </Button>
          )}
        </div>
      </div> */}
      <div className="mt-4">
        <Row gutter={[24, 24]}>
          {listTrue ? (
            <Col span={24} md={24}>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  components={components}
                  onChange={handleChange}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                  }}
                  className="ant-border-space"
                />
              </div>
            </Col>
          ) : (
            list.map((index) => (
              <Col span={24} md={12} lg={12} xl={8} xxl={6}>
                <div className="document-type-main">
                  <div className="document-icon-img-new">
                    <img src={image1} />
                    <div className="im-ex-btn">
                      <Button className="edit-cls btnStyle primary_btn">
                        <EyeOutlined onClick={() => handlePreview(index)} />
                      </Button>

                      {/* {signUrl && (
                        <Tooltip title={"Statement"} color={"purple"} key={"Statement"}>
                          <Button
                            title="Statement"
                            className="edit-cls btnStyle primary_btn"
                            onClick={() => {
                              setSelected(col);
                              setStatementModal(true);
                            }}
                          >
                            <FileUploadOutlinedIcon />
                            <img src={EditIcon} />
                          </Button>
                        </Tooltip>
                      )} */}

                      <Button
                        title="Export"
                        className=" edit-cls btnStyle primary_btn "
                        onClick={() => {
                          sendUrlShare(index._id);
                        }}
                      >
                        <FileShareOutlinedIcon />
                      </Button>

                      <Button
                        title="Export"
                        className=" edit-cls btnStyle primary_btn "
                        onClick={() => {
                          navigate("/create-document/" + index._id);
                        }}
                      >
                        <EditOutlinedIcon />
                      </Button>

                      <a
                        // href={`https://api.whatsapp.com/send/?link=http://13.50.11.124:5501/AssetShare/${userProfile.name}/${item._id}`}
                        href={`https://wa.me/${index.clientId?.mobileNumber}?text=https://crm.brokerland.co.il/document-uploadSign/${index._id}`}
                        target="_blank"
                        type="button"
                        title="Add to wishlist"
                        data-id="135362"
                        className="wishlisted"
                      >
                        <svg
                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="WhatsAppIcon"
                        >
                          <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                        </svg>
                      </a>

                      {!index.signUrl && (
                        <a
                          title="Draw"
                          className="wishlisted"
                          target="_blank"
                          href={"/document-uploadSign/" + index._id}
                        >
                          <DrawIcon />
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="signature">{index.title}</div>
                  <div className="signature-date">{index.description}</div>
                </div>
              </Col>
            ))
          )}
        </Row>
      </div>
      {/* {pdfModal && (
        <PdfModal
          title={lang("Download PDF")}
          subtitle={lang("Download The PDF for " + selected.title)}
          show={pdfModal}
          data={selected}
          hide={() => {
            setPdfModal(false);
            setSelected();
          }}
          onOk={() => handleDownload(selected)}
        />
      )} */}
      {signModal && (
        <SignatureCanva
          title={lang("Upload Signature")}
          subtitle={lang("Upload The Signature for " + selected?.title)}
          show={signModal}
          data={selected}
          hide={() => {
            setSignModal(false);
            setSelected();
          }}
          onOk={(e) => onUpload(e, selected._id)}
        />
      )}

      {/* {statementModal && (
        <Modal
          className="docs-modal"
          title="Add Statement"
          visible={statementModal}
          onCancel={() => setStatementModal(false)}
          footer={[
            <>
              <Button key="cancel" onClick={() => setStatementModal(false)}>
                Cancel
              </Button>
              <Button key="cancel" onClick={() => handleStateVal(selected._id)}>
                Ok
              </Button>
            </>,
          ]}
        >
          <Col span={24} sm={8}>
            <Form.Item
              label="Statement*"
              name="statement"
              rules={[
                { required: true, message: "Please enter the Statement" },
              ]}
            >
              {selected.statement ? (
                <>
                  <h5>{selected.statement}</h5>
                </>
              ) : (
                <Input
                  placeholder="Enter Document Statement"
                  onChange={(e) => handleINputValState(e.target.value)}
                  value={selected.statement}
                />
              )}
            </Form.Item>
          </Col>
        </Modal>
      )} */}

      <Modal
        className="docs-modal"
        title=""
        visible={previewModal}
        onCancel={() => setPreviewModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setPreviewModal(false)}>
            {lang("Back")}
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Modal>
    </>
  );
}

export default Index;
