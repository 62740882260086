import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
  Checkbox,
  Empty,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { uploadFile } from "react-s3";
import { s3Config } from "../../config/s3Config";

import notfound from "../../assets/images/not_found.png";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";

const { Option } = Select;

function CreateTask({ data }) {
  const location = useLocation();
  const params = useParams();
  const req_query = new URLSearchParams(location.search);
  const client_id = req_query.get("client_id");
  const sectionName = params.id ? lang("Edit Task") : lang("Create Task");
  const heading = "My Task";
  const api = {
    add: apiPath.addTask,
    edit: apiPath.editTask,
    clientList: apiPath.listClient,
    singleTask: apiPath.singleTask,
  };
  const [form] = Form.useForm();
  const { setPageHeading } = useContext(AppStateContext);
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [clients, setClients] = useState([]);
  const [assets, setAssets] = useState([]);
  const [clientId, setClientId] = useState("");

  const [formData, setFormData] = useState({
    // notes: "",
    // purpose: "",
    clientId: clientId ? clientId : "",
    assetId: "",
    description: "",
    task_name: "",
    title: "",
    date_of_entry: null,
  });

  useEffect(() => {
    if (!client_id) return;
    if (!clients.length > 0) return;

    if (clients.some((client) => client._id === client_id)) {
      form.setFieldValue("clientId", client_id);
    } else {
      ShowToast("Client Does not Match or A Lead Type", Severty.ERROR);
    }
  }, [client_id, clients]);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
      message.error("File format is not correct");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(`Image must be smaller than 5 MB!`);
      return false;
    }
    return true;
  };
  // const handleChange = (value, data) => {
  //     var country_code = data.dialCode;
  //     setMobileNumber({
  //         country_code: country_code,
  //         mobile_number: value.slice(data.dialCode.length),
  //     });
  // };

  const fetchData = () => {
    request({
      url: api.clientList + `?clientType=client`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");
          setClients(data.list);
          //   setPagination((prev) => ({
          //     ...prev,
          //     current: pagination.current,
          //     total: total,
          //   }));
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error, Severty.ERROR);
      },
    });

    request({
      url: apiPath.list_public,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");
          setAssets(data.list);
          //   setPagination((prev) => ({
          //     ...prev,
          //     current: pagination.current,
          //     total: total,
          //   }));
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onCreate = (values) => {
    const payload = {
      ...values,
    };
    console.log(payload);
    // payload.mobile_number = mobileNumber.mobile_number;
    // payload.country_code = mobileNumber.country_code;

    setLoading(true);
    request({
      url: `${params.id ? api.edit + "/" + params.id : api.add}`,
      method: params.id ? "PUT" : "POST",
      data: payload,

      onSuccess: (data) => {
        setLoading(false);
        if (data?.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate(-1);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    setPageHeading(heading);
    fetchData();
  }, [setPageHeading]);

  useEffect(() => {
    if (!params.id) return;
    fetchTask(params.id);
  }, [params.id]);

  const fetchTask = (id) => {
    request({
      url: `${api.singleTask + "/" + id}`,
      method: "GET",
      onSuccess: (response) => {
        const data = response.data;
        console.log(data);

        setFormData({
          // notes: data.notes,
          // purpose: data.purpose,
          assetId: data.assetId,
          clientId: data.clientId,
          description: data.description,
          task_name: data.task_name,
          title: data.title,
          date_of_entry: moment(data?.date_of_entry),
        });
        form.setFieldsValue({
          // notes: data.notes,
          // purpose: data.purpose,
          assetId: data.assetId,
          clientId: data.clientId,
          description: data.description,
          task_name: data.task_name,
          title: data.title,
          date_of_entry: moment(data?.date_of_entry),
        });
      },
    });
  };

  return (
    <>
      <Card title={sectionName}>
        <Form
          className="edit-page-wrap"
          form={form}
          onFinish={onCreate}
          autoComplete="off"
          layout="vertical"
          name="asset_form"
        >
          <Row gutter={[24, 0]}>
            <Col span={24} sm={8}>
              <Form.Item
                label={lang("Title")}
                name="title"
                rules={[{ required: true, message: lang("Please enter the Title") }]}
              >
                <Select placeholder={lang("Select Title")}>
                  <Option value="task">{lang("Task")}</Option>
                  <Option value="meet">{lang("Meet")}</Option>
                  <Option value="reminder">{lang("Reminder")}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} sm={8}>
              <Form.Item
                label={lang("Task Description")}
                name="description"
              >
                <Input.TextArea placeholder={lang("Enter Task Description")} />
              </Form.Item>
            </Col>
            <Col span={24} sm={8}>
              <Form.Item label={lang("Clients")} name="clientId">
                <Select placeholder={lang("Select client")}>
                  {clients?.map((client) => (
                    <Option key={client._id} value={client._id}>
                      {client.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} sm={8}>
              <Form.Item label={lang("Assets")} name="assetId">
                <Select placeholder={lang("Select Assets")}>
                  {assets?.map((asset) => (
                    <Option key={asset._id} value={asset._id}>
                      {asset.property_title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} sm={8}>
              <Form.Item
                label={lang("Due Date*")}
                name="date_of_entry"
                rules={[{ required: true, message: lang("Please select the date of entry In Date format YYYY-MM-DD") }]}
              >
                <DatePicker format="YYYY-MM-DD" placeholder={lang("Select Date")} />

              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="btn-row float-right">
            <Button
              type="primary"
              onClick={() => navigate(-1)}
              className="back-btns"
            >
              {lang('Back')}
            </Button>
            <Button
              type="primary"
              loading={loading}
              htmlType="submit"
              className="submit-btns"
            >
              {lang('Submit')}

            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default CreateTask;
