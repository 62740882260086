import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const center = {
  lat: -3.745,
  lng: -38.523,
};

function Asset({ style, location, setLocation }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCrfZW4Yn9i6Fk9zq_YEsCwW-sbdXyVCzs",
    libraries: ["places"],
    mapIds: "google-map-script",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(location);
    // map.fitBounds(bounds);
    //   const placeAutocomplete = new window.google.maps.places.PlaceAutocompleteElement();
    // document.body.appendChild(placeAutocomplete);
    // const selectedPlaceTitle = document.createElement("p");
    // selectedPlaceTitle.textContent = "";
    // document.body.appendChild(selectedPlaceTitle);
    // const selectedPlaceInfo = document.createElement("pre");
    // selectedPlaceInfo.textContent = "";
    // document.body.appendChild(selectedPlaceInfo);
    // placeAutocomplete.addEventListener("gmp-placeselect", async ({ place }) => {
    //   await place.fetchFields({
    //     fields: ["displayName", "formattedAddress", "location"],
    //   });
    //   selectedPlaceTitle.textContent = "Selected Place:";
    //   selectedPlaceInfo.textContent = JSON.stringify(
    //     place.toJSON(),
    //     null,
    //    2,
    //   );
    // });

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={style}
      id="google-map-script"
      center={location}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        draggable
        position={location}
        onDragEnd={(e) =>
          setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() })
        }
      />
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Asset);
