import React, { useEffect, useState } from "react";

import { Avatar, List, Skeleton } from "antd";
import moment from "moment";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import InfiniteScroll from "react-infinite-scroll-component";
import lang from "../../helper/langHelper";
import { dateString } from "../../helper/functions";

export const NotificationList = () => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
  });

  const { request } = useRequest();

  const dates = {
    today: moment(new Date()).format("YYYY-MM-DD"),
    yesterday: moment(new Date().getTime() - 24 * 60 * 60 * 1000).format(
      "YYYY-MM-DD"
    ),
  };

  const fetchData = () => {
    request({
      url:
        apiPath.notification +
        `/listBroker?status=${""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination && pagination?.total ? pagination?.total : 10
        }&search=${""}`,
      method: "GET",
      onSuccess: (data) => {
        setList(data.data.docs);
        setLoading(false);
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  };

  const onNext = ({}) => {
    console.log("Call huaa next");
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [pagination]);

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <InfiniteScroll
            dataLength={list.length} //This is important field to render the next data
            next={onNext}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {/* {list.map((data, index) => {
              console.log(data, "datadatadata");
              return ( */}
            <div className="notification-card">
              <List
                key={"groupItem"}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item?.broker?.image} />}
                      description={item.message}
                    />
                    <div>{dateString(item.created_at, "hh:mm a")}</div>
                  </List.Item>
                )}
              />
            </div>

            {/* );
            })} */}
          </InfiniteScroll>
        </>
      )}
    </>
  );
};
