import { UndoOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Select, Tabs, DatePicker } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { Months } from "../../constants/var";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import BannerRevenue from "./charts/BannerRevenue";
import GrowthOrder from "./charts/GrowthOrder";
import GrowthUser from "./charts/GrowthUser";
import RevenueTrends from "./charts/RevenueTrends";
import TotalRevenue from "./charts/TotalRevenue";
import WalletAmount from "./charts/WalletAmount";
import moment from "moment";
import DiscountRevenue from "./charts/DiscountRevenue";
import DiscountPerformance from "./charts/DiscountPerformance";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function Index() {
  const heading = lang("Reports");
  const { setPageHeading } = useContext(AppStateContext);

  const [selectedTab, setSelectedTab] = useState("FinancialDashboard");

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <RestaurantList />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const RestaurantList = () => {
  const heading = lang("Reports");
  const { setPageHeading, country } = useContext(AppStateContext);

  const api = {
    status: apiPath.restaurant + "/status",
    list: apiPath.restaurant,
  };

  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    end_date: undefined,
    year: undefined,
    start_date: undefined,
  });
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getFilter();
    setFilter((prev) => ({ ...prev, country_id: country.country_id }));
  }, [country.country_id]);

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <SectionWrapper cardHeading={lang(`Reports `)}>
        <div>
          <Row className="mt-3 p-3" gutter={[24, 0]}>
            {/* <TotalRevenue filter={filter} />

            <RevenueTrends filter={filter} />
            <DiscountRevenue filter={filter} />

            <WalletAmount filter={filter} /> 

            <BannerRevenue filter={filter} />*/}

            <DiscountPerformance />
          </Row>
        </div>
      </SectionWrapper>
    </>
  );
};

export default Index;
