import { Card, Col, Row, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { AppStateContext } from "../../context/AppContext";
import useRequest from "../../hooks/useRequest";
import { NotificationList } from "./_NotificationList";
import PushNotificationManager from "./_PushNotificationManager";
import lang from "../../helper/langHelper";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";

const { TabPane } = Tabs;

const notificationTabs = {
  NOTIFICATIONS: lang("Notifications"),
  PUSH: lang("Push Notifications"),
};

function Index() {
  const { setPageHeading } = useContext(AppStateContext);

  const { request } = useRequest();

  const [selectedTab, setSelectedTab] = useState(
    notificationTabs.NOTIFICATIONS
  );

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  useEffect(() => {
    setPageHeading(lang("Notifications"));
    fetchData({ ...pagination, current: 1 });
  }, []);

  const fetchData = (pagination, filters) => {
    request({
      url: apiPath.notification,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        console.log(data);
        if (status) {
          const listData = data?.map((item) => ({
            ...item,
            key: item._id,
          }));
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
          console.log(listData);
          setList(listData);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col span={24} md={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane
                  tab={notificationTabs.NOTIFICATIONS}
                  key={notificationTabs.NOTIFICATIONS}
                >
                  <div
                    className="notification-main-wrap"
                    style={{ padding: "16px" }}
                  >
                    <NotificationList />
                  </div>
                </TabPane>

                {/* <TabPane
                  tab={notificationTabs.PUSH}
                  key={notificationTabs.PUSH}
                >
                  <PushNotificationManager />
                </TabPane> */}
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
