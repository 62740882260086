import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Col,
  Row,
  Tabs,
  Card,
  Tag,
  Input,
  Switch,
  Menu,
  Form,
  Select,
  Dropdown,
  Drawer,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { gapi } from "gapi-script";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ConfirmationBox from "../../components/ConfirmationBox";
import apiPath from "../../constants/apiPath";
import { useNavigate } from "react-router";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  EllipsisOutlined
} from "@ant-design/icons";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import DeleteModal from "../../components/DeleteModal";
import AddForm from "./AddForm";
import TextArea from "antd/lib/input/TextArea";
import "moment/locale/he";
moment.locale("he");
const localizer = momentLocalizer(moment);
const { TabPane } = Tabs;
const { Meta } = Card;

const CLIENT_ID = '851995849220-4uumi28nshm74mpeu8ikuvr8agjbr4m7.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCcxjCL2KFRtsZWeM7zqpOkXsmP-YSt4NY';
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

const filterst = [
  {
    text: lang("incomplete"),
    value: "Incomplete",
  },
  {
    text: lang("complete"),
    value: "Complete",
  },
  {
    text: lang("ongoing"),
    value: "OnGoing",
  },
];

function Index() {
  const heading = lang("My Task");
  const { setPageHeading } = useContext(AppStateContext);
  const sectionName = lang("Task");
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [listTrue, setListTrue] = useState(true);
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [deleteModal, showDeleteModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSetNote, setShowSetNote] = useState(false);
  const [showMultiNote, setShowMultiNote] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [logs, showLogs] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const year = urlParams.get("year");
  const { confirm } = Modal;
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
  });

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(lang('Incomplete'));
  const [filterType, setFilterType] = useState(null);
  const [months, setMonths] = useState([]);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [years, setYears] = useState([]);
  const [value, setValue] = useState([]);

  const [tasks, setTasks] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const debouncedSearchFilter = useDebounce(selectedFilter, 300);
  const [incompleteCount, setIncompleteCount] = useState([]);
  const [onGoingCount, setOnGoingCount] = useState([]);
  const [completeCount, setCompleteCount] = useState([]);
  const [notesVal, setNotesVal] = useState("");
  const [multiNotesVal, setMultiNotesVal] = useState("");
  const [addFiltersVisible, setAddFiltersVisible] = useState(false);



  const api = {
    // status: apiPath.statusChange,
    // add: apiPath.addEditClient,
    list: apiPath.taskList,
    ChangeStatus: apiPath.changetaskstatus,
    delete: apiPath.deleteTask,
    editNote: apiPath.editNote,
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("userProfile"));
    request({
      url: `/broker/task/list`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data.docs);
        let list = [];
        for (let ele of data.data.docs) {
          list.push({
            ...ele,
            start: new Date(moment(ele.date_of_entry).format("yyyy, MM, DD")),
            end: new Date(moment(ele.date_of_entry).format("yyyy, MM, DD")),
          });
        }
        console.log("list", list);
        setEvents(list);
      },
      onError: (error) => {
        // ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, startDate, endDate, debouncedSearchText, debouncedSearchFilter]);

  const handleNoteChange = (e) => {
    setNotesVal(e.target.value);
  };
  const handleMultiNoteChange = (e) => {
    setMultiNotesVal(e.target.value);
  };
  const editDataCompleted = (type, recordId) => {
    setLoading(true);
    const payload = {};
    payload.notes = notesVal;
    request({
      url: api.ChangeStatus + "/" + type + "/" + recordId,
      method: "PUT",
      data: payload,
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);

        ShowToast("Note Of Completion Added Successfully", Severty.SUCCESS);
        setShowMultiNote(false);
        setSelected({ recordId: null });

        if (status) {
          fetchData({ ...pagination, current: 1 });
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const addNote = (recordId) => {
    setLoading(true);
    const payload = {};
    payload.notes_multi = multiNotesVal;
    request({
      url: api.editNote + "/" + recordId,
      method: "PUT",
      data: payload,
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);

        ShowToast("Note Added Successfully", Severty.SUCCESS);
        setShowMultiNote(false);
        setSelected({ recordId: null });
        if (status) {
          fetchData({ ...pagination, current: 1 });
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const handleChangeisStatus = (type, id) => {
    setLoading(true);

    request({
      url: api.ChangeStatus + "/" + type + "/" + id,
      method: "PUT",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);

        ShowToast("Status Changed", Severty.SUCCESS);

        if (status) {
          fetchData({ ...pagination, current: 1 });
          // setPagination((prev) => ({
          //     ...prev,
          //     current: pagination.current,
          //     total: total,
          // }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const menu06 = (item) => (
    <Menu className="menu06-sub-menu">
      <Menu.Item key="edit">
        <Button
          title={lang("Edit")}
          className="edit-cls btnStyle primary_btn"
          onClick={(e) => {
            navigate(`/edit-task/${item._id}`);
            setSelected({ recordId: item._id });
            setVisible(true);
          }}
        >
          <EditOutlined />
        </Button>
      </Menu.Item>
      <Menu.Item key="delete">
        <Button
          title={lang("Delete")}
          className="btnStyle deleteDangerbtn"
          onClick={() => {
            setSelected({ recordId: item._id });
            setShowDelete(true);
          }}
        >
          <DeleteOutlined />
        </Button>
      </Menu.Item>
    </Menu>
  );
  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.asset_status : null;

    request({
      url:
        api.list +
        `?search=${debouncedSearchText}&status=${filterActive ? filterActive.join(",") : ""
        }&page=${pagination ? pagination.current : 1}&pageSize=${pagination ? pagination.pageSize : 10
        }&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""
        }&debouncedSearchFilter=${JSON.stringify(debouncedSearchFilter)}`,
      method: "GET",
      onSuccess: ({
        data,
        incompleteCount,
        onGoingCount,
        completeCount,
        status,
        total,
        message,
      }) => {
        setLoading(false);
        setIncompleteCount(incompleteCount);
        setList(data.docs);
        setOnGoingCount(onGoingCount);
        setCompleteCount(completeCount);
        if (status) {
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onDelete = (id) => {
    if (!id) {
      console.error("No record ID provided for deletion");
      return;
    }
    console.log(id);
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const onChangeList = (e) => {
    setListTrue(e);
  };
  const onSearch = (e) => {
    setSearchText(e.target.value);
  };



  const [open, setOpen] = useState(false);
  const showDrawer = (due_date) => {
    setOpen(true);
    console.log("props.values", due_date);
    // setValue(...list.filter((el) => el._id === id));
    let dateToCompare = new Date(due_date);
    let doc = list.filter((item) => {
      const itemDate = new Date(item.date_of_entry);
      return (
        itemDate.getDate() === dateToCompare.getDate() &&
        itemDate.getMonth() === dateToCompare.getMonth() &&
        itemDate.getFullYear() === dateToCompare.getFullYear()
      );
    });
    setValue(doc);
  };

  const onClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
        scope: SCOPES,
      });
    };

    gapi.load("client:auth2", initClient);
  }, []);

  const handleAuthClick = () => {
    gapi.auth2.getAuthInstance().signIn().then(() => {
      console.log("Signed in successfully");
      addTasksToCalendar();
    });
  };
  const addTasksToCalendar = () => {
    const calendar = gapi.client.calendar;
    console.log(events, "events");

    let eventCreationPromises = [];

    events.forEach((task) => {
      const event = {
        summary: task.title,
        start: {
          dateTime: task.date_of_entry,
        },
        end: {
          dateTime: task.date_of_entry,
        },
      };

      // Push each calendar event insertion as a promise to the array
      const eventPromise = calendar.events
        .insert({
          calendarId: "primary",
          resource: event,
        })
        .then((response) => {
          console.log(`Event created: ${response.result.htmlLink}`);
        })
        .catch((err) => {
          console.error("Error creating event", err);
        });

      eventCreationPromises.push(eventPromise);
    });

    // Wait for all events to be added, then redirect to Google Calendar
    Promise.all(eventCreationPromises)
      .then(() => {
        console.log("All events added to calendar!");
        // Redirect user to Google Calendar's web view
        window.open("https://calendar.google.com", "_blank");
      })
      .catch((err) => {
        console.error("Error while adding events or redirecting to calendar", err);
      });
  };
  return (
    <>
      <Row>
        <div className="main-hh-headding">
          <div className="headding-cc">{lang('My Task')}</div>
          <Breadcrumb className="bread-menu">
            <Breadcrumb.Item className="bread-items" href="#">
              {lang('dashboard')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="bread-items" href="#">
              {sectionName}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Row>
      <Card>
        <div className="main-header-meeting">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24}>
              <div className="w-100 d-flex align-items-baseline text-head_right_cont">
                <div className="pageHeadingSearch clint-input ">
                  <Input.Search
                    className="searchInput"
                    placeholder={lang("Search by Task Name")}
                    value={searchText}
                    onChange={onSearch}
                    allowClear
                  />
                </div>

                <Button
                  onClick={() => {
                    setFilter({
                      country_id: undefined,
                      city_id: undefined,
                      year: undefined,
                      month: undefined,
                    });
                    setSearchText("");
                  }}
                  type="primary"
                  icon={<RestartAltOutlinedIcon />}
                  className="edit-cls"
                >
                  {/* {lang("Reset")} */}
                </Button>
                <div className="lead-clint-drop">
                  <Switch
                    checkedChildren={lang("Card")}
                    unCheckedChildren={lang("Calendar")}
                    checked={listTrue}
                    onClick={(listTrue) => onChangeList(listTrue)}
                    className="main-mecu-switch"
                  />
                </div>
                <div className="lg-adddesktop">
                  <Button
                    className="primary_btn btnStyle"
                    onClick={(e) => {
                      console.log(e);
                      navigate(`/create-task`);
                      setVisible(true);
                      setSearchText("");
                    }}
                  >
                    <span className="add-Ic ">
                      <AddCircleOutlineOutlinedIcon />
                    </span>
                    {lang("")} {sectionName}
                  </Button>
                </div>

                <div className="lg-adddesktop">
                  <Button
                    className="primary_btn btnStyle"
                    onClick={handleAuthClick}
                  >

                    {lang('Google Calendar')}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {visible && (
            <AddForm
              section={sectionName}
              api={api}
              show={visible}
              hide={() => {
                setSelected();
                setVisible(false);
              }}
              data={selected}
              refresh={() => setRefresh((prev) => !prev)}
            />
          )}

          {!listTrue ? (
            <>
              <div className="main-second-navtab-hjkl99">
                <Row gutter={[24, 24]} className="mx-not-complete-jjjh">
                  <Col span={24} md={8} sm={8}>
                    <div className="pending-task">
                      <div className="text-danger dostmainpending-task"></div>
                      <p> {lang("Not Completed")}</p>
                      <h4 className="text-danger">{incompleteCount}</h4>
                    </div>
                  </Col>
                  <Col span={24} md={8} sm={8}>
                    <div className="pending-task">
                      <div className=" text-primary dostmainpending-task"></div>
                      <p> {lang("Partially Completed")}</p>
                      <h4 className=" text-primary">{onGoingCount}</h4>
                    </div>
                  </Col>
                  <Col span={24} md={8} sm={8}>
                    <div className="pending-task">
                      <div className=" text-success dostmainpending-task"></div>
                      <p> {lang("Completed")}</p>
                      <h4 className=" text-success">{completeCount}</h4>
                    </div>
                  </Col>
                </Row>
                <Row loading={loading} gutter={[24, 24]}>
                  <Col span={24} md={8}>
                    <Card className="main-second-card-77">
                      {list.length > 0 ? (
                        list?.map(
                          (item) =>
                            item.is_status === "Incomplete" && (
                              <Card
                                className="responsive-mobile-task-card"
                                bordered={false}
                                onClick={() => showDrawer(item.date_of_entry)}
                              >
                                <Tag
                                  color="orange"
                                  className="responsive-mobile-task-tag"
                                >
                                  {item.title}
                                </Tag>
                                <Meta
                                  title={item.task_name}
                                  description={item.description}
                                />

                                <div className="responsive-mobile-task-footer">
                                  <div className="responsive-mobile-avatars">
                                    <div className="invite-actions">
                                      <button
                                        className="invite-button join-button text-success"
                                        onClick={() =>
                                          handleChangeisStatus(
                                            "OnGoing",
                                            item._id
                                          )
                                        }
                                      >
                                        {lang('JOIN')}
                                      </button>

                                      <button
                                        className="invite-button later-button text-warning"
                                        onClick={() => {
                                          setSelected({ recordId: item._id });
                                          setShowSetNote(true);
                                        }}
                                      >
                                        {lang("COMPLETE")}
                                      </button>

                                      <button
                                        className="invite-button later-button text-primary"
                                        onClick={() => {
                                          setSelected({ recordId: item._id });
                                          setShowMultiNote(true);
                                        }}
                                      >
                                        {lang('ADD NOTE')}
                                      </button>

                                      <Dropdown
                                        overlay={menu06(item)}
                                        className="maintherrdropdown-hh"
                                      >
                                        <Button>
                                          <EllipsisOutlined />
                                        </Button>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            )
                        )
                      ) : (
                        <div className="main-no-data-form">{lang("No Data Here")}</div>
                      )}
                    </Card>
                  </Col>

                  <Drawer
                    title={`${lang("Task Details")}`}
                    placement="right"
                    onClose={onClose}
                    open={open}
                    className="main-calender-main-drower"
                  >
                    {value &&
                      value?.map((el, id) => {
                        return (
                          <>
                            <div className="mb-3" key={id}>
                              <div className="main-edit-my-task-button">
                                <Button
                                  onClick={(e) => {
                                    navigate(`/edit-task/${el._id}`);
                                    setSelected({ recordId: el._id });
                                    setVisible(true);
                                  }}
                                >
                                  <EditOutlined />
                                </Button>
                                <Button
                                  onClick={() => {
                                    setSelected({ recordId: el._id });
                                    setShowDelete(true);
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </div>
                              <div className="main-drower-table">
                                <h4>{lang("Task Id")}:</h4> <h5>{el && el.TaskId}</h5>
                              </div>
                              <div className="main-drower-table">
                                <h4>{lang("Task Title")} :</h4> <h5>{el && el.title}</h5>
                              </div>
                              {/* <div className="main-drower-table">
                    <h4> Purpose:</h4> <h5>{el && el.purpose}</h5>
                  </div> */}
                              {/* <div>
                  Create At :{" "}
                  <h5>{el && moment(el.created_at).format("DD/MM/YYYY")}</h5>
                </div> */}
                              <div className="main-drower-table">
                                <h4> {lang("Status")}: </h4>
                                <h5>{el ? el.is_status : "N/A"}</h5>
                              </div>
                              <div className="main-drower-table">
                                <h4> {lang("Client")} :</h4>
                                <h5>
                                  {el && el.clientId ? (
                                    <a
                                      href={`client-detail/${el.clientId._id}`}
                                    >
                                      {el.clientId.name}
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </h5>
                              </div>
                              <div className="main-drower-table">
                                <h4>{lang("Assest")} :</h4>
                                <h5>
                                  {el && el.assetId ? (
                                    <a href={`view-asset/${el.assetId._id}`}>
                                      {el.assetId.property_title}
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </h5>
                              </div>
                              <div className="main-drower-table">
                                <h4>{lang("Notes")} :</h4>{" "}
                                <h5>{el ? el.notes : "N/A"}</h5>
                              </div>
                              <div className="main-drower-table">
                                <h4>{lang("Due Date")}:</h4>
                                <h5>
                                  {el &&
                                    moment(el.date_of_entry).format(
                                      "DD/MM/YYYY"
                                    )}
                                </h5>
                              </div>
                            </div>
                            <div>
                              <table
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                  marginTop: "20px",
                                }}
                              >
                                <thead>
                                  <tr
                                    style={{
                                      backgroundColor: "rgb(24 114 245)",
                                    }}
                                  >
                                    <th
                                      style={{
                                        padding: "10px",
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      {lang("Date & Time")}
                                    </th>
                                    <th
                                      style={{
                                        padding: "10px",
                                        border: "1px solid #ddd",
                                        color: "white",
                                      }}
                                    >
                                      {lang("Notes")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {el.notes_multi?.map((item, index) => (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          padding: "10px",
                                          border: "1px solid #ddd",
                                        }}
                                      >
                                        {el.notes_date
                                          ? new Date(
                                            el.notes_date[index]
                                          ).toLocaleDateString("sv-SE", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                          })
                                          : ""}
                                      </td>
                                      <td
                                        style={{
                                          padding: "10px",
                                          border: "1px solid #ddd",
                                        }}
                                      >
                                        {item}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        );
                      })}
                  </Drawer>

                  <Col span={24} md={8}>
                    <Card className="main-second-card-77">
                      {list.length > 0 ? (
                        list?.map(
                          (item) =>
                            item.is_status === "OnGoing" && (
                              <Card
                                className="responsive-mobile-task-card"
                                bordered={false}
                                onClick={() => showDrawer(item.date_of_entry)}
                              >
                                <Tag
                                  color="orange"
                                  className="responsive-mobile-task-tag"
                                >
                                  {item.title}
                                </Tag>
                                <Meta
                                  title={item.task_name}
                                  description={item.description}
                                />

                                <div className="responsive-mobile-task-footer">
                                  <div className="responsive-mobile-avatars">
                                    <div className="invite-actions">
                                      <button
                                        className="invite-button join-button text-danger"
                                        onClick={() =>
                                          handleChangeisStatus(
                                            "Incomplete",
                                            item._id
                                          )
                                        }
                                      >
                                        {lang('REJECT')}
                                      </button>
                                      <button
                                        className="invite-button later-button text-warning"
                                        // onClick={() =>
                                        //   handleChangeisStatus("Complete", item._id)
                                        // }
                                        onClick={() => {
                                          setSelected({ recordId: item._id });
                                          setShowSetNote(true);
                                        }}
                                      >
                                        {lang('COMPLETE')}
                                      </button>

                                      <button
                                        className="invite-button later-button text-primary"
                                        onClick={() => {
                                          setSelected({ recordId: item._id });
                                          setShowMultiNote(true);
                                        }}
                                      >
                                        {lang('ADD NOTE')}
                                      </button>

                                      <Dropdown
                                        overlay={menu06(item)}
                                        className="maintherrdropdown-hh"
                                      >
                                        <Button>
                                          <EllipsisOutlined />
                                        </Button>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            )
                        )
                      ) : (
                        <div className="main-no-data-form">{lang("No Data Here")}</div>
                      )}
                    </Card>
                  </Col>
                  <Col span={24} md={8}>
                    {" "}
                    <Card className="main-second-card-77">
                      {list.length > 0 ? (
                        list?.map(
                          (item) =>
                            item.is_status === "Complete" && (
                              <Card
                                className="responsive-mobile-task-card"
                                bordered={false}
                              >
                                <Tag
                                  color="orange"
                                  className="responsive-mobile-task-tag"
                                >
                                  {item.title}
                                </Tag>
                                <Meta
                                  title={item.task_name}
                                  description={
                                    <>
                                      {item.description}
                                      <br />
                                      {lang("Completed On") + " :" +
                                        new Date(
                                          item.date_of_completion
                                        ).toLocaleDateString("sv-SE")}
                                      <br />
                                      {lang("Note of Completion") + " :" + item.notes}
                                    </>
                                  }
                                />

                                <div className="responsive-mobile-task-footer">
                                  <div className="responsive-mobile-avatars">
                                    <div className="invite-actions"></div>
                                  </div>
                                </div>
                              </Card>
                            )
                        )
                      ) : (
                        <div className="main-no-data-form">{lang("No Data Here")}</div>
                      )}
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <Cal values={events} />
          )}
        </div>
        <div className="mb-add-mobile">
          <Button
            className="add-inc-btn"
            onClick={(e) => {
              console.log(e);
              navigate(`/create-task`);
              setVisible(true);
              setSearchText("");
            }}
          >
            <AddCircleOutlineOutlinedIcon />
          </Button>
        </div>

        <div className="main-secondory-tabs-neww-56">
          <Tabs defaultActiveKey="1" className="order-tabs-main">
            <TabPane
              className="order-btn-tab"
              tab={
                <div className="pending-task">
                  <div className="text-danger dostmainpending-task"></div>
                  <p>{lang('Not Completed')}</p>
                  <h4 className="text-danger">{incompleteCount}</h4>
                </div>
              }
              key="1"
            >
              <Col span={24} md={8}>
                <Card className="main-second-card-77">
                  {list.length > 0 ? (
                    list?.map(
                      (item) =>
                        item.is_status === "Incomplete" && (
                          <Card
                            className="responsive-mobile-task-card"
                            bordered={false}
                            onClick={() => showDrawer(item.date_of_entry)}
                          >
                            <Tag
                              color="orange"
                              className="responsive-mobile-task-tag"
                            >
                              {item.title}
                            </Tag>
                            <Meta
                              title={item.task_name}
                              description={item.description}
                            />

                            <div className="responsive-mobile-task-footer">
                              <div className="responsive-mobile-avatars">
                                <div className="invite-actions">
                                  <button
                                    className="invite-button join-button text-success"
                                    onClick={() =>
                                      handleChangeisStatus("OnGoing", item._id)
                                    }
                                  >
                                    {lang('JOIN')}
                                  </button>

                                  <button
                                    className="invite-button later-button text-warning"
                                    onClick={() => {
                                      setSelected({ recordId: item._id });
                                      setShowSetNote(true);
                                    }}
                                  >
                                    {lang('COMPLETE')}
                                  </button>

                                  <button
                                    className="invite-button later-button text-primary"
                                    onClick={() => {
                                      setSelected({ recordId: item._id });
                                      setShowMultiNote(true);
                                    }}
                                  >
                                    {lang('ADD NOTE')}
                                  </button>

                                  <Dropdown
                                    overlay={menu06(item)}
                                    className="maintherrdropdown-hh"
                                  >
                                    <Button>
                                      <EllipsisOutlined />
                                    </Button>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </Card>
                        )
                    )
                  ) : (
                    <div className="main-no-data-form">{lang("No Data Here")}</div>
                  )}
                </Card>
              </Col>
            </TabPane>

            <TabPane
              className="order-btn-tab"
              tab={
                <div className="pending-task">
                  <div className=" text-primary dostmainpending-task"></div>
                  <p>{lang('Partially Completed')}</p>
                  <h4 className=" text-primary">{onGoingCount}</h4>
                </div>
              }
              key="2"
            >
              <Col span={24} md={8}>
                <Card className="main-second-card-77">
                  {list.length > 0 ? (
                    list?.map(
                      (item) =>
                        item.is_status === "OnGoing" && (
                          <Card
                            className="responsive-mobile-task-card"
                            bordered={false}
                            onClick={() => showDrawer(item.date_of_entry)}
                          >
                            <Tag
                              color="orange"
                              className="responsive-mobile-task-tag"
                            >
                              {item.title}
                            </Tag>
                            <Meta
                              title={item.task_name}
                              description={item.description}
                            />

                            <div className="responsive-mobile-task-footer">
                              <div className="responsive-mobile-avatars">
                                <div className="invite-actions">
                                  <button
                                    className="invite-button join-button text-danger"
                                    onClick={() =>
                                      handleChangeisStatus(
                                        "Incomplete",
                                        item._id
                                      )
                                    }
                                  >
                                    {lang('REJECT')}
                                  </button>
                                  <button
                                    className="invite-button later-button text-warning"
                                    // onClick={() =>
                                    //   handleChangeisStatus("Complete", item._id)
                                    // }
                                    onClick={() => {
                                      setSelected({ recordId: item._id });
                                      setShowSetNote(true);
                                    }}
                                  >
                                    {lang('COMPLETE')}

                                  </button>

                                  <button
                                    className="invite-button later-button text-primary"
                                    onClick={() => {
                                      setSelected({ recordId: item._id });
                                      setShowMultiNote(true);
                                    }}
                                  >
                                    {lang('ADD NOTE')}
                                  </button>

                                  <Dropdown
                                    overlay={menu06(item)}
                                    className="maintherrdropdown-hh"
                                  >
                                    <Button>
                                      <EllipsisOutlined />
                                    </Button>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </Card>
                        )
                    )
                  ) : (
                    <div className="main-no-data-form">{lang('No Data Here')}</div>
                  )}
                </Card>
              </Col>
            </TabPane>

            <TabPane
              className="order-btn-tab"
              tab={
                <div className="pending-task">
                  <div className=" text-success dostmainpending-task"></div>
                  <p>{lang('Completed')}</p>
                  <h4 className=" text-success">{completeCount}</h4>
                </div>
              }
              key="3"
            >
              <Col span={24} md={8}>
                {" "}
                <Card className="main-second-card-77">
                  {list.length > 0 ? (
                    list?.map(
                      (item) =>
                        item.is_status === "Complete" && (
                          <Card
                            className="responsive-mobile-task-card"
                            bordered={false}
                          >
                            <Tag
                              color="orange"
                              className="responsive-mobile-task-tag"
                            >
                              {item.title}
                            </Tag>
                            <Meta
                              title={item.task_name}
                              description={
                                <>
                                  {item.description}
                                  <br />
                                  {"Completed On : " +
                                    new Date(
                                      item.date_of_completion
                                    ).toLocaleDateString("sv-SE")}
                                  <br />
                                  {"Note of Completion : " + item.notes}
                                </>
                              }
                            />

                            <div className="responsive-mobile-task-footer">
                              <div className="responsive-mobile-avatars">
                                <div className="invite-actions"></div>
                              </div>
                            </div>
                          </Card>
                        )
                    )
                  ) : (
                    <div className="main-no-data-form">{lang("No Data Here")}</div>
                  )}
                </Card>
              </Col>
            </TabPane>
          </Tabs>
        </div>
      </Card>
      {showDelete && (
        <DeleteModal
          title={lang("Delete Task")}
          subtitle={lang("Are you sure you want to Delete this Task?")}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected({ recordId: null });
          }}
          onOk={() => onDelete(selected.recordId)}
        />
      )}
      {showMultiNote && (
        <Modal
          width={700}
          open={showMultiNote}
          onOk={(value) => {
            // editDataCompleted("Complete", selected.recordId);
            addNote(selected.recordId);
          }}
          okText={lang("Ok")}
          cancelText={lang("Cancel")}
          onCancel={() => {
            setShowMultiNote(false);
            setSelected({ recordId: null });
          }}
          centered
          className="tab_modal deleteWarningModal"
        >
          <Form layout="vertical" className="p-2 for-forgottt">
            <div
              style={{
                margin: "auto",
                width: 100,
                fontSize: 80,
                color: "red",
                textAlign: "center",
              }}
            ></div>
            <h4 className="modal_title_cls mb-2"> {lang('ADD NOTE')}</h4>
            <Form.Item>
              <TextArea
                autoComplete="off"
                name="notes_multi"
                onChange={handleMultiNoteChange}
                placeholder={lang(`Note`)}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {showSetNote && (
        <Modal
          width={700}
          open={showSetNote}
          onOk={(value) => {
            editDataCompleted("Complete", selected.recordId);
          }}
          okText={lang("Ok")}
          cancelText={lang("Cancel")}
          onCancel={() => {
            setShowSetNote(false);
            setSelected({ recordId: null });
          }}
          centered
          className="tab_modal deleteWarningModal"
        >
          <Form layout="vertical" className="p-2 for-forgottt">
            <div
              style={{
                margin: "auto",
                width: 100,
                fontSize: 80,
                color: "red",
                textAlign: "center",
              }}
            ></div>
            <h4 className="modal_title_cls mb-2">
              {lang('Add Note Of Completion and Complete The Asset')}
            </h4>
            <Form.Item>
              <TextArea
                autoComplete="off"
                name="notes"
                onChange={handleNoteChange}
                placeholder={lang(`Note Of Completion`)}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}

function Cal(props) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const { request } = useRequest();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const api = {
    list: apiPath.taskList,
    ChangeStatus: apiPath.changetaskstatus,
    delete: apiPath.deleteTask,
  };
  const onDelete = (id) => {
    if (!id) {
      console.error("No record ID provided for deletion");
      return;
    }
    console.log(id);
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const showDrawer = (due_date) => {
    setOpen(true);
    console.log("props.values", props.values[0]);
    // setValue(...props.values.filter((el) => el._id === id));
    let dateToCompare = new Date(due_date);
    let doc = props.values.filter((item) => {
      const itemDate = new Date(item.date_of_entry);
      return (
        itemDate.getDate() === dateToCompare.getDate() &&
        itemDate.getMonth() === dateToCompare.getMonth() &&
        itemDate.getFullYear() === dateToCompare.getFullYear()
      );
    });
    setValue(doc);
  };

  const onClose = () => {
    setOpen(false);
  };

  const EventComponent = ({ event }) => (
    <div
      className="rbc-event-rbc-selected"
      onClick={() => {
        showDrawer(event.date_of_entry);
      }}
    >
      <h5>{event.task_name}</h5>
    </div>
  );


  const messages = {
    today: lang("today"),
    previous: lang("previous"),
    next: lang("next"),
    month: lang("month"),
    week: lang("week"),
    day: lang("day"),
    agenda: lang("agenda"),
    date: lang("date"),
    time: lang("time"),
    event: lang("event"),
    noEventsInRange: lang("noEventsInRange"),
    showMore: (count) => `${lang("showMore")} (${count})`,
  };

  return (
    <div>
      <div className="calendar-container">
        <Calendar
          localizer={localizer}
          events={props.values}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 800, width: "100%" }}
          components={{
            event: EventComponent,
          }}
          messages={messages}
        />
      </div>
      <div>
        <Drawer
          title="Task Details"
          placement="right"
          onClose={onClose}
          open={open}
          width={320}
          className="main-calender-main-drower"
        >
          {value &&
            value?.map((el, id) => {
              return (
                <>
                  <div className="mb-3" key={id}>
                    {el.is_status !== "Complete" && (
                      <div className="main-edit-my-task-button">
                        <Button
                          onClick={(e) => {
                            navigate(`/edit-task/${el._id}`);
                            setSelected({ recordId: el._id });
                            setVisible(true);
                          }}
                        >
                          <EditOutlined />
                        </Button>
                        <Button
                          onClick={() => {
                            setSelected({ recordId: el._id });
                            setShowDelete(true);
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </div>
                    )}
                    <div className="main-drower-table">
                      <h4>Task Id:</h4> <h5>{el && el.TaskId}</h5>
                    </div>
                    <div className="main-drower-table">
                      <h4> Task Title:</h4> <h5>{el && el.title}</h5>
                    </div>
                    {/* <div className="main-drower-table">
                    <h4> Purpose:</h4> <h5>{el && el.purpose}</h5>
                  </div> */}
                    {/* <div>
                  Create At :{" "}
                  <h5>{el && moment(el.created_at).format("DD/MM/YYYY")}</h5>
                </div> */}
                    <div className="main-drower-table">
                      <h4> Status: </h4>
                      <h5>{el ? el.is_status : "N/A"}</h5>
                    </div>
                    <div className="main-drower-table">
                      <h4> Client :</h4>
                      <h5>
                        {el && el.clientId ? (
                          <a href={`client-detail/${el.clientId._id}`}>
                            {el.clientId.name}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </h5>
                    </div>
                    <div className="main-drower-table">
                      <h4>Assest :</h4>
                      <h5>
                        {el && el.assetId ? (
                          <a href={`view-asset/${el.assetId._id}`}>
                            {el.assetId.property_title}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </h5>
                    </div>
                    <div className="main-drower-table">
                      <h4>Note of Completion :</h4>{" "}
                      <h5>{el ? el.notes : "N/A"}</h5>
                    </div>
                    <div className="main-drower-table">
                      <h4>Due Date:</h4>
                      <h5>
                        {el && moment(el.date_of_entry).format("DD/MM/YYYY")}
                      </h5>
                    </div>
                  </div>
                  <div>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginTop: "20px",
                      }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: "rgb(24 114 245)" }}>
                          <th
                            style={{
                              padding: "10px",
                              border: "1px solid #ddd",
                              color: "white",
                            }}
                          >
                            Date
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              border: "1px solid #ddd",
                              color: "white",
                            }}
                          >
                            Note
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {el.notes_multi &&
                          el.notes_multi.length > 0 &&
                          el.notes_multi?.map((item, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  padding: "10px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {el.notes_date
                                  ? new Date(
                                    el.notes_date[index]
                                  ).toLocaleDateString("sv-SE", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                  : ""}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {item}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
        </Drawer>
      </div>
      {showDelete && (
        <DeleteModal
          title={lang("Delete Task")}
          subtitle={lang("Are you sure you want to Delete this Task?")}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected({ recordId: null });
          }}
          onOk={() => onDelete(selected.recordId)}
        />
      )}
    </div>
  );
}
export default Index;
