import { PlusCircleOutlined, UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Image,
  Card,
  Tag,
  Menu,
  Dropdown,
  Space,
  Input,
  Upload,
  Typography,
  Switch,
  Slider,
  Form,
} from "antd";
import {
  UnorderedListOutlined,
  IdcardOutlined,
  UploadOutlined,
  WhatsAppOutlined,
  PlusOutlined,
  EnvironmentOutlined,
  MailOutlined,
  CloseOutlined,
  DownOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import moment from "moment";

import React, { useContext, useEffect, useRef, useState } from "react";

import EditIcon from "../../../assets/images/edit.png";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import Avatar from "../../../assets/images/avatar-1.png";
import deleteWhiteIcon from "../../../assets/images/icon/deleteWhiteIcon.png";
import Plus from "../../../assets/images/plus.svg";
import ConfirmationBox from "../../../components/ConfirmationBox";
import DeleteModal from "../../../components/DeleteModal";
import SectionWrapper from "../../../components/SectionWrapper";
import ViewLogs from "../../../components/ViewLogs";
import apiPath from "../../../constants/apiPath";
import clint22Img from "../../../assets/images/myclintimg.png";
import sampleFile from "../../../assets/images/sample.xlsx";
import { DownloadExcel } from "../../../components/ExcelFile";
import * as XLSX from "xlsx";

import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../../constants/reasons";
import { Months } from "../../../constants/var";
import { AppStateContext, useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import AddFrom from "./AddFrom";
import { ApproveStatus } from "../../Driver/_ChangeRequest";
import notfound from "../../../assets/images/not_found.png";
import { useNavigate, useParams } from "react-router";
import ChangeModal from "./_ChangeModal";
import View from "./View";
import PhoneInput from "react-phone-input-2";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";

const { Dragger } = Upload;
const { Title } = Typography;
// const options = [];
// for (let i = 0; i < 100000; i++) {
//   const value = `${i.toString(36)}${i}`;
//   options.push({
//     label: value,
//     value,
//     disabled: i === 10,
//   });
// }

const rooms = ["1", "2", "3", "4", "5", "6"];
// const options = [
//   {
//     text: "Buyer",
//     value: "buyer",
//   },
//   {
//     text: "Seller",
//     value: "seller",
//   },
//   {
//     text: "Renting",
//     value: "renting",
//   },
//   {
//     text: "Renter",
//     value: "renter",
//   },
// ];

const options = [
  { text: lang("Buyer"), value: "buyer" },
  { text: lang("Seller"), value: "seller" },
  { text: lang("Renting"), value: "renting" },
  { text: lang("Renter"), value: "renter" }
];

const filterOP = [
  { text: lang("New"), value: "new" },
  { text: lang("First Contact"), value: "first_contact" },
  { text: lang("In Progress"), value: "in_progress" },
  { text: lang("Active Client"), value: "active_client" },
  { text: lang("Not Relevant"), value: "not_relevant" }
];

const filterplt = [
  { text: lang("Residential"), value: "residential" },
  { text: lang("Commercial"), value: "commercial" }
];

const filtercl = [
  { text: lang("Client"), value: "client" },
  { text: lang("Lead"), value: "lead" }
];

// // const options = [
// //   { text: lang("Buyer"), value: "buyer" },
// //   { text: lang("Seller"), value: "seller" },
// //   { text: lang("Renting"), value: "renting" },
// //   { text: lang("Renter"), value: "renter" }
// // ];
// const filterOP = [
//   {
//     text: "New",
//     value: "new",
//   },
//   {
//     text: "First Contact",
//     value: "first_contact",
//   },
//   {
//     text: "In Progress",
//     value: "in_progress",
//   },
//   {
//     text: "Active Client",
//     value: "active_client",
//   },
//   {
//     text: "Not Relevant",
//     value: "not_relevant",
//   },
// ];
// const filterplt = [
//   {
//     text: "Residential",
//     value: "residential",
//   },
//   {
//     text: "Commercial",
//     value: "commercial",
//   },
// ];
// const filtercl = [
//   {
//     text: "Client",
//     value: "client",
//   },
//   {
//     text: "Lead",
//     value: "lead",
//   },
// ];

const filterat = [
  {
    text: "Real Estate",
    value: "real estate",
  },
  {
    text: "Personal Property",
    value: "personal property",
  },
];

const filterpt = [
  {
    text: "House",
    value: "house",
  },
  {
    text: "Apartment",
    value: "apartment",
  },
  {
    text: "Villa",
    value: "villa",
  },
];

const filtersn = [
  {
    text: "Whatsapp",
    value: "whatsapp",
  },
  {
    text: "Social Media",
    value: "social media",
  },
  {
    text: "By Phone",
    value: "by phone",
  },
];
const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { TabPane } = Tabs;

const { Text } = Typography;
const { Search } = Input;

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async (name) => {
    try {
      console.log(record);
      console.log(name);
      // const values = await form.validateFields();
      // toggleEdit();
      // handleSave({
      //   ...record,
      //   ...values,
      // });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <>
        {type === "text" && (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            <Input
              ref={inputRef}
              onChange={(e) => save(e.target.value)}
            // onBlur={save}
            // value={dataIndex}
            />
          </Form.Item>
        )}
        {type === "phone" && (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.reject(lang("Please enter phone number"));
                  }
                  if (!/^\d{8,15}$/.test(value)) {
                    return Promise.reject(
                      lang("Phone number must be between 8 and 15 digits")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInput
              inputProps={{
                name: { dataIndex },
                // ["countryCode", "mobileNumber"]
                required: true,
                autoFocus: false,
                autoFormat: false,
                autoComplete: "off",
                ref: { inputRef },
              }}
              isValid={(value, country) => {
                if (value.match(/1234/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else {
                  return true;
                }
              }}
              country={"il"}
              preferredCountries={["il"]}
              onBlur={save}
              onEnterKeyPress={save}
            />
          </Form.Item>
        )}
      </>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function Index() {
  const items = [
    {
      key: "1",
      label: (
        <button onClick={(e) => onChangeList(false)} className="list-item-btns">
          <IdcardOutlined />
          Card
        </button>
      ),
      onClick: () => handleMenuClick("client"),
    },
    {
      key: "2",
      label: (
        <button onClick={(e) => onChangeList(true)} className="list-item-btns">
          <UnorderedListOutlined />
          List
        </button>
      ),
      onClick: () => handleMenuClick("lead"),
    },
  ];

  const handleMenuClick = (option) => {
    console.log(option);
    // Add your custom logic here
    // fetchData(pagination, { clientType: option });
    setClient(option);
  };

  const heading = lang("My") + " " + lang("Clients");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();
  const navigate = useNavigate();

  const sectionName = lang("Clients");
  const routeName = "client";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const year = urlParams.get("year");

  const api = {
    status: apiPath.statusClient,
    uploadClientExcel: apiPath.uploadClientExcel,
    add: apiPath.addEditClient,
    list: apiPath.listClient,
    delete: apiPath.deleteClient,
    update: apiPath.editClient,
    user: apiPath.deletemultiClient,
  };

  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [selected, setSelected] = useState({ recordId: null });
  const [showMessage, setShowMessage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [deleteAllModal, showDeleteAllModal] = useState(false);
  const [priceLimit, setPriceLimit] = useState([0, 10000000]);

  const [clientCount, setclientCount] = useState(0);
  const [allCount, setallCount] = useState(0);
  const [leadCount, setleadCount] = useState(0);

  const [detailsVisible, setDetailsVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;

  const [clients, setClients] = useState();
  const [client, setClient] = useState(null);
  const [cities, setCities] = useState([]);
  const [listTrue, setListTrue] = useState(true);

  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [importFile, setImportFile] = useState([]);
  const [file, setFile] = useState([]);
  const fileType = [
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleImport = (info) => {
    const { file, fileList } = info;
    console.log("-----------------:: ", info);
    if (fileList?.length) {
      if (!fileType.includes(file.type)) {
        setError(lang("File format is not correct"));
        return false;
      }
      setError();

      const fileData = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        uid: file.uid,
        status: "done",
        originFileObj: file.originFileObj,
        size: file.size,
        type: file.type,
        percent: file.percent,
      };

      setFile([fileData]);
      parseExcel(file.originFileObj);
    } else {
      setError(lang("Missing Excel File."));
    }
  };

  const parseExcel = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Parse the worksheet data into an array of objects
      const parsedData = XLSX.utils.sheet_to_json(worksheet);

      // Filter data based on some conditions like below
      const filteredData = parsedData.filter((row) => {
        const email = row.email;
        const country_code = row.countryCode;
        const mobile_number = row.mobileNumber;
        const name = row.name;

        const shouldExclude = () => {
          let exclude = false;
          return exclude;
        };

        return !shouldExclude();
      });

      // Extract the remaining rows and set to setImportFile state
      setImportFile(filteredData);

      // Excluded rows and download the excel file with name of duplicate
      const excludedRows = parsedData.filter(
        (row) => !filteredData.includes(row)
      );
      if (excludedRows && excludedRows.length > 0) {
        const name = "Duplicate" + "-" + sectionName;
        // DownloadExcel(excludedRows, name)
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
  });

  const [city, setCity] = useState([]);

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("all");

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const [optionType, setOptionType] = useState([]);
  const [isType, setIsType] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const debouncedSearchFilter = useDebounce(selectedFilter, 300);

  const [selectedFiltersMulti, setSelectedFiltersMulti] = useState({
    size: [null, null],
  });
  const debouncedSearchFilterMulti = useDebounce(selectedFiltersMulti, 300);

  useEffect(() => {
    getCity();
  }, []);

  const getCity = () => {
    request({
      url: `/common/city`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data.list, "city");
        setCity(data.list);
      },
    });
  };

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };



  const onChangeList = (e) => {
    setListTrue(e);
  };

  const onDelete = (id) => {
    if (!id) {
      console.error("No record ID provided for deletion");
      return;
    }
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.list + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const defaultColumns = [
    {
      title: lang("Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <>
          <Image
            width={40}
            src={image ? image : Avatar}
            className="table-img"
          />
        </>
      ),
    },
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      type: "text",
      editable: true,
      width: 200,
      render: (_, { name, last_name, _id }) => {
        return name ? (
          <a
            onClick={() => navigate("/client-detail/" + _id)}
          >{`${name} ${last_name}`}</a>
        ) : (
          "-"
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: lang("Phone"),
      type: "phone",
      key: "mobileNumber",
      render: (_, { countryCode, mobileNumber }) => (
        <a
          href={`tel:${mobileNumber ? mobileNumber : ''}`}
          target="_blank"
          className="wishlisted wishlisted-new"
        >
          {mobileNumber ? mobileNumber : '--'}
        </a>
      ),




    },
    {
      title: lang("City"),
      dataIndex: "city",
      key: "city",
      width: 200,
      render: (_, { city }) => {
        return city ? city : "-";
      },
    },

    {
      title: lang('Client Type'),
      key: "optionType",
      filters: [
        {
          text: lang('Buyer'),
          value: "buyer",
        },
        {
          text: lang('Seller'),
          value: "seller",
        },
        {
          text: lang('Renting'),
          value: "renting",
        },
        {
          text: lang('Renter'),
          value: "renter",
        },
      ],
      render: (_, { optionType }) => {
        let statusText;
        let color;
        switch (optionType) {
          case "buyer":
            color = "green";
            statusText = lang('Buyer');
            break;
          case "seller":
            color = "blue";
            statusText = lang('Seller');
            break;
          case "renting":
            color = "red";
            statusText = lang('Renting');
            break;
          case "renter":
            color = "orange";
            statusText = lang('Renter');
            break;
          default:
            color = "olivedrab";
            statusText = lang('Unknown');
        }
        return (
          <h6>
            <Tag color={color} key={optionType}>
              {statusText ? statusText : "-"}
            </Tag>
          </h6>
        );
      },
    },
    {
      title: lang("Budget"),
      dataIndex: "budget",
      key: "budget",
      width: 200,
      render: (_, { budget }) => {
        return budget
          ? `${new Intl.NumberFormat().format(
            budget[0]
          )
          } ₪.--- ${new Intl.NumberFormat().format(budget[1])} ₪.`
          : "-";
      },
    },
    {
      title: lang("Size"),
      dataIndex: "size",
      key: "size",
      width: 200,
      render: (_, { size }) => {
        return size ? `${size[0]} ${lang('Meter')}. -- ${size[1]} ${lang('Meter')}.` : "-";
      },
    },
    {
      title: lang("No of Rooms"),
      dataIndex: "numberOfRooms",
      key: "numberOfRooms",
      width: 200,
      render: (_, { numberOfRooms }) => {
        return numberOfRooms ? numberOfRooms : "-";
      },
    },
    {
      title: lang('Status'),
      key: "is_type",
      filters: [
        {
          text: lang("New"),
          value: "new",
        },
        {
          text: lang("First Contact"),
          value: "first_contact",
        },
        {
          text: lang("In Progress"),
          value: "in_progress",
        },
        {
          text: lang("Active Client"),
          value: "active_client",
        },
        {
          text: lang("Not Relevant"),
          value: "not_relevant",
        },
      ],
      render: (_, { _id, is_type }) => {
        let statusText;

        switch (is_type) {
          case "new":
            statusText = lang("New");
            break;
          case "first_contact":
            statusText = lang("First Contact");
            break;
          case "in_progress":
            statusText = lang("In Progress");
            break;
          case "active_client":
            statusText = lang("Active Client");
            break;
          case "not_relevant":
            statusText = lang("Not Relevant");
            break;
          default:
            statusText = lang("Not Relevant");
        }
        return (
          <Select
            style={{ width: "100%" }}
            placeholder={lang(`Select Asset Status`)}
            value={is_type}
            onChange={(value) => handleChangeClientStatus(value, _id)}
            className="status-main-sold"
          >
            <Select.Option value="new">{lang("New")}</Select.Option>
            <Select.Option value="first_contact">
              {lang("First Contact")}
            </Select.Option>
            <Select.Option value="in_progress">
              {lang("In Progress")}
            </Select.Option>
            <Select.Option value="active_client">
              {lang("Active Client")}
            </Select.Option>
            <Select.Option value="not_relevant">
              {lang("Not Relevant")}
            </Select.Option>
          </Select>
        );
      },
    },

    {
      title: lang("Action"),
      key: "_id",
      dataIndex: "_id",
      render: (_, { _id, mobileNumber }) => {
        return (
          <div className="d-flex justify-content-start">
            <Tooltip
              title={lang("Whatsapp")}
              color={"purple"}
              key={"whatapp" + _id}
            >
              <a
                href={`https://api.whatsapp.com/send/?phone=${mobileNumber}`}
                className="whatsapp-cls"
                target="_blank"
              >
                <WhatsAppIcon />
              </a >
            </Tooltip >
            <Tooltip title={lang("Edit")} color={"purple"} key={"update" + _id}>
              <Button
                title={lang("Edit")}
                className="edit-cls btnStyle primary_btn"
                onClick={(e) => {
                  navigate(`/edit-client/${_id}`);
                  setSelected({ recordId: _id });
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title={lang("View")} color={"purple"} key={"View"}>
              <Button

                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  navigate(`/client-detail/${_id}`);
                  setSelected({ recordId: _id });
                  setDetailsVisible(true);
                }}
              >
                <RemoveRedEyeOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title={lang("Delete")} color={"purple"} key={"Delete"}>
              <Button
                title={lang("Delete")}
                className="btnStyle deleteDangerbtn"
                onClick={() => {
                  setSelected({ recordId: _id });
                  setShowDelete(true);
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title={lang("Search")} key={"Search"}>
              <Button
                title={lang("Search")}
                className="edit-cls btnStyle primary_btn"
                onClick={() => navigate("/client-detail/" + _id + "?setKey=2")}
              >
                <SearchOutlinedIcon />
              </Button>
            </Tooltip>
          </div >
        );
      },
    },
  ];
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
      }),
    };
  });

  const handleChangeClientStatus = (value, id) => {
    setLoading(true);
    console.log("Selected status Type: ", value, id);
    request({
      url: api.status + "/" + id,
      method: "PUT",
      data: { is_type: value },
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(lang("Status changed successfully"), Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleOptionTypeChange = (value) => {
    console.log(value);
    setOptionType(value);
  };

  const handleIsTypeChange = (value) => {
    console.log(value);
    setIsType(value);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [
    refresh,
    debouncedSearchText,
    filter,
    selectedTab,
    country?.country_id,
    debouncedSearchFilter,
    debouncedSearchFilterMulti,
  ]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterIsType = filters ? filters.is_type : null;
    const filterStatus = filters ? filters.optionType : null;
    console.log(debouncedSearchFilter);
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url:
        api.list +
        `?is_type=${filterIsType ? filterIsType.join(",") : ""}&optionType=${filterStatus ? filterStatus.join(",") : ""
        }&page=${pagination ? pagination.current : 1}&pageSize=${pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}${path ? `&status=1` : ""
        }&clientType=${selectedTab}&debouncedSearchFilter=${JSON.stringify(
          debouncedSearchFilter
        )}${debouncedSearchFilterMulti
          ? `&selectedFiltersMulti=${JSON.stringify(
            debouncedSearchFilterMulti
          )}`
          : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");

          const list = data.list.map((item) => ({ ...item, key: item._id }));

          setClients(list);

          setclientCount(data.clientCount);
          setallCount(data.allCount);
          setleadCount(data.leadCount);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const excelData =
    clients &&
    clients.length > 0 &&
    clients.map((row, index) => ({
      Name: row.name ? row.name : "-",
      mobileNumber: row.mobileNumber ? row.mobileNumber : "-",
      countryCode: row.countryCode ? row.countryCode : "-",
      City: row.city ? row.city : "-",
      Option_Type: row.optionType ? row.optionType : "-",
      budget:
        row?.budget?.length > 0
          ? row.budget[0] + "," + row.budget[1]
          : "0,10000",
      size: row?.size?.length > 0 ? row.size[0] + "," + row.size[1] : "0,10000",
      numberOfRooms: row.numberOfRooms ? row.numberOfRooms : "0",
      Is_Type: row.is_type ? row.is_type : "-",

      Email: row.email ? row.email : "-",
      Neighborhood: row.neighborhood ? row.neighborhood : "-",
      Client_Type: row.clientType ? row.clientType : "-",
      Property_Living_Type: row.propertylivType ? row.propertylivType : "-",
    }));

  const handleOtFilter = (pagination, filters) => {
    filters = {
      is_type: isType,
      optionType: optionType,
    };
    setIsModalOpen(false);
    fetchData(pagination, filters);
  };

  const handleChange = (pagination, filters) => {
    console.log(filters);
    fetchData(pagination, filters);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const DeleteAll = () => {
    if (!hasSelected) return;
    request({
      url: api.user + "/delete-all",
      method: "POST",
      data: {
        ids: selectedRowKeys,
      },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast("Data Deleted Successfully", Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [uploadModal, setUploadModal] = useState(false);
  const showUploadModal = () => {
    setUploadModal(true);
  };
  const handleOk01 = () => {
    setUploadModal(false);
  };
  const handleCancel01 = () => {
    setUploadModal(false);
  };

  const onImport = () => {
    if (error) {
      return;
    }
    if (file?.length) {
      setLoading(true);
      const payload = {};
      payload.import_file = importFile;
      request({
        url: api.uploadClientExcel,
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            // hide();
            fetchData({ ...pagination, current: 1 });
            setUploadModal(false);
            // refresh();
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error.response.data.errors[0].message, Severty.ERROR);
          setLoading(false);
        },
      });
    } else {
      ShowToast(lang("Please Select an Excel File"), Severty.ERROR);

    }
  };
  const menuItems = [
    // { text: lang("Name"), key: "name", label: lang("Name") },
    // { text: lang("Email"), key: "email", label: lang("Email") },
    { text: lang("City"), key: "city", label: lang("City") },
    // { text: lang("Client/Lead"), key: "client_lead", label: lang("Client/Lead") },
    { text: lang("Client Type"), key: "clientType", label: lang("Client Type") },
    // { text: lang("Status"), key: "status", label: lang("Status") },
    // { text: lang("Asset Type"), key: "assetType", label: lang("Asset Type") },
    // { text: lang("Property Type"), key: "propertyType", label: lang("Property Type") },
    // { text: lang("Source Name"), key: "SourceName", label: lang("Source Name") },
    { text: lang("No of Rooms"), key: "numberOfRooms", label: lang("No of Rooms") },
    { text: lang("Budget"), key: "budget", label: lang("Budget") },
    { text: lang("Size"), key: "size", label: lang("Size") },
    // {
    //   text: lang("Property Living Type"),
    //   key: "propertylivType",
    //   label: lang("Property Living Type"),
    // },
  ];

  // const menuItems = [
  //   // { text: "Name", key: "name", label: "Name" },
  //   // { text: "Email", key: "email", label: "Email" },
  //   { text: "City", key: "city", label: "City" },
  //   // { text: "Client/Lead", key: "client_lead", label: "Client/Lead" },
  //   { text: "Client Type", key: "clientType", label: "Client Type" },
  //   // { text: "Status", key: "status", label: "Status" },
  //   // { text: "Asset Type", key: "assetType", label: "Asset Type" },
  //   // { text: "Property Type", key: "propertyType", label: "Property Type" },
  //   // { text: "Source Name", key: "SourceName", label: "Source Name" },
  //   { text: "No of Rooms", key: "numberOfRooms", label: "No of Rooms" },
  //   { text: "budget", key: "budget", label: "budget" },
  //   { text: "size", key: "size", label: "size" },
  //   // {
  //   //   text: "Property Living Type",
  //   //   key: "propertylivType",
  //   //   label: "Property Living Type",
  //   // },
  // ];

  // const handleMeClick = (e) => {
  //   if (!selectedFilters.includes(e.key)) {
  //     const newSelectedFilters = [...selectedFilters, e.key];
  //     setSelectedFilters(newSelectedFilters);

  //     const filtersObject = newSelectedFilters.reduce((acc, key) => {
  //       acc[key] = null;
  //       return acc;
  //     }, {});

  //     setSelectedFilter(filtersObject);
  //   }
  // };

  // const handleClose = (removedFilter) => {
  //   const newFilters = selectedFilters.filter(
  //     (filter) => filter !== removedFilter
  //   );
  //   setSelectedFilters(newFilters);

  //   const filtersObject = newFilters.reduce((acc, key) => {
  //     acc[key] = null;
  //     return acc;
  //   }, {});
  //   setSelectedFilter(filtersObject);
  // };

  const handleMeClick = (e) => {
    if (!selectedFilters.includes(e.key)) {
      const newSelectedFilters = [...selectedFilters, e.key];
      setSelectedFilters(newSelectedFilters);
      setAddFiltersVisible(true);
      setSelectedFilter((prevSelectedFilter) => ({
        ...prevSelectedFilter,
        [e.key]: null,
      }));
    }
  };

  const handleClose = (removedFilter) => {
    const newFilters = selectedFilters.filter(
      (filter) => filter !== removedFilter
    );
    setSelectedFilters(newFilters);

    setSelectedFilter((prevSelectedFilter) => {
      const { [removedFilter]: _, ...rest } = prevSelectedFilter;
      return rest;
    });
    setSelectedFiltersMulti((prevSelectedFilterMulti) => {
      const { [removedFilter]: _, ...rest } = prevSelectedFilterMulti;
      return rest;
    });
  };

  const menu = (
    <Menu onClick={handleMeClick}>
      {menuItems.map((item) => (
        <Menu.Item text={item.text} key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  const [isToggled, setIsToggled] = useState(false);

  const [addFiltersVisible, setAddFiltersVisible] = useState(false); // State to manage visibility

  const handleToggleFilters = () => {
    setAddFiltersVisible(!addFiltersVisible); // Toggle visibility
    setIsToggled(!isToggled);
  };

  // const handleSetVal = (value) => {
  //   console.log(value);
  // };

  // const menu02 = (value) => (
  //   <div className="menu-items-nav">
  //     <Input
  //       placeholder={value}
  //       value={selectedFilter ? selectedFilter.value : ""}
  //       onChange={handleSetVal(value)}
  //     />
  //   </div>
  // );

  const handleSetVal = (key, value) => {
    console.log(`Key: ${key}, Value: ${value}`);
    console.log(selectedFilter);
    setSelectedFilter((prevSelectedFilter) => ({
      ...prevSelectedFilter,
      [key]: value,
    }));
    setAddFiltersVisible(true);
    // fetchData(pagination, debouncedSearchFilter);
  };
  const handleSetMultipleSize = (key, value, index) => {
    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };

      newSelectedFilters[key] = [...(prevSelectedFilters[key] || [])];
      newSelectedFilters[key][index] = value;

      console.log(newSelectedFilters);

      return newSelectedFilters;
    });
  };
  const setPriceLimitFilter = (value) => {
    console.log(value);
    setPriceLimit(value);
    setSelectedFiltersMulti((prevSelectedFilters) => {
      const newSelectedFilters = { ...prevSelectedFilters };

      newSelectedFilters["budget"] = [...(prevSelectedFilters["budget"] || [])];
      newSelectedFilters["budget"] = value;

      console.log(newSelectedFilters);

      return newSelectedFilters;
    });
  };

  // const changableFeilds = (value) => {
  //   switch (value) {
  //     case "city":
  //       return "City";
  //     case "clientType":
  //       return "Client Type";
  //     case "numberOfRooms":
  //       return "Rooms";
  //     case "budget":
  //       return "Budget";
  //     case "size":
  //       return "Size";
  //     default:
  //       return value;
  //   }
  // };
  const changableFeilds = (value) => {
    switch (value) {
      case "city":
        return lang("City");
      case "clientType":
        return lang("Client Type");
      case "numberOfRooms":
        return lang("Rooms");
      case "budget":
        return lang("Budget");
      case "size":
        return lang("Size");
      default:
        return lang(value); // Ensure the default value is also translated
    }
  };
  const menu02 = (key) => {
    if (key === "assetType") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="optionType"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filterat.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }
    if (key === "propertyType") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="optionType"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filterpt.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }
    if (key === "sourceName") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="optionType"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filtersn.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }
    if (key === "clientType") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="optionType"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={options.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }

    if (key === "client_lead") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="client_lead"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filtercl.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }

    if (key === "propertylivType") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="propertylivType"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filterplt.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }

    if (key === "status") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            key="status"
            onChange={(selectedOption) => handleSetVal(key, selectedOption)}
            options={filterOP.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </div>
      );
    }

    if (key === "city") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            showSearch
            key="status"
            onChange={(e) => handleSetVal(key, e)}
            options={city.map((option) => ({
              label: option,
              value: option,
            }))}
          />
        </div>
      );
    }

    if (key === "numberOfRooms") {
      return (
        <div className="drop-mask">
          <Select
            placeholder={lang("Please select")}
            showSearch
            key="numberOfRooms"
            onChange={(e) => handleSetVal(key, e)}
            options={rooms.map((option) => ({
              label: option,
              value: option,
            }))}
          />
        </div>
      );
    }

    if (key === "size") {
      return (
        <div className="drop-mask">
          <div className="two-columns">
            <Input
              id="minSize"
              name="size"
              placeholder="minimum"
              onChange={(e) => handleSetMultipleSize("size", e.target.value, 0)}
            />
            <Input
              id="maxSize"
              name="size"
              placeholder="maximum"
              onChange={(e) => handleSetMultipleSize("size", e.target.value, 1)}
            />
          </div>
        </div>
      );
    }

    if (key === "budget") {
      return (
        <div className="drop-mask">
          <div className="price-range">
            <Slider
              className="slaider-row"
              min={1}
              max={10000000}
              range={{
                draggableTrack: true,
              }}
              tooltip={{ open: false }}
              value={priceLimit}
              onChange={setPriceLimitFilter} // use onChange instead of onchange
            />
            <div className="count-box">
              <div className="count-detail">
                <span>
                  {priceLimit
                    ? new Intl.NumberFormat().format(priceLimit[0])
                    : "0"}
                  {"  "}₪
                </span>
                <span>
                  {priceLimit
                    ? new Intl.NumberFormat().format(priceLimit[1])
                    : "100,000"}
                  {"  "}₪
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (key === "email" || key === "name") {
      return (
        <div className="drop-mask">
          <div className="menu-items-nav">
            <Input
              placeholder={changableFeilds(key)}
              value={selectedFilter ? selectedFilter[key] : ""}
              onChange={(e) => handleSetVal(key, e.target.value)}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <div className="main-hh-headding">
          <div className="headding-cc">{lang("My Client")}</div>
          <Breadcrumb className="bread-menu">
            <Breadcrumb.Item className="bread-items" href="#">
              {lang("Dashboard")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="bread-items" href="#">
              {lang("My Client")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="im-ex-btn">
          <Button
            title={lang("Import")}
            className="man-reset-bttn man-list-bttn "
            onClick={showUploadModal}
          >
            {/* <FileUploadOutlinedIcon /> */}
            <FileDownloadOutlinedIcon />
            {lang("Import")}
          </Button>
          {/* <Upload className="man-upload-btn" >
                  <Button className="man-btn-upload" icon={<FileUploadOutlinedIcon />}> </Button>
                </Upload> */}

          <Button
            title={lang("Export")}
            className="man-reset-bttn man-list-bttn "
            onClick={(e) => DownloadExcel(excelData, sectionName)}
          >
            {/* <FileDownloadOutlinedIcon /> */}
            <FileUploadOutlinedIcon />
            {lang("Export")}
          </Button>
        </div>
      </Row>
      <Card>
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <div className="w-100 d-flex align-items-baseline text-head_right_cont">
              <div className="pageHeadingSearch clint-input ">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("Search by name")}
                  value={searchText}
                  onChange={onSearch}
                  allowClear
                />
              </div>

              <Button
                onClick={() => {
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  });
                  setSearchText("");
                }}
                type="primary"
                icon={<RestartAltOutlinedIcon />}
                className="edit-cls"
              >
                {/* {lang("Reset")} */}
              </Button>
              <div className="sub-headding02">
                <Button
                  title={lang("report")}
                  className="report-btn"
                  onClick={() => navigate("/reports")}
                >
                  {lang("Report")}
                </Button>
              </div>
              <div className="lead-clint-drop">
                <Switch
                  checkedChildren={lang("Card")}
                  unCheckedChildren={lang("List")}
                  checked={listTrue}
                  onClick={(listTrue) => onChangeList(listTrue)}
                  className="main-mecu-switch"
                />
              </div>

              <Dropdown
                overlay={menu}
                trigger={["click"]}
              // onClick={handleToggleFilters}
              >
                <Button className="report-btn">
                  <span className="add-more">
                    <AddCircleOutlineOutlinedIcon />
                  </span>
                  {lang("Filter")}
                </Button>
              </Dropdown>
              {/* <Button
                title="Filter"
                className="report-btn"
                onClick={handleToggleFilters} // Toggle visibility on button click
              >
                <ExpandMoreOutlinedIcon
                  className={` ${isToggled ? "angle-up" : "angle-dawn"}`}
                />
              </Button> */}
              <div className="lg-adddesktop">
                <Button
                  className="primary_btn btnStyle "
                  onClick={(e) => {
                    console.log(e);
                    navigate(`/create-client`);
                    setVisible(true);
                    setSearchText("");
                  }}
                >
                  <span className="add-Ic ">
                    <AddCircleOutlineOutlinedIcon />
                  </span>
                  {lang("")} {sectionName}
                </Button>
              </div>
              {hasSelected && (
                <Button
                  className="man-list-bttn edit-cls"
                  onClick={() => {
                    showDeleteAllModal(true);
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                  {/* {lang("Delete All")} */}
                </Button>
              )}
            </div>
          </Col>
          <Col xs={24} xl={24}>
            <div className="mb-add-mobile">
              <Button
                className="add-inc-btn"
                onClick={(e) => {
                  console.log(e);
                  navigate(`/create-client`);
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <AddCircleOutlineOutlinedIcon />
              </Button>
            </div>
            <div>
              <div
                className={`add-filters ${addFiltersVisible ? "show" : "hide"}`}
              >
                <Space className="flex-wrap">
                  {selectedFilters.map((filter) => (
                    <Tag
                      className="ftter-main-btn"
                      key={filter}
                      closable
                      closeIcon={<CloseOutlined />}
                      onClose={() => handleClose(filter)}
                    >
                      {filter && (
                        <>
                          <Dropdown
                            overlay={menu02(filter)}
                            trigger={["click"]}
                          >
                            <Button className="filter-btns">
                              {selectedFilter[filter]
                                ? changableFeilds(filter) +
                                " : " +
                                selectedFilter[filter]
                                : changableFeilds(filter)}
                            </Button>
                          </Dropdown>
                          {/* <Button className="filter-btns">{filter}</Button> */}
                        </>
                      )}
                    </Tag>
                  ))}
                  {/* <Dropdown overlay={menu} trigger={["click"]}>
                    <Button className="man-reset-bttn man-list-bttn ">
                      <AddCircleOutlineOutlinedIcon />
                    </Button>
                  </Dropdown> */}
                </Space>
              </div>
            </div>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={selectedTab}
          className="myclients-tab"
          onTabClick={(e) => {
            setSelectedTab(e);
          }}
        >
          <TabPane
            tab={
              <span className="myclients-tab-button"> {lang("All")} ({allCount})</span>
            }
            key="all"
            className="myclients-tab-body"
          ></TabPane>
          <TabPane
            tab={
              <span className="myclients-tab-button">
                {lang("Clients")} ({clientCount})
              </span>
            }
            key="client"
            className="myclients-tab-body"
          ></TabPane>
          <TabPane
            tab={
              <span className="myclients-tab-button"> {lang("Leads")} ({leadCount})</span>
            }
            key="lead"
            className="myclients-tab-body"
          ></TabPane>
        </Tabs>
        <Row gutter={[24, 16]}>
          {listTrue ? (
            <Col span={24} md={24}>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  components={components}
                  rowSelection={rowSelection}
                  onChange={handleChange}
                  dataSource={clients}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                    // showSizeChanger: true,
                    // showQuickJumper: true,
                    // pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  className="ant-border-space"
                />
              </div>
            </Col>
          ) : (
            clients?.map((item) => <Client data={item} key={item._id} />)
          )}
        </Row>
      </Card>
      {visible && (
        <AddFrom
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {detailsVisible && (
        <View
          section={sectionName}
          api={api}
          show={detailsVisible}
          hide={() => {
            setSelected();
            setDetailsVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {showDelete && (
        <DeleteModal
          title={lang("Delete Client")}
          subtitle={lang("Are you sure you want to Delete this Client?")}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected({ recordId: null });
          }}
          onOk={() => onDelete(selected.recordId)}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${selected?.is_active ? lang(`Block`) : lang(`UnBlock`)
            } ${lang("User")}`}
          subtitle={`${lang("Are you sure you want to")} ${selected?.is_active ? lang(`block`) : lang(`unblock`)
            } ${lang("this user?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
          reasons={selected?.is_active ? BlockCustomerReasons : []}
        />
      )}

      {deleteAllModal && (
        <DeleteModal
          title={lang("Delete All Users")}
          subtitle={lang(`Are you sure you want to Delete all Users?`)}
          show={deleteAllModal}
          hide={() => {
            showDeleteAllModal(false);
            // setSelectedIds([]);
            setSelectedRowKeys([]);
          }}
          onOk={() => DeleteAll()}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}

      {/* <Modal
        title="Filter"
        className="tab_modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form.Item label={"title"} className="mt-3">
          <Select
            mode="multiple"
            placeholder={lang("Please select")}
            defaultValue={[]}
            key="optionType"
            onChange={handleChange}
            options={options}
          />
        </Form.Item>
        <Form.Item label={"title"} className="mt-3">
          <Select
            mode="multiple"
            placeholder={lang("Please select")}
            defaultValue={[]}
            key="is_type"
            onChange={handleChange}
            options={filterOP}
          />
        </Form.Item>
      </Modal> */}

      <Modal
        title={lang("Filter")}
        className="tab_modal"
        open={isModalOpen}
        onOk={handleOtFilter}
        onCancel={handleCancel}
      >
        <Form.Item label={"Client Type"} className="mt-3">
          <Select
            mode="multiple"
            placeholder={lang("Please select")}
            defaultValue={[]}
            key="optionType"
            onChange={handleOptionTypeChange}
            options={options.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </Form.Item>
        <Form.Item label={"Status"} className="mt-3">
          <Select
            mode="multiple"
            placeholder={lang("Please select")}
            defaultValue={[]}
            key="is_type"
            onChange={handleIsTypeChange}
            options={filterOP.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </Form.Item>
      </Modal>

      {/* <Modal
        title="Upload CSV/Excel File"
        className="tab_modal"
        open={uploadModal}
        onOk={handleCsvUpload(e.target.file)}
        onCancel={handleCancel01}
      >
        <div className="dawnload-btn">
          <a href={sampleFile} download="sample.xlsx">
            <Button className="dawn-bt">
              Download Sample <ArrowDownwardOutlinedIcon />
            </Button>
          </a>
        </div>
        <Form.Item label={"Upload"} className="mt-2">
          <Upload
          // beforeUpload={beforeUpload}
          // customRequest={handleCsvUpload}
          >
            <Button className="btn-upload" icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
      </Modal> */}

      <Modal
        open={uploadModal}
        title={lang("Import Excel")}
        okText={lang("Import")}
        cancelText={lang("Cancel")}
        onCancel={handleCancel01}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
        }}
      >
        <Form id="create" form={form} onFinish={onImport} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item name="import_file">
                <div className="text-center">
                  <Button

                    title={lang("Download Sample File")}
                    href={sampleFile}
                    download="sample.xlsx"
                  // onClick={() => {
                  //   SampleFileDownload(sectionName);
                  //   console.log(sectionName);
                  // }}
                  >
                    <i class="fas fa-download"></i>&nbsp;&nbsp;{lang("Download Sample File")}
                  </Button>
                </div>

                <div className="text-center mt-1 mb-2">
                  <span className="text-danger" style={{ fontWeight: "500" }}>
                    {lang("The column names in the sheet should match those given in the sample file.")}
                  </span>
                </div>

                {/* <Divider orientation="center" orientationMargin={0} className="devider-color">OR</Divider> */}

                <Dragger
                  fileList={file}
                  onRemove={(e) => setFile([])}
                  maxCount={1}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleImport}
                  customRequest={(e) => null}
                >
                  <p className="ant-upload-drag-icon">
                    {" "}
                    <InboxOutlined />{" "}
                  </p>
                  <p className="ant-upload-text">
                    {lang("Click or Drag File to This Area to Upload")}
                  </p>
                  <p className="ant-upload-hint">
                    {lang("Please Select an Excel File.")}
                  </p>
                </Dragger>
                {error && (
                  <div style={{ color: "red", padding: 5 }}>{error}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

const Client = ({ data, name: sectionHeading }) => {
  const navigate = useNavigate();
  const { country } = useAppContext();
  const { request } = useRequest();
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const year = urlParams.get("year");
  const { showConfirm } = ConfirmationBox();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState({ recordId: null });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [cities, setCities] = useState([]);
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
  });

  const api = {
    delete: apiPath.deleteClient,
  };

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };
  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [refresh, debouncedSearchText, filter, client, country?.country_id]);

  const fetchData = (pagination, filters) => {
    // const filterActive = filters ? filters.is_active : null;
    const filterActive = filters ? filters.clientType : null;
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url:
        api.list +
        `?page=${pagination ? pagination.current : 1}&pageSize=${pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}${path ? `&status=1` : ""
        }&clientType=${client}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");
          setClients(data.list);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const onDelete = (id) => {
    if (!id) {
      console.error("No record ID provided for deletion");
      return;
    }
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  return (
    <Col span={24} lg={12} xxl={8} xl={12} md={12} sm={24}>
      <div
        className="main-card-all OnMouseP"
        onClick={() => navigate(`/client-detail/${data._id}`)}
      >
        <div className="main-card-headding">
          <div className="main-clint-profile">
            <Image
              src={data.image ? data.image : Avatar}
              alt="Profile"
              width={80}
              height={80}
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div
            className="for-sale-main-div-fffff"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="ass-view-btn">
              <Button
                type="default"
                shape="round"
                className="add-btn"
                onClick={() => {
                  navigate(`/edit-client/${data._id}`);
                }}
              >
                <EditOutlinedIcon />
              </Button>
              <Button
                type="default"
                shape="round"
                className="add-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelected({ recordId: data._id });
                  setShowDelete(true);
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </Button>
              <Button
                type="default"
                shape="round"
                className="add-btn"
                onClick={() => {
                  navigate(`/client-detail/${data._id}`);
                }}
              >
                {" "}
                <RemoveRedEyeOutlinedIcon />{" "}
              </Button>

              <a
                href={`https://api.whatsapp.com/send/?phone=${data.mobileNumber}`}
                target="_blank"
                type="button"
                title={lang('Whatsapp')}
                key={"whatapp" + data._id}
                className="wishlisted"
              >
                <svg
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="WhatsAppIcon"
                >
                  <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                </svg>
              </a>

              <Button
                title={lang('Search')}
                className="edit-cls btnStyle primary_btn"
                onClick={() =>
                  navigate("/client-detail/" + data._id + "?setKey=2")
                }
              >
                <SearchOutlinedIcon />
              </Button>
            </div>
          </div>
        </div>

        <div className="card-detail-client">
          <div>
            <Text strong style={{ fontSize: "16px" }}>
              {data?.name + data?.last_name
                ? data.name + " " + data?.last_name
                : "-"}
            </Text>
            {/* <div
              title={lang("Whatsapp")}
              color={"purple"}
              key={"whatapp" + data._id}
            >
              <a
                href={`https://api.whatsapp.com/send/?phone=${data.mobileNumber}`}
                className="whatsapp-cls"
                target="_blank"
              >
                <WhatsAppOutlined />
              </a>
            </div> */}
            {/* <Space>
              <WomanOutlined style={{ color: "#8c8c8c" }} />
              <Text type="secondary">{getGenderLabel(data.gender)}</Text>
            </Space> */}
          </div>
          <Space direction="vertical" size="small" className="for-grid-1">
            <Space className="card-big-icon">

              <a
                href={`tel:${data.mobileNumber ? data.mobileNumber : ''}`}
                target="_blank"

              >
                <LocalPhoneOutlinedIcon />


                <div className="main-active-status">
                  <Text>
                    {data?.mobileNumber}
                  </Text>
                </div>
              </a>
            </Space>
            <Space className="card-big-icon">
              <EmailOutlinedIcon />
              <Text>{data.email ? data.email : "-"}</Text>
            </Space>
            <Space className="card-big-icon">
              <BedOutlinedIcon />
              <Text>{data.numberOfRooms ? data.numberOfRooms : "-"}</Text>
            </Space>
            <Space className="card-big-icon">
              <TuneOutlinedIcon />
              <Text>
                {data.optionType
                  ? (data.optionType === "buyer" ? lang('Buyer') :
                    data.optionType === "seller" ? lang('Seller') :
                      data.optionType === "renting" ? lang('Renting') :
                        data.optionType === "renter" ? lang('Renter') :
                          lang('Unknown'))
                  : "-"}
              </Text>
            </Space>
            <Space className="card-big-icon">
              <SavingsOutlinedIcon />
              <Text>
                {data.budget
                  ? new Intl.NumberFormat().format(data?.budget[0]) +
                  "---" +
                  new Intl.NumberFormat().format(data?.budget[1])
                  : "-"}
              </Text>
            </Space>
            <Space className="card-big-icon">
              <ContactPageOutlinedIcon />
              <Text>
                {data.is_type
                  ? (data.is_type === "new" ? lang("New") :
                    data.is_type === "first_contact" ? lang("First Contact") :
                      data.is_type === "in_progress" ? lang("In Progress") :
                        data.is_type === "active_client" ? lang("Active Client") :
                          data.is_type === "not_relevant" ? lang("Not Relevant") :
                            lang("Not Relevant"))
                  : "-"}
              </Text>
            </Space>
            <Space className="card-big-icon">
              <LocationOnOutlinedIcon />
              <Text>{data?.city ? data.city : "-"}</Text>
            </Space>
          </Space>
        </div>
        {showDelete && (
          <DeleteModal
            title={lang("Delete Client")}
            subtitle={lang("Are you sure you want to Delete this Client?")}
            show={showDelete}
            hide={() => {
              setShowDelete(false);
              setSelected({ recordId: null });
            }}
            onOk={() => onDelete(selected.recordId)}
          />
        )}
        {/* 
        <div style={{}}>
          <Button
            type="primary"
            shape="round"
            className="view-btnnn"
            onClick={(e) => {
              navigate(`/client-detail/${data._id}`);
              // setVisible(true);
              // setSearchText("");
            }}
          >
            View
          </Button>
          <Button
            type="default"
            shape="round"
            className="edit-btnnn"
            onClick={() => navigate(`/edit-client/${data._id}`)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            shape="round"
            className="delete-btnnn"
            onClick={() => {
              setSelected({ recordId: data._id });
              setShowDelete(true);
            }}
          >
            Delete
          </Button>
        </div> */}
      </div>
    </Col>
  );
};

export default Index;
